import { Box, Button, Icon } from "@chakra-ui/react";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";

import WaveSurfer from "wavesurfer.js";

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "#BAA1F0",
  progressColor: "#7353BA",
  cursorColor: "#7353BA",
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 150,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
});

export default function Waveform({ url }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(1);

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", function () {
      // https://wavesurfer-js.org/docs/methods.html
      // wavesurfer.current.play();
      // setPlay(true);

      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy();
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const onVolumeChange = (e) => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  return (
    <div>
      <Box
        textAlign={"center"}
        display={"flex"}
        // mt={"100px"}
        alignItems={"center"}
        w={"100%"}
        h="300px"
      >
        <div style={{ width: "100%" }} id="waveform" ref={waveformRef} />
      </Box>
      <Box h="50px" />
      <Box textAlign={"center"} className="controls">
        <Button
          rounded={"100%"}
          type="button"
          bg={"#7353BA"}
          h="60px"
          w="60px"
          color={"white"}
          shadow={"md"}
          onClick={handlePlayPause}
        >
          {!playing ? <Icon as={PlayIcon} /> : <Icon as={PauseIcon} />}
        </Button>
      </Box>
    </div>
  );
}
