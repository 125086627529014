import { Box, FormLabel, Heading, Image, Text } from "@chakra-ui/react";
import { closeMedicalUpload, medicalCardUpload } from "../../../images";
import { useState } from "react";

export default function MedicalUpload({ id, description, callback }) {
    const [uploadUrl, setUploadUrl] = useState("")
    const fileUploadChange = (e) => {
        if (e.target.files) {
            const file = e.target.files[0]
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    setUploadUrl(reader.result);
                },
                false,
            );
            if (file) {
                callback(file)
                reader.readAsDataURL(file);
            }
        } else {
            setUploadUrl("")
        }
    }
    return (
        <Box p="0" m="0" position={"relative"}>
            <FormLabel m="0" position={"relative"} htmlFor={uploadUrl == ""? id : ""} rounded={"18px"} border={"#C4C4C4"} borderStyle={"dashed"} borderWidth={uploadUrl == "" ? "1px" :"0px"} shadow={"md"} >
                {
                    uploadUrl !== ""
                        ?
                        <Image rounded={"18px"} src={uploadUrl} h={{base : "115px", md : "150px"}} width={"100%"} />
                        :
                        <Box textAlign={"center"} h={{base : "115px", md : "150px"}} display={"flex"} flexDir={"column"} alignItems={"center"} justifyContent={"center"}>
                            <Image src={medicalCardUpload} boxSize={"35px"} />
                            <Box h="8px" />
                            <Heading fontSize={"14px"} color={"#FF6E57"} fontWeight={"600"}>Take a Photo or Upload</Heading>
                            <Box h="8px" />
                            <Text color={"#747A8B"} fontSize={"12px"}>{description}</Text>
                        </Box>
                }
                <input onChange={(e) => fileUploadChange(e)} hidden type="file" name="upload_dp" id={id} accept='.png,.jpg,.jpeg,.webp' />
            </FormLabel>
            {uploadUrl && <Image cursor={"pointer"} onClick={()=> setUploadUrl("")} roundedBottomRight={"10px"} position={"absolute"} right="0" bottom={"0"} src={closeMedicalUpload} h={"50px"} /> }

        </Box>
    )
}