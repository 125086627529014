import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import { Link as ReachLink, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import NoTask from "../../components/dashboard/no_task";
import JornerCard from "../../components/dashboard/jorner_card";
import { ChevronRightIcon, PlusIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import AddJorner from "../../components/dashboard/add_jorner_card";
import TaskCard from "../../components/dashboard/task_card";
import CreateTaskModal from "./create_task_modal";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { PencilSquareIcon, TrashIcon, CalendarDaysIcon, UserCircleIcon, FlagIcon, BellAlertIcon } from "@heroicons/react/24/solid";
import { httpGetWithTokenRest, httpPatchWithTokenRest } from "../../utils/http_utils";
import NoTabItem from "../../components/dashboard/no_tab_item";
import moment from "moment";
import RemoveTask from "./remove_task_modal";
import CompletedTask from "./completed_task";

function ViewTask() {
  const [isLoading, setIsLoading] = useState(true)
  const [task, setTask] = useState({})
  const [noTask, setNoTask] = useState(false)
  const params  = useParams();
  const navigate = useNavigate()
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const {
    isOpen: isOpenComplete,
    onOpen: onOpenComplete,
    onClose: onCloseComplete,
  } = useDisclosure();
  useEffect(() => {
    loadConstants()
  }, [])

  const loadConstants = async () => {
    var j = await httpGetWithTokenRest(`tasks/${params.id}`);
    if(j.status == "success"){
      setIsLoading(false)
      setTask(j.data)
      console.log(j.data)
    }else{
      setNoTask(true)
    }
  }

  const getMinutes = (date1, date2) => {
    var d1 = new Date(date1)
    var d2 = new Date(date2)
    var d3 =  Math.abs(d2 - d1);
    return Math.ceil(d3 / (1000 * 60)); 
    // return "";
  }

  const getMoment = (date) => {
    var d = ((date).replace("T", " ")).replace("Z", "");
    return moment(d).format("h:mm:ss a")
  }
  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      <RemoveTask 
      isOpen={isOpenCreate}
      onClose={onCloseCreate}
      task={task.id}
      />

      <CompletedTask 
      task={task.name}
      isOpen={isOpenComplete}
      onClose={onCloseComplete}
      />

      {
      isLoading ?
      ""
      :
      <>
      <Spacer height={"22px"} />
      {
      noTask ? 
      <NoTabItem hasButton={false} title="No Task found" />
        :
      <Box>
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{base : "flex-start", md : "center"}}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems={"center"}>
            <AppLink
              hasLeftIcon={true}
              leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
              shadow="none"
              padding="0"
              text=""
              background="transparent"
              color="black"
              width="fit-content"
              textDecoration="none"
              href="/tasks"
            />
            <Box width="2px" />
            <Heading
              color={"black"}
              fontSize={"20px"}
              fontWeight={"600"}
              as={"h3"}
            >
              {task.name}
            </Heading>
          </Box>
          {
            task.status == "to do" ?
          <Flex gap="3"
           alignItems={"center"}>
            <AppLink
              hasLeftIcon={true}
              shadow="none"
              height="35px"
              leftIcon={<Icon as={PencilSquareIcon} fontSize="20px" />}
              padding="0 15px"
              text={window.innerWidth < 900 ? "" : "Edit Task"}
              background="white"
              color="paragraph"
              textDecoration="none"
              href={"/tasks/edit/"+task.id}
            />
            <AppLink
              shadow="none"
              padding="0 15px"
              text="Complete Task"
              height="35px"
              background="success"
              color="white"
              textDecoration="none"
              href={"#?"}
              onclick={ async () =>{
                var response = await httpPatchWithTokenRest("tasks/status/"+task.id)
                if(response.error){

                }else{
                  onOpenComplete()
                }
              }}
            />
            <AppLink
              hasLeftIcon={true}
              onclick={onOpenCreate}
              shadow="none"
              height="40px"
              leftIcon={<Icon as={TrashIcon} fontSize="20px" />}
              padding="0 15px"
              text={window.innerWidth < 900 ? "" : "Delete"}
              background="#DB504A"
              color="white"
              textDecoration="none"
              href="#?"
            />
          </Flex>
          : ""}
        </Flex>
        <Box h="10px" />

        <Box
          shadow={"sm"}
          background="white"
          padding="30px"
          borderRadius={"16px"}
        >
          <Box my="2">
              <Heading fontSize={"13px"} fontWeight="600">
                {task.name}
              </Heading>
            <Box height="5px" />
            <Text color="paragraph" fontSize="13px">
             {task.description}
            </Text>
            <Box height="10px" />
            <Flex>
                <Flex alignItems={'center'} width="100%">
                    <Icon as={Squares2X2Icon} fontSize="14px" />
                    <Box width="10px" />
                    <Text fontSize={'13px'}>{task.category}</Text>
                </Flex>

             
            </Flex>
            <Box height="10px" />
            <Divider color={"#EBECEF"} />
            <Box height="10px" />
            <Flex>
                <Flex alignItems={'center'} width="100%">
                    <Icon as={CalendarDaysIcon} fontSize="14px" />
                    <Box width="10px" />
                    <Text fontSize={'13px'}>
                    {
                    moment(task.when_to_perform_task).format("ddd, MMM, Do")
                    }
                      </Text>

                    <Text fontSize={'13px'}> &nbsp; &nbsp; {
                      getMoment(task.when_to_perform_task)
                    // moment(task.when_to_perform_task).format("h:mm:ss a")
                    }</Text>
                </Flex>

                
            </Flex>
            <Box height="10px" />
            <Divider color={"#EBECEF"} />
            <Box height="15px" />
            <Flex>
                <Flex alignItems={'center'} width="100%">
                    <Icon as={UserCircleIcon} fontSize="14px" />
                    <Box width="10px" />
                    {
                    task.guests.length === 1 ?
                    <Flex  gap="2" alignItems={"center"}>
                      {
                        task.guests[0].avatar ?
                        <Image src={task.guests[0].avatar} boxSize="24px"></Image>
                        :
                        <Box position={"relative"} fontWeight="600" color="white" boxSize={"24px"} borderRadius={"100%"} display="flex" background={"secondary"} alignItems="center" justifyContent="center">
                          <Text fontSize={"8px"} >{!task.guests[0].first_name ? "" :task.guests[0].first_name.substring(0,1)}{!task.guests[0].last_name? "" : task.guests[0].last_name.substring(0,1)}</Text>
                        </Box>
                      }
                      <Heading fontSize={"12px"}>{task.guests[0].first_name} {task.guests[0].last_name}</Heading>
                    </Flex>
                    :
                    <Flex alignItems={"center"} w="100%"  position="relative">
                      {
                      (task.guests.slice(0, 2)).map((guest, i)=> (
                        guest.avatar ?
                        <Image position={"absolute"} top="-13px" borderRadius="100%" left={i*22} key={i} src={guest.avatar} boxSize="24px"></Image>
                        : 
                        <Box position={"absolute"} top="-13px" left={i*22} key={i} fontWeight="600" color="white" boxSize={"24px"} borderRadius={"100%"} display="flex" background={"secondary"} alignItems="center" justifyContent="center">
                          <Text fontSize={"8px"} >{guest.first_name.substring(0,1)}{guest.last_name.substring(0,1)}</Text>
                        </Box>
                      ))
                      }
                      {
                        task.guests.length > 3 ?
                        <Text position={"absolute"} top="-13px" left={3*24}>+ {task.guests.length -3}</Text>
                        : ""
                      }
                    </Flex>
                    }
                </Flex>

              
            </Flex>
            
            <Box height="10px" />
            <Divider color={"#EBECEF"} />
            <Box height="10px" />
            <Flex>
                <Flex alignItems={'center'} width="100%">
                    <Icon as={FlagIcon} fontSize="14px" />
                    <Box width="10px" />
                    <Text fontSize={'13px'}>  
                    {
                    moment(task.due_date).format("ddd, MMM, Do")
                    }
                    </Text>
                    &nbsp;
                    <Text fontSize={'13px'} color={'paragraph'}>
                    {
                    (moment(task.due_date).fromNow()).replace("in", "") 
                  }
                    &nbsp;
                    {
                    (moment(task.due_date).fromNow()).includes("in") ? "Left" : "" 
                    }
                    </Text>
                </Flex>

               
            </Flex>


            <Box height="10px" />
            <Divider color={"#EBECEF"} />
            <Box height="10px" />
            <Flex>
                <Flex alignItems={'center'} width="100%">
                    <Icon as={BellAlertIcon} fontSize="14px" />
                    <Box width="10px" />
                    <Text fontSize={'13px'}>
                      {
                        getMinutes(task.reminder, task.due_date)
                      }
                       minutes before the due time
                    </Text>
                </Flex>

                
            </Flex>
          </Box>
          
        </Box>
      </Box>
      }

      <Spacer height={"22px"} />
      </>
      }
      </Box>
  
  );
}

export default ViewTask;
