import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { httpPatch2WithTokenRest } from "../../utils/http_utils";
import { containsCapitalLetter, containsNumber, containsSpecialChars } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import DeleteAccountModal from "./delete_account_modal";

export default function DeleteAccount() {
  const [show, setShow] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [oldShow, setOldShow] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate()
  const handleClickConfirm = () => setShowConfirm(!showConfirm);
  const handleClickOld = () => setOldShow(!oldShow);
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  async function onSubmit(values) {
    setReason(values.reason);
    onOpenDelete();
  }

  return (
    <Box px={{ base: 2, md: 12 }} py={3}>
      <DeleteAccountModal 
       isOpen={isOpenDelete}
       onClose={onCloseDelete}
       reason={reason}
       onFinished={()=> {}}
      />
      <Box h={6} />
      <Text color={"#FF6E57"} fontSize={20} fontWeight={600} my={1}>
        Delete Account
      </Text>
      
      <Text fontSize={14} fontWeight={400} color={"#747A8B"} mb={5}>
      Deleting your account would make you lose your Sojorne account, and you won't be able to log in with your current details.
      </Text>
      <Box h={6} />
      <Box padding={"2"} >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt={5} isInvalid={errors.reason} fontWeight={600}>
          <FormLabel htmlFor="name">Reason</FormLabel>
          <Textarea
              id="reason"
              name="reason"
              placeholder=""
              {...register("reason", {
              })}
              borderColor={"#E3E4E8"}
          />
            
          <FormErrorMessage>
            {errors.reason && errors.reason.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          mt={8}
          mb={4}
          size={"lg"}
          w={"100%"}
          color="#fff"
          borderRadius={64}
          background={"primary"}
          isLoading={isSubmitting}
          type="submit"
        >
          Delete my account
        </Button>
      </form>
      </Box>
    </Box>
  );
}
