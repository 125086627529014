import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import { Link as ReachLink, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  finance,
  insuranceNotFound,
  noise,
  provider,
  team,
  tribe,
  who,
  xIcon,
} from "../../images";
import AppLink from "../../components/link";
import {
  ChevronRightIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/solid";
import { ChevronLeftIcon, EditIcon, AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  PencilSquareIcon,
  TrashIcon,
  CalendarDaysIcon,
  UserCircleIcon,
  FlagIcon,
  BellAlertIcon,
} from "@heroicons/react/24/solid";
import {
  httpGetWithTokenRest,
  httpPatchWithTokenRest,
} from "../../utils/http_utils";
import NoTabItem from "../../components/dashboard/no_tab_item";
import moment from "moment";

import CreateAppointmentModal from "./create_insurance_modal";
import AddInsuranceModal from "./add_insurance_modal";
import VisionAddedModal from "./insurance_vision_added";
import EditInsuranceModal from "./edit_insurance";
import InsuranceUpdatedModal from "./insurance_updated";
import DeleteInsuranceModal from "./delete_insurance_modal";

function ViewInsurance() {
  const [isLoading, setIsLoading] = useState(true);
  const [insurance, setInsurance] = useState({});
  const [noTask, setNoTask] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  useEffect(() => {
    loadConstants();
  }, []);

  const loadConstants = async () => {
    var j = await httpGetWithTokenRest(`insurance/${params.id}`);
    if (j.status == "success") {
      setIsLoading(false);
      setInsurance(j.data);
      console.log(j.data);
    } else {
      setNoTask(true);
    }
  };
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
  } = useDisclosure();

  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();
  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      {isLoading ? (
        ""
      ) : (
        <>
          <AddInsuranceModal
            onClose={onClose1}
            isOpen={isOpen1}
            newinsurance={insurance?.id}
            onFinished={() => {
              loadConstants()
              onOpenCreate()
            }}
          />

          <EditInsuranceModal
            onClose={onCloseEdit}
            isOpen={isOpenEdit}
            newinsurance={insurance}
            onFinished={() => {
              loadConstants()
              onOpenUpdate()
            }}
          />

            <DeleteInsuranceModal
              onClose={onCloseDelete}
              isOpen={isOpenDelete}
              insurance={insurance?.id}
              onFinished={(onCloseDelete)}
            />
          <VisionAddedModal
            onClose={onCloseCreate}
            isOpen={isOpenCreate}
            onFinished={(onCloseCreate)}
          />
          <InsuranceUpdatedModal
           onClose={onCloseUpdate}
           isOpen={isOpenUpdate}
           onFinished={(onCloseUpdate)}
          />

          <Spacer height={"22px"} />

          <Box>
            <Flex alignItems={"center"} justifyContent="space-between">
              <Box display="flex" alignItems={"center"}>
                <AppLink
                  hasLeftIcon={true}
                  leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
                  shadow="none"
                  padding="0"
                  text=""
                  background="transparent"
                  color="black"
                  width="fit-content"
                  textDecoration="none"
                  href="/insurance-card"
                />
                <Box width="2px" />
                <Heading
                  color={"black"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                  as={"h3"}
                >
                  {insurance.insurance_type}
                </Heading>
              </Box>

              <AppLink
                  hasLeftIcon={true}
                  leftIcon={<Icon as={DeleteIcon} fontSize="20px" />}
                  shadow="none"
                  padding="0"
                  text=""
                  background="transparent"
                  color="red"
                  width="fit-content"
                  textDecoration="none"
                  href="#?"
                  onclick={()=>onOpenDelete()}
                />
            </Flex>
            <Box h="10px" />

            <Box p="3" bg="white">

              <SimpleGrid columns={{ base: "1", md: "2" }} gap={"4"}>
                <Image rounded={"18px"} src={insurance.front_view} width={"100%"} h={{ base: "180px", md: "250px" }} />
                <Image rounded={"18px"} src={insurance.back_view} width={"100%"} h={{ base: "180px", md: "250px" }} />
              </SimpleGrid>
              <Box h="30px" />
              <SimpleGrid columns={{ base: "1", md: "2" }} gap={"4"}>
                <Box>
                  <Flex bg="#F9F6FF" alignItems={"center"} px="2" py="1" justifyContent={"space-between"}>
                    <Heading fontSize={"18px"} fontWeight={"600"}>Medical Card</Heading>
                    <Button onClick={onOpenEdit} justifyContent={"center"} m="0" p="0" fontSize={"14px"} display={"flex"} alignItems={"center"} color={"#FF6E57"}><EditIcon ></EditIcon> &nbsp; Edit</Button>
                  </Flex>


                  <Flex justifyContent={"space-between"} py="3">
                    <Text color={"#747A8B"} fontSize={"14px"}>Plan Name and Type</Text>
                    <Text color={"#17181C"} fontWeight={"500"} fontSize={"14px"} textAlign={"right"}>{insurance.plan}</Text>
                  </Flex>
                  <Box h={"1px"} bg="#00000073" opacity={"0.3"} />

                  <Flex justifyContent={"space-between"} py="3">
                    <Text color={"#747A8B"} fontSize={"14px"}>Member ID</Text>
                    <Text color={"#17181C"} fontWeight={"500"} fontSize={"14px"} textAlign={"right"}>{insurance.member_id}</Text>
                  </Flex>
                </Box>


                <Box>
                  <Flex bg="#F9F6FF" alignItems={"center"} px="2" py="1" justifyContent={"space-between"}>
                    <Heading fontSize={"18px"} fontWeight={"600"}>{insurance.company}</Heading>
                    <Button opacity={0} justifyContent={"center"} m="0" p="0" fontSize={"14px"} display={"flex"} alignItems={"center"} color={"#FF6E57"}><EditIcon ></EditIcon> &nbsp; Edit</Button>
                  </Flex>


                  <Flex justifyContent={"space-between"} py="3">
                    <Text color={"#747A8B"} fontSize={"14px"}>Employer</Text>
                    <Text color={"#17181C"} fontWeight={"500"} fontSize={"14px"} textAlign={"right"}>{insurance.employer}</Text>
                  </Flex>
                  <Box h={"1px"} bg="#00000073" opacity={"0.3"} />

                  <Flex justifyContent={"space-between"} py="3">
                    <Text color={"#747A8B"} fontSize={"14px"}>Member ID</Text>
                    <Text color={"#17181C"} fontWeight={"500"} fontSize={"14px"} textAlign={"right"}>{insurance.member_id}</Text>
                  </Flex>

                  <Box h={"1px"} bg="#00000073" opacity={"0.3"} />

                  <Flex justifyContent={"space-between"} py="3">
                    <Text color={"#747A8B"} fontSize={"14px"}>Effective Date</Text>
                    <Text color={"#17181C"} fontWeight={"500"} fontSize={"14px"} textAlign={"right"}>{moment(insurance.effective_date).format("YYYY-MMM-DD")}</Text>
                  </Flex>
                </Box>
              </SimpleGrid>


              <Box h="30px" />
              <SimpleGrid columns={{ base: "1", md: "2" }} gap={"4"}>
                <Box>
                  <Flex bg="#F9F6FF" alignItems={"center"} px="2" py="1" justifyContent={"space-between"}>
                    <Heading fontSize={"18px"} fontWeight={"600"}>Vision</Heading>
                    {!insurance.vision_plan &&
                      <Button onClick={onOpen1} justifyContent={"center"} m="0" p="0" fontSize={"14px"} display={"flex"} alignItems={"center"} color={"#FF6E57"}><AddIcon /> &nbsp; Add Insurance</Button>
                    }
                  </Flex>


                  {insurance.vision_plan && <>
                    <Flex justifyContent={"space-between"} py="3">
                      <Text color={"#747A8B"} fontSize={"14px"}>Plan</Text>
                      <Text color={"#17181C"} fontWeight={"500"} fontSize={"14px"} textAlign={"right"}>{insurance.vision_plan}</Text>
                    </Flex>
                    <Box h={"1px"} bg="#00000073" opacity={"0.3"} />

                    <Flex justifyContent={"space-between"} py="3">
                      <Text color={"#747A8B"} fontSize={"14px"}>Member ID</Text>
                      <Text color={"#17181C"} fontWeight={"500"} fontSize={"14px"} textAlign={"right"}>{insurance.vision_member_id}</Text>
                    </Flex>
                  </>}
                </Box>
                <Box>

                </Box>
              </SimpleGrid>
            </Box>

          </Box>

          <Spacer height={"22px"} />
        </>
      )}
    </Box>
  );
}

export default ViewInsurance;
