import React from "react";
import DashboardLayout from "../../layout/dashboard";
import { Box, SimpleGrid, Skeleton, Text, useToast } from "@chakra-ui/react";
import ProviderItem from "./providerItem";
import { providersRoute } from "../../utils/allRoutes";
import { httpGetWithTokenRest } from "../../utils/http_utils";

export default function Providers() {
  const toast = useToast();
  const [providerCategories, setProviderCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getProviderCategories = async () => {
    const resp = await httpGetWithTokenRest("providers/categories");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setProviderCategories(resp.data.rows);
    }
    setLoading(false);
  };

  const getRandomColors = () => {
    const colorList = [
      {
        textColor: "secondary",
        bgColor: "#FEEFE6",
      },
      {
        textColor: "primary",
        bgColor: "#F0EDF8",
      },
      {
        textColor: "#1DA2C9",
        bgColor: "#E9F7FC",
      },
      {
        textColor: "secondary",
        bgColor: "#F7F7F8",
      },
    ];
    return colorList[Math.floor(Math.random() * colorList.length)];
  };

  React.useEffect(() => {
    getProviderCategories();
  }, []);

  return (
    <DashboardLayout
      element={
        <Box p={5}>
          <Text fontSize={24} fontWeight={600}>
            Providers
          </Text>
          <Box my={8}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
              {providerCategories.map((category, i) => {
                const color = getRandomColors();
                return (
                  <ProviderItem
                    image={category.avatar}
                    title={category.category}
                    subtitle={category.description}
                    bgColor={color.bgColor}
                    textColor={color.textColor}
                    link={`${providersRoute}/${category.id}`}
                  />
                );
              })}

              {loading
                ? [1, 1, 1, 1].map((e, i) => (
                    <Skeleton h={"50px"} startColor="#808080" />
                  ))
                : null}
            </SimpleGrid>
          </Box>
        </Box>
      }
    />
  );
}
