import React from "react";
import DashboardLayout from "../../layout/dashboard";
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SimpleGrid,
  Skeleton,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import ProviderItem from "./providerItem";
import { providersRoute } from "../../utils/allRoutes";
import { httpGetWithTokenRest } from "../../utils/http_utils";
import { useNavigate, useParams } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { SearchIcon } from "@chakra-ui/icons";
import { MdOutlineLocationOn } from "react-icons/md";
import { Rating } from "react-simple-star-rating";
// import {
//   Previous,
//   Paginator,
//   PageGroup,
//   Page,
//   Next,
//   generatePages,
// } from "chakra-paginator";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import ReactPaginate from "react-paginate";

export default function SingleProvider() {
  const normalStyles = {
    bg: "white",
  };

  const activeStyles = {
    bg: "blue.300",
  };
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const categoryId = params.id;
  const [subCategories, setSubCategories] = React.useState([]);
  const [providers, setProviders] = React.useState([]);
  const [providersOriginal, setProvidersOriginal] = React.useState([]);
  const [category, setCategory] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [pageCount, setPageCount] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState("all");
  const [searchValue, setSearchValue] = React.useState("");
  const getCategorySubCategories = async () => {
    const resp = await httpGetWithTokenRest(
      `providers/${categoryId}/sub_categories`
    );
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setSubCategories(resp.data.rows?.sub_categories);
    }
    setLoading(false);
  };

  const getSingleCategory = async () => {
    const resp = await httpGetWithTokenRest(
      `providers/categories/${categoryId}`
    );
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setCategory(resp.data);
    }
    setLoading(false);
  };

  const fetchCategoryProviders = async (page = 1) => {
    const resp = await httpGetWithTokenRest(
      `providers/${categoryId}/all?page=${page}`
    );
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setProviders(resp.data.rows);
      setProvidersOriginal(resp.data.rows);
      setPageCount(resp.data.totalPages);
      setCurrentPage(resp.data.currentPage);
    }
  };
  const handlePageClick = (value) => {
    setCurrentPage(value.selected + 1);
    if (selectedSubCategory == "all") {
      fetchCategoryProviders(value.selected);
    } else {
      filterProviderBySubCategory(selectedSubCategory, value.selected);
    }
  };
  const filterProviderBySubCategory = async (id, page = 1) => {
    setSelectedSubCategory(id);
    if (id === "all") {
      return fetchCategoryProviders();
    }
    setProviders([]);

    const resp = await httpGetWithTokenRest(
      `providers/${id}/providers?page=${page}`
    );
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      // console.log(resp);
      setProviders(resp.data.rows);
      setPageCount(resp.data.totalPages);
      setCurrentPage(resp.data.currentPage);
      setProvidersOriginal(resp.data.rows);
    }
  };

  const handleSearchProvider = async (e) => {
    let val = e.target.value;
    setSearchValue(val);
    if (val.length < 2) {
      return fetchCategoryProviders();
    }
    const resp = await httpGetWithTokenRest(
      selectedSubCategory == "all"
        ? `providers/${categoryId}/all?page=1&search=${val}`
        : `providers/${selectedSubCategory}/providers?search=${val}`
    );
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setProviders(resp.data.rows);
      setProvidersOriginal(resp.data.rows);
      setPageCount(resp.data.totalPages);
      setCurrentPage(resp.data.currentPage);
    }
  };
  React.useEffect(() => {
    getSingleCategory();
    getCategorySubCategories();
    fetchCategoryProviders();
  }, []);

  return (
    <DashboardLayout
      element={
        <Box p={5} bg={"#FAF8FF"} minH={"100vh"}>
          {loading ? (
            <Skeleton height={"100px"} width={"100%"} bg={"red"} />
          ) : (
            <Box>
              <Flex gap={5} alignItems={"center"}>
                <FaChevronLeft
                  cursor={"pointer"}
                  onClick={() => navigate(-1)}
                />
                <Text fontSize={24} fontWeight={600}>
                  {category.category}
                </Text>
              </Flex>

              <HStack
                mt={2}
                w={"100%"}
                pb={3}
                overflowX={"scroll"}
                sx={{
                  "&::-webkit-scrollbar": {
                    mt: "2px",
                    width: "16px",
                    height: "1px",
                    borderRadius: "8px",
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: `rgba(0, 0, 0, 0.05)`,
                  },
                }}
              >
                <Box
                  px={4}
                  py={2}
                  bg={selectedSubCategory === "all" ? "#FFF2F0" : "#fff"}
                  border={
                    selectedSubCategory === "all"
                      ? "1px solid #FF6E57"
                      : "1px solid #EBECEF"
                  }
                  borderRadius={100}
                  cursor={"pointer"}
                  _hover={{ border: "1px solid #FF6E57", bg: "#FFF2F0" }}
                  onClick={() => filterProviderBySubCategory("all")}
                >
                  <Text>All</Text>
                </Box>

                {subCategories.map((e, i) => (
                  <Box
                    px={4}
                    py={2}
                    bg={selectedSubCategory === e?.id ? "#FFF2F0" : "#fff"}
                    border={
                      selectedSubCategory === e?.id
                        ? "1px solid #FF6E57"
                        : "1px solid #EBECEF"
                    }
                    borderRadius={100}
                    cursor={"pointer"}
                    onClick={() => filterProviderBySubCategory(e?.id)}
                  >
                    <Text>{e?.title}</Text>
                  </Box>
                ))}
              </HStack>

              <Box my={8} p={5} bg={"#fff"} minH={"500px"}>
                <InputGroup w={{ base: "100%", md: "500px" }}>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    border={"1px solid #EBECEF"}
                    placeholder="Search"
                    key={"searchList"}
                    borderRadius={100}
                    onChange={handleSearchProvider}
                    borderColor={"#E3E4E8"}
                  />
                  <InputRightElement
                    borderRadius={100}
                    bg={"primary"}
                    w={"100px"}
                    color={"white"}
                  >
                    Search
                    {/* <Button onClick={handleSearchProvider}>
                      <Flex alignContent={"center"}>
                        <MdOutlineLocationOn color="#fff" />
                        <Text color={"#fff"} fontSize={12} fontWeight={500}>
                          City, State
                        </Text>
                      </Flex>
                    </Button> */}
                  </InputRightElement>
                </InputGroup>

                <Box mt={5}>
                  <TableContainer>
                    <Table variant="unstyled">
                      <Thead bg={"#FAFAFA"}>
                        <Tr>
                          <Th>Name</Th>
                          <Th>City</Th>
                          <Th>State</Th>
                          <Th>Address</Th>
                          <Th>Rating</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {providers.map((provider, i) => (
                          <Tr
                            key={`provider${i}`}
                            id={`${provider.sub_category_id}`}
                            cursor={"pointer"}
                            onClick={() =>
                              navigate(
                                `${providersRoute}/${categoryId}/${provider?.id}`
                              )
                            }
                            borderBottom={"1px solid #EBECEF"}
                          >
                            <Td>
                              <Text fontSize={14} fontWeight={400}>
                                {provider?.providers_name}{" "}
                              </Text>
                            </Td>
                            <Td>
                              <Text fontSize={14} fontWeight={400}>
                                {provider?.city}
                              </Text>
                            </Td>
                            <Td>
                              <Text fontSize={14} fontWeight={400}>
                                {provider?.state}
                              </Text>
                            </Td>
                            <Td>
                              <Text fontSize={14} fontWeight={400}>
                                {provider?.address}
                              </Text>
                            </Td>
                            <Td>
                              <Rating
                                size={15}
                                readonly
                                allowFraction
                                initialValue={provider?.google_rating}
                                SVGstyle={{ display: "inline" }}
                              />
                            </Td>
                          </Tr>
                        ))}
                        {providers.length == 0 && (
                          <Tr>
                            <Td colSpan={"4"}>No record found</Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Flex p={2} mt="20px">
                    <Spacer />
                    <ReactPaginate
                      breakLabel="..."
                      className="paginator"
                      activeClassName="activeClassName"
                      pageClassName="pageClassName"
                      pageLinkClassName="pageLinkClass"
                      nextLabel={<FaChevronRight />}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel={<FaChevronLeft />}
                      renderOnZeroPageCount={null}
                    />
                  </Flex>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      }
    />
  );
}
