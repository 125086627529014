import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
// import {authReducer} from './states';
import { unauthenticatedMiddleware } from "./middleware/error-handling.middleware";
import { authReducer } from "./reducers";

const encryptor = encryptTransform({
  secretKey: "1b9d6bef-bbfd-4b2d-9b5d-ab8dfbbd4ccc",
  onError: function (error) {
    //console.log(error);
  },
});

const persistAuthConfig = {
  key: "auth_sj",
  version: 1,
  storage,
  transforms: [encryptor],
};

const authpersistedReducer = persistReducer(persistAuthConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: authpersistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([unauthenticatedMiddleware]),
});

export const  RootState = store.getState;
export const AppDispatch = store.dispatch;
setupListeners(store.dispatch);
