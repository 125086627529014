import React from "react";
import {
  who,
  squareGreen,
  calendarBlue,
  bellIconGreen,
  editIconGreen,
  deleteIconRed,
  insuranceAvatar,
} from "../../images";
import {
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import moment from "moment";
import { capitalizeFirstWord } from "../../utils/utils";
import { BiDotsHorizontal, BiDotsVertical } from "react-icons/bi";
import { FaChevronRight } from "react-icons/fa";
function AppointmentCard({ card, editAppointment, deleteAppointment }) {
  return (
    <Link as={ReachLink} to={`/insurance-card/${card.id}`} style={{textDecoration : "none"}}>
    <Box p="3" shadow="sm" rounded={"15px"} bg={"#F7F7F8"}>
      <Flex alignItems={"center"} w="100%" gap={"3"}>
        <Image src={insuranceAvatar} boxSize={"41px"} />
        <Box flex={1}>
          <Heading fontWeight={"600"} fontSize={"16px"} color="#17181C">{card.insurance_type}</Heading>
          <Text color={"#747A8B"} fontSize={"12px"}>{card.company}</Text>
        </Box>
        <FaChevronRight />
      </Flex>

    </Box>
    </Link>
  );
}

export default AppointmentCard;
