import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";

export default function TaskCategory({
  isOpen,
  onClose,
  constants,
  selectedOriginal,
  onFinished,
}) {
  const [selected, setselected] = React.useState(
    selectedOriginal
  );
  const finishSelection = (item) => {
    onFinished(item);
    onClose()
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
            <ModalHeader>Category</ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody marginBottom={'0'}>
              {
                constants.map((item, i)=> (
                <Box cursor={'pointer'} key={i} py="2" onClick={()=>{
                  setselected(item)
                    finishSelection(item)
                }}>
                  <Text fontWeight={'500'}>{item}</Text>
                  <Divider mt={'1'} color={"#EBECEF"} />
                </Box>
                ))
              }
              
          
            </ModalBody>
           
      </ModalContent>
    </Modal>
  );
}
