import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";

export default function CreateTaskModal({
  isOpen,
  onClose,
  selectedOriginal,
  onFinished,
}) {
  const [selected, setselected] = React.useState(selectedOriginal);
  const finishSelection = () => {
    onFinished(selected);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader>Tasks</ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <ModalBody marginBottom={"0"}>
          <Divider color={"#EBECEF"} />
          <Box w="100%" mt={"25px"}>
            <Flex gap={3} mb="20px" alignItems="start">
              <Image
                boxSize={"45px"}
                objectFit="contain"
                src={homeIcon1}
              ></Image>
              <Box>
                <Heading fontSize={"18px"}>Organize Your Life</Heading>
                <Text mt={1} color={"paragraph"} fontSize="14px">
                  Document your tasks. Start your day feeling in control.
                </Text>
              </Box>
            </Flex>
            <Flex gap={3} mb="20px" alignItems="start">
              <Image
                boxSize={"45px"}
                objectFit="contain"
                src={homeIcon1}
              ></Image>
              <Box>
                <Heading fontSize={"18px"}>
                  Share Tasks with Your Care Team
                </Heading>
                <Text mt={1} color={"paragraph"} fontSize="14px">
                  Collaborate with your co-caregivers and share responsibilities
                  with them.
                </Text>
              </Box>
            </Flex>
            <Flex gap={3} mb="20px" alignItems="start">
              <Image
                boxSize={"45px"}
                objectFit="contain"
                src={homeIcon1}
              ></Image>
              <Box>
                <Heading fontSize={"18px"}>
                  Set Reminders for your Tasks
                </Heading>
                <Text mt={1} color={"paragraph"} fontSize="14px">
                  Set up reminders to make sure you stay on top of things.
                </Text>
              </Box>
            </Flex>
            <Button
              mt={8}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => finishSelection()}
            >
              Create Task
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
