import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";

export default function InsurancePlanModal({
  isOpen,
  onClose,
  constants,
  selectedOriginal,
  onFinished,
}) {
  const [selected, setselected] = React.useState(
    selectedOriginal
  );
  const [allList, setAllList] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const finishSelection = (item) => {
    onFinished(item);
    onClose()
  };
  useEffect(()=>{
    setAllList(constants)
  }, [constants])

  const searchChange = (e) => {
    let v = e.target.value;
    if(v == ""){
      setAllList(constants)
    }else{
      v = v.toLowerCase()
      setAllList(constants.filter((c)=> c.toLowerCase().includes(v)))
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"md"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} maxH={"60vh"}>
            <ModalHeader>Plan name and Type</ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody minH={"300px"} maxH={"480px"} marginBottom={'0'}>
              <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    borderRadius={100}
                    onChange={searchChange}
                    borderColor={"#E3E4E8"}
                  />
                </InputGroup>
              {
                allList.map((item, i)=> (
                <Box cursor={'pointer'} key={i} py="2" onClick={()=>{
                  setselected(item)
                    finishSelection(item)
                }}>
                  <Text fontWeight={'500'}>{item}</Text>
                  <Divider mt={'1'} color={"#EBECEF"} />
                </Box>
                ))
              }
              
          
            </ModalBody>
           
      </ModalContent>
    </Modal>
  );
}
