import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  PinInput,
  PinInputField,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useRef } from "react";
import AuthCarousel from "../../components/authCarousel";
import { useNavigate } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import { AppLogo, successGif } from "../../images";
import CustomModal from "../../components/customModal";
import { onboardingStartRoute } from "../../utils/allRoutes";
import ls from "localstorage-slim";
import { httpPostWithoutToken } from "../../utils/http_utils";
import { setUser } from "../../reducers/authSlice";
import { useAppDispatch } from "../../redux/hook";
import { setAuth, setTokens } from "../../utils/auth";
import { AppContext } from "../../redux/appContext";
export default function OtpVerification() {
  const email = ls.get("sj_email", { decrypt: true });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [otp, setOtp] = React.useState("");
  const [otpInvalid, setOtpInvalid] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const countdownRef = useRef();
  const { updateUser, setSub } = useContext(AppContext);
  const toast = useToast();
  const restart = () => {
    countdownRef.current.start();
  };
  const onChangeOtp = (val) => {
    setOtp(val);
    setOtpInvalid(false);
  };

  const onDone = () => {
    navigate(onboardingStartRoute);
  };

  async function onSubmit(values) {
    setIsSubmitting(true);
    let data = {
      otp: otp,
    };

    const resp = await httpPostWithoutToken("caregiver/verify-otp", data);
    if (resp.error !== null && resp.error !== undefined) {
      setOtpInvalid(true);
      setIsSubmitting(false);
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setIsSubmitting(false);
      ls.set("sj_user", resp.data.user, { encrypt: true });
      ls.set("sj_token", resp.data.token, { encrypt: true });

      let u = resp.data.user;
      u.phone_number = u.phone_number
        ? u.phone_number.replace("undefined", "")
        : "";
      console.log(u);

      sessionStorage.setItem("sj_user", JSON.stringify(u));
      setTokens(resp.data.token, resp.data.refreshToken);
      updateUser(u);

      setAuth(dispatch);
      // dispatch(
      //   setUser({
      //     user: resp.data.user,
      //     token: resp.data.token,
      //     sj_isAuth: true,
      //   })
      // );
      onOpen();
    }
  }

  const resendOtp = async () => {
    const resp = await httpPostWithoutToken("caregiver/resend-otp", { email });
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      restart();
      return toast({
        title: "Success.",
        description: `OTP sent`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Text onClick={resendOtp} cursor={"pointer"}>
          {" "}
          Resend Code
        </Text>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {" "}
          Resend in {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };
  return (
    <Flex min-height={"100vh"} width={"100%"}>
      <AuthCarousel />
      <Box
        zIndex={2}
        w={{ base: "100%", md: "50%" }}
        background={"white"}
        borderTopRadius={{ base: "20px", md: 0 }}
        paddingTop={{ base: "40px", md: 0 }}
        h={{ base: "calc(100vh - 30px)", md: "100vh" }}
        px={32}
        overflowY={"scroll"}
      >
        <Box position="relative" h={"100vh"}>
          <Box display={{ md: "none" }} borderRadius={20}>
            <Center p={2}>
              <Image src={AppLogo} />
            </Center>
          </Box>
          <AbsoluteCenter p="4" axis="both" width={"auto"}>
            <Heading
              pt={8}
              display={"flex"}
              justifyContent="center"
              flexWrap="nowrap"
              fontWeight={600}
              fontSize="32px"
              color={"black"}
            >
              OTP Verification
            </Heading>
            <Text py={8} textAlign={"center"}>
              Enter OTP sent to{" "}
              <Text as={"span"} color={"primary"}>
                {email}
              </Text>
            </Text>
            <Center>
              <HStack>
                <PinInput
                  otp
                  size={"lg"}
                  onChange={onChangeOtp}
                  isInvalid={otpInvalid}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Center>
            {otpInvalid ? (
              <Text pt={1} size={"sm"} color={"#B32924"}>
                Incorrect Code
              </Text>
            ) : null}
            <Flex mt={3}>
              <Text mr={2}>Didn't receive code? </Text>
              <Countdown
                ref={countdownRef}
                date={Date.now() + 30000}
                renderer={renderer}
              />
            </Flex>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              isLoading={isSubmitting}
              onClick={onSubmit}
              isDisabled={otp.length < 6}
            >
              Verify
            </Button>
          </AbsoluteCenter>
        </Box>
      </Box>

      <CustomModal
        onClose={onClose}
        onDone={onDone}
        isOpen={isOpen}
        body={
          <Box>
            <Center>
              <Image src={successGif} />
            </Center>
            <Text textAlign={"center"} fontSize={20} fontWeight={600}>
              Successful
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              Your Jorner account was created successfully
            </Text>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={onDone}
            >
              Done
            </Button>
          </Box>
        }
      />
    </Flex>
  );
}
