import React from 'react'
import {Box, Text, Divider} from '@chakra-ui/react'

function TabListItem({text}) {
  return (
    <Box my='4'>
                <Text>{text}</Text>
                <Box height="5px" />
                <Divider color={"#EBECEF"} />
              </Box>
  )
}

export default TabListItem