import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuth } from '../utils/auth';

const useAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setAuth(dispatch);
  }, []);
};

export default useAuth;
