import { Box, Center, Image } from '@chakra-ui/react'
import React from 'react'
import { LoginImg, Logo, caregiverLogin } from '../images'

export default function LoginSide({isFamily = false}) {
  return (
    <Box
        flex="1"
        borderTopRadius={{ base: "20px", md: 0 }}
        width={{ base: "90%", md: "auto" }}
        background={isFamily ? "#65CBE9" : "primary"}
        position={{ base: "fixed", md: "static" }}
        justifyContent={"center"}
        alignItems="center"
        h={"100vh"}
        m={{ base: "10px 5% 0", md: "0" }}
    >
        <Center pt={8}>
        <Image src={Logo} />
        </Center>
        <Center>
        <Image src={isFamily ? caregiverLogin : LoginImg} height={'500px'} objectFit={"contain"} />
        </Center>
    </Box>
  )
}
