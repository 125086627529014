import React, { useContext, useEffect } from "react";
import ReactConfetti from "react-confetti";
import useWindowSize from "../../utils/windowSize";
import {
  Avatar,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { AppLogo } from "../../images";
import { FaChevronRight, FaRegAddressCard, FaUser } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { dashboardRoute, onBoardingRoute } from "../../utils/allRoutes";
import { httpGetWithTokenRest } from "../../utils/http_utils";
import { AppContext } from "../../redux/appContext";

export default function OnboardingStart() {
  const { width, height } = useWindowSize();
  const navigate = useNavigate();
  const { updateUser, setSub } = useContext(AppContext);

  const startOnboarding = () => {
    navigate(onBoardingRoute);
  };

  const skipOnboarding = () => {
    navigate(dashboardRoute);
  };
  useEffect(() => {
    fetchSubscription();
  }, []);

  const fetchSubscription = async () => {
    const rs = await httpGetWithTokenRest("subscription");
    if (rs.data) {
      setSub(rs.data);
    }
  };
  return (
    <Box position={"relative"} overflowX={"hidden"}>
      <Center axis="both">
        <Card p={8} background={"#fff"} textAlign={"center"} mt={12}>
          <Center>
            <Image src={AppLogo} />
          </Center>
          <Heading
            pt={6}
            display={"flex"}
            justifyContent="center"
            flexWrap="nowrap"
            fontWeight={600}
            fontSize="32px"
            color={"black"}
          >
            You're in already
          </Heading>
          <Text fontSize={20} mt={6} fontWeight={600}>
            One last thing{" "}
          </Text>
          <Text fontSize={16} mt={8} fontWeight={400} textAlign={"center"}>
            {" "}
            Complete the following task to have a best <br /> experience on
            Sojorne
          </Text>
          <Flex
            mt={8}
            gap={2}
            mb="15px"
            alignItems="start"
            cursor={"pointer"}
            onClick={startOnboarding}
          >
            <Avatar bg={"#FFE9E5"} icon={<FaUser color="#FF6E57" />} />
            <Box>
              <Heading textAlign={"left"} fontSize={"18px"}>
                Complete Profile
              </Heading>
              <Text mt={1} color={"paragraph"} fontSize="14px">
                Provide more basic information...
              </Text>
            </Box>
            <Spacer />
            <FaChevronRight />
          </Flex>
          <hr />
          <Flex
            mt={8}
            gap={2}
            mb="15px"
            alignItems="start"
            cursor={"pointer"}
            onClick={startOnboarding}
          >
            <Avatar
              bg={"#E7FEEE"}
              icon={<FaRegAddressCard color="#099A38" />}
            />
            <Box>
              <Heading textAlign={"left"} fontSize={"18px"}>
                Create Jorners Profile
              </Heading>
              <Text mt={1} color={"paragraph"} fontSize="14px">
                Name of Loved one, age, disability...
              </Text>
            </Box>
            <Spacer />
            <FaChevronRight />
          </Flex>
          <hr />
          <Flex
            display={"none"}
            mt={8}
            gap={2}
            mb="15px"
            alignItems="start"
            cursor={"pointer"}
            onClick={startOnboarding}
          >
            <Avatar bg={"#FFF7E5"} icon={<IoIosPeople color="#C78600" />} />
            <Box>
              <Heading textAlign={"left"} fontSize={"18px"}>
                Build Your Care Team
              </Heading>
              <Text mt={1} color={"paragraph"} fontSize="14px">
                Invite your co-caregivers and collaborate on the go
              </Text>
            </Box>
            <Spacer />
            <FaChevronRight />
          </Flex>

          <Button
            mt={10}
            mb={4}
            size={"lg"}
            w={"100%"}
            color="#fff"
            borderRadius={64}
            background={"primary"}
            onClick={startOnboarding}
          >
            Proceed
          </Button>
          <Button
            mt={2}
            mb={4}
            size={"lg"}
            w={"100%"}
            color="#747A8B"
            borderRadius={64}
            background={"#fff"}
            type="submit"
            border={"1px solid #747A8B"}
            onClick={skipOnboarding}
          >
            Skip
          </Button>
        </Card>
      </Center>
      <ReactConfetti width={width} height={height} recycle={false} />
    </Box>
  );
}
