import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { healthcare } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { EmailChipInput } from "../../components/emailInput";

export default function SingleModal({
  isOpen,
  defaultV = [],
  onClose,
  wDWFMConstantsOriginal,
  onFinished,
  title = "title",
  showOthers = false
}) {
  const [wDWFMConstants, setWDWFMConstants] = React.useState(
    wDWFMConstantsOriginal
  );
  const [strengthList, setWDWFMList] = React.useState([]);
  const [wDWFMListOriginal, setWDWFMListOriginal] = React.useState(
    wDWFMConstantsOriginal
  );
  const [inputValue, setInputValue] = useState("");

  const [allOtherSelected, setAllOtherSelected] = useState([]);
  const [allOtherSelectedConstant, setAllOtherSelectedConstant] = useState([]);
  const [allOtherSelectedPending, setAllOtherSelectedPending] = useState([]);

  const [generalSearch, setGeneralSearch] = useState([]);
  const [showGeneralSearch, setShowGeneralSearch] = useState(false);
  const [showWDWFMList, setShowWDWFMList] = React.useState(false);
  const [wDWFMTitle, setWDWFMTitle] = React.useState(title);
  const { value: selectedWDWFM, setValue, getCheckboxProps } = useCheckboxGroup({
    defaultValue: defaultV.length > 0 ? defaultV : [],
  });
  useEffect(() => {
    setInputValue("");
    setShowWDWFMList(false);
    setValue(defaultV)
    if (wDWFMConstantsOriginal) {
      let originalkeys = Object.keys(wDWFMConstantsOriginal)
      let originalValuesOnly = Object.values(wDWFMConstantsOriginal)
      setValue(defaultV)
      if (showOthers) {
        let allothers = []
        allothers = defaultV.filter((l) => wDWFMConstantsOriginal.includes(l) == false);
        setAllOtherSelected([...new Set(allothers)])
        setAllOtherSelectedConstant([...new Set(allothers)])
      }
    }
    setShowGeneralSearch(false);
  }, [isOpen, wDWFMConstantsOriginal]);
  const searchJornerWDWFMConstants = (e) => {
    // console.log(wDWFMConstantsOriginal);
    let keyword = e.target.value;
    setInputValue(keyword);
    let allListItems = wDWFMConstantsOriginal;
    if (keyword < 1) {
      setWDWFMConstants(wDWFMConstantsOriginal);
    } else {
      let allDisabilities = wDWFMConstantsOriginal;
      let searchResult = allListItems.filter((e) =>
        e.toLowerCase().includes(keyword.toLowerCase())
      );
      // setWDWFMConstants(searchResult);
      setGeneralSearch(searchResult);
      setShowGeneralSearch(true);
    }
  };

  const searchWDWFMDetailsList = (e) => {
    let keyword = e.target.value;

    if (keyword < 1) {
      setWDWFMList(wDWFMListOriginal);
    } else {
      let allStrengthList = wDWFMListOriginal;
      let searchResult = allStrengthList.filter((e) =>
        e.toLowerCase().includes(keyword.toLowerCase())
      );
      setWDWFMList(searchResult);
    }
  };

  const handleShowWDWFMDetails = (e = null) => {
    if (e == "Others") {
      setWDWFMTitle(e);
      setShowWDWFMList(true);
      setWDWFMList(allOtherSelected);
      setAllOtherSelectedConstant(allOtherSelected)
      setAllOtherSelectedPending(allOtherSelected)
    } else {
      setWDWFMList(wDWFMConstantsOriginal);
      setWDWFMListOriginal(wDWFMConstantsOriginal);
      setWDWFMTitle(title);
      setShowWDWFMList(true);
    }

  };

  const finishSelection = () => {
    let s = selectedWDWFM;
    s = [...new Set(selectedWDWFM), ...new Set(allOtherSelected)];
    onFinished(s);
    setShowWDWFMList(false);
    setAllOtherSelected([])
    setAllOtherSelectedPending([])
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"2xl"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        {!showWDWFMList ? (
          <>
            <ModalHeader display={"flex"} alignItems={"center"} w={"100%"}>
              {showGeneralSearch && (
                <FaChevronLeft
                  onClick={() => {
                    setShowGeneralSearch(false);
                    setInputValue("");
                    setGeneralSearch([]);
                  }}
                />
              )}
              <Text>{showGeneralSearch ? "Search Result" : title}</Text>
            </ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />
              <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {selectedWDWFM.length} selected
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  borderRadius={100}
                  onChange={searchJornerWDWFMConstants}
                  borderColor={"#E3E4E8"}
                />
              </InputGroup>
              {showGeneralSearch ? (
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                  {generalSearch.map((e, i) => (
                    <Checkbox
                      colorScheme="red"
                      style={{ color: "secondary" }}
                      {...getCheckboxProps({ value: e })}
                    >
                      <Text>{e}</Text>
                    </Checkbox>
                  ))}
                </SimpleGrid>
              ) : (
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3} mt={5}>
                  <Box
                    key={`title`}
                    bg={"gray"}
                    cursor={"pointer"}
                    borderRadius={8}
                    p={3}
                    onClick={() => handleShowWDWFMDetails()}
                  >
                    <Image src={healthcare} />
                    <Text fontSize={14} fontWeight={500}>
                      All
                    </Text>
                    <Text
                      color={"paragraph"}
                      fontSize={12}
                      fontWeight={500}
                      my={3}
                    >
                      {checkArraySimilarities(
                        selectedWDWFM,
                        wDWFMConstantsOriginal
                      )}{" "}
                      /{wDWFMConstantsOriginal.length} Selected
                    </Text>
                  </Box>
                  {
                    showOthers
                    &&
                    <Box
                      bg={"gray"}
                      cursor={"pointer"}
                      borderRadius={8}
                      p={3}
                      onClick={() => handleShowWDWFMDetails("Others")}
                    >
                      <Image src={healthcare} />
                      <Text fontSize={14} fontWeight={500}>
                        Others
                      </Text>
                      <Text
                        color={"paragraph"}
                        fontSize={12}
                        fontWeight={500}
                        my={3}
                      >
                        {allOtherSelected.length} Selected
                      </Text>
                    </Box>
                  }

                </SimpleGrid>
              )}
            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={finishSelection}
                >
                  Next
                </Button>
              </Box>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalHeader>
              <Flex alignItems={"center"}>
                <FaChevronLeft onClick={() => setShowWDWFMList(false)} />
                <Text ml={2}>{wDWFMTitle}</Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />
              {
                showOthers && wDWFMTitle.toLowerCase() === "others"
                  ?
                  <>
                    <Text fontSize={"13px"} mb="1">Use the (+) Icon to save your entries.</Text>
                    <EmailChipInput
                      initialEmails={allOtherSelectedPending}
                      callBackFunc={(e) => setAllOtherSelectedPending([...new Set(e)])}
                      placeholder={"Type here"}
                      isEmail={false}
                    />
                  </>
                  :
                  <>

                    <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                      {checkArraySimilarities(selectedWDWFM, strengthList)} selected
                    </Text>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.300" />
                      </InputLeftElement>
                      <Input
                        placeholder="Search"
                        key={"searchList"}
                        borderRadius={100}
                        onChange={searchWDWFMDetailsList}
                        borderColor={"#E3E4E8"}
                      />
                    </InputGroup>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                      {strengthList.map((e, i) => (
                        <Checkbox
                          colorScheme="red"
                          style={{ color: "secondary" }}
                          {...getCheckboxProps({ value: e })}
                        >
                          <Text>{e}</Text>
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </>
              }
            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={() => {
                    if(showOthers && wDWFMTitle.toLowerCase() == "others") {
                      setAllOtherSelected(allOtherSelectedPending)
                    }
                    setShowWDWFMList(false)
                  } }
                >
                  Proceed
                </Button>
              </Box>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
