import {
  Icon,
  Box,
  Image,
  Spacer,
  Text,
  useToast,
  Link,
  Flex,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  Logo,
  remindersIcon,
  noteIcon,
  aiNote,
  insuranceCardImg,
  subscriptinBadge,
} from "../../images";
import SidebarItem from "../sidebarItem";
import { HiOutlineLogout } from "react-icons/hi";
import {
  QueueListIcon,
  CalendarDaysIcon,
  Cog8ToothIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { Squares2X2Icon } from "@heroicons/react/24/solid";
import {
  remindersRoute,
  calendarRoute,
  communityRoute,
  dashboardRoute,
  notesRoute,
  profileRoute,
  appointmentsRoute,
  aiRoute,
  insurancesRoute,
  plansRoute,
  documentRoute,
} from "../../utils/allRoutes";
import ls from "localstorage-slim";
import { httpGetWithTokenRest } from "../../utils/http_utils";
import { Link as ReachLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ScrollContainer } from "react-nice-scroll";
import "react-nice-scroll/dist/styles.css";
import { AppContext } from "../../redux/appContext";

function Sidebar() {
  const activeStyle = {
    borderRadius: "50px",
    background: "rgba(255, 255, 255, 0.1)",
  };

  const inActiveStyle = {
    borderRadius: "50px",
  };
  const navigate = useNavigate();
  const { subscription } = useContext(AppContext);

  const [isCoCaregiver, setIsCaregiver] = useState(false);
  const [isCareChatMember, setIsCareChatMember] = useState(false);
  const currentPath = window.location.pathname;
  const userPermission = ls.get("sj_cocg_permission", { decrypt: true });
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  // const user = JSON.parse(sessionStorage.getItem("sj_user"));
  useEffect(() => {
    // console.log(user)
    if (!user) {
      handleLogout();
    } else {
      setIsCaregiver(user.tag !== "cg");
      setIsCareChatMember(user.is_care_member);
    }
  }, []);
  const toast = useToast();
  const fetchUserPermission = async () => {
    const resp = await httpGetWithTokenRest("permissions");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      ls.set("sj_cocg_permission", resp.data.permissions, { encrypt: true });
    }
  };

  const handleLogout = async () => {
    window.location.href = "/login";
    // ls.clear();
    // sessionStorage.clear();
    // await httpGetWithTokenRest("caregiver/logout");
    // navigate(loginRoute);
  };

  React.useEffect(() => {
    if (user.tag === "cocg" && userPermission === null) {
      fetchUserPermission();
    }
  }, [userPermission]);
  React.useEffect(() => {
    fetchUserPermission();
  }, []);
  return (
    <Box position="fixed" zIndex={"12121"} left={0} top="0">
      <Box
        display={"flex"}
        flexDirection="column"
        w={"264px"}
        h={"100vh"}
        background={"primary"}
        overflowY={"auto"}
      >
        {/* comment here */}
        <Box margin={"10px 0"} paddingLeft="28px">
          <Image
            onClick={() => navigate("/dashboard")}
            src={Logo}
            height="34px"
            objectFit={"contain"}
          />
        </Box>
        <Box margin={"20px 0 0 0"} paddingLeft="28px" paddingRight={"14px"}>
          <Text color="white" opacity={0.5} fontSize="13px">
            MAIN
          </Text>
          {
            isCareChatMember
              ?
              <>
                {!isCoCaregiver ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === dashboardRoute ? activeStyle : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={Squares2X2Icon} fontSize={"22px"} />}
                      text="Home"
                      href={dashboardRoute}
                    />
                  </Box>
                ) : userPermission !== null &&
                  userPermission.includes("home:view") ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === dashboardRoute ? activeStyle : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={Squares2X2Icon} fontSize={"22px"} />}
                      text="Home"
                      href={dashboardRoute}
                    />
                  </Box>
                ) : null}


                {!isCoCaregiver ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === communityRoute ||
                        currentPath.includes(communityRoute)
                        ? activeStyle
                        : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={UserGroupIcon} fontSize={"22px"} />}
                      text="Care Circle"
                      href={communityRoute}
                    />
                  </Box>
                ) : userPermission !== null &&
                  userPermission.includes("community:view") ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === communityRoute ||
                        currentPath.includes(communityRoute)
                        ? activeStyle
                        : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={UserGroupIcon} fontSize={"22px"} />}
                      text="Care Circle"
                      href={communityRoute}
                    />
                  </Box>
                ) : null}

                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={QueueListIcon} fontSize={"22px"} />}
                      text="Care Plan"
                      href={"/profile/careplan"}
                    />
                  </Box>
              </>
              :
              <>
                {!isCoCaregiver ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === dashboardRoute ? activeStyle : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={Squares2X2Icon} fontSize={"22px"} />}
                      text="Home"
                      href={dashboardRoute}
                    />
                  </Box>
                ) : userPermission !== null &&
                  userPermission.includes("home:view") ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === dashboardRoute ? activeStyle : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={Squares2X2Icon} fontSize={"22px"} />}
                      text="Home"
                      href={dashboardRoute}
                    />
                  </Box>
                ) : null}

                {!isCoCaregiver ? (
                  // <Box
                  //   _hover={activeStyle}
                  //   margin={"10px 0 0 0"}
                  //   style={
                  //     currentPath === providersRoute ||
                  //     currentPath.includes(providersRoute)
                  //       ? activeStyle
                  //       : inActiveStyle
                  //   }
                  //   padding="10px 12px 10px"
                  // >
                  //   <SidebarItem
                  //     icon={<Icon as={BuildingOfficeIcon} fontSize={"22px"} />}
                  //     text="Providers"
                  //     href={providersRoute}
                  //   />
                  // </Box>
                  <></>
                ) : userPermission !== null &&
                  userPermission.includes("provider:view") ? (
                  // <Box
                  //   _hover={activeStyle}
                  //   margin={"10px 0 0 0"}
                  //   style={
                  //     currentPath === providersRoute ||
                  //     currentPath.includes(providersRoute)
                  //       ? activeStyle
                  //       : inActiveStyle
                  //   }
                  //   padding="10px 12px 10px"
                  // >
                  //   <SidebarItem
                  //     icon={<Icon as={BuildingOfficeIcon} fontSize={"22px"} />}
                  //     text="Providers"
                  //     href={providersRoute}
                  //   />
                  // </Box>
                  <></>
                ) : null}

                {!isCoCaregiver ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === calendarRoute ||
                        currentPath.includes(calendarRoute)
                        ? activeStyle
                        : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={CalendarDaysIcon} fontSize={"22px"} />}
                      text="Calendar"
                      href={calendarRoute}
                    />
                  </Box>
                ) : userPermission !== null &&
                  userPermission.includes("calendar:view") ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === calendarRoute ||
                        currentPath.includes(calendarRoute)
                        ? activeStyle
                        : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={CalendarDaysIcon} fontSize={"22px"} />}
                      text="Calendar"
                      href={calendarRoute}
                    />
                  </Box>
                ) : null}
               
                {!isCoCaregiver ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === communityRoute ||
                        currentPath.includes(communityRoute)
                        ? activeStyle
                        : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={UserGroupIcon} fontSize={"22px"} />}
                      text="Care Circle"
                      href={communityRoute}
                    />
                  </Box>
                ) : userPermission !== null &&
                  userPermission.includes("community:view") ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    style={
                      currentPath === communityRoute ||
                        currentPath.includes(communityRoute)
                        ? activeStyle
                        : inActiveStyle
                    }
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={UserGroupIcon} fontSize={"22px"} />}
                      text="Care Circle"
                      href={communityRoute}
                    />
                  </Box>
                ) : null}

                {!isCoCaregiver ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={QueueListIcon} fontSize={"22px"} />}
                      text="Task"
                      href={"/tasks"}
                    />
                  </Box>
                ) : userPermission !== null &&
                  userPermission.includes("task:view") ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Icon as={QueueListIcon} fontSize={"22px"} />}
                      text="Task"
                      href={"/tasks"}
                    />
                  </Box>
                ) : null}
                <Box
                  _hover={activeStyle}
                  margin={"10px 0 0 0"}
                  padding="10px 12px 10px"
                >
                  <SidebarItem
                    icon={<Image src={remindersIcon} boxSize={"22px"} />}
                    text="Appointments"
                    href={appointmentsRoute}
                  />
                </Box>
                <Box
                  _hover={activeStyle}
                  margin={"10px 0 0 0"}
                  padding="10px 12px 10px"
                >
                  <SidebarItem
                    icon={<Image src={remindersIcon} boxSize={"22px"} />}
                    text="Remind me"
                    href={remindersRoute}
                  />
                </Box>



                {!isCoCaregiver ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Image src={noteIcon} boxSize={"22px"} />}
                      text="Jorner's Schedule"
                      href={notesRoute}
                    />
                  </Box>

                ) : userPermission !== null &&
                  userPermission.includes("jorner_profile:view") ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Image src={noteIcon} boxSize={"22px"} />}
                      text="Jorner's Schedule"
                      href={notesRoute}
                    />
                  </Box>
                ) : null}

                {!isCoCaregiver ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Image src={aiNote} boxSize={"22px"} />}
                      text="Jorner's AI Note"
                      href={aiRoute}
                    />
                  </Box>
                ) : userPermission !== null &&
                  userPermission.includes("jorner_profile:view") ? (
                  <Box
                    _hover={activeStyle}
                    margin={"10px 0 0 0"}
                    padding="10px 12px 10px"
                  >
                    <SidebarItem
                      icon={<Image src={aiNote} boxSize={"22px"} />}
                      text="Jorner's AI Note"
                      href={aiRoute}
                    />
                  </Box>

                ) : null}


                <Box
                  _hover={activeStyle}
                  margin={"10px 0 0 0"}
                  padding="10px 12px 10px"
                >
                  <SidebarItem
                    icon={<Image src={insuranceCardImg} boxSize={"22px"} />}
                    text="Insurance Card"
                    href={insurancesRoute}
                  />
                </Box>

                <Box
                  _hover={activeStyle}
                  margin={"10px 0 0 0"}
                  padding="10px 12px 10px"
                >
                  <SidebarItem
                    icon={<Image src={insuranceCardImg} boxSize={"22px"} />}
                    text="Documents"
                    href={documentRoute}
                  />
                </Box>
              </>
          }
        </Box>
        <Box margin={"20px 0 0 0"} paddingLeft="28px" paddingRight={"14px"}>
          <Text color="white" opacity={0.5} fontSize="13px">
            SETTINGS
          </Text>
          <Box
            _hover={activeStyle}
            margin={"10px 0 0 0"}
            style={
              currentPath === profileRoute || currentPath.includes(profileRoute)
                ? activeStyle
                : inActiveStyle
            }
            padding="10px 12px 10px"
          >
            <SidebarItem
              icon={<Icon as={Cog8ToothIcon} fontSize={"22px"} />}
              text="Profile Settings"
              href={profileRoute}
            />
          </Box>
        </Box>
        <Spacer />
        <Box margin={"20px 0 0 0"} paddingLeft="28px" paddingRight={"14px"}>
          <Link
            display={{ base: "block", md: "none" }}
            style={{ textDecoration: "none" }}
            as={ReachLink}
            to={isCoCaregiver ? "" : plansRoute}
          >
            <Flex
              w="220px"
              justifyContent={{ base: "flex-start", md: "space-between" }}
              alignItems={"center"}
              borderRadius={"60px"}
              shadow={"sm"}
              bg="#EBECEF"
              gap="2"
              p="2"
            >
              <Image src={subscriptinBadge} boxSize={"22px"} />
              <Text fontWeight={"600"} fontSize={"12"} color={"#17181C"}>
                {subscription.plan_name} Plan
              </Text>
              {
                !isCoCaregiver
                &&
                <>
                  {subscription?.plan_name?.toLowerCase() != "premium" &&
                    <Box
                      style={{ textDecoration: "none" }}
                      fontWeight={"600"}
                      fontSize={"12"}
                      bg={"primary"}
                      borderRadius={"25px"}
                      color="white"
                      p="2"
                    >
                      Upgrade
                    </Box>
                  }
                </>
              }

            </Flex>
          </Link>

          <Box
            _hover={activeStyle}
            margin={"10px 0 0 0"}
            padding="10px 12px 10px"
            onClick={() => handleLogout()}
          >
            <SidebarItem
              icon={<HiOutlineLogout size={22} />}
              text="Logout"
            // href={"/"}
            />
          </Box>
          <Box height={"100px"} />

        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;
