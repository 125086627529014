import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLogo, LoginImg, Logo, successGif } from "../../images";
import CustomModal from "../../components/customModal";
import { otpForgetPasswordRoute } from "../../utils/allRoutes";
import LoginSide from "../../components/loginSide";
import ls from "localstorage-slim";
import { httpPostWithoutToken } from "../../utils/http_utils";

function ForgetPassword() {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const emailAddress = ls.get('sj_user', {decrypt: true})
  // console.log(emailAddress)

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    const resp = await httpPostWithoutToken(
      "caregiver/forgot-password",
      values
    );
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      console.log(resp)
      ls.set("sj_email", values.email, { encrypt: true });
      onOpen();
    }
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     onOpen();
    //     resolve();
    //   }, 3000);
    // });
  }

  const onDone = () => {
    navigate(otpForgetPasswordRoute);
  };

  return (
    <>
      <Flex min-height={"100vh"} width={"100%"}>
        <LoginSide />
        <Box
          zIndex={2}
          w={{ base: "100%", md: "50%" }}
          background={"white"}
          position="relative"
          overflowY={"scroll"}
          flex="1"
          borderTopRadius={{ base: "20px", md: 0 }}
          paddingTop={{ base: "40px", md: 0 }}
          display="flex"
          h={{ base: "calc(100vh - 30px)", md: "100vh" }}
          flexDirection={{ base: "column", md: "row" }}
          m={{ base: "30px 0 0 0", md: "0" }}
        >
          <Box display={{ md: "none" }} borderRadius={20}>
            <Center p={2}>
              <Image src={AppLogo} />
            </Center>
          </Box>

          <AbsoluteCenter p="4" axis="both" width={{ base: "auto", md: "70%" }}>
            <Heading
              pt={8}
              display={"flex"}
              justifyContent="center"
              flexWrap="nowrap"
              fontWeight={600}
              fontSize="32px"
              color={"black"}
            >
              Forgot Password
            </Heading>
            <Text py={8} fontSize={16} fontWeight={400} textAlign={"center"}>
              Enter your registered email address
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl
                mt={5}
                isInvalid={errors.emailAddress}
                fontWeight={600}
              >
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  id="email"
                  placeholder="e.g. jane@sojorne.com"
                  {...register("email", {
                    required: "This is required",
                    validate: {
                      matchPattern: (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        "Email address must be a valid address",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>

              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                isLoading={isSubmitting}
                type="submit"
              >
                Get OTP
              </Button>
              <Button
                mt={2}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#747A8B"
                borderRadius={64}
                background={"#fff"}
                border={"1px solid #7353BA"}
              >
                <Text
                  textAlign={"right"}
                  fontWeight={400}
                  color={"black"}
                  fontSize={16}
                  m={3}
                >
                  Remember Password?
                  <Text as={"span"} color={"secondary"}>
                    <ReachLink to={"/login"}> Log In</ReachLink>
                  </Text>
                </Text>
              </Button>
            </form>
          </AbsoluteCenter>
        </Box>
      </Flex>

      <CustomModal
        onClose={onClose}
        onDone={onDone}
        isOpen={isOpen}
        body={
          <Box>
            <Center>
              <Image src={successGif} />
            </Center>
            <Text textAlign={"center"} fontSize={20} fontWeight={600}>
              Check your email
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              OTP has been sent successfully to your email
            </Text>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={onDone}
            >
              Ok
            </Button>
          </Box>
        }
      />
    </>
  );
}

export default ForgetPassword;
