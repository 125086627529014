import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  finance,
  noise,
  notask,
  provider,
  team,
  tribe,
  who,
} from "../../images";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import NoTask from "../../components/dashboard/no_task";
import JornerCard from "../../components/dashboard/jorner_card";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/24/solid";
import AddJorner from "../../components/dashboard/add_jorner_card";
import TaskCard from "../../components/dashboard/task_card";
import CreateTaskModal from "./create_task_modal";
import { useNavigate } from "react-router-dom";
import { httpGetWithTokenRest } from "../../utils/http_utils";

function TaskPage() {
  // const [tasks, setTasks] = useState([1,2,3,4,5,6])
  const [tasktype, setTasktype] = useState("");
  const [todos, setTodos] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const finishSelectionType = (arr) => {
    navigate("/task/create");
    setTasktype(arr);
    // setValue('strength', arr)
  };
  useEffect(() => {
    loadConstants();
  }, []);

  const loadConstants = async () => {
    var j = await httpGetWithTokenRest("tasks?status=to do");
    if (j.status == "success") {
      var tasks = j.data;
      var todo = tasks.filter((c) => c.status === "to do");
      setTodos(todo);
    }

    var j = await httpGetWithTokenRest("tasks?status=completed");
    if (j.status == "success") {
      setCompletedTasks(j.data);
    }
    setLoading(false);
  };

  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      <CreateTaskModal
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
        selectedOriginal={tasktype}
        onFinished={finishSelectionType}
      />
      {/* tsak */}
      <Spacer height={"22px"} />
      <Box>
        <Flex alignItems={"center"} justifyContent="space-between">
          <Heading as={"h3"} fontSize={"18px"} fontWeight="600" color="black">
            Tasks
          </Heading>

          <Button
            borderRadius={"50px"}
            onClick={onOpenCreate}
            colorScheme={"purple"}
          >
            {" "}
            <Icon color={"white"} as={PlusIcon} />
            &nbsp; Create Task
          </Button>
        </Flex>
        <Box h="10px" />
        {loading && (
          <Stack mt={5}>
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
          </Stack>
        )}
        {!loading && todos.length === 0 && completedTasks.length === 0 ? (
          <>
            <Box
              shadow={"sm"}
              background="white"
              padding="30px"
              borderRadius={"16px"}
              display="flex"
              flexDirection="column"
              justifyContent={"center"}
              alignItems="center"
            >
              <Image src={notask} boxSize={"105px"}></Image>
              <Box h="10px" />
              <Heading as="h6" fontSize={"16px"}>
                No Tasks
              </Heading>
              <Box h="10px" />

              <Box textAlign={"center"} maxW="480px">
                <Text color="paragraph">
                  The tasks tool to help you to stay organized and helps you
                  manage your day-to-day activity.
                </Text>
                <Box h="10px" />

                <AppLink href="/task/create" text="Create task" />
                <Box h="5px"></Box>
                <AppLink
                  href="#?"
                  onclick={onOpenCreate}
                  textDecoration="underline"
                  text="Understand how Sojorne task works"
                  color="primary"
                  padding="0"
                  height="fit-content"
                  background="transparent"
                  fontWeight="500"
                  shadow="none"
                />
              </Box>
            </Box>
          </>
        ) : (
          <Box
            shadow={"sm"}
            background="white"
            padding="30px"
            borderRadius={"16px"}
          >
            <Tabs borderColor={"#F1F2F4"} colorScheme="red">
              <TabList
                flexDirection={{
                  base: "column",
                  md: "row",
                }}
              >
                <Tab px="30px" fontWeight={"600"}>
                  To-do ({todos.length})
                </Tab>
                <Tab px="30px" fontWeight={"600"}>
                  Completed ({completedTasks.length})
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  {/* a task */}
                  {todos.map((item, i) => (
                    <TaskCard task={item} key={i} />
                  ))}
                </TabPanel>
                <TabPanel>
                  {completedTasks.map((item, i) => (
                    <TaskCard task={item} key={i} />
                  ))}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </Box>
      <Spacer height={"22px"} />
    </Box>
  );
}

export default TaskPage;
