import React from "react";
import {
  who,
  squareGreen,
  calendarBlue,
  bellIconGreen,
  editIconGreen,
  deleteIconRed,
} from "../../images";
import {
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import moment from "moment";
import { capitalizeFirstWord } from "../../utils/utils";
import { BiDotsHorizontal, BiDotsVertical } from "react-icons/bi";
function ReminderCard({ appointment, editAppointment, deleteAppointment }) {
  return (
    <Box my="2">
      <Flex m={0} p={0} justifyContent={"space-between"}>
        <Heading fontSize={"14px"} fontWeight="600">
          {appointment.name}{" "}
          <Text fontWeight={400} fontSize={"12px"} as={"span"}>
            {appointment.department}
          </Text>
        </Heading>
        <Menu>
          <MenuButton
            background="transparent"
            border={0}
            color="black"
            fontSize={"13px"}
            as={Button}
            rightIcon={<BiDotsVertical />}
            borderRadius="0"
            fontWeight={600}
          ></MenuButton>
          <MenuList border={0}>
            <MenuItem onClick={() => editAppointment(appointment)}>
              {" "}
              <Image src={editIconGreen} boxSize={"16px"} /> &nbsp; Edit
              Reminder
            </MenuItem>
            <MenuItem onClick={() => deleteAppointment(appointment)}>
              {" "}
              <Image src={deleteIconRed} boxSize={"16px"} /> &nbsp; Delete
              Reminder
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <Heading fontSize={"13px"} fontWeight="600">
        {appointment.country_code} {appointment.phone_number}
      </Heading>
      <Box height="5px" />
      <Text color="paragraph" fontSize="12px">
        {appointment.description}
      </Text>
      <Box height="5px" />
      <Flex gap="3">
        <Flex
          padding={"4px 8px"}
          borderRadius="10px"
          background="successLight"
          alignItems="center"
        >
          <Image src={calendarBlue} boxSize="22px"></Image>
          <Text color="#1DA2C9" fontSize={"12px"}>
            {appointment.appt_date}
          </Text>
        </Flex>

        <Flex
          padding={"4px 8px"}
          borderRadius="10px"
          background="successLight"
          alignItems="center"
        >
          <Image src={bellIconGreen} boxSize="22px"></Image>
          <Text color="success" fontSize={"12px"}>
            {capitalizeFirstWord(appointment.reminder)} : {moment(`2024-01-01 ${appointment.reminder_time}`).format("h:mm a")}
          </Text>
        </Flex>
      </Flex>

      <Box height="35px" />
      <Divider color={"#EBECEF"} />
      <Box height="15px" />
    </Box>
  );
}

export default ReminderCard;
