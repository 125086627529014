import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useToast,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { basic, iconCompletey, standard, starter } from "../../images";
import { useNavigate } from "react-router-dom";
import { httpGetWithTokenRest } from "../../utils/http_utils";
import moment from "moment";

export default function SubscriptionModal({
  isOpen,
  onClose,
  plan,
  planType,
  onFinished,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [subPlan, setSubPlan] = useState(plan);
  const [subType, setSubType] = useState(false);
  const [is100, setIs100] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [showCouponBox, setShowcouponBox] = useState(false);

  const [isCoupon, setIsCoupon] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const finishSelection = () => {
    onFinished(subPlan, couponCode, coupon ? coupon.amount_to_pay : 1);
    onClose();
  };
  const toast = useToast();
  const planImages = {
    starter: starter,
    basic: basic,
    standard: standard,
  };

  useEffect(() => {
    setSubPlan(plan);
    setSubType(planType);
    setCoupon(null)
    setCouponCode("")
  }, [plan, planType]);

  const validateCoupon = async () => {
    setCouponLoading(true);
    let isPercent = false;
    let resp = await httpGetWithTokenRest("coupons/code/" + couponCode);
    setCouponLoading(false);
    if (resp.status == "success") {
      let c = resp.data;
      let packages = c.package_id.split(",");
      if (!packages.includes(subPlan.id)) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Invalid Coupon code`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (moment() > moment(c.valid_until)) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Invalid Coupon code`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      let cType = c.coupon_type;
      let cAmount = c.coupon_amount;
      let newPrice = 0;
      let currentPrice =
        subType == "monthly" ? subPlan.monthly : subPlan.yearly;

      if (cType == "percentage") {
        cAmount = parseFloat(cAmount);
        let percent = (cAmount / 100) * parseFloat(currentPrice);
        newPrice = parseFloat(currentPrice) - percent;
      }
      if (cType == "flat rate") {
        newPrice = parseFloat(currentPrice) - parseFloat(cAmount);
      }
      if (cType == "amount") {
        cAmount = parseFloat(cAmount);
        newPrice = cAmount;
      }
      let coupp = resp.data;
      coupp.amount_to_pay = newPrice.toFixed(2);
      if(newPrice === 0) {
        setIs100(true)
      }else{
        if (newPrice < 0.5) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `Invalid coupon, minimum payable amount is 0.5USD`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
      setCoupon(coupp);
    } else {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader display={"flex"} alignItems={"center"} w={"100%"}>
          <Text>Subscription Plan</Text>
        </ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>

        <ModalBody>
          <Divider color={"#EBECEF"} />
          <Box h="12px" />
          <Image
            mx="auto"
            shadow={"sm"}
            src={planImages["basic"]}
            boxSize={"56px"}
          />
          <Box h="10px" />
          <ModalHeader textAlign={"center"}>
            {subPlan?.plan_name} Plan
          </ModalHeader>
          <Text textAlign={"center"}>
            You are about to subscribe to Sojorne {subPlan?.plan_name} plan
          </Text>

          <Box w="100%" mt={"25px"}>
            <Button
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => finishSelection()}
            >
              {coupon && coupon.amount_to_pay == 0 ?
              "Subscribe"
              :
              <>
              Pay $
              {coupon
                ? coupon.amount_to_pay
                : subType == "monthly"
                ? plan.monthly
                : plan.yearly
              }
              </>
              }
            </Button>
            <Box h="10px" />
            {coupon ? (
              <Box textAlign={"center"}>
                <Text>
                {coupon && coupon.amount_to_pay == 0 
                ?
                "" 
                :
                  <>Proceed to pay{" "}
                  <del style={{ color: "#ccc" }}>
                    ${subType == "monthly" ? plan.monthly : plan.yearly}
                  </del>{" "}
                  <strong>${coupon.amount_to_pay}</strong>
                  </>
                  }
                </Text>

                <Button
                  onClick={() => {
                    setCoupon(null);
                    setShowcouponBox(false);
                  }}
                  h="40px"
                  color={"red"}
                >
                  Cancel
                </Button>
              </Box>
            ) : (
              <Box>
                {showCouponBox ? (
                  <Box
                    mt={"30px"}
                    bg="#eee"
                    p="3"
                    display={"flex"}
                    flexDir={"column"}
                    justifyContent={"space-between"}
                  >
                    <Heading
                      textAlign={"center"}
                      fontWeight={"bold"}
                      fontSize={"14px"}
                    >
                      Enter Coupon Code
                    </Heading>
                    <Box h="5px" />
                    <Input
                      id="email"
                      placeholder="Enter coupon code"
                      borderColor={"#E3E4E8"}
                      bg={"white"}
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <Box h="5px" />
                    <Box display={"flex"} gap={3} justifyContent={"center"}>
                      <Button
                        onClick={validateCoupon}
                        loading={isLoading}
                        colorScheme="purple"
                        h="40px"
                        text
                        isLoading={couponLoading}
                      >
                        Validate
                      </Button>

                      <Button
                        onClick={() => {
                          setShowcouponBox(false);
                        }}
                        h="40px"
                        color={"red"}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box textAlign={"center"}>
                    <Text
                      onClick={() => setShowcouponBox(true)}
                      cursor={"pointer"}
                      fontSize={"14px"}
                      fontWeight={600}
                      color={"green.600"}
                    >
                      Apply coupon
                    </Text>
                  </Box>
                )}
              </Box>
            )}
            <Box h="15px" />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
