import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useToast,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { checkIconSuccess } from "../../images";

export default function GroupMemberAdded({ isOpen, onClose, onFinished }) {
  const finishSelection = () => {
    onFinished();
    onClose();
  };
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} maxW={"340px"} p={4}>
        <ModalBody textAlign={"center"} marginBottom={"0"}>
          <Flex justifyContent={"center"}>
            <Image src={checkIconSuccess} boxSize={"150px"} />
          </Flex>
          <ModalHeader textAlign={"center"}>New Members Added!</ModalHeader>
          <Text color={"#747A8B"} textAlign={"center"}>
            You have successfully added member(s) to this Care circle
          </Text>
          <Box w="100%" mt={"25px"}>
            <Button
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => finishSelection()}
            >
              Ok
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
