import { CheckIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
  InputRightElement,
  FormLabel,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { DatePicker } from "rsuite";
import moment from "moment";
import { BiCaretDown } from "react-icons/bi";
import { CalendarDaysIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

export default function CreateReminderFirstModal({
  isOpen,
  onClose,
  selectedOriginal,
  onFinished,
}) {
  const [selected, setselected] = React.useState("");
  const [selectedCalendar, setSelectedCalendar] = React.useState("");

  const finishSelection = () => {
    if (selected == "") return false;
    onFinished(selected);
    onClose();
  };
  const [isPicker, setIsPicker] = useState(false);
  useEffect(() => {
    setIsPicker(false);
    setselected("");
  }, [selectedOriginal]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader>Remind Me!</ModalHeader>
        <ModalCloseButton onClick={() => onClose()}>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <DatePicker
          onOk={(value) => {
            var d = new Date(value);
            // setSelectedCalendar(moment(d).format("MMMM Do YYYY, h:mm:ss a"));
            var date = moment(d).format("MMM, YYYY");
            setselected(date);
            setIsPicker(false);
            // formik.setFieldValue("appointment_date", date);
            // setShowWhen(false)
          }}
          open={isPicker}
          format="yyyy-MM"
        />
        <ModalBody marginBottom={"0"}>
          <Divider color={"#EBECEF"} />
          <Box w="100%" mt={"5px"}>
            <Box gap={3} mb="20px" alignItems="start">
              <Text>When does your provider wants to see your Jorner?</Text>
              <Box></Box>
            </Box>
            <FormLabel htmlFor="date">Date</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
              >
                <Icon as={CalendarDaysIcon} color="black.500" />
              </InputLeftElement>
              <Input
                value={selected}
                onChange={() => {}}
                onClick={() => {
                  setIsPicker(!isPicker);
                }}
                onFocus={() => setIsPicker(true)}
                placeholder="Select date "
              />
              <InputRightElement>
                <Icon as={ChevronDownIcon} color="black.500" />
              </InputRightElement>
            </InputGroup>
            <Button
              mt={8}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => finishSelection()}
            >
              Continue
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
