import {
  Box,
  useToast,
  RadioGroup,
  useDisclosure,
  Radio,
  Link,
  Select,
  Image,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Button,
  Divider,
  Grid,
  GridItem,
  Input,
  VStack,
  FormLabel,
  FormControl,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  FormErrorMessage,
  InputRightElement,
} from "@chakra-ui/react";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import JornerCard from "../../components/dashboard/jorner_card";
import {
  PencilSquareIcon,
  PlusIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/solid";
import AddJorner from "../../components/dashboard/add_jorner_card";
import JornerHeader from "../../components/dashboard/jorner_header";
import NoTabItem from "../../components/dashboard/no_tab_item";
import TabListItem from "../../components/dashboard/tab_list_item";
import { medicationIcon } from "../../images";
import { useFormik } from "formik";
import { ChevronDownIcon, PhoneIcon } from "@chakra-ui/icons";
import { BiCaretDown } from "react-icons/bi";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import PhoneNumberInput2 from "../../components/phoneNumberInput2";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import StrengthModal from "../onboarding/strengthModal";
import WhatWorksForMeModal from "../onboarding/whatWorksForMeModal";
import WhatDoesNotWorksForMeModal from "../onboarding/whatDoesNotWorksForMeModal";
import GoalsModal from "../onboarding/goalsModal";
import MedicationModal2 from "../onboarding/medicationModal2";
import ls from "localstorage-slim";

function AddJornerPage2() {
  const [jorners, setJorners] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 0]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  var toast = useToast();

  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const {
    isOpen: isOpenStrength,
    onOpen: onOpenStrength,
    onClose: onCloseStrength,
  } = useDisclosure();
  const [disabilitiesConstantsOriginal, setDisabilitiesConstantsOriginal] =
    React.useState({});
  const [strengthConstantsOriginal, setStrengthConstantsOriginal] =
    React.useState({});
  const [whatWorksForMeOriginal, setWhatWorksForMeOriginal] = React.useState(
    {}
  );
  const [whatDoesNotWorksForMeOriginal, setWhatDoesNotWorksForMeOriginal] =
    React.useState({});
  const [goalsOriginal, setGoalsOriginal] = React.useState({});
  const [medicationOriginal, setMedicationOriginal] = React.useState({});
  const [dietaryRestrictionsOriginal, setDietaryRestrictionsOriginal] =
    React.useState({});
  const [allergiesOriginal, setAllergiesOriginal] = React.useState({});
  const [selectedStrength, setSelectedStrength] = React.useState([]);
  const [jornerId, setJornerId] = React.useState("");

  const [showStrengtModal, setShowStrengtModal] = useState(true);
  const [contact1PhoneCode, setContact1PhoneCode] = React.useState("+1");
  const [contact1Phone, setContact1Phone] = React.useState("");
  const [contact2PhoneCode, setContact2PhoneCode] = React.useState("+1");
  const [contact2Phone, setContact2Phone] = React.useState("");
  const [phone, setPhone] = useState("second");
  const {
    isOpen: isOpenWhatWorksForMe,
    onOpen: onOpenWhatWorksForMe,
    onClose: onCloseWhatWorksForMe,
  } = useDisclosure();
  const {
    isOpen: isOpenWhatDoesNotWorkForMe,
    onOpen: onOpenWhatDoesNotWorkForMe,
    onClose: onCloseWhatDoesNotWorkForMe,
  } = useDisclosure();
  const {
    isOpen: isOpenGoals,
    onOpen: onOpenGoals,
    onClose: onCloseGoals,
  } = useDisclosure();
  const {
    isOpen: isOpenMedication,
    onOpen: onOpenMedication,
    onClose: onCloseMedication,
  } = useDisclosure();
  const {
    isOpen: isOpenDietaryRestrictions,
    onOpen: onOpenDietaryRestrictions,
    onClose: onCloseDietaryRestrictions,
  } = useDisclosure();
  const {
    isOpen: isOpenAllergies,
    onOpen: onOpenAllergies,
    onClose: onCloseAllergies,
  } = useDisclosure();
  const [selectedCoCaregivers, setSelectedCoCaregivers] = React.useState([]);
  const [selectedDisability, setSelectedDisability] = React.useState([]);
  const [selectedWhatWorksForMe, setSelectedWhatWorksForMe] = React.useState(
    []
  );
  const [selectedWhatDoesNotWorksForMe, setSelectedWhatDoesNotWorksForMe] =
    React.useState([]);
  const [selectedGoals, setSelectedGoals] = React.useState([]);
  const [selectedMedication, setSelectedMedication] = React.useState([]);
  const [selectedDietaryRestrictions, setSelectedDietaryRestrictions] =
    React.useState([]);
  const [selectedAllergies, setSelectedAllergies] = React.useState([]);

  const [emergencyContact1, setEmergencyContact1] = React.useState("");
  const [emergencyContact2, setEmergencyContact2] = React.useState("");
  const [displayMedication, setDisplayMedication] = useState("");
  // jorner

  const updateJorner = async (data) => {
    // caregivers/jorners/:jorner_id
    //  console.log(data)
    setIsLoading(true);
    var response = await httpPutWithTokenRest(
      "caregivers/jorners/" + jornerId,
      data
    );
    if (response.error) {
      toast({
        title: "Sorry, we hit a snag!",
        description: `${response.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return false;
    } else {
      toast({
        title: "Jorner updated successfully",
        // description: `${response.error}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);

      return true;
    }
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      type: "",
      has_disability: "",
      disabilities: [],
      what_works_for_me: [],
      what_doesnt_work_for_me: [],
      goals_am_working_on: [],
      dietary_restrictions: [],
      dietary_restrictions: [],
      allergies: [],
      strengths: [],
      medications: [],
      contact_1_country_code: "+1",
      contact_2_country_code: "+1",
      contact_1: "",
      contact_2: "",
    },
    onSubmit: async (values) => {
      const sccg = selectedCoCaregivers.map((item, i) => {
        return item.value;
      });
      if (values.contact_1 == "") {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Please input emergency contact`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.contact_1.length < 7) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Invalid Emergency Contact 1`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.contact_2 !== "") {
        if (values.contact_2.length < 7) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `Invalid Emergency Contact 2`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
      var data = {
        // first_name: values.first_name,
        // last_name: values.last_name,
        // type: values.type,
        // has_disability: values.has_disability,
        // disabilities: values.disabilities,
        // what_works_for_me: values.what_works_for_me,
        // what_doesnt_work_for_me: values.what_doesnt_work_for_me,
        // goals_am_working_on: values.goals_am_working_on,
        // dietary_restrictions: values.dietary_restrictions,
        // allergies: values.allergies,
        // strengths: values.strengths,
        // medications: values.medications,
        // caregivers: sccg,
      };

      data.contacts = {
        emergency_contact_one: {
          country_code: values.contact_1_country_code,
          phone_number: values.contact_1,
        },
        emergency_contact_two: {
          country_code: values.contact_2_country_code,
          phone_number: values.contact_2,
        },
      };

      //  console.log(data)
      var response = await httpPutWithTokenRest(
        "caregivers/jorners/" + jornerId,
        data
      );
      if (response.error) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Jorner updated successfully",
          // description: `${response.error}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setTimeout(() => {
          navigate("/jorner/" + jornerId);
        }, 1000);
      }
      console.log(response);
      // navigate("add-jorner/profile")
    },
  });
  const fetchJornersConstants = async () => {
    const resp = await httpGetWithTokenRest("constants");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setDisabilitiesConstantsOriginal(resp.data.disabilities);
      setStrengthConstantsOriginal(resp.data.strengths);
      setWhatWorksForMeOriginal(resp.data.what_works_for_me);
      setWhatDoesNotWorksForMeOriginal(resp.data.what_does_not_work_for_me);
      setGoalsOriginal(resp.data.goals);
      setMedicationOriginal(resp.data.medications);
      setDietaryRestrictionsOriginal(resp.data.dietary_restrictions);
      setAllergiesOriginal(resp.data.allergies);
    }
  };

  const finishSelectionStrength = async (arr) => {
    var r = await updateJorner({ strengths: arr });
    if (r) {
      setSelectedStrength(arr);
      formik.values.strengths = arr;
    }
  };

  const finishSelectionWhatWorksForMe = async (arr) => {
    var r = await updateJorner({ what_works_for_me: arr });
    if (r) {
      setSelectedWhatWorksForMe(arr);
      formik.values.what_works_for_me = arr;
    }
  };

  const finishSelectionWhatDoesNotWorksForMe = async (arr) => {
    var r = await updateJorner({ what_doesnt_work_for_me: arr });
    if (r) {
      setSelectedWhatDoesNotWorksForMe(arr);
      // setValue('what_doesnt_work_for_me', arr)
      formik.values.what_doesnt_work_for_me = arr;
    }
  };

  const finishSelectionGoals = async (arr) => {
    var r = await updateJorner({ goals_am_working_on: arr });
    if (r) {
      setSelectedGoals(arr);
      // setValue('goals_am_working_on', arr)
      formik.values.goals_am_working_on = arr;
    }
  };

  const finishSelectionMedications = async (arr) => {
    const r = await updateJorner({ medications: arr });
    if (r) {
      setSelectedMedication(arr);
      formik.values.medications = arr;
      var joinedMed = "";
      arr.map((item) => {
        var newmed = item.brand_name + " " + item.generic_name;
        joinedMed = joinedMed == "" ? newmed : joinedMed + ", " + newmed;
      });

      setDisplayMedication(joinedMed);
    }
  };

  const finishSelectionDietaryRestrictions = async (arr) => {
    var r = await updateJorner({ dietary_restrictions: arr });
    if (r) {
      setSelectedDietaryRestrictions(arr);
      // setValue('dietary_restrictions', arr)
      formik.values.dietary_restrictions = arr;
    }
  };

  const finishSelectionAllergies = async (arr) => {
    var r = await updateJorner({ allergies: arr });
    if (r) {
      setSelectedAllergies(arr);
      formik.values.allergies = arr;
    }
  };

  useEffect(() => {
    fetchJornersConstants();
  }, []);
  useEffect(() => {
    var data = ls.get("sj_jorner1", { decrypt: true });
    // setSelectedCoCaregivers(data.caregivers);
    if (data) {
      setJornerId(data.id);
      formik.values.first_name = data.first_name;
      formik.values.last_name = data.last_name;
      formik.values.has_disability = data.has_disability;
      formik.values.type = data.type;
      formik.values.disabilities = data.disabilities;
      getJornerDetails(data);
      // setSelectedDisability(data.disabilities);
    } else {
      toast({
        title: "Sorry, we hit a snag!",
        description: `Invalid data`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setTimeout(() => {
        navigate("/add-jorner");
      }, 2000);
    }
  }, []);
  const getJornerDetails = async (data) => {
    var j = await httpGetWithTokenRest("/jorner/" + data.id);
    if (j.status == "success") {
      // console.log(j.data)
      setSelectedStrength(j.data.disabilities ? j.data.disabilities : []);
      setSelectedStrength(j.data.strengths ? j.data.strengths : []);
      setSelectedGoals(
        j.data.goals_am_working_on ? j.data.goals_am_working_on : []
      );
      setSelectedAllergies(j.data.allergies ? j.data.allergies : []);
      setSelectedMedication(j.data.medications ? j.data.medications : []);
      if (j.data.medications) {
        var joinedMed = "";
        j.data.medications.map((item) => {
          var newmed = item.brand_name + " " + item.generic_name;
          joinedMed = joinedMed == "" ? newmed : joinedMed + ", " + newmed;
        });
        setDisplayMedication(joinedMed);
      }
      setSelectedDietaryRestrictions(
        j.data.dietary_restrictions ? j.data.dietary_restrictions : []
      );
      setSelectedWhatDoesNotWorksForMe(
        j.data.what_doesnt_work_for_me ? j.data.what_doesnt_work_for_me : []
      );
      setSelectedWhatWorksForMe(
        j.data.what_works_for_me ? j.data.what_works_for_me : []
      );
    }
  };
  return (
    <Box
      padding={"19px 20px"}
      height="100%"
      minHeight={"100vh"}
      background={"primaryBg"}
    >
      <Box h="42px" />
      <Box>
        <StrengthModal
          isOpen={isOpenStrength}
          defaultV={selectedStrength ?? []}
          onClose={onCloseStrength}
          strengthConstantsOriginal={strengthConstantsOriginal}
          onFinished={finishSelectionStrength}
        />

        <WhatWorksForMeModal
          isOpen={isOpenWhatWorksForMe}
          onClose={onCloseWhatWorksForMe}
          whatWorksForMeOriginal={whatWorksForMeOriginal}
          onFinished={finishSelectionWhatWorksForMe}
          showOthers={true}
        />

        <WhatDoesNotWorksForMeModal
          isOpen={isOpenWhatDoesNotWorkForMe}
          onClose={onCloseWhatDoesNotWorkForMe}
          wDWFMConstantsOriginal={whatDoesNotWorksForMeOriginal}
          onFinished={finishSelectionWhatDoesNotWorksForMe}
          title="What Does Not Work For Me"
        />

        <GoalsModal
          isOpen={isOpenGoals}
          onClose={onCloseGoals}
          goalsConstantsOriginal={goalsOriginal}
          onFinished={finishSelectionGoals}
          title="Goals I am working on"
        />

        <MedicationModal2
          isOpen={isOpenMedication}
          onClose={onCloseMedication}
          selected={selectedMedication}
          medicationConstantsOriginal={medicationOriginal}
          onFinished={finishSelectionMedications}
        />
        {/* //This is for Dietary */}
        <WhatDoesNotWorksForMeModal
          isOpen={isOpenDietaryRestrictions}
          onClose={onCloseDietaryRestrictions}
          wDWFMConstantsOriginal={dietaryRestrictionsOriginal}
          onFinished={finishSelectionDietaryRestrictions}
          title="Dietary Restrictions"
        />

        {/* This is for allergies */}
        <GoalsModal
          isOpen={isOpenAllergies}
          onClose={onCloseAllergies}
          goalsConstantsOriginal={allergiesOriginal}
          onFinished={finishSelectionAllergies}
          title="Allergies"
        />
      </Box>
      <Box
        margin={"0 auto"}
        borderRadius="15px"
        maxWidth={"720px"}
        width={"100%"}
        shadow={"sm"}
        background="white"
      >
        <Box h="42px" />
        <Box margin={"0 auto"} width={{ base: "95%", md: "80%" }}>
          <Flex alignItems={"center"}>
            <AppLink
              hasLeftIcon={true}
              leftIcon={<Icon as={ChevronLeftIcon} fontSize="17px" />}
              shadow="none"
              padding="0"
              text=""
              background="transparent"
              color="black"
              width="fit-content"
              textDecoration="none"
              href="/add-jorner"
            />
            <Box width="4px" />
            <Heading
              color={"black"}
              fontSize={"15px"}
              fontWeight={"600"}
              as={"h3"}
            >
              Back
            </Heading>
          </Flex>

          <Box>
            <Heading fontSize={"20px"}>Create Jorners Profile</Heading>
            <Text>Name of Loved one, age, disability...</Text>
            <Box height={"15"} />
            <Grid templateColumns={"repeat(2, 1fr)"} gap="4">
              <GridItem
                width={"100%"}
                borderRadius="60px"
                height="6px"
                background={"#0CCA4A"}
              />
              <GridItem
                width={"100%"}
                borderRadius="60px"
                height="6px"
                background={"#0CCA4A"}
              />
            </Grid>
            <Box height="14px" />
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl onClick={onOpenStrength} marginTop={"5px"}>
                  <FormLabel margin={"0"} p="0" htmlFor="no">
                    Strenghts
                  </FormLabel>
                  <InputGroup>
                    <Input
                      cursor={"pointer"}
                      readOnly={true}
                      onClick={onOpenStrength}
                      _disabled={{
                        border: "2px solid #E3E4E8",
                        opacity: selectedStrength.length === 0 ? 0.7 : 1,
                      }}
                      focusBorderColor="#E3E4E8"
                      border={"1px solid #E3E4E8"}
                      height="50px"
                      name="strenths"
                      value={formik.values.disabilityType}
                      placeholder={
                        selectedStrength.length == 0
                          ? "Select"
                          : selectedStrength.join()
                      }
                    />
                    <InputRightElement>
                      <ChevronDownIcon
                        mt="2"
                        fontSize={"22"}
                        color="gray.500"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl onClick={onOpenWhatWorksForMe} marginTop={"5px"}>
                  <FormLabel margin={"0"} p="0" htmlFor="no">
                    What works for me
                  </FormLabel>
                  <InputGroup>
                    <Input
                      cursor={"pointer"}
                      readOnly={true}
                      _disabled={{
                        border: "2px solid #E3E4E8",
                        opacity: selectedWhatWorksForMe.length === 0 ? 0.7 : 1,
                      }}
                      onClick={onOpenWhatWorksForMe}
                      focusBorderColor="#E3E4E8"
                      border={"1px solid #E3E4E8"}
                      height="50px"
                      name="strenths"
                      value={formik.values.disabilityType}
                      placeholder={
                        selectedWhatWorksForMe.length == 0
                          ? "Select"
                          : selectedWhatWorksForMe.join()
                      }
                    />
                    <InputRightElement>
                      <ChevronDownIcon
                        mt="2"
                        fontSize={"22"}
                        color="gray.500"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl
                  onClick={onOpenWhatDoesNotWorkForMe}
                  marginTop={"5px"}
                >
                  <FormLabel margin={"0"} p="0" htmlFor="no">
                    What does not work for me
                  </FormLabel>
                  <InputGroup>
                    <Input
                      cursor={"pointer"}
                      readOnly={true}
                      onClick={onOpenWhatDoesNotWorkForMe}
                      _disabled={{
                        border: "2px solid #E3E4E8",
                        opacity:
                          selectedWhatDoesNotWorksForMe.length === 0 ? 0.7 : 1,
                      }}
                      focusBorderColor="#E3E4E8"
                      border={"1px solid #E3E4E8"}
                      height="50px"
                      name="strenths"
                      value={formik.values.disabilityType}
                      placeholder={
                        selectedWhatDoesNotWorksForMe.length == 0
                          ? "Select"
                          : selectedWhatDoesNotWorksForMe.join()
                      }
                    />
                    <InputRightElement>
                      <ChevronDownIcon
                        mt="2"
                        fontSize={"22"}
                        color="gray.500"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl onClick={onOpenGoals} marginTop={"5px"}>
                  <FormLabel margin={"0"} p="0" htmlFor="no">
                    Goals am working on
                  </FormLabel>
                  <InputGroup>
                    <Input
                      cursor={"pointer"}
                      readOnly={true}
                      onClick={onOpenGoals}
                      _disabled={{
                        border: "2px solid #E3E4E8",
                        opacity: selectedGoals.length === 0 ? 0.7 : 1,
                      }}
                      focusBorderColor="#E3E4E8"
                      border={"1px solid #E3E4E8"}
                      height="50px"
                      name="strenths"
                      value={formik.values.disabilityType}
                      placeholder={
                        selectedGoals.length == 0
                          ? "Select"
                          : selectedGoals.join()
                      }
                    />
                    <InputRightElement>
                      <ChevronDownIcon
                        mt="2"
                        fontSize={"22"}
                        color="gray.500"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl onClick={onOpenMedication} marginTop={"5px"}>
                  <FormLabel margin={"0"} p="0" htmlFor="no">
                    Medication
                  </FormLabel>
                  <InputGroup>
                    <Input
                      cursor={"pointer"}
                      readOnly={true}
                      onClick={onOpenMedication}
                      _disabled={{
                        border: "2px solid #E3E4E8",
                        opacity: selectedMedication.length === 0 ? 0.7 : 1,
                      }}
                      focusBorderColor="#E3E4E8"
                      border={"1px solid #E3E4E8"}
                      height="50px"
                      name="strenths"
                      value={formik.values.disabilityType}
                      placeholder={
                        selectedMedication.length == 0
                          ? "Select"
                          : displayMedication
                      }
                    />
                    <InputRightElement>
                      <ChevronDownIcon
                        mt="2"
                        fontSize={"22"}
                        color="gray.500"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl
                  onClick={onOpenDietaryRestrictions}
                  marginTop={"5px"}
                >
                  <FormLabel margin={"0"} p="0" htmlFor="no">
                    Dietary Restrictions
                  </FormLabel>
                  <InputGroup>
                    <Input
                      cursor={"pointer"}
                      readOnly={true}
                      onClick={onOpenDietaryRestrictions}
                      _disabled={{
                        border: "2px solid #E3E4E8",
                        opacity:
                          selectedDietaryRestrictions.length === 0 ? 0.7 : 1,
                      }}
                      focusBorderColor="#E3E4E8"
                      border={"1px solid #E3E4E8"}
                      height="50px"
                      name="strenths"
                      value={formik.values.disabilityType}
                      placeholder={
                        selectedDietaryRestrictions.length == 0
                          ? "Select"
                          : selectedDietaryRestrictions.join()
                      }
                    />
                    <InputRightElement>
                      <ChevronDownIcon
                        mt="2"
                        fontSize={"22"}
                        color="gray.500"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                <FormControl onClick={onOpenAllergies} marginTop={"5px"}>
                  <FormLabel margin={"0"} p="0" htmlFor="no">
                    Allergies
                  </FormLabel>
                  <InputGroup>
                    <Input
                      cursor={"pointer"}
                      readOnly={true}
                      onClick={onOpenAllergies}
                      _disabled={{
                        border: "2px solid #E3E4E8",
                        opacity: selectedAllergies.length === 0 ? 0.7 : 1,
                      }}
                      focusBorderColor="#E3E4E8"
                      border={"1px solid #E3E4E8"}
                      height="50px"
                      name="strenths"
                      value={formik.values.disabilityType}
                      placeholder={
                        selectedAllergies.length == 0
                          ? "Select"
                          : selectedAllergies.join()
                      }
                    />
                    <InputRightElement>
                      <ChevronDownIcon
                        mt="2"
                        fontSize={"22"}
                        color="gray.500"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mt={5} fontWeight={600}>
                  <FormLabel htmlFor="phone">
                    Emergency Contact {formik.values.contact_1_country_code}{" "}
                    {formik.values.contact_1}
                  </FormLabel>
                  <PhoneNumberInput2
                    key={"phoneInput"}
                    id={"phone"}
                    onCodeChange={(v) =>
                      formik.setFieldValue("contact_1_country_code", v)
                    }
                    value={formik.values.contact_1}
                    options={countryOptions}
                    placeholder="Enter phone number"
                    onChange={(v) => formik.setFieldValue("contact_1", v)}
                  />
                  <FormErrorMessage></FormErrorMessage>
                </FormControl>

                <FormControl mt={5} fontWeight={600}>
                  <FormLabel htmlFor="phone">
                    Emergency Contact 2 {formik.values.contact_2_country_code}{" "}
                    {formik.values.contact_2}
                  </FormLabel>
                  <PhoneNumberInput2
                    key={"phoneInput"}
                    id={"phone"}
                    onCodeChange={(v) =>
                      formik.setFieldValue("contact_2_country_code", v)
                    }
                    value={formik.values.contact_2}
                    options={countryOptions}
                    placeholder="Enter phone number"
                    onChange={(v) => formik.setFieldValue("contact_2", v)}
                  />
                  <FormErrorMessage></FormErrorMessage>
                </FormControl>

                <FormControl marginTop={"5px"}>
                  <Button
                    type="submit"
                    isLoading={formik.isSubmitting}
                    h="50px"
                    borderRadius={"60px"}
                    colorScheme="purple"
                    width="full"
                  >
                    Next
                  </Button>
                </FormControl>
              </VStack>
            </form>
          </Box>
        </Box>
        <Box h="102px" />
      </Box>
    </Box>
  );
}

export default AddJornerPage2;
