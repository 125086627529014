import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  Text,
  FormLabel,
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { AsYouType } from "libphonenumber-js";
import { getCountryTelCode } from "../utils/countries";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { containsSpecialChars } from "../utils/utils";

export default function PhoneNumberInput2({
  size,
  value,
  country,
  options,
  onChange,
  onCodeChange,
  placeholder,
  required = true,
  ...rest
}) {
  let [number, setNumber] = useState(value);
  let [display, setDisplay] = useState("");
  let [hasChanged, setHasChanged] = useState(false);
  const [code, setCode] = useState("+1");
  
  let [selectedCountry, setSelectedCountry] = useState(country || "");
  let [countryCode, setCountryCode] = useState(
    getCountryTelCode(country) || ""
  );

  useEffect(() => {
    let c = "+1";
    let co = "USA";
    if(country){
      c = getCountryTelCode(country);
      co = country;  
    }
    setTimeout(() => {
      setSelectedCountry(co);
      setCountryCode(getCountryTelCode(co));
      let code = getCountryTelCode("USA");
      if(value == "") {

      }else{
        let parsedNumber = new AsYouType().input(`${code}${value}`);
        setCode(code);
        setCountryCode(code);
        setSelectedCountry("USA");
        setDisplay(parsedNumber)  
      }
    }, 1000);
    
    // onChange(parsedNumber);
  }, [country]);

  const onCountryChange = (e) => {
    let value = e.target.value;
    let code = getCountryTelCode(value);
    let parsedNumber = new AsYouType().input(`${number}`);
    setCode(code);
    onCodeChange(code);

    setCountryCode(code);
    setSelectedCountry(value);
    onChange(parsedNumber);
  };

  const onPhoneNumberChange = (e) => {
    let value = e.target.value;
    let last = value.substring(value.length - 1);
    const alpha = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];
    if (alpha.includes(last)) {
      value = value.replace(last, "");
    }
    if (containsSpecialChars(last)) value = value.replace(last, "");
    
    value = value.replace(" ", "").replace("+1", "").replace("undefined", "").replace("  ", "")
    if(value.length > 12) return;

    if (containsSpecialChars(last)) value = value.replace(last, "");
    let parsedNumber = new AsYouType().input(`${countryCode}${value}`);
    let d = value.replace(/\D/g, "");
    setDisplay(parsedNumber)
    setNumber(d);
    onChange(d);
  };

  return (
    <InputGroup size={size} {...rest}>
      <InputLeftElement
        height={"50px"}
        display={"flex"}
        width="fit-content"
        alignItems="center"
      >
        {/* {selectedCountry ? ( */}
        <FormLabel
          justifyContent={"center"}
          gap="1"
          display={"flex"}
          pl={2}
          width="100%"
          alignItems="center"
          htmlFor="phoneDrop"
        >
          <Box mt="10px" width={"50px"} pl="1" mr="-1px" flex={1}>
            <Flag width={"45px"} height="35px" code={selectedCountry || ""} />
          </Box>
          <Text fontSize={"14px"} m="0" p="0" mt="10px" as={"span"}>
            {code}{" "}
          </Text>
          <Icon mt="10px" as={ChevronDownIcon} fontSize="14px" />
          <Box>
            <Select
              icon={ChevronDownIcon}
              top="6px"
              right="-1"
              zIndex={1}
              id="phoneDrop"
              bottom={0}
              opacity={0}
              height="100%"
              position="absolute"
              value={selectedCountry}
              onChange={onCountryChange}
            >
              <option value="" />
              {options
                .filter((o) => o.value == "USA")
                .map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </Select>
          </Box>
        </FormLabel>
        {/* )  */}
        {/* : ( */}
        {/* <FaPhone /> */}
        {/* )} */}
      </InputLeftElement>
      <Input
        height={"50px"}
        focusBorderColor="#e3e4e8"
        border="1px solid #E3E4E8"
        pl={`${
          code.length === 5 ? "120px" : code.length === 4 ? "115px" : "110px"
        }`}
        type="tel"
        value={display}
        // pattern="[0-9]"
        isRequired={required}
        placeholder={placeholder}
        onChange={onPhoneNumberChange}
      />
    </InputGroup>
  );
}

PhoneNumberInput2.defaultProps = {
  options: [],
  size: "md",
};
