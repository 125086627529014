import axios from 'axios';

import {  getAccessToken, onRefreshToken, checkIfTokenExpired } from './auth';

// The list of the endpoints which should be skipped by "request" interceptor
const anonymousEndpoints = ['caregiver/auth'];

const setUpAxiosInterceptors = () => {
  axios.interceptors.request.use(async (config) => {
    if (anonymousEndpoints.some(x => config.url.includes(x))) return config;

    let accessToken = getAccessToken();

    if (checkIfTokenExpired(accessToken)) {
      await onRefreshToken();
      accessToken = getAccessToken();
    }
      config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  });
};

export default setUpAxiosInterceptors;
