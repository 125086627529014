import { Box, Link, Flex, Heading, Image, Text, Icon } from "@chakra-ui/react";
import React from "react";
import { who } from "../../images";
import { Link as ReachLink } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { capitalizeFirstWord } from "../../utils/utils";

function JornerCard({ key = 1, width = "320px", jorner }) {
  return (
    <Box
      width={{ base: "100%", md: width }}
      padding="12px 14px"
      alignItems="center"
      justifyContent={"space-between"}
      borderRadius={"16px"}
      border="1px solid #EBECEF"
      display="flex"
      shadow="sm"
      background={"white"}
    >
      <Flex gap="3" alignItems="center">
        {jorner.avatar ? (
          <Image
            boxSize={"50px"}
            borderRadius={"100%"}
            src={jorner.avatar}
          ></Image>
        ) : (
          <Box
            fontWeight="600"
            color="white"
            boxSize={"50px"}
            borderRadius={"100%"}
            display="flex"
            background={"warning"}
            alignItems="center"
            justifyContent="center"
          >
            {jorner.first_name.substring(0, 1)}
            {jorner.last_name.substring(0, 1)}
          </Box>
        )}
        <Box>
          <Heading fontWeight={"500"} fontSize="15px">
            {jorner.first_name} {jorner.last_name}
          </Heading>
          <Box height="10px"></Box>
          <Text color="paragraph" fontSize="13px">
            {capitalizeFirstWord(jorner.type)}
          </Text>
        </Box>
      </Flex>
      <Link
        background={"gray"}
        as={ReachLink}
        to={`/jorner/${jorner.id}`}
        borderRadius={"100%"}
        width="34px"
        height="34px"
        display={"flex"}
        justifyContent="center"
        alignItems="center"
      >
        <Icon as={ChevronRightIcon} fontSize={"18px"} />
      </Link>
    </Box>
  );
}

export default JornerCard;
