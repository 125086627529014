import {
    HStack,
    Box,
    Image,
    Link,
    Heading,
    Text,
    Flex,
    Spacer,
    Icon,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Button,
    useDisclosure,
    Stack,
    Skeleton,
    SimpleGrid,
    Toast,
    useToast,
} from "@chakra-ui/react";
import { basic, checkIcon, checkIconWhite, standard, starter } from "../images"

export default function PlanComponent({ plan, subForMe, loading, i, planType }) {
    const planImages = {
        'starter': starter,
        'basic': basic,
        'standard': standard,
    }
    return (
        <>
            <Box
                display={"flex"}
                flexDirection="column"
                height={"540px"}
                borderColor={"#fff"}
                borderWidth={(i + 1) % 2 == 0 ? '4px' : '0'}
                rounded={"15px"}
                shadow={"sm"} px="4" py="4" key={i} bg={(i + 1) % 2 == 0 ? 'primary' : '#EEE8FF'} >
                <Box h={"10px"} />
                <Box textAlign={"center"}>
                    <Image mx="auto" src={planImages[plan.plan_name.toLowerCase()]} boxSize={"36px"} />
                    <Box h={"10px"} />
                    <Heading color={(i + 1) % 2 == 0 ? 'white' : '#17181C'} fontSize={"20px"} fontWeight={"600"}>{plan.plan_name}</Heading>
                    <Box h={"15px"} />
                    {
                        plan.plan_name.toLowerCase() == "starter" ?
                            <Heading color={"#7353BA"}>Free</Heading>
                            :
                            <Heading fontSize={"40px"} color={(i + 1) % 2 == 0 ? 'white' : '#7353BA'} >${planType == "monthly" ? plan.monthly : plan.yearly}</Heading>
                    }
                </Box>
                {(i + 1) % 2 == 0 ?
                    <Box h={"25px"} />
                    :
                    <Box h={"45px"} />
                }
                {
                    plan.features?.map((item, o) => (
                        <Flex my={"2"} color={(i + 1) % 2 == 0 ? 'white' : '#17181C'} key={o} alignItems={"center"} gap={"2"}>
                            <Image src={(i + 1) % 2 == 0 ? checkIconWhite : checkIcon} boxSize={"20px"} />
                            <Text fontWeight={"500"} fontSize={"14px"}>{item}</Text>
                        </Flex>
                    ))
                }

                <Spacer />
                {plan.plan_name.toLowerCase() !== "starter" &&
                    <Button
                        onClick={() => subForMe(plan.id, plan.plan_name)}
                        isLoading={loading}
                        w={"100%"}
                        bg={(i + 1) % 2 == 0 ? '#65CBE9' : 'primary'} color={"white"} >Get started</Button>
                }
                {(i + 1) % 2 == 0 ?
                    <Box h={"35px"} />
                    :
                    <Box h={"10px"} />
                }
            </Box></>
    )
}