import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Input,
  Button,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft, FaSleigh } from "react-icons/fa";
import moment from "moment";
import { httpPostWithoutToken } from "../../utils/http_utils";

export default function AgreemenModal({
  isOpen,
  onClose,
  constants,
  selectedOriginal,
  u,
  cg,
  onFinished,
}) {
  const [selected, setselected] = React.useState([]);
  const [current, setCurrent] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [caregiver, setCaregiver] = React.useState({});
  const [caregivername, setCaregivername] = React.useState("");
  const toast = useToast();

  const [agreementJorners, setagreementJorners] = React.useState([]);
  const styles = {
    ul: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    li: {
      marginBottom: "10px",
    },
  };
  const finishSelection = () => {
    onFinished();
    onClose();
    setselected([]);
  };
  const inputTag = () => {
    return (
      <Input
        value={caregivername}
        onChange={(e) => setCaregivername(e.target.value)}
        borderColor={"#E3E4E8"}
      />
    );
  };
  useEffect(() => {
    if (constants) {
      setagreementJorners(constants);
    } else {
      setagreementJorners([]);
    }

    if (u) setUser(u);
    if (cg) setCaregiver(cg);
    setCurrent(0);
    console.log(user);

    setLoading(false);
    setselected([]);
  }, [constants, user, cg]);
  const signAgreement = async (jorner, i) => {
    // let name = [
    //   `${user?.first_name.toLowerCase()}${user?.last_name.toLowerCase()}`.replace(" ", ""),
    //   // `${user?.last_name.toLowerCase()}${user?.first_name.toLowerCase()}`.replace(" ", ""),
    // ];
    let name = `${user?.first_name.replace(" ", "")}${user?.last_name.replace(" ", "")}`;

    let c = caregivername.replace(" ", "").trim();
    // let c = caregivername.replace(" ", "").trim().toLowerCase();
    if (!(name == c)) {
      return toast({
        title: "Please input your first name and last name in the box to sign as shown below",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    let s = selected;
    s = [...s, i];
    setLoading(true);
    await httpPostWithoutToken("co_caregiver/sign", {
      cocaregiver_id: user?.id,
      jorner_id: jorner.id,
    });
    // return false;
    setTimeout(() => {
      setCaregivername("");
      setLoading(false);
      if (i + 1 >= agreementJorners.length) {
        finishSelection();
      } else {
        setCurrent(i + 1);
        setselected(s);
      }
    }, 1000);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} maxW={"95%"} width={"820px"}>
        <ModalHeader>HIPPA Agreement</ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <ModalBody marginBottom={"0"}>
          {loading ? (
            <Box>please wait...</Box>
          ) : (
            <>
              {agreementJorners.map((jorner, i) => (
                <>
                  {!selected.includes(i) && (
                    <>
                      {current == i && (
                        <Box cursor={"pointer"} key={i} py="2">
                          <Box>
                            Dear{" "}
                            <strong>
                              {user?.first_name} {user?.last_name}
                            </strong>
                            ,
                          </Box>
                          <br />
                          <p>
                            I,{" "}
                            <strong>
                              {caregiver?.first_name} {caregiver?.last_name}
                            </strong>
                            , am the parent/legal guardian of{" "}
                            <strong>
                              {jorner?.first_name} {jorner?.last_name}
                            </strong>
                            . I am inviting you to participate in{" "}
                            <strong>{jorner?.first_name}</strong> care circle on
                            the <strong>Sojorne App</strong>.
                          </p>
                          <br />
                          <ol style={styles.ul}>
                            <li style={styles.li}>
                            <strong>Accept the Invitation</strong>: The Co-Caregiver receives the invitation and, after verifying its authenticity, accepts it. You or your designee must sign into Sojorne and confirm your participation.
                            </li>
                            <li style={styles.li}>
                              {" "}
                              <strong>Access to Patient’s Health Data</strong>: Once accepted, the Co-Caregiver gains access to the patient’s health information, which may include medical records, medical notes, progress notes, medication history, and any data the patient or parent has chosen to share.
                            </li>
                            <li style={styles.li}>
                            <strong>Communication and Collaboration</strong>: The Co-Caregiver and parents will be able to communicate within the app.
                            </li>
                            <li style={styles.li}>
                              <strong>Updates and Alerts</strong>: The Co-Caregiver can receive updates and alerts about the patient’s health, such as medication reminders or important changes in the patient’s condition.
                            </li>
                            <li style={styles.li}>
                              <strong>Care Coordination</strong>: Co-Caregiver  s can collaborate with other healthcare professionals and family caregivers in the patient’s care circle to ensure coordinated and comprehensive healthcare delivery.
                            </li>
                            <li style={styles.li}>
                              <strong>Privacy and Security</strong>: Sojorne maintains strict security measures to protect the confidentiality of patient data, often complying with HIPAA. Providers and Co-Caregivers are expected to adhere to privacy guidelines and ethical standards.
                            </li>
                          </ol>
                          <br />
                          <Box>
                            <p>
                              <strong>For the Parent/Legal Guardian</strong>
                            </p>
                            <p>
                              I hereby authorize the release of my child’s
                              protected health information to{" "}
                              <strong>
                                {caregiver?.first_name} {caregiver?.last_name}
                              </strong>{" "}
                              for the purposes of providing healthcare services
                              to my child.
                            </p>
                            <br />
                            <p>
                              I understand that the information disclosed may
                              include, but is not limited to, medical records,
                              diagnosis, treatment plans, and any other
                              information related to my child’s healthcare.This
                              authorization is granted for the following
                              purposes:
                            </p>
                            <br />
                            <p>
                              <b>Care Circle Coordination</b> <br /> This
                              authorization is valid until otherwise revoked in
                              the app.
                              <br />I understand that I have the right to revoke
                              this authorization at any time, in writing, except
                              to the extent that the provider has already taken
                              action in reliance on this authorization. I also
                              understand that once the information is disclosed,
                              it may no longer be protected by federal privacy
                              regulations.
                            </p>
                          </Box>
                          <br />
                          <Box>
                            {/* I acknowledge that my child’s healthcare information
                            is protected by the Health Insurance Portability and
                            Accountability Act (HIPAA) and that I am voluntarily
                            authorizing the release of this information. */}
                            {/* <strong>Signature of Parent/Guardian:</strong> */}
                            <br />
                            {/* <strong>
                              <i>
                                {caregiver.first_name} {caregiver.last_name}
                              </i>
                            </strong> */}
                            <Box pl={"45px"}>
                              {moment().format("YYYY-MM-DD")}
                            </Box>
                            Date: _______________________
                            <br />
                            <br />
                            Please provide a copy of this authorization to me
                            for my records.
                            <br />
                            <br />
                            Sincerely,
                            <br />
                          </Box>
                          {inputTag()}
                          <p>
                            <small>
                              Enter your Fullname{" "}
                              <strong>
                                {user?.first_name} {user?.last_name}
                              </strong>{" "}
                              to <strong>sign</strong> this agreement
                            </small>
                          </p>
                          <br />
                          <Box textAlign={"center"}>
                            <Button
                              mx={"auto"}
                              rounded={15}
                              w={"200px"}
                              bg={"primary"}
                              color={"white"}
                              onClick={() => signAgreement(jorner, i)}
                            >
                              Proceed
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                </>
              ))}
            </>
          )}
          <br />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
