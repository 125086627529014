import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React from "react";
import { healthcare } from "../../images";
import { capitalizeFirstWord, checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";

export default function DisabilityModal({
  isOpen,
  defaultV=[],
  onClose,
  disabilitiesConstantsOriginal,
  onFinished,
}) {
  const [disabilitiesConstants, setDisabilitiesConstants] = React.useState(
    disabilitiesConstantsOriginal
  );
  const [disabilityList, setDisabilityList] = React.useState([]);
  const [disabilityListOriginal, setDisabilityListOriginal] = React.useState(
    []
  );
  const [showDisabilityList, setShowDisabilityList] = React.useState(false);
  const [disabilityTitle, setDisabilityTitle] = React.useState("");
  const { value: selectedDisability, getCheckboxProps } = useCheckboxGroup({
    defaultValue: defaultV.length > 0 ? defaultV : [],
  });
  const searchJornerDisabilitiesConstants = (e) => {
    let keyword = e.target.value;
    if (keyword < 1) {
      setDisabilitiesConstants(disabilitiesConstantsOriginal);
    } else {
      let allDisabilities = disabilitiesConstantsOriginal;
      let searchResult = Object.fromEntries(
        Object.entries(allDisabilities).filter(([key]) =>
          key.includes(keyword.toLowerCase())
        )
      );
      setDisabilitiesConstants(searchResult);
    }
  };

  const searchJornerDisabilityDetailsList = (e) => {
    let keyword = e.target.value;
    if (keyword < 1) {
      setDisabilityList(disabilityListOriginal);
    } else {
      let allDisabilityList = disabilityListOriginal;
      let searchResult = allDisabilityList.filter((e) =>
        e.toLowerCase().includes(keyword.toLowerCase())
      );
      setDisabilityList(searchResult);
    }
  };

  const handleShowDisabilityDetails = (disabilityKey) => {
    setDisabilityList(disabilitiesConstants[disabilityKey]);
    setDisabilityListOriginal(disabilitiesConstants[disabilityKey]);
    setDisabilityTitle(capitalizeFirstWord(disabilityKey.replaceAll("_", " ")));
    setShowDisabilityList(true);
  };

  const finishSelection = () => {
    onFinished(selectedDisability);
    onClose()
  };


  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"2xl"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        {!showDisabilityList ? (
          <>
            <ModalHeader>Disability</ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />
              <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {selectedDisability.length} selected
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  borderRadius={100}
                  onChange={searchJornerDisabilitiesConstants}
                  borderColor={'#E3E4E8'}
                />
              </InputGroup>
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3} mt={5}>
                {Object.keys(disabilitiesConstants).map((e, i) => (
                  <Box
                    key={`disability${i}`}
                    bg={"gray"}
                    cursor={"pointer"}
                    borderRadius={8}
                    p={3}
                    onClick={() => handleShowDisabilityDetails(e)}
                  >
                    <Image src={healthcare} />
                    <Text fontSize={14} fontWeight={500}>
                      {capitalizeFirstWord(e.replaceAll("_", " "))}
                    </Text>
                    <Text
                      color={"paragraph"}
                      fontSize={12}
                      fontWeight={500}
                      my={3}
                    >
                      {checkArraySimilarities(
                        selectedDisability,
                        disabilitiesConstants[e]
                      )}{" "}
                      /{disabilitiesConstants[e].length} Selected
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={finishSelection}
                >
                  Next
                </Button>
              </Box>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalHeader>
              <Flex alignItems={"center"}>
                <FaChevronLeft onClick={() => setShowDisabilityList(false)} />
                <Text ml={2}>{disabilityTitle}</Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />
              <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {checkArraySimilarities(selectedDisability, disabilityList)}{" "}
                selected
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  key={"searchList"}
                  borderRadius={100}
                  onChange={searchJornerDisabilityDetailsList}
                  borderColor={'#E3E4E8'}
                />
              </InputGroup>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                {disabilityList.map((e, i) => (
                  <Checkbox
                    key={i}
                    colorScheme="red"
                    style={{ color: "secondary" }}
                    {...getCheckboxProps({ value: e })}
                  >
                    <Text>{e}</Text>
                  </Checkbox>
                ))}
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={() => setShowDisabilityList(false)}
                >
                  Proceed
                </Button>
              </Box>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
