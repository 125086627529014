import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  useCheckboxGroup,
  useToast,
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Select,
  Textarea,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import {
  ValidDate,
  capitalizeFirstWord,
  checkArraySimilarities,
} from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { useFormik } from "formik";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import { DatePicker } from "rsuite";
import PhoneNumberInput2 from "../../components/phoneNumberInput2";
import {
  httpPatchWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { useEffect } from "react";
import moment from "moment";
import MedicalUpload from "./components/medical_upload";
import InsurancePlanModal from "./insurance_plan_modal";

export default function AddInsuranceModal({
  isOpen,
  newinsurance,
  onClose,
  onFinished,
  appointment,
}) {
  const [insurance, setInsurance] = useState("")
  const [frontCover, setFrontCover] = useState(null)
  const [backCover, setBackCover] = useState(null)
  const finishSelection = () => {
    onClose();
  };
  const plansAndNames = [
    "United Health Care",
    "Oxford Health Plans",
    "Allsavers UHC",
    "Havard Pilgrim",
    "Meritain",
    "Nippon",
    "Aetna",
    "UHC Student Resources",
    "Oscar",
    "Allsavers UHC",
  ]
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const {
    isOpen: isOpenCreate2,
    onOpen: onOpenCreate2,
    onClose: onCloseCreate2,
  } = useDisclosure();

  const insuranceCompany = [
    "Healthcare Insurance",
    "Life Insurance",
    "Long-Term Disability Coverage",
    "Auto Insurance",
  ];

  useEffect(() => {
    setInsurance(newinsurance)
  }, [newinsurance])

  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      insurance_plan: "",
      member_id: "",
    },
    onSubmit: async (values) => {
      if (
        values.insurance_plan == "" || values.member_id == ""
      ) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `All fields are required`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      // alert(values.insurance_plan)
      // alert(values.member_id)

      const formdata = new FormData()
      formdata.append("vision_plan", values.insurance_plan)
      formdata.append("vision_member_id", values.member_id)

      const resp = await httpPatchWithTokenRest("insurance/add-vision/"+insurance, formdata)
      if(resp.status == "success"){
        formik.resetForm()
        onClose()
        onFinished()
      }else{
        onClose()
        onFinished()

        return toast({
          title: "Sorry, we hit a snag!",
          description:resp.message ?? resp.error,
          status: "error",
          duration: 5000,
          isClosable: true,
        }); 
      }

      return;
    },
  });
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <InsurancePlanModal
        onClose={onCloseCreate}
        isOpen={isOpenCreate}
        constants={plansAndNames}
        onFinished={(v)=> {
          formik.setFieldValue("insurance_plan", v)
        }}
      />

      <InsurancePlanModal
        onClose={onCloseCreate2}
        isOpen={isOpenCreate2}
        constants={insuranceCompany}
        onFinished={(v)=> {
          formik.setFieldValue("insurance_company", v)
        }}
      />

      <ModalContent borderRadius={16} p="3">
        <ModalBody marginBottom={"0"}>
          <Box>
            <Flex alignItems={"center"} justifyContent="space-between">
              <Heading fontSize={"20px"}>
                Add Insurance
              </Heading>
              <Button onClick={finishSelection}>close</Button>
            </Flex>
            <Box mt={"2"} />
            <Box h={"1px"} bg="#00000073" opacity={"0.3"} />


            <Box mt={"3"} />
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={4} align="flex-start">
                
                <FormControl mt={5} fontWeight={600}>
                  <FormLabel fontSize={"14px"} htmlFor="plan">Plan Name and Type</FormLabel>
                  <InputGroup>
                    <Input
                      cursor={"pointer"}
                      readOnly={true}
                      onClick={onOpenCreate}
                      _disabled={{
                        border: "2px solid #E3E4E8",
                        opacity: formik.values.insurance_plan == "" ? 0.7 : 1,
                      }}
                      focusBorderColor="#E3E4E8"
                      border={"1px solid #E3E4E8"}
                      height="50px"
                      name="plan_name_type"
                      value={formik.values.insurance_plan}
                      placeholder={
                       formik.values.insurance_plan == "" 
                          ? "Select"
                          : formik.values.insurance_plan
                      }
                    />
                    <InputRightElement>
                      <ChevronDownIcon
                        mt="2"
                        fontSize={"22"}
                        color="gray.500"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl marginTop={"5px"}>
                <FormLabel htmlFor="name">Member ID</FormLabel>
                  <Input
                    height={"50px"}
                    id="member_id"
                    name="member_id"
                    type="text"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="E.g Sojorne"
                    onChange={formik.handleChange}
                    value={formik.values.member_id}
                  />
                </FormControl>
                <Button
                  isLoading={formik.isSubmitting}
                  type="submit"
                  h="50px"
                  borderRadius={"60px"}
                  colorScheme="purple"
                  width="full"
                >
                  Proceed
                </Button>
              </VStack>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
