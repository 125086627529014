import { combineReducers } from "redux";
import auth from "./auth";
import authenticationSlice  from "../../reducers/authSlice";

export default combineReducers({
  auth,
});

export const authReducer = combineReducers({
  authenticationSlice,
});