import { Link, Text, Box } from "@chakra-ui/react";
import React from "react";
import { Link as ReachLink } from "react-router-dom";

function AppLink({onclick=null, hasLeftIcon=false, leftIcon="", hasRightIcon=false, href="/signup", rightIcon = "", padding= "auto" , fontSize="15px", fontWeight = "500", text = "Get Started", shadow='md', borderRadius='50', height='45px', width='100%', textDecoration="none", color="white", background="primary"}) {
  return (
    <Link as={ReachLink} onClick={onclick} to={href} _hover={{
        textDecoration:"none"
      }} textDecoration={'none'}>
      <Text padding={padding} fontSize={fontSize} fontWeight={fontWeight} shadow={shadow} borderRadius={borderRadius} height={height} display="flex" justifyContent={"center"} alignItems={"center"} width={width} textDecoration={textDecoration} color={color} textAlign="center" background={background}>
        {hasLeftIcon ? leftIcon : ''}
        {hasLeftIcon ? <Box width={'2px'} /> : ''}
        {/* the greatness of react :) */}
        {hasRightIcon ? <Text>{text}</Text> : text} {hasRightIcon ? rightIcon : ''}</Text>
    </Link>
  )
}

export default AppLink