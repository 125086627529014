import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { healthcare } from "../../images";
import {
  capitalizeFirstWord,
  checkArraySimilarities,
  isObjEmpty,
} from "../../utils/utils";
import { EmailChipInput } from "../../components/emailInput";

import { FaChevronLeft } from "react-icons/fa";

export default function WhatWorksForMeModal({
  isOpen,
  defaultV = [],
  onClose,
  whatWorksForMeOriginal,
  onFinished,
  showOthers=null,
  title=""
}) {
  const [whatWorksForMeConstants, setWhatWorksForMeConstants] = React.useState(
    whatWorksForMeOriginal
  );

  const [disabilityList, setWhatWorksForMeList] = React.useState([]);
  const [whatWorksForMeListOriginal, setWhatWorksForMeListOriginal] =
    React.useState([]);
  const [inputValue, setInputValue] = useState("");
  const [generalSearch, setGeneralSearch] = useState([]);
  const [allOtherSelected, setAllOtherSelected] = useState([]);
  const [allOtherSelectedConstant, setAllOtherSelectedConstant] = useState([]);
  const [allOtherSelectedPending, setAllOtherSelectedPending] = useState([]);
  const [showGeneralSearch, setShowGeneralSearch] = useState(false);
  const [showWhatWorksForMeList, setShowWhatWorksForMeList] =
    React.useState(false);
  const [disabilityTitle, setDisabilityTitle] = React.useState("");
  const { value: selectedWhatWorksForMe, setValue, getCheckboxProps } = useCheckboxGroup({
    defaultValue: defaultV.length > 0 ? defaultV : [],
  });
  useEffect(() => {
    setInputValue("");
    setShowGeneralSearch(false);
    if(whatWorksForMeOriginal) {
        let originalkeys = Object.keys(whatWorksForMeOriginal)
        let originalValuesOnly = Object.values(whatWorksForMeOriginal)
        setValue(defaultV)
        if(showOthers) {
          let allothers = []

          for (let i = 0; i < defaultV.length; i++) {
            const s = defaultV[i];
            let isOther = true;
            // let 
            for (let index = 0; index < originalValuesOnly.length; index++) {
              const wwkm = originalValuesOnly[index];
              let isExist = wwkm.includes(s)
              if(isExist){
                isOther = false;
              }
            }
            if(isOther) allothers = [...allothers, s];
          }

          setAllOtherSelected([...new Set(allothers)])
          setAllOtherSelectedConstant([...new Set(allothers)])
        }
    }
  }, [isOpen, whatWorksForMeOriginal]);

  const searchJornerWhatWorksForMeConstants = (e) => {
    let keyword = e.target.value;
    setInputValue(keyword);
    let allListItems = [];
    const values = Object.values(whatWorksForMeConstants);
    values.map((list, i) => {
      allListItems = [...allListItems, ...list];
    });
    allListItems = [...new Set(allListItems)];
    if (keyword < 1) {
      setWhatWorksForMeConstants(whatWorksForMeOriginal);
    } else {
      let searchResult = allListItems.filter((e) =>
        e.toLowerCase().includes(keyword.toLowerCase())
      );
      setGeneralSearch(searchResult);
      setShowGeneralSearch(true);
    }
  };

  const searchJornerDisabilityDetailsList = (e) => {
    let keyword = e.target.value;
    let allListItems = [];
    const values = Object.values(whatWorksForMeConstants);
    values.map((list, i) => {
      allListItems = [...allListItems, ...list];
    });
    if (keyword < 1) {
      setWhatWorksForMeList(whatWorksForMeListOriginal);
    } else {
      let allDisabilityList = whatWorksForMeListOriginal;
      let searchResult = allDisabilityList.filter((e) =>
        e.toLowerCase().includes(keyword.toLowerCase())
      );
      setWhatWorksForMeList(searchResult);
    }
  };

  const handleShowDisabilityDetails = (disabilityKey) => {
    if(showOthers && disabilityKey.toLowerCase() == "others") {
        setWhatWorksForMeList(allOtherSelected);
        setWhatWorksForMeListOriginal(allOtherSelected);
        setDisabilityTitle(capitalizeFirstWord(disabilityKey.replaceAll("_", " ")));
        setShowWhatWorksForMeList(true);
        setAllOtherSelectedPending(allOtherSelected)
    }else{
        setWhatWorksForMeList(whatWorksForMeConstants[disabilityKey]);
        setWhatWorksForMeListOriginal(whatWorksForMeConstants[disabilityKey]);
        setDisabilityTitle(capitalizeFirstWord(disabilityKey.replaceAll("_", " ")));
        setShowWhatWorksForMeList(true);
    }
    
  };

  const finishSelection = () => {
    let withoutOthers = selectedWhatWorksForMe.filter((w)=> allOtherSelectedConstant.includes(w) === false);
    console.log("withou", withoutOthers);
    let s = [... new Set(withoutOthers), ...allOtherSelected]
    s = [... new Set(s)];
    onFinished(s);
    setAllOtherSelectedConstant([])
    setAllOtherSelected([])
    setInputValue("");
    setShowGeneralSearch(false);
    onClose();
  };

  const reset = () => {
    setWhatWorksForMeConstants(whatWorksForMeOriginal);
  };

  useEffect(() => {
    if (!isObjEmpty(whatWorksForMeOriginal)) {
      reset();
    }
  }, [whatWorksForMeOriginal]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"2xl"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        {!showWhatWorksForMeList ? (
          <>
            <ModalHeader display={"flex"} alignItems={"center"} w={"100%"}>
              {showGeneralSearch && (
                <FaChevronLeft
                  onClick={() => {
                    setShowGeneralSearch(false);
                    setInputValue("");
                    setGeneralSearch([]);
                  }}
                />
              )}
              <Text>
                {showGeneralSearch ? "Search Result" : title ? title : "What works for me"}
              </Text>
            </ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />
              <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {selectedWhatWorksForMe.length + allOtherSelected.length} selected
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  borderRadius={100}
                  value={inputValue}
                  onChange={searchJornerWhatWorksForMeConstants}
                  borderColor={"#E3E4E8"}
                />
              </InputGroup>
              {showGeneralSearch ? (
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                  {generalSearch.map((e, i) => (
                    <Checkbox
                      colorScheme="red"
                      style={{ color: "secondary" }}
                      {...getCheckboxProps({ value: e })}
                    >
                      <Text>{e}</Text>
                    </Checkbox>
                  ))}
                </SimpleGrid>
              ) : (
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3} mt={5}>
                  {Object.keys(whatWorksForMeConstants).map((e, i) => (
                    <Box
                      key={`disability${i}`}
                      bg={"gray"}
                      cursor={"pointer"}
                      borderRadius={8}
                      p={3}
                      onClick={() => handleShowDisabilityDetails(e)}
                    >
                      <Image src={healthcare} />
                      <Text fontSize={14} fontWeight={500}>
                        {capitalizeFirstWord(e.replaceAll("_", " "))}
                      </Text>
                      <Text
                        color={"paragraph"}
                        fontSize={12}
                        fontWeight={500}
                        my={3}
                      >
                        {checkArraySimilarities(
                          selectedWhatWorksForMe,
                          whatWorksForMeConstants[e]
                        )}{" "}
                        /{whatWorksForMeConstants[e].length} Selected
                      </Text>
                    </Box>
                  ))}
                  {
                    showOthers
                    &&
                   <Box
                      bg={"gray"}
                      cursor={"pointer"}
                      borderRadius={8}
                      p={3}
                      onClick={() => handleShowDisabilityDetails("Others")}
                    >
                      <Image src={healthcare} />
                      <Text fontSize={14} fontWeight={500}>
                        Others
                      </Text>
                      <Text
                        color={"paragraph"}
                        fontSize={12}
                        fontWeight={500}
                        my={3}
                      >
                        {allOtherSelected.length} Selected
                      </Text>
                    </Box>
                    }

                </SimpleGrid>
              )}
            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={finishSelection}
                >
                  Next
                </Button>
              </Box>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalHeader>
              <Flex alignItems={"center"}>
                <FaChevronLeft
                  onClick={() => setShowWhatWorksForMeList(false)}
                />
                <Text ml={2}>{disabilityTitle}</Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />
             {
              showOthers && disabilityTitle.toLowerCase() == "others"
              ?
              <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {allOtherSelectedPending.length}{" "}
                selected
              </Text>
              :
             <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {checkArraySimilarities(selectedWhatWorksForMe, disabilityList)}{" "}
                selected
              </Text>
             } 
              {
                showOthers && disabilityTitle.toLowerCase() === "others"
                ?
                <>
                      <Text fontSize={"13px"} mb="1">Use the (+) Icon to save your entries.</Text>
                 <EmailChipInput
                      initialEmails={allOtherSelectedPending}
                      callBackFunc={(e) =>  setAllOtherSelectedPending([...new Set(e)])}
                      placeholder={"Type here"}
                      isEmail={false}
                    />
                </>
                :
              <>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  key={"searchList"}
                  borderRadius={100}
                  onChange={searchJornerDisabilityDetailsList}
                  borderColor={"#E3E4E8"}
                />
              </InputGroup>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                {disabilityList.map((e, i) => (
                  <Checkbox
                    colorScheme="red"
                    style={{ color: "secondary" }}
                    {...getCheckboxProps({ value: e })}
                  >
                    <Text>{capitalizeFirstWord(e)}</Text>
                  </Checkbox>
                ))}
              </SimpleGrid> 
              </>
              }


            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={() => {
                    if(showOthers && disabilityTitle.toLowerCase() == "others") {
                      setAllOtherSelected(allOtherSelectedPending)
                    }else{
                    }
                    setShowWhatWorksForMeList(false)
                  }
                  } 
                >
                  Proceed
                </Button>
              </Box>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
