import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  useCheckboxGroup,
  useDisclosure,
  useToast,
  Select,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { careTeamImg, successGif } from "../../images";
import { FaChevronLeft, FaPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
} from "../../utils/http_utils";
import {
  capitalizeFirstWord,
  checkArraySimilarities,
  containsNumber,
  containsSpecialChars,
} from "../../utils/utils";
import CoCaregiverBox from "./coCaregiverBox";
import CustomModal from "../../components/customModal";
import { dashboardRoute } from "../../utils/allRoutes";
import { useNavigate } from "react-router-dom";
import { httpPatch2WithTokenRest } from "../../utils/http_utils";
import { MultiSelect } from "react-multi-select-component";
import { AppContext } from "../../redux/appContext";
import AgreementCaregiverModal from "../auth/agreement_caregiver";
// import { Select } from "chakra-multiselect";
export default function CareTeam({ updateHaveCaregivers }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [communityFamily, setCommunityFamily] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [addCareTeam, setAddCareTeam] = React.useState(false);
  const [editCareTeam, setEditCareTeam] = React.useState(false);
  const [allJorners, setAllJorners] = useState([]);
  const [selectedCareTeam, setSelectedCareTeam] = React.useState(0);
  const [allCareTeam, setAllCareTeam] = React.useState([]);
  const [selectedJorners, setSelectedJorners] = React.useState([]);
  const [profileStep, setProfileStep] = React.useState(1);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [ccgStatus, setCcgStatus] = React.useState("");
  const [caregiverType, setCaregiverType] = React.useState("");
  const [inviteeName, setInviteename] = React.useState("");
  const [postData, setData] = React.useState(null);
  const { subscription } = useContext(AppContext);

  const [role, setRole] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("+1");
  const toast = useToast();
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const {
    isOpen: isOpenAgreement,
    onOpen: onOpenAgreement,
    onClose: onCloseAgreement,
  } = useDisclosure();
  const {
    value: selectedPermissions,
    getCheckboxProps,
    setValue: setCheckValue,
  } = useCheckboxGroup({
    defaultValue: [
      `community:view`,
      `community:edit`,
      `community:delete`,
      `community:create`,
    ],
  });
  useEffect(() => {
    // navigate("/dashboard");
    fetchJorners();
    setCheckValue([
      `community:view`,
      `community:edit`,
      `community:delete`,
      `community:create`,
    ]);
  }, [updateHaveCaregivers]);
  const fetchJorners = async () => {
    var j = await httpGetWithTokenRest("jorner");
    if (j.status == "success") {
      const jor = j.data;
      const jornersss = jor.map((item, i) => {
        return {
          id: i,
          key: i,
          label: item.first_name + " " + item.last_name,
          value: item.id,
          ...item,
        };
      });
      setAllJorners(jornersss);
    }
  };
  // const allPermissions = ["home", "calendar", "task", "provider", "community"];
  const allPermissions = ["home", "calendar", "task", "community", "jorner_profile", "caregivers", "documents_care_plan", "documents_power_of_attorney"];
  const gotoStep = (index) => {
    setProfileStep(index);
  };
  const caregiverRoles = [
    "Parent",
    "Primary Caregiver",
    "Grandparent",
    "Sitter, Au pair or Nanny",
    "Aunt or Uncle",
    "Other Relative",
    "Family Friend",
    "Teacher",
    "School Official",
    "Medical Practitioner",
    "Therapist or Technician",
    "Other",
  ];
  const fetchCoCaregiverPermission = async () => {
    const resp = await httpGetWithTokenRest("permissions");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
    }
  };
  const resendNotification = (caregiver) => {};
  const fetchFamilyCommunity = async () => {
    const resp = await httpGetWithTokenRest("communities/family");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setCommunityFamily(resp.data);
    }
  };

  const fetchAllCoCaregivers = async () => {
    const resp = await httpGetWithTokenRest("co_caregiver");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      console.log(resp.data);
      setAddCareTeam(false);
      setAllCareTeam(resp.data);
      if (resp.data.length > 0) {
        updateHaveCaregivers(true);
      }
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const editCareGiver = (care) => {
    setValue("firstName", care?.first_name);
    setValue("lastName", care?.last_name);
    setValue("emailAddress", care?.email);
    setPhoneNumber(care?.phone_number);
    setCheckValue(care?.permissions);
    setAddCareTeam(true);
    setEditCareTeam(true);
    setCcgStatus(care.ccg_status);
    setCaregiverType(care.caregiver_type);
    setRole(care.caregiver_type);
    // console.log(care);
    setSelectedCareTeam(care?.id);
    var j = care.jorners;
    var selectedJ = [];
    // console.log(allJorners)
    // console.log(j)
    j.map((item, i) => {
      var d = allJorners.find((c) => c.value == item.jorner_id);
      // console.log(d);
      if (d) selectedJ = [...selectedJ, d];
    });
    setSelectedJorners(selectedJ);
  };

  async function onSubmit(values) {
    const ssJorner = selectedJorners.map((item, i) => {
      return item.value;
    });

    if (profileStep === 1) {
      if (
        containsSpecialChars(values.firstName) ||
        containsSpecialChars(values.lastName)
      ) {
        return toast({
          title: "Names cannot contain special characters.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (containsNumber(values.lastName) || containsNumber(values.firstName)) {
        return toast({
          title: "Names cannot contain numbers.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (countryCode.length < 1) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Please select country code`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      gotoStep(2);
    } else {
      // console.log(selectedPermissions);
      // return false;
      const data = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.emailAddress,
        country_code: countryCode,
        phone_number: phoneNumber,
        permissions: selectedPermissions,
        caregiver_type: role,
        jorners: ssJorner ? ssJorner : [],
      };
      setData(data);
      let resp;

      if (editCareTeam) {
        let d = {
          permissions: selectedPermissions,
        };
        resp = await httpPatch2WithTokenRest(
          `caregivers/co_caregivers/${selectedCareTeam}`,
          d
        );
      } else {
        
        if (subscription.co_caregivers != "unlimited") {
          const sCcg = parseInt(subscription.co_caregivers);
          if (allCareTeam.length >= sCcg) {
            return toast({
              title: "Access Denied!",
              description: `Please upgrade your account to Invite ${
                allCareTeam.length == 0 ? "Co-caregiver" : "more Co-caregivers"
              }`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
        setInviteename(values.firstName + " " + values.lastName);
        onOpenAgreement();
        return;
        // resp = await httpPostWithToken("co_caregiver/invite", data);
      }

      if (resp.error !== null && resp.error !== undefined) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `${resp.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        reset();
        fetchAllCoCaregivers();
        setEditCareTeam(false);
        setAddCareTeam(false);
        let ed = editCareTeam;
        setSelectedCareTeam(null);
        gotoStep(1);
        setRole("");
        setCaregiverType("");
        setCcgStatus("");
        setSelectedJorners([]);
        setValue("firstName", "");
        setValue("lastName", "");
        setValue("emailAddress", "");
        setPhoneNumber("");
        setCheckValue([]);
        return toast({
          title: "Success.",
          description: `Co-caregiver ${ed ? "edited" : "created"} successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }
  const onSubmit2 = async () => {
      reset();
      fetchAllCoCaregivers();
      setEditCareTeam(false);
      setAddCareTeam(false);
      let ed = editCareTeam;
      setSelectedCareTeam(null);
      gotoStep(1);
      setRole("");
      setCaregiverType("");
      setCcgStatus("");
      setSelectedJorners([]);
      setValue("firstName", "");
      setValue("lastName", "");
      setValue("emailAddress", "");
      setPhoneNumber("");
      setCheckValue([]);
      return toast({
        title: "Success.",
        description: `Co-caregiver created successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  }
  const completeOnboarding = () => {
    navigate(dashboardRoute);
  };

  React.useEffect(() => {
    fetchFamilyCommunity();
    fetchAllCoCaregivers();
  }, []);

  const fillCheckBox = (permission) => {
    var isChecked = checkArraySimilarities(
      [
        `${permission}:create`,
        `${permission}:edit`,
        `${permission}:delete`,
        `${permission}:view`,
      ],
      selectedPermissions
    );
    if (!isChecked) {
      var s = selectedPermissions;
      s = [
        ...s,
        ...[
          `${permission}:create`,
          `${permission}:edit`,
          `${permission}:delete`,
          `${permission}:view`,
        ],
      ];
      setCheckValue(s);
      // console.log(s)
    } else {
      var s = selectedPermissions;
      var newS = [];
      s.map((item) => {
        if (item.includes(permission)) {
        } else {
          newS = [...newS, item];
        }
      });
      setCheckValue(newS);
    }
  };
  return (
    <Box px={{ base: 1, md: 8 }}>
      <AgreementCaregiverModal 
      isOpen={isOpenAgreement}
      onClose={onCloseAgreement}
      onFinished={()=> {
        onSubmit2()
      }}
      inviteeName={inviteeName}
      values={postData}
      />
      {!addCareTeam ? (
        <>
          <Box mb={8}>
            <Text fontSize={20} fontWeight={600}>
              Build Your Care Team
            </Text>
            <Text fontSize={14} fontWeight={400} color={"#747A8B"}>
              Invite your co-caregivers and collaborate on the go
            </Text>
          </Box>
          {allCareTeam.length > 0 ? (
            allCareTeam.map((e, i) => (
              <CoCaregiverBox
                key={`caregiver${i}`}
                refetchAllCaregivers={fetchAllCoCaregivers}
                caregiver={e}
                // editCareGiver={()=>{}}
                editCareGiver={() => editCareGiver(e)}
                resendNotification={() => resendNotification(e)}
              />
            ))
          ) : (
            <Box>
              {loading ? (
                <Stack mt={5}>
                  <Skeleton startColor="#808080" height="50px" />
                  <Skeleton startColor="#808080" height="50px" />
                  <Skeleton startColor="#808080" height="50px" />
                </Stack>
              ) : (
                <>
                  <Center>
                    <Image src={careTeamImg} />
                  </Center>
                  <Text textAlign={"center"} fontSize={16} fontWeight={600}>
                    Let's Build Your Care Team
                  </Text>
                  <Text
                    textAlign={"center"}
                    fontSize={14}
                    fontWeight={400}
                    color={"paragraph"}
                    mt={3}
                  >
                    Your care team make up your jorner's secondary caregivers
                    and <br /> they include - Your Nanny, Family, Friends, etc.
                  </Text>
                </>
              )}
            </Box>
          )}
          <Center
            mt={4}
            color={"secondary"}
            fontSize={16}
            fontWeight={600}
            textAlign={"center"}
            cursor={"pointer"}
            onClick={() => {
              if (subscription.co_caregivers != "unlimited") {
                const sCcg = parseInt(subscription.co_caregivers);
                if (allCareTeam.length >= sCcg) {
                  return toast({
                    title: "Access Denied!",
                    description: `Please upgrade your account to Invite ${
                      allCareTeam.length == 0
                        ? "Co-caregiver"
                        : "more Co-caregivers"
                    }`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }
              setProfileStep(1);
              setAddCareTeam(true);
            }}
          >
            <FaPlus />
            <Box w={1} />
            <Text>Invite Co-caregiver</Text>
          </Center>
          {!window.location.href.includes("onboarding") && (
            <Center>
              {
                !loading &&
              
              <Button
                display={"none"}
                maxW={"250px"}
                width={"100%"}
                borderRadius={"15px"}
                mt={"3"}
                textColor={"white"}
                mx="auto"
                bg={"primary"}
                onClick={() => {
                  navigate("/care-circle/" + communityFamily.id+"?type=careteam");
                  // communityFamily.id
                }}
              >
                Chat with Care Team
              </Button>
              }
            </Center>
          )}

          {currentPath.includes("onboarding") && (
            <Button
              mt={8}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={onOpen}
            >
              Complete Onboarding
            </Button>
          )}
        </>
      ) : (
        <>
          <Box mb={8}>
            <Flex alignItems={"center"} gap={"2"}>
              {/* {profileStep === 2 && ( */}
              <FaChevronLeft
                onClick={() => {
                  if (profileStep === 1) {
                    setAddCareTeam(false);
                  } else {
                    setProfileStep(1);
                  }
                }}
              />
              {/* )} */}
              <Text fontSize={20} fontWeight={600}>
                Invite Co-caregiver
              </Text>
            </Flex>
            <Text fontSize={14} fontWeight={400} color={"#747A8B"}>
              Invite your co-caregivers and collaborate on the go
            </Text>
          </Box>
          <Flex mb={5}>
            <Box w={"45%"} h={2} bg={"success"} borderRadius={8} />
            <Box w={2} />
            <Box
              w={"45%"}
              h={2}
              bg={profileStep > 1 ? "success" : "grey"}
              borderRadius={8}
            />
          </Flex>

          <form onSubmit={handleSubmit(onSubmit)}>
            {profileStep === 1 ? (
              <>
                <FormControl isInvalid={errors.firstName}>
                  <FormLabel htmlFor="firstName" fontWeight={600} fontSize={14}>
                    First Name
                  </FormLabel>
                  <Input
                    id="firstName"
                    placeholder="e.g Simon"
                    isDisabled={
                      editCareTeam && ccgStatus == "accepted" ? true : false
                    }
                    _disabled={{
                      borderColor: "#E3E4E8",
                    }}
                    {...register(
                      editCareTeam && ccgStatus == "fn" ? true : "firstName",
                      {
                        required: "This is required",
                      }
                    )}
                    borderColor={"#E3E4E8"}
                  />
                  <FormErrorMessage>
                    {errors.firstName && errors.firstName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl mt={5} isInvalid={errors.lastName}>
                  <FormLabel htmlFor="name" fontWeight={600} fontSize={14}>
                    Last name
                  </FormLabel>
                  <Input
                    id="lastName"
                    placeholder="e.g Anderson"
                    isDisabled={
                      editCareTeam && ccgStatus == "accepted" ? true : false
                    }
                    _disabled={{
                      borderColor: "#E3E4E8",
                    }}
                    {...register(
                      editCareTeam && ccgStatus == "ln" ? true : "lastName",
                      {
                        required: "This is required",
                      }
                    )}
                    borderColor={"#E3E4E8"}
                  />
                  <FormErrorMessage>
                    {errors.lastName && errors.lastName.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mt={5}
                  isInvalid={errors.emailAddress}
                  fontWeight={600}
                >
                  <FormLabel htmlFor="name" fontWeight={600} fontSize={14}>
                    Email Address
                  </FormLabel>
                  <Input
                    id="emailAddress"
                    placeholder="e.g. jane@sojorne.com"
                    isDisabled={
                      editCareTeam ? true : false
                    }
                    _disabled={{
                      borderColor: "#E3E4E8",
                    }}
                    {...register(
                      editCareTeam && ccgStatus == "em" ? true : "emailAddress",
                      {
                        required: "This is required",
                        validate: {
                          matchPattern: (v) =>
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                              v
                            ) || "Email address must be a valid address",
                        },
                      }
                    )}
                    borderColor={"#E3E4E8"}
                  />
                  <FormErrorMessage>
                    {errors.emailAddress && errors.emailAddress.message}
                  </FormErrorMessage>
                </FormControl>
                {caregiverType.toLowerCase() !== "primary caregiver" && (
                  <FormControl mt={3} fontWeight={600}>
                    <FormLabel htmlFor="role">Jorners</FormLabel>
                    <MultiSelect
                      options={allJorners}
                      value={selectedJorners}
                      onChange={setSelectedJorners}
                      labelledBy="Select"
                    />
                  </FormControl>
                )}

                <FormControl mt={5} fontWeight={600}>
                  <FormLabel htmlFor="role">Role</FormLabel>
                  <Select
                    focusBorderColor="#E3E4E8"
                    border={"1px solid #E3E4E8"}
                    height="50px"
                    id="role"
                    onChange={(e) => setRole(e.target.value)}
                    name="role"
                    value={role}
                    placeholder=""
                  >
                    <option value="">Select Role</option>
                    {caregiverRoles.map((r, i) => (
                      <option key={i} value={r}>
                        {r}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mt={5} isInvalid={errors.phone} fontWeight={600}>
                  <FormLabel fontSize={14} fontWeight={600} htmlFor="phone1">
                    Emergency Contact 1
                  </FormLabel>

                  <PhoneNumberInput
                    key={"phoneInput1"}
                    id={"phone1"}
                    isDisabled={
                      editCareTeam && ccgStatus == "accepted" ? true : false
                    }
                    country={"USA"}
                    value={phoneNumber
                      .replace("+1", "")
                      .replace("undefined", "")}
                    options={countryOptions}
                    defaultCountry={"+1"}
                    placeholder="Enter phone number"
                    onChange={(value) => setPhoneNumber(value)}
                    onChangeCode={(value) => setCountryCode(value)}
                  />
                  <FormErrorMessage>
                    {errors.phone && errors.phone.message}
                  </FormErrorMessage>
                </FormControl>
              </>
            ) : null}

            {profileStep === 2 ? (
              <>
                <Accordion allowMultiple>
                  {allPermissions.map((permission, i) => (
                    <AccordionItem
                      key={`permission${i}`}
                      border={"1px solid white"}
                      mb={4}
                    >
                      <h2>
                        <AccordionButton bg={"#fff"}>
                          <Box as="span" flex="1" textAlign="left">
                            <Flex
                              onClick={(e) => {
                                e.preventDefault();
                                fillCheckBox(permission);
                              }}
                            >
                              <Checkbox
                                colorScheme="red"
                                isChecked={
                                  checkArraySimilarities(
                                    [
                                      `${permission}:create`,
                                      `${permission}:edit`,
                                      `${permission}:delete`,
                                      `${permission}:view`,
                                    ],
                                    selectedPermissions
                                  ) > 0
                                }
                                fontSize={14}
                                fontWeight={500}
                              >
                                <Box>
                                {
                                  permission.includes("documents")
                                  ?
                                  capitalizeFirstWord(
                                      permission.replace("_", " - ").replaceAll("_", " ")
                                  )
                                  :
                                  capitalizeFirstWord(
                                    permission == "community"
                                      ? "Care circle"
                                      : permission.replaceAll("_", " ")
                                  )
                                }
                                </Box>
                              </Checkbox>
                            </Flex>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} bg={"#FAF8FF"}>
                        <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
                          <Box bg={"#fff"} p={2}>
                            <Flex gap={2}>
                              <Checkbox
                                colorScheme="red"
                                {...getCheckboxProps({
                                  value: `${permission}:view`,
                                })}
                              >
                                <Box>
                                  <Text
                                    fontSize={14}
                                    fontWeight={400}
                                    color={"#17181C"}
                                  >
                                    View
                                  </Text>
                                  <Text
                                    fontSize={12}
                                    fontWeight={400}
                                    color={"#747A8B"}
                                  >
                                    Allow user view all {permission}
                                  </Text>
                                </Box>
                              </Checkbox>
                            </Flex>
                          </Box>
                          <Box bg={"#fff"} p={2}>
                            <Flex gap={2}>
                              <Checkbox
                                colorScheme="red"
                                {...getCheckboxProps({
                                  value: `${permission}:create`,
                                })}
                              >
                                <Box>
                                  <Text
                                    fontSize={14}
                                    fontWeight={400}
                                    color={"#17181C"}
                                  >
                                    Create
                                  </Text>
                                  <Text
                                    fontSize={12}
                                    fontWeight={400}
                                    color={"#747A8B"}
                                  >
                                    Allow user create a new {permission}{" "}
                                  </Text>
                                </Box>
                              </Checkbox>
                            </Flex>
                          </Box>
                          <Box bg={"#fff"} p={2}>
                            <Flex gap={2}>
                              <Checkbox
                                colorScheme="red"
                                {...getCheckboxProps({
                                  value: `${permission}:edit`,
                                })}
                              >
                                <Box>
                                  <Text
                                    fontSize={14}
                                    fontWeight={400}
                                    color={"#17181C"}
                                  >
                                    Edit
                                  </Text>
                                  <Text
                                    fontSize={12}
                                    fontWeight={400}
                                    color={"#747A8B"}
                                  >
                                    Allow user edit a {permission}{" "}
                                  </Text>
                                </Box>
                              </Checkbox>
                            </Flex>
                          </Box>
                          {
                            permission == "community"
                            &&
                            <Box bg={"#fff"} p={2}>
                            <Flex gap={2}>
                              <Checkbox
                                colorScheme="red"
                                {...getCheckboxProps({
                                  value: `${permission}:edit`,
                                })}
                              >
                                <Box>
                                  <Text
                                    fontSize={14}
                                    fontWeight={400}
                                    color={"#17181C"}
                                  >
                                    Chat
                                  </Text>
                                  <Text
                                    fontSize={12}
                                    fontWeight={400}
                                    color={"#747A8B"}
                                  >
                                    Allow user to chat in a {permission}
                                  </Text>
                                </Box>
                              </Checkbox>
                            </Flex>
                          </Box>
                          }
                          <Box bg={"#fff"} p={2}>
                            <Flex gap={2}>
                              <Checkbox
                                colorScheme="red"
                                {...getCheckboxProps({
                                  value: `${permission}:delete`,
                                })}
                              >
                                <Box>
                                  <Text
                                    fontSize={14}
                                    fontWeight={400}
                                    color={"#17181C"}
                                  >
                                    Delete
                                  </Text>
                                  <Text
                                    fontSize={12}
                                    fontWeight={400}
                                    color={"#747A8B"}
                                  >
                                    Allow user delete a new {permission}{" "}
                                  </Text>
                                </Box>
                              </Checkbox>
                            </Flex>
                          </Box>
                        </SimpleGrid>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </>
            ) : null}

            <Button
              mt={8}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              isLoading={isSubmitting}
              type="submit"
            >
              Next
            </Button>
          </form>
        </>
      )}

      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        body={
          <Box>
            <Center>
              <Image src={successGif} />
            </Center>
            <Text textAlign={"center"} fontSize={20} fontWeight={600}>
              Onboarding Completed
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              You have successfully completed your onboarding on Sojorne
            </Text>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={completeOnboarding}
            >
              Let's Sojorne
            </Button>
          </Box>
        }
      />
    </Box>
  );
}
