import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  useCheckboxGroup,
  useToast,
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { capitalizeFirstWord, checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { useFormik } from "formik";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import PhoneNumberInput2 from "../../components/phoneNumberInput2";
import { httpPostWithToken } from "../../utils/http_utils";
import { MultiSelect } from "react-multi-select-component";
import AgreementCaregiverModal from "../auth/agreement_caregiver";

export default function InviteCareGiverModal({
  isOpen,
  coGiverConstants,
  onClose,
  jorners,
  community = "",
  callback = null
}) {
  const finishSelection = () => {
    if(callback) callback();
    onClose();
  };
  const {
    value: selectedPermissions,
    getCheckboxProps,
    setValue: setCheckValue,
  } = useCheckboxGroup({
    defaultValue: [
      `community:edit`,
      `community:create`,
      `community:view`,
      `community:delete`,
    ],
  });
  const caregiverRoles = [
    "Parent",
    "Primary Caregiver",
    "Grandparent",
    "Sitter, Au pair or Nanny",
    "Aunt or Uncle",
    "Other Relative",
    "Family Friend",
    "Teacher",
    "School Official",
    "Medical Practitioner",
    "Therapist or Technician",
    "Other",
  ];
  const allPermissions = ["home", "calendar", "task", "provider", "community"];
  const [constants, setConstants] = useState(coGiverConstants);
  const [selectedJorners, setSelectedJorners] = useState([]);
  const [allJorners, setAllJorners] = useState([]);
  const [comm, setCommunity] = useState("");
  const [inviteeName, setInviteename] = React.useState("");
  const [postData, setData] = React.useState(null);
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const toast = useToast();
  const {
    isOpen: isOpenAgreement,
    onOpen: onOpenAgreement,
    onClose: onCloseAgreement,
  } = useDisclosure();
  const validateEmail = (e) => {
    return String(e)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    setCheckValue([
      `home:edit`,
      `home:create`,
      `home:view`,
      `home:delete`,
      `home:edit`,
      `community:chat`,
      `community:create`,
      `community:view`,
      `community:delete`,
      `jorner_profile:edit`,
      `jorner_profile:create`,
      `jorner_profile:view`,
      `jorner_profile:delete`,
      `documents_care_plan:edit`,
      `documents_care_plan:create`,
      `documents_care_plan:view`,
      `documents_care_plan:delete`,
      
    ]);
    setAllJorners(jorners);
    setCommunity(community);
  }, [jorners, onClose, isOpen, community]);

  const formik = useFormik({
    initialValues: {
      last_name: "",
      first_name: "",
      email: "",
      phone: "",
      role: "",
      permissions: [],
      phone_code: "+1",
    },
    onSubmit: async (values) => {
      const ssJorner = selectedJorners.map((item, i) => {
        return item.value;
      });
      if (
        values.last_name == "" ||
        values.first_name == "" ||
        values.email == "" ||
        values.phone == "" ||
        values.role == ""
      ) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `All fields are required`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (!validateEmail(values.email)) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Please enter a valid email address`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      if (selectedPermissions.length === 0) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Please assign permission`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      //console.log("selected", selectedPermissions)
      var data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        country_code: values.phone_code,
        phone_number: values.phone,
        permissions: selectedPermissions,
        caregiver_type: values.role,
        jorners: ssJorner ? ssJorner : [],
      };
      if (comm != "") {
        data.community_id = comm;
      }
      setData(data);
      setInviteename(values.first_name + " " + values.last_name);
      onOpenAgreement();
      return;
      // var response = await httpPostWithToken("/co_caregiver/invite", data);

      // if (response.error) {
      //   return toast({
      //     title: "Sorry, we hit a snag!",
      //     description: `${response.error}`,
      //     status: "error",
      //     duration: 5000,
      //     isClosable: true,
      //   });
      // } else {
        
      // }
    },
  });

  const onSubmit2 = () => {
    toast({
      title: "Invite sent successfully",
      // description: `${response.error}`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    formik.values.email = "";
    formik.values.first_name = "";
    formik.values.last_name = "";
    formik.values.phone = "";
    formik.values.phone_code = "+1";
    formik.values.permissions = [];
    formik.values.role = "";
    setCheckValue([]);
    setTimeout(() => {
      finishSelection();
    }, 1000);
  }
  const fillCheckBox = (permission) => {
    var isChecked = checkArraySimilarities(
      [
        `${permission}:create`,
        `${permission}:edit`,
        `${permission}:delete`,
        `${permission}:view`,
      ],
      selectedPermissions
    );
    if (!isChecked) {
      var s = selectedPermissions;
      s = [
        ...s,
        ...[
          `${permission}:create`,
          `${permission}:edit`,
          `${permission}:delete`,
          `${permission}:view`,
        ],
      ];
      setCheckValue(s);
    } else {
      var s = selectedPermissions;
      var newS = [];
      s.map((item) => {
        if (item.includes(permission)) {
        } else {
          newS = [...newS, item];
        }
      });
      setCheckValue(newS);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p="3">
      <ModalHeader>Invite Co-caregiver</ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <ModalBody marginBottom={"0"}>
          <Box>
          <AgreementCaregiverModal
          isOpen={isOpenAgreement}
          onClose={onCloseAgreement}
          onFinished={()=> {
            onSubmit2()
          }}
          inviteeName={inviteeName}
          values={postData}
          />
            <Text fontSize={"14px"}>
              Invite your co-caregivers and collaborate on the go
            </Text>
            <Box mt={"3"} />
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="first_name">First Name</FormLabel>
                  <Input
                    height={"50px"}
                    id="first_name"
                    name="first_name"
                    type="text"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="E.g Andrew"
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                  />
                </FormControl>

                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="last_name">Last Name</FormLabel>
                  <Input
                    height={"50px"}
                    id="last_name"
                    name="last_name"
                    type="text"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="E.g Andrew"
                    onChange={formik.handleChange}
                    value={formik.values.last_name}
                  />
                </FormControl>

                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="last_name">Email Address</FormLabel>
                  <Input
                    height={"50px"}
                    id="email"
                    name="email"
                    type="email"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="someone@example.com"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </FormControl>

                <FormControl mt={5} fontWeight={600}>
                  <FormLabel htmlFor="phone">
                    Phone number {formik.values.phone_code}{" "}
                    {formik.values.phone}{" "}
                  </FormLabel>
                  <PhoneNumberInput2
                    onCodeChange={(v) => formik.setFieldValue("phone_code", v)}
                    key={"phoneInput"}
                    id={"phone"}
                    value={formik.values.phone}
                    options={countryOptions}
                    placeholder="Enter phone number"
                    onChange={(v) => formik.setFieldValue("phone", v)}
                  />
                </FormControl>
                {/* <FormControl mt="5" fontWeight={600}>
                <Text>Assign Permissions</Text>
                {
                  constants.map((item, i)=>(
                    <Box>
                      {i}
                    </Box>
                  ))
                }

              </FormControl> */}
                <FormControl mt={5} display={"none"} fontWeight={600}>
                  <FormLabel htmlFor="phone">Assign Permission</FormLabel>
                  <Accordion allowMultiple>
                    {allPermissions.map((permission, i) => (
                      <AccordionItem
                        key={`permission${i}`}
                        border={"1px solid white"}
                        mb={4}
                      >
                        <h2>
                          <AccordionButton bg={"#fff"}>
                            <Box as="span" flex="1" textAlign="left">
                            <Flex
                              onClick={(e) => {
                                e.preventDefault();
                                fillCheckBox(permission);
                              }}
                            >
                              <Checkbox
                                colorScheme="red"
                                isChecked={
                                  checkArraySimilarities(
                                    [
                                      `${permission}:create`,
                                      `${permission}:edit`,
                                      `${permission}:delete`,
                                      `${permission}:view`,
                                    ],
                                    selectedPermissions
                                  ) > 0
                                }
                                fontSize={14}
                                fontWeight={500}
                              >
                                <Box>
                                  {capitalizeFirstWord(
                                    permission == "community"
                                      ? "Care circle"
                                      : permission
                                  )}{" "}
                                </Box>
                              </Checkbox>
                            </Flex>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} bg={"#FAF8FF"}>
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
                            <Box bg={"#fff"} p={2}>
                              <Flex gap={2}>
                                <Checkbox
                                  colorScheme="red"
                                  {...getCheckboxProps({
                                    value: `${permission}:view`,
                                  })}
                                >
                                  <Box>
                                    <Text
                                      fontSize={14}
                                      fontWeight={400}
                                      color={"#17181C"}
                                    >
                                      View
                                    </Text>
                                    <Text
                                      fontSize={12}
                                      fontWeight={400}
                                      color={"#747A8B"}
                                    >
                                      Allow user view all {permission}
                                    </Text>
                                  </Box>
                                </Checkbox>
                              </Flex>
                            </Box>
                            <Box bg={"#fff"} p={2}>
                              <Flex gap={2}>
                                <Checkbox
                                  colorScheme="red"
                                  {...getCheckboxProps({
                                    value: `${permission}:create`,
                                  })}
                                >
                                  <Box>
                                    <Text
                                      fontSize={14}
                                      fontWeight={400}
                                      color={"#17181C"}
                                    >
                                      Create
                                    </Text>
                                    <Text
                                      fontSize={12}
                                      fontWeight={400}
                                      color={"#747A8B"}
                                    >
                                      Allow user create a new {permission}{" "}
                                    </Text>
                                  </Box>
                                </Checkbox>
                              </Flex>
                            </Box>
                            <Box bg={"#fff"} p={2}>
                              <Flex gap={2}>
                                <Checkbox
                                  colorScheme="red"
                                  {...getCheckboxProps({
                                    value: `${permission}:edit`,
                                  })}
                                >
                                  <Box>
                                    <Text
                                      fontSize={14}
                                      fontWeight={400}
                                      color={"#17181C"}
                                    >
                                      Edit
                                    </Text>
                                    <Text
                                      fontSize={12}
                                      fontWeight={400}
                                      color={"#747A8B"}
                                    >
                                      Allow user edit a {permission}{" "}
                                    </Text>
                                  </Box>
                                </Checkbox>
                              </Flex>
                            </Box>
                            <Box bg={"#fff"} p={2}>
                              <Flex gap={2}>
                                <Checkbox
                                  colorScheme="red"
                                  {...getCheckboxProps({
                                    value: `${permission}:delete`,
                                  })}
                                >
                                  <Box>
                                    <Text
                                      fontSize={14}
                                      fontWeight={400}
                                      color={"#17181C"}
                                    >
                                      Delete
                                    </Text>
                                    <Text
                                      fontSize={12}
                                      fontWeight={400}
                                      color={"#747A8B"}
                                    >
                                      Allow user delete a new {permission}{" "}
                                    </Text>
                                  </Box>
                                </Checkbox>
                              </Flex>
                            </Box>
                          </SimpleGrid>
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </FormControl>
                {/* <FormControl mt={3} fontWeight={600}>
                  <FormLabel htmlFor="role">Jorners</FormLabel>
                  <MultiSelect
                    options={allJorners}
                    value={selectedJorners}
                    onChange={setSelectedJorners}
                    labelledBy="Select"
                  />
                </FormControl> */}
                <FormControl mt={5} fontWeight={600}>
                  <FormLabel htmlFor="role">Role</FormLabel>
                  <Select
                    focusBorderColor="#E3E4E8"
                    border={"1px solid #E3E4E8"}
                    height="50px"
                    id="role"
                    onChange={(e) =>
                      formik.setFieldValue("role", e.target.value)
                    }
                    name="role"
                    value={formik.values.role}
                    placeholder=""
                  >
                    <option value="">Select Role</option>
                    {caregiverRoles.map((role, i) => (
                      <option key={i} value={role}>
                        {role}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  isLoading={formik.isSubmitting}
                  type="submit"
                  h="50px"
                  borderRadius={"60px"}
                  colorScheme="purple"
                  width="full"
                >
                  Send Invite
                </Button>
                <Box p="2" color={"red.600"} fontSize={"14px"}>
                  <Text textAlign={"center"} fontWeight={"bold"}>Care members will only have access to care circle and care plan.</Text>
                </Box>
              </VStack>
            </form>
            <Box height={"20px"} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
