import React from "react";
import ls from "localstorage-slim";
import { loginRoute } from "../utils/allRoutes";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const token = sessionStorage.getItem("sj_token");
  // const token = ls.get('sj_token', {decrypt: true});
  if (!token) {
    return <Navigate to={loginRoute} />;
  }
  return children;
}
