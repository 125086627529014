import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { healthcare } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { EmailChipInput } from "../../components/emailInput";

export default function StrengthModal({
  isOpen,
  defaultV = [],
  onClose,
  strengthConstantsOriginal,
  onFinished,
  showOthers=false
}) {
  const [strengthConstants, setStrengthConstants] = React.useState(
    strengthConstantsOriginal
  );

  const [inputValue, setInputValue] = useState("");
  const [generalSearch, setGeneralSearch] = useState([]);
  const [showGeneralSearch, setShowGeneralSearch] = useState(false);
  const [strengthList, setStrengthList] = React.useState([]);
  const [strengthListOriginal, setStrengthListOriginal] = React.useState([]);
  const [showStrengthList, setShowStrengthList] = React.useState(false);
  const [disabilityTitle, setDisabilityTitle] = React.useState("");

  const [allOtherSelected, setAllOtherSelected] = useState([]);
  const [allOtherSelectedConstant, setAllOtherSelectedConstant] = useState([]);
  const [allOtherSelectedPending, setAllOtherSelectedPending] = useState([]);

  const { value: selectedStrength, setValue, getCheckboxProps } = useCheckboxGroup({
    defaultValue: defaultV.length > 0 ? defaultV : [],
  });

  useEffect(() => {
    setShowGeneralSearch(false);
    setValue(defaultV)
    if(showOthers) {
      let allothers = []
      allothers = defaultV.filter((l) => strengthConstantsOriginal.includes(l) == false);
      console.log("Others", allothers);
      setAllOtherSelected([...new Set(allothers)])
      setAllOtherSelectedConstant([...new Set(allothers)])
    }
    setInputValue("");
  }, [isOpen, strengthConstantsOriginal]);
  const searchJornerStrengthConstants = (e) => {
    let keyword = e.target.value;
    setInputValue(keyword);
    let allListItems = strengthConstantsOriginal;

    if (keyword < 1) {
      setStrengthConstants(strengthConstantsOriginal);
    } else {
      let allDisabilities = strengthConstantsOriginal;
      let searchResult = allListItems.filter((e) =>
        e.toLowerCase().includes(keyword.toLowerCase())
      );
      setGeneralSearch(searchResult);
      setShowGeneralSearch(true);
      // setStrengthConstants(searchResult);
    }
  };

  const searchJornerDisabilityDetailsList = (e) => {
    let keyword = e.target.value;
    if (keyword < 1) {
      setStrengthList(strengthListOriginal);
    } else {
      let allStrengthList = strengthListOriginal;
      let searchResult = allStrengthList.filter((e) =>
        e.toLowerCase().includes(keyword.toLowerCase())
      );
      setStrengthList(searchResult);
    }
  };

  const handleShowDisabilityDetails = (e = "") => {
    if(e.toLowerCase() == "others") {
        setStrengthList(strengthConstantsOriginal);
        setStrengthListOriginal(strengthConstantsOriginal);
        setAllOtherSelectedPending(allOtherSelected);
        setDisabilityTitle("Others")
        setShowStrengthList(true);

    }else{
      setStrengthList(strengthConstantsOriginal);
      setStrengthListOriginal(strengthConstantsOriginal);
      setDisabilityTitle("Strength");
      setShowStrengthList(true);
    }
  };

  const finishSelection = () => {
    let s = selectedStrength;
    s = [...selectedStrength, ...allOtherSelected];
    //console.log("s", allOtherSelected);
    onFinished(s);
    setInputValue("");
    setShowGeneralSearch(false);
    setAllOtherSelected([])
    setAllOtherSelectedConstant([])
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"2xl"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        {!showStrengthList ? (
          <>
            <ModalHeader display={"flex"} alignItems={"center"} w={"100%"}>
              {showGeneralSearch && (
                <FaChevronLeft
                  onClick={() => {
                    setShowGeneralSearch(false);
                    setInputValue("");
                    setGeneralSearch([]);
                  }}
                />
              )}
              <Text>{showGeneralSearch ? "Search Result" : "Strength"}</Text>
            </ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />
              <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {selectedStrength.length} selected
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  borderRadius={100}
                  value={inputValue}
                  onChange={searchJornerStrengthConstants}
                  borderColor={"#E3E4E8"}
                />
              </InputGroup>
              {showGeneralSearch ? (
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                  {generalSearch.map((e, i) => (
                    <Checkbox
                      colorScheme="red"
                      style={{ color: "secondary" }}
                      {...getCheckboxProps({ value: e })}
                    >
                      <Text>{e}</Text>
                    </Checkbox>
                  ))}
                </SimpleGrid>
              ) : (
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3} mt={5}>
                  <Box
                    key={`strength`}
                    bg={"gray"}
                    cursor={"pointer"}
                    borderRadius={8}
                    p={3}
                    onClick={() => handleShowDisabilityDetails()}
                  >
                    <Image src={healthcare} />
                    <Text fontSize={14} fontWeight={500}>
                      Strengths
                    </Text>
                    <Text
                      color={"paragraph"}
                      fontSize={12}
                      fontWeight={500}
                      my={3}
                    >
                      {checkArraySimilarities(
                        selectedStrength,
                        strengthConstantsOriginal
                      )}{" "}
                      /{strengthConstantsOriginal.length} Selected
                    </Text>
                  </Box>
                  {
                    showOthers
                    &&
                   <Box
                      bg={"gray"}
                      cursor={"pointer"}
                      borderRadius={8}
                      p={3}
                      onClick={() => handleShowDisabilityDetails("Others")}
                    >
                      <Image src={healthcare} />
                      <Text fontSize={14} fontWeight={500}>
                        Others
                      </Text>
                      <Text
                        color={"paragraph"}
                        fontSize={12}
                        fontWeight={500}
                        my={3}
                      >
                        {allOtherSelected.length} Selected
                      </Text>
                    </Box>
                    }

                </SimpleGrid>
              )}
            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={finishSelection}
                >
                  Next
                </Button>
              </Box>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalHeader>
              <Flex alignItems={"center"}>
                <FaChevronLeft onClick={() => setShowStrengthList(false)} />
                <Text ml={2}>{disabilityTitle}</Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />

              {
                showOthers && disabilityTitle.toLowerCase() === "others"
                ?
                <>
                      <Text fontSize={"13px"} mb="1">Use the (+) Icon to save your entries.</Text>
                 <EmailChipInput
                      initialEmails={allOtherSelectedPending}
                      callBackFunc={(e) =>  setAllOtherSelectedPending([...new Set(e)])}
                      placeholder={"Type here"}
                      isEmail={false}
                    />
                </>
                :
              <>
              <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {checkArraySimilarities(selectedStrength, strengthList)}{" "}
                selected
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  key={"searchList"}
                  borderRadius={100}
                  onChange={searchJornerDisabilityDetailsList}
                  borderColor={"#E3E4E8"}
                />
              </InputGroup>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                <Checkbox
                  colorScheme="red"
                  style={{ color: "secondary" }}
                  {...getCheckboxProps({ value: "None" })}
                >
                  <Text>None</Text>
                </Checkbox>
                {strengthList.map((e, i) => (
                  <Checkbox
                    colorScheme="red"
                    style={{ color: "secondary" }}
                    {...getCheckboxProps({ value: e })}
                  >
                    <Text>{e}</Text>
                  </Checkbox>
                ))}
              </SimpleGrid>
              </>
              }

            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={() => {
                    if(showOthers && disabilityTitle.toLowerCase() === "others") {
                      setAllOtherSelected(allOtherSelectedPending)

                    }else{
                    }
                    setShowStrengthList(false)
                  } }
                >
                  Proceed
                </Button>
              </Box>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
