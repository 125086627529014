import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  useCheckboxGroup,
  useToast,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { capitalizeFirstWord, checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { useFormik } from "formik";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import { httpPostWithToken } from "../../utils/http_utils";

export default function AddNewFamilyModal({
  isOpen,
  onClose,
}) {
  const finishSelection = () => {
    onClose();
  };
  

  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      family_name: "",
    },
    onSubmit: async (values) => {
     
      if (
        values.family_name == ""
      ) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `All fields are required`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      
      //console.log("selected", selectedPermissions)
      var data = {
        family_name: values.family_name,
      };
    },
  });


  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p="3">
      <ModalHeader>Create New Family</ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <ModalBody marginBottom={"0"}>
          <Box>
        
            <Text fontSize={"14px"}>
            Complete the field to create a new family
            </Text>
            <Box mt={"3"} />
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="family_name">First Name</FormLabel>
                  <Input
                    height={"50px"}
                    id="family_name"
                    name="family_name"
                    type="text"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="E.g Andrew"
                    onChange={formik.handleChange}
                    value={formik.values.family_name}
                  />
                </FormControl>

              <Box display={"flex"} w={"full"} justifyContent={"flex-end"}>
                <Button
                  ml={"auto"}
                  isLoading={formik.isSubmitting}
                  type="submit"
                  h="50px"
                  w={"200px"}
                  borderRadius={"60px"}
                  colorScheme="purple"
                >
                  Create Family
                </Button>
              </Box>

                
              </VStack>
            </form>
            <Box height={"20px"} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
