import {
  Box,
  Link,
  Image,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Button,
  Divider,
  Grid,
  GridItem,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link as ReachLink, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import JornerCard from "../../components/dashboard/jorner_card";
import {
  PencilSquareIcon,
  PlusIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/solid";
import AddJorner from "../../components/dashboard/add_jorner_card";
import JornerHeader from "../../components/dashboard/jorner_header";
import NoTabItem from "../../components/dashboard/no_tab_item";
import TabListItem from "../../components/dashboard/tab_list_item";
import { medicationIcon, tabitemnotfound } from "../../images";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import StrengthModal from "../onboarding/strengthModal";
import DietaryModal from "../onboarding/SingleModal";
// import DisabilityModal from "../onboarding/disabilityModal";
import WhatWorksForMeModal from "../onboarding/whatWorksForMeModal";
import GoalsModal from "../onboarding/DoubleModal";
import DisabilityModal from "../onboarding/whatWorksForMeModal";
import WhatDoesNotWorksForMeModal from "../onboarding/whatDoesNotWorksForMeModal";
import MedicationModal from "../onboarding/medicationModal2";

import ls from "localstorage-slim";
import RemoveJorner from "./remove_jorner_modal";
import ShareJorner from "./share_jorner";
import { PDFDownloadLink } from "@react-pdf/renderer";
import JornerPdf from "./pdf/generate_pdf";
import moment from "moment";

function SingleJorner() {
  const [jorner, setJorner] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 0]);
  const [selectedTab, setSelectedTab] = useState("disability");
  const navigate = useNavigate();
  var toast = useToast();

  const {
    isOpen: isOpenStrength,
    onOpen: onOpenStrength,
    onClose: onCloseStrength,
  } = useDisclosure();
  const [disabilitiesConstantsOriginal, setDisabilitiesConstantsOriginal] =
    React.useState({});
  const [strengthConstantsOriginal, setStrengthConstantsOriginal] =
    React.useState({});
  const [whatWorksForMeOriginal, setWhatWorksForMeOriginal] = React.useState(
    {}
  );
  const [whatDoesNotWorksForMeOriginal, setWhatDoesNotWorksForMeOriginal] =
    React.useState({});
  const [goalsOriginal, setGoalsOriginal] = React.useState({});
  const [medicationOriginal, setMedicationOriginal] = React.useState({});
  const [dietaryRestrictionsOriginal, setDietaryRestrictionsOriginal] =
    React.useState({});
  const [allergiesOriginal, setAllergiesOriginal] = React.useState({});

  const {
    isOpen: isOpenDisability,
    onOpen: onOpenDisability,
    onClose: onCloseDisability,
  } = useDisclosure();

  const {
    isOpen: isOpenWhatWorksForMe,
    onOpen: onOpenWhatWorksForMe,
    onClose: onCloseWhatWorksForMe,
  } = useDisclosure();
  const {
    isOpen: isOpenWhatDoesNotWorkForMe,
    onOpen: onOpenWhatDoesNotWorkForMe,
    onClose: onCloseWhatDoesNotWorkForMe,
  } = useDisclosure();
  const {
    isOpen: isOpenGoals,
    onOpen: onOpenGoals,
    onClose: onCloseGoals,
  } = useDisclosure();
  const {
    isOpen: isOpenMedication,
    onOpen: onOpenMedication,
    onClose: onCloseMedication,
  } = useDisclosure();
  const {
    isOpen: isOpenDietaryRestrictions,
    onOpen: onOpenDietaryRestrictions,
    onClose: onCloseDietaryRestrictions,
  } = useDisclosure();
  const {
    isOpen: isOpenAllergies,
    onOpen: onOpenAllergies,
    onClose: onCloseAllergies,
  } = useDisclosure();

  const {
    isOpen: isOpenRemoveJorner,
    onOpen: onOpenRemoveJorner,
    onClose: onCloseRemoveJorner,
  } = useDisclosure();

  const {
    isOpen: isOpenShareJorner,
    onOpen: onOpenShareJorner,
    onClose: onCloseShareJorner,
  } = useDisclosure();

  const [disabilities, setDisabilities] = useState([1, 1, 1]);
  const [allergies, setAllergies] = useState([1, 1, 1]);
  const [strengths, setStrengths] = useState([1, 1, 1]);
  const [whatworksforme, setWhatworksforme] = useState([1, 1, 1]);
  const [whatdoesnotworkforme, setWhatdoesnotworkforme] = useState([1, 1, 1]);
  const [goals, setGoals] = useState([]);
  const [medications, setMedications] = useState([1, 1, 1]);
  const [dietary, setDietary] = useState([1, 1, 1]);

  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const params = useParams();
  const tabItems = [
    "Disability",
    "Strengths",
    "What works for me",
    "What does not work for me",
    "Goals I am working on",
    "Medications",
    "Dietary Restriction",
  ];

  // download pdf
  const pdfButton = React.useRef();

  const fetchJornersConstants = async () => {
    const resp = await httpGetWithTokenRest("constants");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setDisabilitiesConstantsOriginal(resp.data.disabilities);
      setStrengthConstantsOriginal([...resp.data.strengths]);
      let wwfm = resp.data.what_works_for_me;
      setWhatWorksForMeOriginal(wwfm);
      // console.log(resp.data.goals)
      setWhatDoesNotWorksForMeOriginal([...resp.data.what_does_not_work_for_me]);
      setGoalsOriginal(resp.data.goals ? resp.data.goals : {} );
      setMedicationOriginal(resp.data.medications);
      // console.log(resp.data.medications);
      setDietaryRestrictionsOriginal([...resp.data.dietary_restrictions]);
      setAllergiesOriginal(resp.data.allergies);
    }
  };
  useEffect(() => {
    (async () => {
      await fetchJornersConstants();

      await fetchJorner();
    })();
  }, []);

  const fetchJorner = async () => {
    var j = await httpGetWithTokenRest("/jorner/" + params.id);
    if (j.status == "success") {
      j.data.what_does_not_work_for_me = j.data.what_doesnt_work_for_me
      setJorner(j.data);
      setLoading(false);
      setDisabilities(j.data.disabilities ? j.data.disabilities : []);
      setStrengths(j.data.strengths ? j.data.strengths : []);
      setStrengths(j.data.strengths ? j.data.strengths : []);
      setGoals(j.data.goals_am_working_on ? j.data.goals_am_working_on : []);
      setMedications(j.data.medications ? j.data.medications : []);
      setDietary(
        j.data.dietary_restrictions ? j.data.dietary_restrictions : []
      );
      setWhatdoesnotworkforme(
        j.data.what_doesnt_work_for_me ? j.data.what_doesnt_work_for_me : []
      );
      setWhatworksforme(
        j.data.what_works_for_me ? j.data.what_works_for_me : []
      );
      // setDisabilities(["Cataract"])

      // console.log("m", j.data.medications)
    } else {
      setNotFound(true);
      setLoading(false);
    }
  }

  const finishSelectionStrength = async (arr) => {
    var r = await updateJorner({ strengths: arr });
    if (r) {
      setStrengths(arr);
      fetchJorner()
    }
  };

  const finishSelectionWhatWorksForMe = async (arr) => {
    var r = await updateJorner({ what_works_for_me: arr });
    if (r) {
      setWhatworksforme(arr);
      fetchJorner()
    }
  };

  const finishSelectionWhatDoesNotWorksForMe = async (arr) => {
    var r = await updateJorner({ what_doesnt_work_for_me: arr });
    if (r) {
      setWhatdoesnotworkforme(arr);
      fetchJorner()
    }
  };

  const finishSelectionGoals = async (arr) => {
    var r = await updateJorner({ goals_am_working_on: arr });
    if (r) {
      setGoals(arr);
      fetchJorner()
    }
  };

  const finishSelectionMedications = async (arr) => {
    // setMedications(arr);
    var r = await updateJorner({ medications: arr });
    if (r) {
      setMedications(arr);
      fetchJorner()
    }
  };

  const finishSelectionDietaryRestrictions = async (arr) => {
    var r = await updateJorner({ dietary_restrictions: arr });
    if (r) {
      setDietary(arr);
      fetchJorner()
    }
  };

  const finishSelectionAllergies = (arr) => {
    setAllergies(arr);
  };
  const finishSelection = async (selectedDisabilityArr) => {
    var r = await updateJorner({ disabilities: selectedDisabilityArr });
    if (r) {
      setDisabilities(selectedDisabilityArr);
      fetchJorner()
    }
  };
  const updateJorner = async (data) => {
    // caregivers/jorners/:jorner_id
    //  console.log(data)
    setIsLoading(true);
    var response = await httpPutWithTokenRest(
      "caregivers/jorners/" + params.id,
      data
    );
    if (response.error) {
      toast({
        title: "Sorry, we hit a snag!",
        description: `${response.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return false;
    } else {
      toast({
        title: "Jorner updated successfully",
        // description: `${response.error}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);

      return true;
    }
  };
  useEffect(() => {
    // fetchJornersConstants()
  }, []);

  return (
    <Box
      padding={"19px 20px"}
      height="100%"
      minHeight={"100vh"}
      background={"primaryBg"}
    >
      {loading ? (
        ""
      ) : notFound ? (
        <NoJorner />
      ) : (
        <Box>
          {/* greetings */}
          <Flex alignItems="center" justifyContent={"space-between"}>
            <Box display="flex" alignItems={"center"}>
              <AppLink
                hasLeftIcon={true}
                leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
                shadow="none"
                padding="0"
                text=""
                background="transparent"
                color="secondary"
                width="fit-content"
                textDecoration="none"
                href="/jorners"
              />
              <Box width="2px" />
              <Heading
                color={"secondary"}
                fontSize={"22px"}
                fontWeight={"600"}
                as={"h3"}
              >
                {jorner.first_name} {jorner.last_name}
              </Heading>
            </Box>

            <Box
              onClick={() => navigate("/jorner/edit/" + jorner.id)}
              display={{ base: "block", md: "none" }}
            >
              <Icon color={"paragraph"} as={PencilSquareIcon} fontSize="20px" />
            </Box>
            <Box display={{ base: "none", md: "block" }}>
              <AppLink
                hasLeftIcon={true}
                shadow="none"
                leftIcon={<Icon as={PencilSquareIcon} fontSize="20px" />}
                padding="0 15px"
                text="Edit Jorner"
                background="white"
                color="paragraph"
                textDecoration="none"
                href={"/jorner/edit/" + jorner.id}
              />
            </Box>
          </Flex>
          <Box h="20px" />

          <ShareJorner
            jorner={jorner}
            onClose={onCloseShareJorner}
            isOpen={isOpenShareJorner}
          />

          <RemoveJorner
            jorner={jorner.id}
            onClose={onCloseRemoveJorner}
            isOpen={isOpenRemoveJorner}
          />
          <StrengthModal
            defaultV={strengthConstantsOriginal.length > 0 ? strengths : []}
            isOpen={isOpenStrength}
            onClose={onCloseStrength}
            strengthConstantsOriginal={strengthConstantsOriginal}
            onFinished={finishSelectionStrength}
            showOthers={true}
          />

          <DisabilityModal
            defaultV={disabilities}
            isOpen={isOpenDisability}
            onClose={onCloseDisability}
            whatWorksForMeOriginal={disabilitiesConstantsOriginal}
            onFinished={finishSelection}
            showOthers={true}
            title="Disabilities"
          />
          <div className="text-center">
            {/* {!loading && (
              <PDFDownloadLink
                ref={pdfButton}
                className="bg-blue rounded-[10px] font-bold font-primary text-yellow h-[45px] mb-3 w-[150px] mx-auto flex items-center justify-center px-[10px] text-[14px]"
                style={{ color: "yellow" }}
                document={<JornerPdf jorner={jorner} />}
                fileName={`jorner.pdf`}
              >
                {(blog) => (blog.loading ? "Loading document..." : "Export")}
              </PDFDownloadLink>
            )} */}
          </div>
          <WhatWorksForMeModal
            defaultV={whatworksforme}
            isOpen={isOpenWhatWorksForMe}
            onClose={onCloseWhatWorksForMe}
            whatWorksForMeOriginal={whatWorksForMeOriginal}
            onFinished={finishSelectionWhatWorksForMe}
            showOthers={true}
          />

          <WhatDoesNotWorksForMeModal
            defaultV={whatdoesnotworkforme}
            isOpen={isOpenWhatDoesNotWorkForMe}
            onClose={onCloseWhatDoesNotWorkForMe}
            wDWFMConstantsOriginal={whatDoesNotWorksForMeOriginal}
            onFinished={finishSelectionWhatDoesNotWorksForMe}
            title="What Does Not Work For Me"
            showOthers={true}
          />

          <GoalsModal
            defaultV={goals}
            isOpen={isOpenGoals}
            onClose={onCloseGoals}
            whatWorksForMeOriginal={goalsOriginal}
            onFinished={finishSelectionGoals}
            showOthers={true}
            title="Goals I am working on"
          />

          <MedicationModal
            isOpen={isOpenMedication}
            onClose={onCloseMedication}
            medicationConstantsOriginal={medicationOriginal}
            onFinished={finishSelectionMedications}
            selected={medications}
          />
          {/* //This is for Dietary */}
          <DietaryModal
            defaultV={dietary}
            isOpen={isOpenDietaryRestrictions}
            onClose={onCloseDietaryRestrictions}
            wDWFMConstantsOriginal={dietaryRestrictionsOriginal}
            onFinished={finishSelectionDietaryRestrictions}
            title="Dietary Restrictions"
            showOthers={true}
          />

          {/* This is for allergies */}
        
          <JornerHeader
            onOpenShare={onOpenShareJorner}
            onOpenRemove={onOpenRemoveJorner}
            jorner={jorner}
            finishSelection={() => {}}
          />
          <Spacer height={"22px"} />

          <Flex flexWrap={"wrap"} gap={"2"}>
            {tabItems.map((tabItem, i) => (
              <Button
                key={i}
                onClick={() => setSelectedTab(tabItem.toLocaleLowerCase())}
                width={"fit-content"}
                fontWeight="normal"
                fontSize="13px"
                borderRadius={"50px"}
                border={`1px solid ${
                  selectedTab === tabItem.toLocaleLowerCase()
                    ? "#FF6E57"
                    : "grey"
                }`}
                background={
                  selectedTab === tabItem.toLocaleLowerCase()
                    ? "#FFF2F0"
                    : "white"
                }
                height="36px"
                padding="4px 12px"
              >
                {tabItem}
              </Button>
            ))}
          </Flex>

          <Spacer height={"22px"} />

          {/* strength */}
          <Box>
            {selectedTab === "strengths" ? (
              <Box
                shadow={"sm"}
                background="white"
                padding="30px"
                borderRadius={"16px"}
              >
                {strengths.length > 0 ? (
                  strengths.map((item, i) => (
                    <TabListItem key={i} text={item} />
                  ))
                ) : (
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent={"center"}
                      alignItems="center"
                    >
                      <Image src={tabitemnotfound} boxSize={"55px"}></Image>
                      <Box h="10px" />
                      <Heading as="h6" fontSize={"16px"}>
                        No Strength
                      </Heading>
                      <Box h="10px" />

                      <Box textAlign={"center"} maxW="480px">
                        <Text color="paragraph">
                          Click the button below to add Item
                        </Text>
                        <AppLink
                          onclick={onOpenStrength}
                          href="#?"
                          textDecoration="underline"
                          text={"Add"}
                          color="primary"
                          padding="0"
                          height="fit-content"
                          background="transparent"
                          fontWeight="500"
                          shadow="none"
                        />
                      </Box>
                    </Box>
                  </>
                )}

                {strengths.length > 0 ? (
                  <Flex justifyContent={"end"}>
                    <Button
                      onClick={onOpenStrength}
                      isLoading={isLoading}
                      width={"fit-content"}
                      fontWeight="600"
                      fontSize="13px"
                      borderRadius={"50px"}
                      border={`2px solid #EBECEF`}
                      background={"white"}
                      height="38px"
                      padding="4px 14px"
                    >
                      Edit strengths
                    </Button>
                  </Flex>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>

          {/* disablities */}
          <Box>
            {selectedTab === "disability" ? (
              <Box
                shadow={"sm"}
                background="white"
                padding="20px"
                borderRadius={"16px"}
              >
                {disabilities.length > 0 ? (
                  disabilities.map((item, i) => (
                    <TabListItem key={i} text={item} />
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Image src={tabitemnotfound} boxSize={"55px"}></Image>
                    <Box h="10px" />
                    <Heading as="h6" fontSize={"16px"}>
                      No Disability
                    </Heading>
                    <Box h="10px" />

                    <Box textAlign={"center"} maxW="480px">
                      <Text color="paragraph">
                        Click the button below to add Item
                      </Text>
                      <AppLink
                        onclick={onOpenDisability}
                        href="#?"
                        textDecoration="underline"
                        leftIcon={<Icon as={PlusIcon} fontSize={"16px"} />}
                        hasLeftIcon={true}
                        text={"Add"}
                        color="primary"
                        padding="0"
                        height="fit-content"
                        background="transparent"
                        fontWeight="500"
                        shadow="none"
                      />
                    </Box>
                  </Box>
                )}

                {disabilities.length > 0 ? (
                  <Flex justifyContent={"end"}>
                    <Button
                      isLoading={isLoading}
                      onClick={onOpenDisability}
                      width={"fit-content"}
                      fontWeight="600"
                      fontSize="13px"
                      borderRadius={"50px"}
                      border={`2px solid #EBECEF`}
                      background={"white"}
                      height="38px"
                      padding="4px 14px"
                    >
                      Edit disabilities
                    </Button>
                  </Flex>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>

          <Box>
            {selectedTab === "what works for me" ? (
              <Box
                shadow={"sm"}
                background="white"
                padding="20px"
                borderRadius={"16px"}
              >
                {whatworksforme.length > 0 ? (
                  whatworksforme.map((item, i) => (
                    <TabListItem key={i} text={item} />
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Image src={tabitemnotfound} boxSize={"55px"}></Image>
                    <Box h="10px" />
                    <Heading as="h6" fontSize={"16px"}>
                      No Item found
                    </Heading>
                    <Box h="10px" />

                    <Box textAlign={"center"} maxW="480px">
                      <Text color="paragraph">
                        Click the button below to add Item
                      </Text>
                      <AppLink
                        onclick={onOpenWhatWorksForMe}
                        href="#?"
                        textDecoration="underline"
                        leftIcon={<Icon as={PlusIcon} fontSize={"16px"} />}
                        hasLeftIcon={true}
                        text={"Add"}
                        color="primary"
                        padding="0"
                        height="fit-content"
                        background="transparent"
                        fontWeight="500"
                        shadow="none"
                      />
                    </Box>
                  </Box>
                )}

                {whatworksforme.length > 0 ? (
                  <Flex justifyContent={"end"}>
                    <Button
                      isLoading={isLoading}
                      onClick={onOpenWhatWorksForMe}
                      width={"fit-content"}
                      fontWeight="600"
                      fontSize="13px"
                      borderRadius={"50px"}
                      border={`2px solid #EBECEF`}
                      background={"white"}
                      height="38px"
                      padding="4px 14px"
                    >
                      Edit
                    </Button>
                  </Flex>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>

          <Box>
            {selectedTab === "dietary restriction" ? (
              <Box
                shadow={"sm"}
                background="white"
                padding="20px"
                borderRadius={"16px"}
              >
                {dietary.length > 0 ? (
                  dietary.map((item, i) => <TabListItem key={i} text={item} />)
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Image src={tabitemnotfound} boxSize={"55px"}></Image>
                    <Box h="10px" />
                    <Heading as="h6" fontSize={"16px"}>
                      No Item found
                    </Heading>
                    <Box h="10px" />

                    <Box textAlign={"center"} maxW="480px">
                      <Text color="paragraph">
                        Click the button below to add Item
                      </Text>
                      <AppLink
                        onclick={onOpenDietaryRestrictions}
                        href="#?"
                        textDecoration="underline"
                        leftIcon={<Icon as={PlusIcon} fontSize={"16px"} />}
                        hasLeftIcon={true}
                        text={"Add"}
                        color="primary"
                        padding="0"
                        height="fit-content"
                        background="transparent"
                        fontWeight="500"
                        shadow="none"
                      />
                    </Box>
                  </Box>
                )}

                {dietary.length > 0 ? (
                  <Flex justifyContent={"end"}>
                    <Button
                      onClick={onOpenDietaryRestrictions}
                      isLoading={isLoading}
                      width={"fit-content"}
                      fontWeight="600"
                      fontSize="13px"
                      borderRadius={"50px"}
                      border={`2px solid #EBECEF`}
                      background={"white"}
                      height="38px"
                      padding="4px 14px"
                    >
                      Edit
                    </Button>
                  </Flex>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>

          <Box>
            {selectedTab === "what does not work for me" ? (
              <Box
                shadow={"sm"}
                background="white"
                padding="20px"
                borderRadius={"16px"}
              >
                {whatdoesnotworkforme.length > 0 ? (
                  whatdoesnotworkforme.map((item, i) => (
                    <TabListItem key={i} text={item} />
                  ))
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Image src={tabitemnotfound} boxSize={"55px"}></Image>
                    <Box h="10px" />
                    <Heading as="h6" fontSize={"16px"}>
                      No Item found
                    </Heading>
                    <Box h="10px" />

                    <Box textAlign={"center"} maxW="480px">
                      <Text color="paragraph">
                        Click the button below to add Item
                      </Text>
                      <AppLink
                        onclick={onOpenWhatDoesNotWorkForMe}
                        href="#?"
                        textDecoration="underline"
                        leftIcon={<Icon as={PlusIcon} fontSize={"16px"} />}
                        hasLeftIcon={true}
                        text={"Add"}
                        color="primary"
                        padding="0"
                        height="fit-content"
                        background="transparent"
                        fontWeight="500"
                        shadow="none"
                      />
                    </Box>
                  </Box>
                )}

                {whatdoesnotworkforme.length > 0 ? (
                  <Flex justifyContent={"end"}>
                    <Button
                      onClick={onOpenWhatDoesNotWorkForMe}
                      isLoading={isLoading}
                      width={"fit-content"}
                      fontWeight="600"
                      fontSize="13px"
                      borderRadius={"50px"}
                      border={`2px solid #EBECEF`}
                      background={"white"}
                      height="38px"
                      padding="4px 14px"
                    >
                      Edit
                    </Button>
                  </Flex>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>

          <Box>
            {selectedTab === "goals i am working on" ? (
              <Box
                shadow={"sm"}
                background="white"
                padding="20px"
                borderRadius={"16px"}
              >
                {goals.length > 0 ? (
                  goals.map((item, i) => <TabListItem key={i} text={item} />)
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Image src={tabitemnotfound} boxSize={"55px"}></Image>
                    <Box h="10px" />
                    <Heading as="h6" fontSize={"16px"}>
                      No Item found
                    </Heading>
                    <Box h="10px" />

                    <Box textAlign={"center"} maxW="480px">
                      <Text color="paragraph">
                        Click the button below to add Item
                      </Text>
                      <AppLink
                        onclick={onOpenGoals}
                        href="#?"
                        leftIcon={<Icon as={PlusIcon} fontSize={"16px"} />}
                        hasLeftIcon={true}
                        textDecoration="underline"
                        text={"Add"}
                        color="primary"
                        padding="0"
                        height="fit-content"
                        background="transparent"
                        fontWeight="500"
                        shadow="none"
                      />
                    </Box>
                  </Box>
                )}

                {goals.length > 0 ? (
                  <Flex justifyContent={"end"}>
                    <Button
                      onClick={onOpenGoals}
                      isLoading={isLoading}
                      width={"fit-content"}
                      fontWeight="600"
                      fontSize="13px"
                      borderRadius={"50px"}
                      border={`2px solid #EBECEF`}
                      background={"white"}
                      height="38px"
                      padding="4px 14px"
                    >
                      Edit goals
                    </Button>
                  </Flex>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>

          <Box>
            {selectedTab === "medications" ? (
              <Box
                shadow={"sm"}
                background="white"
                padding="20px"
                borderRadius={"16px"}
              >
                {medications.length > 0 ? (
                  <Grid
                    templateColumns={{
                      base: "repeact(1,1fr)",
                      md: "repeat(3, 1fr)",
                    }}
                    gap={6}
                  >
                    {medications.map((item, i) => (
                      <GridItem w="100%" background={"#FAF8FF"} padding="14px">
                        <Box>
                          <Flex alignItems={"center"} mb="3" b>
                            <Image src={medicationIcon} boxSize="30px" />
                            <Heading
                              display={"flex"}
                              fontSize={"14px"}
                              fontWeight="600"
                            >
                              {item.generic_name}&nbsp;
                              <Text fontWeight={"400"} color="#747A8B">
                                {item.brand_name}
                              </Text>
                            </Heading>
                          </Flex>
                          <Flex
                            alignItems={"center"}
                            justifyContent="space-between"
                          >
                            <Text fontSize={"12"} fontWeight="400">
                              Daily
                            </Text>
                            <Text
                              fontSize={"12"}
                              fontWeight="600"
                              color={"#17181C"}
                            >
                              {item.daily ? item.daily : "no"}
                            </Text>
                          </Flex>

                          <Flex
                            alignItems={"center"}
                            justifyContent="space-between"
                          >
                            <Text fontSize={"12"} fontWeight="400">
                              Dosage Description
                            </Text>
                            <Text
                              fontSize={"12"}
                              fontWeight="600"
                              color={"#17181C"}
                              textAlign={"right"}
                            >
                              {item.dosage_description
                                ? item.dosage_description
                                : ""}
                            </Text>
                          </Flex>

                          <Flex
                            alignItems={"center"}
                            justifyContent="space-between"
                          >
                            <Text fontSize={"12"} fontWeight="400">
                              Duration
                            </Text>
                            <Text
                              fontSize={"12"}
                              fontWeight="600"
                              color={"#17181C"}
                            >
                              {item.is_short_time ? "Short term" : "Long term"}
                            </Text>
                          </Flex>
                        </Box>
                      </GridItem>
                    ))}
                  </Grid>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Image src={tabitemnotfound} boxSize={"55px"}></Image>
                    <Box h="10px" />
                    <Heading as="h6" fontSize={"16px"}>
                      No Medications
                    </Heading>
                    <Box h="10px" />

                    <Box textAlign={"center"} maxW="480px">
                      <Text color="paragraph">
                        Click the button below to add Item
                      </Text>
                      <AppLink
                        onclick={onOpenMedication}
                        href="#?"
                        textDecoration="underline"
                        leftIcon={<Icon as={PlusIcon} fontSize={"16px"} />}
                        hasLeftIcon={true}
                        text={"Add"}
                        color="primary"
                        padding="0"
                        height="fit-content"
                        background="transparent"
                        fontWeight="500"
                        shadow="none"
                      />
                    </Box>
                  </Box>
                )}

                {medications.length > 0 ? (
                  <Flex alignItems={"end"} flexDir={"column"} gap={4}>
                    <Box height={"30px"} />

                    <Button
                      onClick={onOpenMedication}
                      isLoading={isLoading}
                      width={"fit-content"}
                      fontWeight="600"
                      fontSize="13px"
                      borderRadius={"50px"}
                      border={`2px solid #EBECEF`}
                      background={"white"}
                      height="38px"
                      padding="4px 14px"
                    >
                      Edit medications
                    </Button>
                    <Text fontSize={"12px"}>
                      Last updated: &nbsp;
                      {moment(jorner.medication_last_update).format(
                        "MMM Do YYYY, h:mm:ss a"
                      )}
                    </Text>
                  </Flex>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SingleJorner;
