/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";


export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
        user: null,
        token: null,
        sj_isAuth: false,
    },
    reducers: {
       setUser:(state, action) => {
           state.user = action.payload.user;
           state.token = action.payload.token;
           state.sj_isAuth = action.payload.sj_isAuth;
       },
       removeUser:(state)=>{
        state.account.user = null;
        state.token = null;
        state.sj_isAuth = false;
       },
    }
})

export const { setUser, removeUser} = authenticationSlice.actions;

export default authenticationSlice.reducer;
