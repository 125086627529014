import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLogo, successGif } from "../../images";
import { loginRoute } from "../../utils/allRoutes";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CustomModal from "../../components/customModal";
import * as Yup from "yup";
import LoginSide from "../../components/loginSide";
import ls from "localstorage-slim";
import {
  APP_API_URL,
  httpPatchWithOneTimeToken,
  httpPatchWithoutToken,
} from "../../utils/http_utils";
import { containsCapitalLetter, containsNumber, containsSpecialChars } from "../../utils/utils";

function ResetPassword() {
  const navigate = useNavigate();
  const authCode = ls.get("sj_r_p_a", { decrypt: true });
  const isCC = ls.get("sj_iscc", { decrypt: true });
  const user_id = ls.get("sj_uid", { decrypt: true });
  const [show, setShow] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleClickConfirm = () => setShowConfirm(!showConfirm);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is mandatory")
      .min(3, "Password must be at 3 char long"),
    confirmPassword: Yup.string()
      .required("Password is mandatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm(formSchema);

  const onDone = () => {
    navigate(loginRoute);
  };

  async function onSubmit(values) {
    if (values.password !== values.confirmPassword) {
      return toast({
        title: "Password mismatch.",
        description: "Password does'nt match",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (!containsCapitalLetter(values.password)) {
      return toast({
        title: "Please use a secure password.",
        description: "Password must contain atleast one Capital letter.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
        if (!containsNumber(values.password)) {
      return toast({
        title: "Please use a secure password.",
        description: "Password must contain atleast one number.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (!containsSpecialChars(values.password)) {
      return toast({
        title: "Please use a secure password.",
        description:
          "Password must be at least 8 characters, password must contain at least 1 special character.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    const data = {
      password: values.password,
      user_id: user_id,
    };
    let resp;
    if (isCC) {
      resp = await httpPatchWithoutToken("co_caregiver/reset-password", data);
      if (resp.error !== null && resp.error !== undefined) {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `${resp.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        ls.remove("sj_r_p_a");
        ls.remove("sj_iscc");
        ls.remove("sj_uid");
        onOpen();
      }
    } else {
      const password = values.password;
      let response = await fetch(`${APP_API_URL}/caregiver/reset-password-post`,{
        method : "PATCH",
        headers : new Headers({
          Authorization: `${authCode}`,
          'content-type' : "application/json"
        }),
        body : JSON.stringify({
          password : password,
          user_id : authCode
        })
      })
      resp = await response.json();
      if(resp.statusCode > 201) {
        return toast({
              title: "Sorry, we hit a snag!.",
              description: `${resp.message}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
      }else{
        ls.remove("sj_r_p_a");
        ls.remove("sj_iscc");
        ls.remove("sj_uid");
        onOpen();
      }
 
    }

   
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     onOpen();
    //     resolve();
    //   }, 3000);
    // });
  }

  return (
    <>
      <Flex min-height={"100vh"} width={"100%"}>
        <LoginSide />
        <Box
          zIndex={2}
          w={{ base: "100%", md: "50%" }}
          background={"white"}
          position="relative"
          overflowY={"scroll"}
          flex="1"
          borderTopRadius={{ base: "20px", md: 0 }}
          paddingTop={{ base: "40px", md: 0 }}
          display="flex"
          h={{ base: "calc(100vh - 30px)", md: "100vh" }}
          flexDirection={{ base: "column", md: "row" }}
          m={{ base: "30px 0 0 0", md: "0" }}
        >
          <Box display={{ md: "none" }} borderRadius={20}>
            <Center p={2}>
              <Image src={AppLogo} />
            </Center>
          </Box>

          <AbsoluteCenter p="4" axis="both" width={{ base: "90%", md: "70%" }}>
            <Heading
              pt={8}
              display={"flex"}
              justifyContent="center"
              flexWrap="nowrap"
              fontWeight={600}
              fontSize="32px"
              color={"black"}
              textAlign={"center"}
            >
              Reset Password
            </Heading>
            <Text py={8} fontSize={16} fontWeight={400} textAlign={"center"}>
              Create your new password
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl mt={5} isInvalid={errors.password} fontWeight={600}>
                <FormLabel htmlFor="name">Password</FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    name="password"
                    type={show ? "text" : "password"}
                    placeholder="e.g Sojorne1234$"
                    {...register("password", {
                      required: "This is required",
                      minLength: {
                        value: 8,
                        message: "Minimum length should be 8",
                      },
                    })}
                    borderColor={"#E3E4E8"}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl mt={5} isInvalid={errors.password} fontWeight={600}>
                <FormLabel htmlFor="name">Confirm Password</FormLabel>
                <InputGroup>
                  <Input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirm ? "text" : "password"}
                    placeholder="e.g Sojorne1234$"
                    {...register("confirmPassword", {
                      required: "This is required",
                      minLength: {
                        value: 8,
                        message: "Minimum length should be 8",
                      },
                    })}
                    borderColor={"#E3E4E8"}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClickConfirm}>
                      {showConfirm ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage>
                  {errors.confirmPassword && errors.confirmPassword.message}
                </FormErrorMessage>
              </FormControl>

              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                background={"primary"}
                isLoading={isSubmitting}
                type="submit"
                // isDisabled={authCode == null}
              >
                Reset
              </Button>
            </form>
          </AbsoluteCenter>
        </Box>
      </Flex>

      <CustomModal
        onClose={onClose}
        onDone={onDone}
        isOpen={isOpen}
        body={
          <Box>
            <Center>
              <Image src={successGif} />
            </Center>
            <Text textAlign={"center"} fontSize={20} fontWeight={600}>
              Password Created
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              Your password was created successfully
            </Text>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={onDone}
            >
              Done
            </Button>
          </Box>
        }
      />
    </>
  );
}

export default ResetPassword;
