import {
  Box,
  RadioGroup,
  Radio,
  Link,
  Select,
  Image,
  Heading,
  Text,
  Flex,
  Icon,
  Button,
  Grid,
  GridItem,
  Input,
  VStack,
  FormLabel,
  FormControl,
  useDisclosure,
  useToast,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import { Link as ReachLink, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import JornerCard from "../../components/dashboard/jorner_card";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import AddJorner from "../../components/dashboard/add_jorner_card";
import JornerHeader from "../../components/dashboard/jorner_header";
import NoTabItem from "../../components/dashboard/no_tab_item";
import TabListItem from "../../components/dashboard/tab_list_item";
import { medicationIcon } from "../../images";
import { useFormik } from "formik";
import DisabilityModal from "../onboarding/disabilityModal";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import ls from "localstorage-slim";
import { MultiSelect } from "react-multi-select-component";
import { Today, ValidDate, getYearDifference } from "../../utils/utils";
import moment from "moment/moment";

function AddJornerPage() {
  const navigate = useNavigate();
  const [selectedDisability, setSelectedDisability] = React.useState([]);
  const [allCoCaregivers, setAllCoCaregivers] = React.useState([]);
  const [selectedCoCaregivers, setSelectedCoCaregivers] = React.useState([]);
  const [jorner, setJorner] = useState({});
  const [loading, setLoading] = useState(true);
  const [disabilityConstantOriginal, setDisabilitiesConstantsOriginal] =
    React.useState({});
  const [isEdit, setIsEdit] = useState(
    window.location.href.includes("jorner/edit") ? true : false
  );
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams();
  const {
    isOpen: isOpenDisability,
    onOpen: onOpenDisability,
    onClose: onCloseDisability,
  } = useDisclosure();
  const formik = useFormik({
    initialValues: {
      last_name: "",
      first_name: "",
      type: "",
      has_disability: "",
      disabilities: [],
      dob: "",
    },
    onSubmit: async (values) => {
      // const ssCCG = selectedCoCaregivers.map((item, i) => {
      //   return item.value;
      // });

      const ssCCG = selectedCoCaregivers.map((item, i) => {
        return item.value;
      });
      if (isEdit) {
        if (
          values.first_name === "" ||
          values.last_name === "" ||
          values.dob == ""
        ) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `All fields are required`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        // if the valid date function return true, it means, the date is in future, so its invalid in this case

        // calclulate age
        if (ValidDate(values.dob)) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `Invalid date selected`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        let today = Today();
        let selected = moment(values.dob).format("YYYY-MM-DD");
        if (today == selected) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `Jorner DOB cannot be today`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        const jornerdob = new Date(values.dob);
        const y = getYearDifference(jornerdob);
        if (y < 1) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `Invalid date selected`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        var type = "child";
        if (y > 18) {
          type = "adult";
        }
        var data = {
          first_name: values.first_name,
          last_name: values.last_name,
          type: type,
          caregivers: ssCCG,
          dob: values.dob,
        };
        setIsSubmitting(true);
        var resp = await httpPutWithTokenRest(
          "caregivers/jorners/" + params.id,
          data
        );
        if (resp.error) {
          setIsSubmitting(false);
          return toast({
            title: "Sorry, we hit a snag!",
            description: `Something went wrong`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setIsSubmitting(false);
          toast({
            title: "Jorner updated successfully.",
            description: ``,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        if (
          values.first_name === "" ||
          values.last_name === "" ||
          values.has_disability === "" ||
          values.dob === ""
        ) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `All fields are required`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }

        if (values.has_disability === "yes") {
          if (values.disabilities.length === 0) {
            return toast({
              title: "Sorry, we hit a snag!",
              description: `Please select disability types`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }

        var data = values;
        data.caregivers = ssCCG;
        // calclulate age
        const jornerdob = new Date(values.dob);

        const y = getYearDifference(jornerdob);
        if (y < 1) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `Invalid date selected`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        data.type = "child";
        if (y > 18) {
          data.type = "adult";
        }
        var response = await httpPostWithToken("jorner", data);
        if (response.error) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `${response.error}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Jorner added successfully",
            // description: `${response.error}`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          data.id = response.data.id;
          ls.set("sj_jorner1", data, { encrypt: true });
          // alert(JSON.stringify(values, null, 2));
          navigate("/add-jorner/profile");
        }
      }
    },
  });

  useEffect(() => {
    fetchCoCaregivers();
    var toEdit = false;
    if (window.location.href.includes("jorner/edit")) {
      toEdit = false;
      var id = params.id;
      (async () => {
        var j = await httpGetWithTokenRest("/jorner/" + params.id);
        if (j.status == "success") {
          setJorner(j.data);

          var data = j.data;
          fillFormik(data);
        } else {
          toast({
            title: "Sorry, we hit a snag!",
            description: `${j.error}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          navigate("/dashboard");
        }
      })();
    } else {
      var data = ls.get("sj_jorner1", { decrypt: true });
      if (data) {
        setSelectedCoCaregivers(data.caregivers);
        fillFormik(data);
        setSelectedDisability(data.disabilities);
      }
    }
  }, []);
  const fillFormik = (data) => {
    formik.values.first_name = data.first_name;
    formik.values.last_name = data.last_name;
    formik.values.has_disability = data.has_disability;
    formik.values.type = data.type;
    formik.values.disabilities = data.disabilities;
    formik.values.dob = data.dob;
  };
  const fetchJornersConstants = async () => {
    const resp = await httpGetWithTokenRest("constants");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setDisabilitiesConstantsOriginal(resp.data.disabilities);
    }
  };
  const fetchCoCaregivers = async () => {
    const resp = await httpGetWithTokenRest("co_caregiver");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const jor = resp.data;
      const jornersss = jor.map((item, i) => {
        return {
          id: i,
          key: i,
          value: item.id,
          label: item.first_name + " " + item.last_name,
        };
      });

      setAllCoCaregivers(jornersss);
      if (params.id) {
        var j = await httpGetWithTokenRest(
          "/jorner/" + params.id + "/cocaregiver"
        );
        if (j.status == "success") {
          let selectCCG = [];
          j.data.map((item) => {
            var search = jornersss.find((c) => c.value == item.ccg_id);
            if (search) {
              selectCCG = [...selectCCG, search];
            }
          });
          console.log(selectCCG);
          // jor.map((item, i) => {
          //   var search = j.data.find((c) => c.ccg_id == item.id);
          //   if (search) {
          //     selectCCG = [
          //       ...selectCCG,
          //       {
          //         id: i,
          //         key: i,
          //         value: item.id,
          //         label: item.first_name + " " + item.last_name,
          //       },
          //     ];
          //   }
          // });

          setLoading(false);

          setSelectedCoCaregivers(selectCCG);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const finishSelection = (selectedDisabilityArr) => {
    setSelectedDisability(selectedDisabilityArr);
    formik.values.disabilities = selectedDisabilityArr;
    // setValue('disabilities', selectedDisabilityArr)
  };

  useEffect(() => {
    fetchJornersConstants();
  }, []);

  return (
    <Box
      padding={"19px 20px"}
      height="100%"
      minHeight={"100vh"}
      background={"primaryBg"}
    >
      {loading ? (
        <Stack mt={5}>
          <Skeleton startColor="#808080" height="50px" />
          <Skeleton startColor="#808080" height="50px" />
          <Skeleton startColor="#808080" height="50px" />
        </Stack>
      ) : (
        <>
          <Box h="42px" />

          {formik.values.has_disability === "yes" && (
            <DisabilityModal
              isOpen={isOpenDisability}
              onClose={onCloseDisability}
              disabilitiesConstantsOriginal={disabilityConstantOriginal}
              onFinished={finishSelection}
            />
          )}

          <Box
            margin={"0 auto"}
            borderRadius="15px"
            maxWidth={"720px"}
            width={"100%"}
            shadow={"sm"}
            background="white"
          >
            <Box h="42px" />
            <Box margin={"0 auto"} width={{ base: "95%", md: "80%" }}>
              <Flex alignItems={"center"}>
                {isEdit ? (
                  <AppLink
                    hasLeftIcon={true}
                    leftIcon={<Icon as={ChevronLeftIcon} fontSize="17px" />}
                    shadow="none"
                    padding="0"
                    text=""
                    background="transparent"
                    color="black"
                    width="fit-content"
                    textDecoration="none"
                    href={`/jorner/${params.id}`}
                  />
                ) : (
                  <AppLink
                    hasLeftIcon={true}
                    leftIcon={<Icon as={ChevronLeftIcon} fontSize="17px" />}
                    shadow="none"
                    padding="0"
                    text=""
                    background="transparent"
                    color="black"
                    width="fit-content"
                    textDecoration="none"
                    href="/dashboard"
                  />
                )}
                <Box width="4px" />
                <Heading
                  color={"black"}
                  fontSize={"15px"}
                  fontWeight={"600"}
                  as={"h3"}
                >
                  Back
                </Heading>
              </Flex>

              <Box>
                <Heading>
                  {" "}
                  {isEdit ? "Edit Jorner " : "Create Jorners Profile"}
                </Heading>
                <Text>Name of Loved one, age, disability...</Text>
                <Box height={"15"} />
                {!isEdit && (
                  <Grid templateColumns={"repeat(2, 1fr)"} gap="4">
                    <GridItem
                      width={"100%"}
                      borderRadius="60px"
                      height="6px"
                      background={"#0CCA4A"}
                    />
                    <GridItem
                      width={"100%"}
                      borderRadius="60px"
                      height="6px"
                      background={"gray"}
                    />
                  </Grid>
                )}
                <Box height="14px" />
                <form onSubmit={formik.handleSubmit}>
                  <VStack spacing={4} align="flex-start">
                    <FormControl marginTop={"5px"}>
                      <FormLabel htmlFor="first_name">
                        Jorner's First Name
                      </FormLabel>
                      <Input
                        height={"50px"}
                        id="first_name"
                        name="first_name"
                        type="text"
                        variant="outlined"
                        border={"1px solid #E3E4E8"}
                        focusBorderColor="#E3E4E8"
                        placeholder="E.g Andrew"
                        onChange={formik.handleChange}
                        value={formik.values.first_name}
                      />
                    </FormControl>

                    <FormControl marginTop={"5px"}>
                      <FormLabel htmlFor="last_name">
                        Jorner's Last Name
                      </FormLabel>
                      <Input
                        height={"50px"}
                        id="last_name"
                        name="last_name"
                        type="text"
                        variant="outlined"
                        border={"1px solid #E3E4E8"}
                        focusBorderColor="#E3E4E8"
                        placeholder="E.g Tate"
                        onChange={formik.handleChange}
                        value={formik.values.last_name}
                      />
                    </FormControl>

                    <FormControl marginTop={"5px"}>
                      <FormLabel htmlFor="last_name">Date of Birth</FormLabel>
                      <Input
                        height={"50px"}
                        id="dob"
                        name="dob"
                        type="date"
                        variant="outlined"
                        border={"1px solid #E3E4E8"}
                        focusBorderColor="#E3E4E8"
                        placeholder="Select date"
                        onChange={formik.handleChange}
                        value={formik.values.dob}
                      />
                    </FormControl>
                    {2 == 3 && (
                      <FormControl marginTop={"5px"}>
                        <FormLabel htmlFor="fullname">
                          Select Jorner Profile
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => {
                            formik.setFieldValue("type", e);
                          }}
                          value={formik.values.type}
                          display={"flex"}
                          name="profile"
                        >
                          <FormLabel
                            background={
                              formik.values.type == "child"
                                ? "#FFF2F0"
                                : "white"
                            }
                            htmlFor="child"
                            width={"100%"}
                            borderRadius="8px"
                            h="50px"
                            gap="3"
                            display={"flex"}
                            alignItems="center"
                            p="0 12px"
                            border={`1px solid ${
                              formik.values.type === "child"
                                ? "#FF6E57"
                                : "#E3E4E8"
                            }`}
                          >
                            <Radio
                              colorScheme="orange"
                              border={"1px solid gray"}
                              id="child"
                              value="child"
                            ></Radio>
                            <FormLabel margin={"0"} p="0">
                              Child
                            </FormLabel>
                          </FormLabel>

                          <FormLabel
                            background={
                              formik.values.type == "adult"
                                ? "#FFF2F0"
                                : "white"
                            }
                            width={"100%"}
                            htmlFor="adult"
                            borderRadius="8px"
                            h="50px"
                            gap="3"
                            display={"flex"}
                            alignItems="center"
                            p="0 12px"
                            border={`1px solid ${
                              formik.values.type === "adult"
                                ? "#FF6E57"
                                : "#E3E4E8"
                            }`}
                          >
                            <Radio
                              colorScheme="orange"
                              border={"1px solid gray"}
                              id="adult"
                              value="adult"
                            ></Radio>
                            <FormLabel margin={"0"} p="0">
                              Adult
                            </FormLabel>
                          </FormLabel>
                        </RadioGroup>
                      </FormControl>
                    )}

                    {!isEdit ? (
                      <FormControl marginTop={"5px"}>
                        <FormLabel htmlFor="fullname">
                          Does your Jorner have a Disability
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => {
                            formik.setFieldValue("has_disability", e);
                          }}
                          // value={formik.values.disability}
                          display={"flex"}
                          name="disability"
                        >
                          <FormLabel
                            background={
                              formik.values.has_disability == "yes"
                                ? "#FFF2F0"
                                : "white"
                            }
                            width={"100%"}
                            htmlFor="yes"
                            borderRadius="8px"
                            h="50px"
                            gap="3"
                            display={"flex"}
                            alignItems="center"
                            p="0 12px"
                            border={`1px solid ${
                              formik.values.has_disability === "yes"
                                ? "#FF6E57"
                                : "#E3E4E8"
                            }`}
                          >
                            <Radio
                              colorScheme="orange"
                              border={"1px solid gray"}
                              id="yes"
                              value="yes"
                            ></Radio>
                            <FormLabel margin={"0"} p="0" htmlFor="yes">
                              Yes
                            </FormLabel>
                          </FormLabel>

                          <FormLabel
                            background={
                              formik.values.has_disability == "no"
                                ? "#FFF2F0"
                                : "white"
                            }
                            width={"100%"}
                            htmlFor="no"
                            borderRadius="8px"
                            h="50px"
                            gap="3"
                            display={"flex"}
                            alignItems="center"
                            p="0 12px"
                            border={`1px solid ${
                              formik.values.has_disability === "no"
                                ? "#FF6E57"
                                : "#E3E4E8"
                            }`}
                          >
                            <Radio
                              colorScheme="orange"
                              border={"1px solid gray"}
                              id="no"
                              value="no"
                            ></Radio>
                            <FormLabel margin={"0"} p="0" htmlFor="no">
                              No
                            </FormLabel>
                          </FormLabel>
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      ""
                    )}
                    <FormControl mt={2} fontWeight={600}>
                      <FormLabel htmlFor="role">Caregivers</FormLabel>
                      <MultiSelect
                        style={{
                          border: "1px solid #E3E4E8",
                          height: "45px !important",
                          padding: "10px 10px",
                        }}
                        options={allCoCaregivers}
                        value={selectedCoCaregivers}
                        onChange={setSelectedCoCaregivers}
                        labelledBy="Select"
                      />
                    </FormControl>
                    {formik.values.has_disability === "yes" ? (
                      <FormControl onClick={onOpenDisability} marginTop={"5px"}>
                        <FormLabel margin={"0"} p="0" htmlFor="disability">
                          Type of Disability
                        </FormLabel>
                        <Select
                          id="disability"
                          _disabled={{
                            border: "2px solid #E3E4E8",
                            opacity: selectedDisability.length === 0 ? 0.7 : 1,
                          }}
                          focusBorderColor="#E3E4E8"
                          border={"1px solid #E3E4E8"}
                          height="50px"
                          name="strenths"
                          placeholder={
                            selectedDisability.length == 0
                              ? "Select"
                              : selectedDisability.join()
                          }
                        ></Select>
                      </FormControl>
                    ) : (
                      ""
                    )}
                    <FormControl marginTop={"5px"}>
                      <Button
                        isLoading={isEdit ? isSubmitting : formik.isSubmitting}
                        type="submit"
                        h="50px"
                        borderRadius={"60px"}
                        colorScheme="purple"
                        width="full"
                      >
                        {isEdit ? "Update" : "Next"}
                      </Button>
                    </FormControl>
                  </VStack>
                </form>
              </Box>
            </Box>
            <Box h="102px" />
          </Box>
        </>
      )}
    </Box>
  );
}

export default AddJornerPage;
