import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
  useToast,
  useCheckboxGroup,
  Textarea,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { healthcare } from "../../images";
import {
  capitalizeFirstWord,
  checkArraySimilarities,
  checkArraySimilaritiesWithObject,
} from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { FaChevronCircleDown } from "react-icons/fa";
import { useFormik } from "formik";
import { duration } from "moment/moment";

export default function MedicationModal2({
  isOpen,
  onClose,
  medicationConstantsOriginal,
  onFinished,
  selected,
}) {
  const [medicationConstants, setMedicationConstants] = React.useState(
    medicationConstantsOriginal
  );
  const [inputValue, setInputValue] = useState("");
  const [generalSearch, setGeneralSearch] = useState([]);
  const [showGeneralSearch, setShowGeneralSearch] = useState(false);

  const [medicationList, setMedicationList] = React.useState([]);
  const [showSecondStep, setShowSecondStep] = useState(true);
  const [selectedFromFirstStep, setSelectedFromFirstStep] = useState({
    brand_name: "brand",
    generic_name: "generic",
    dosage_description: "",
    daily: "yes",
    am_dose: 2,
    pm_dose: 3,
    is_short_time: true,
  });
  const [medicationListOriginal, setMedicationListOriginal] = React.useState(
    []
  );
  useEffect(() => {
    setInputValue("");
    setShowGeneralSearch(false);
  }, [isOpen, medicationConstantsOriginal]);
  const toast = useToast();
  const [showMedicationList, setShowMedicationList] = React.useState(false);
  const [medicationTitle, setMedicationTitle] = React.useState("");
  const [selectedMedication, setSelectedMedication] = useState(selected);
  const [submittingSecond, setSubmittingSecond] = useState(false);

  useEffect(() => {
    setShowSecondStep(false);
  }, []);

  var formik = useFormik({
    initialValues: {
      daily: "",
      dosage_description: "None",
      duration: "",
      medication_id : ""
    },
    onSubmit: (values) => {
      if (
        values.daily == "" ||
        values.dosage_description == "" ||
        values.duration == ""
      ) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `All fields are required`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setSubmittingSecond(true);
      var s = selectedMedication;
      var s_ = [];
      s.map((item) => {
        var search = item;
        if (
          item.brand_name.trim() == selectedFromFirstStep.brand_name.trim() &&
          item.generic_name.trim() == selectedFromFirstStep.generic_name.trim()
        ) {
          search = {
            brand_name: selectedFromFirstStep.brand_name,
            generic_name: selectedFromFirstStep.generic_name,
            dosage_description:
              values.dosage_description == ""
                ? "None"
                : values.dosage_description.toString(),
            is_short_time: values.duration == "short_term" ? true : false,
            medication_id : values.medication_id,
            daily : values.daily,
          };
        }
        s_ = [...s_, search];
      });
      setSelectedMedication(s_);
      setSubmittingSecond(false);
      setShowSecondStep(false);
    },
  });
  const searchJornerMedicationConstants = (e) => {
    let keyword = e.target.value;
    setInputValue(keyword);
    let allListItems = medicationListOriginal;
    if (keyword < 1) {
      setMedicationConstants(medicationConstantsOriginal);
    } else {
      let allMedication = medicationConstantsOriginal;
      let searchResult = allListItems.filter((e) =>
        `${e?.brand_name} ${e?.generic_name}`
          .toLowerCase()
          .includes(keyword.toLowerCase())
      );
      setGeneralSearch(searchResult);
      setShowGeneralSearch(true);
      // setStrengthConstants(searchResult);
    }
  };

  const searchMedicationList = (e) => {
    let keyword = e.target.value;
    if (keyword < 1) {
      setMedicationList(medicationListOriginal);
    } else {
      let allMedicationList = medicationListOriginal;
      let searchResult = allMedicationList.filter((e) =>
        `${e?.brand_name} ${e?.generic_name}`
          .toLowerCase()
          .includes(keyword.toLowerCase())
      );
      setMedicationList(searchResult);
    }
  };

  const handleShowMedicationDetails = () => {
    setMedicationList(medicationConstantsOriginal);
    setMedicationListOriginal(medicationConstantsOriginal);
    setMedicationTitle("All");
    setShowMedicationList(true);
  };

  const finishSelection = () => {
    // console.log(selectedMedication);
    let s = [];
    for (let index = 0; index < selectedMedication.length; index++) {
      let medi = selectedMedication[index];
      if (medi.dosage_description == "") medi.dosage_description = "None";
      if (medi.medication_id)      s = [...s, medi];
    }
    console.log("s", s)
    onFinished(s);
    onClose();
  };
  const fillFormik = (duration, daily, dosage_description, medication_id) => {
    formik.values.dosage_description = dosage_description
      ? dosage_description
      : "";
    formik.values.duration = duration == true ? "short_time" : "long_term";
    formik.values.daily = daily ? daily : "yes";
    formik.values.medication_id = medication_id
  };
  const handleChange = (e, source = 1) => {
    // alert("ere");
    setShowMedicationList(true);
    var s = selectedMedication;
    var search = s.find(
      (c) =>
        c.brand_name.trim() == e.brand_name &&
        c.generic_name.trim() == e.generic_name
    );
    if (!search) {
      search = {
        brand_name: e.brand_name,
        generic_name: e.generic_name,
        daily: "yes",
        dosage_description: "",
        is_short_time: true,
        medication_id : e.id
      };
      s = [...s, search];
      fillFormik(search.duration, search.daily, search.dosage_description, search.medication_id);
      setShowSecondStep(true);
      setSelectedFromFirstStep(search);
    } else {
      if (source == 1) {
        s = [];
        selectedMedication.map((item) => {
          if (
            item.brand_name.trim() == e.brand_name.trim() &&
            item.generic_name.trim() == e.generic_name.trim()
          ) {
          } else {
            s = [...s, item];
          }
        });
      } else {
        console.log("search", search);
        fillFormik(search.duration, search.daily, search.dosage_description, search.medication_id);
        setShowSecondStep(true);
        setSelectedFromFirstStep(search);
      }
    }

    setSelectedMedication(s ? s : []);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"2xl"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <form onSubmit={formik.handleSubmit}>
        <ModalContent borderRadius={16}>
          {!showMedicationList ? (
            <>
              <ModalHeader display={"flex"} alignItems={"center"} w={"100%"}>
                {showGeneralSearch && (
                  <FaChevronLeft
                    onClick={() => {
                      setShowGeneralSearch(false);
                      setInputValue("");
                      setGeneralSearch([]);
                    }}
                  />
                )}
                <Text>
                  {showGeneralSearch ? "Search Result" : "Medications"}
                </Text>
              </ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <Divider color={"#EBECEF"} />
                <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                  {selectedMedication.length} selected
                </Text>
                {/* <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    borderRadius={100}
                    onChange={searchJornerMedicationConstants}
                    borderColor={"#E3E4E8"}
                  />
                </InputGroup> */}
                {showGeneralSearch ? (
                  <Flex width={"100%"} gap={3} mt={5} flexWrap={"wrap"}>
                    {generalSearch.map((e, i) => (
                      <Box
                        w={{ base: "100%", md: "48%" }}
                        cursor={"pointer"}
                        key={i}
                        py="2"
                      >
                        <Flex>
                          <Flex
                            onClick={() => handleChange(e)}
                            gap="3"
                            alignItems={"start"}
                          >
                            <Checkbox
                              disabled={true}
                              isChecked={
                                selectedMedication.find(
                                  (c) =>
                                    c.brand_name.trim() == e.brand_name &&
                                    c.generic_name.trim() == e.generic_name
                                )
                                  ? true
                                  : false
                              }
                              id={`${e?.brand_name}-${e?.generic_name}`}
                              size="sm"
                              colorScheme="red"
                            ></Checkbox>
                            <FormLabel
                              mt="-1"
                              display="flex"
                              justifyContent={"space-between"}
                              alignItems={"start"}
                              htmlFor={`${e?.brand_name}-${e?.generic_name}`}
                            >
                              <Text p="0">{`${capitalizeFirstWord(e?.brand_name)} ${capitalizeFirstWord(e?.generic_name)}`}</Text>
                              &nbsp;
                            </FormLabel>
                          </Flex>
                          <Box mx={"2"}></Box>
                          <Icon
                            onClick={() => {
                              handleChange(e, 2);
                            }}
                            mt="-1"
                            as={ChevronDownIcon}
                            fontSize="22px"
                            color="black"
                          />
                        </Flex>
                      </Box>
                    ))}
                  </Flex>
                ) : (
                  <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3} mt={5}>
                    <Box
                      key={`strength`}
                      bg={"gray"}
                      cursor={"pointer"}
                      borderRadius={8}
                      p={3}
                      onClick={() => handleShowMedicationDetails()}
                    >
                      <Image src={healthcare} />
                      <Text fontSize={14} fontWeight={500}>
                        All
                      </Text>
                      <Text
                        color={"paragraph"}
                        fontSize={12}
                        fontWeight={500}
                        my={3}
                      >
                        {selectedMedication.length}/
                        {medicationConstantsOriginal.length} Selected
                      </Text>
                    </Box>
                  </SimpleGrid>
                )}
              </ModalBody>
              <ModalFooter>
                <Box w={"160px"} float={"right"}>
                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    onClick={finishSelection}
                  >
                    Next
                  </Button>
                </Box>
              </ModalFooter>
            </>
          ) : showSecondStep ? (
            <>
              <ModalHeader>
                <Flex alignItems={"center"}>
                  <FaChevronLeft onClick={() => setShowSecondStep(false)} />
                  <Text ml={2}>
                    {selectedFromFirstStep.brand_name}{" "}
                    {selectedFromFirstStep.generic_name}
                  </Text>
                </Flex>
              </ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody height={"300pxs"}>
                <Box>
                  <Text mb={2} color="black" fontSize={14} fontWeight={600}>
                    Medication Name
                  </Text>
                  <Input
                    h="45px"
                    _disabled={{ color: "black", fontSize: "14px" }}
                    disabled={true}
                    border={"1px solid grey"}
                    value={`${selectedFromFirstStep.brand_name} ${selectedFromFirstStep.generic_name}`}
                  />
                </Box>

                <FormControl marginTop={"13px"}>
                  <FormLabel htmlFor="fullname">Daily</FormLabel>
                  <RadioGroup
                    onChange={(e) => {
                      formik.setFieldValue("daily", e);
                    }}
                    value={formik.values.daily}
                    display={"flex"}
                    name="daily"
                  >
                    <FormLabel
                      background={
                        formik.values.daily == "yes" ? "#FFF2F0" : "white"
                      }
                      htmlFor="yes"
                      width={"100%"}
                      borderRadius="8px"
                      h="50px"
                      gap="3"
                      display={"flex"}
                      alignItems="center"
                      p="0 12px"
                      border={`1px solid ${
                        formik.values.daily === "yes" ? "#FF6E57" : "#E3E4E8"
                      }`}
                    >
                      <Radio
                        colorScheme="orange"
                        border={"1px solid gray"}
                        id="yes"
                        value="yes"
                      ></Radio>
                      <FormLabel margin={"0"} p="0">
                        Yes
                      </FormLabel>
                    </FormLabel>

                    <FormLabel
                      background={
                        formik.values.daily == "no" ? "#FFF2F0" : "white"
                      }
                      width={"100%"}
                      htmlFor="no"
                      borderRadius="8px"
                      h="50px"
                      gap="3"
                      display={"flex"}
                      alignItems="center"
                      p="0 12px"
                      border={`1px solid ${
                        formik.values.daily === "no" ? "#FF6E57" : "#E3E4E8"
                      }`}
                    >
                      <Radio
                        colorScheme="orange"
                        border={"1px solid gray"}
                        id="no"
                        value="no"
                      ></Radio>
                      <FormLabel margin={"0"} p="0">
                        No
                      </FormLabel>
                    </FormLabel>
                  </RadioGroup>
                </FormControl>

                <Flex my={"15px"}>
                  <FormLabel
                    background={"white"}
                    width={"100%"}
                    h="50px"
                    gap="3"
                    alignItems="center"
                    p="0"
                  >
                    <FormLabel w="100%" margin={"0"} p="0" mb={"1"}>
                      Dosage Description
                    </FormLabel>
                    <Textarea
                      _active={{
                        border: "1px solid #FF6E57",
                      }}
                      _focus={{
                        border: "1px solid #FF6E57",
                      }}
                      style={{
                        padding: "5px",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      id="dosage_description"
                      name="dosage_description"
                      onChange={formik.handleChange}
                      value={formik.values.dosage_description}
                      placeholder="Dosage"
                      border={`1px solid grey`}
                    ></Textarea>
                    <br />
                  </FormLabel>
                </Flex>

                <FormControl marginTop={"65px"}>
                  <FormLabel htmlFor="fullname">Duration</FormLabel>
                  <RadioGroup
                    onChange={(e) => {
                      formik.setFieldValue("duration", e);
                    }}
                    value={formik.values.duration}
                    display={"flex"}
                    name="duration"
                  >
                    <FormLabel
                      background={
                        formik.values.duration == "short_term"
                          ? "#FFF2F0"
                          : "white"
                      }
                      htmlFor="short_term"
                      width={"100%"}
                      borderRadius="8px"
                      h="50px"
                      gap="3"
                      display={"flex"}
                      alignItems="center"
                      p="0 12px"
                      border={`1px solid ${
                        formik.values.duration === "short_term"
                          ? "#FF6E57"
                          : "#E3E4E8"
                      }`}
                    >
                      <Radio
                        colorScheme="orange"
                        border={"1px solid gray"}
                        id="short_term"
                        value="short_term"
                      ></Radio>
                      <FormLabel margin={"0"} p="0">
                        Short term
                      </FormLabel>
                    </FormLabel>

                    <FormLabel
                      background={
                        formik.values.duration == "long_term"
                          ? "#FFF2F0"
                          : "white"
                      }
                      width={"100%"}
                      htmlFor="long_term"
                      borderRadius="8px"
                      h="50px"
                      gap="3"
                      display={"flex"}
                      alignItems="center"
                      p="0 12px"
                      border={`1px solid ${
                        formik.values.duration === "long_term"
                          ? "#FF6E57"
                          : "#E3E4E8"
                      }`}
                    >
                      <Radio
                        colorScheme="orange"
                        border={"1px solid gray"}
                        id="long_term"
                        value="long_term"
                      ></Radio>
                      <FormLabel margin={"0"} p="0">
                        Long term
                      </FormLabel>
                    </FormLabel>
                  </RadioGroup>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Box w={"160px"} float={"right"}>
                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    isLoading={submittingSecond}
                    type={"submit"}
                    background={"primary"}
                  >
                    Proceed
                  </Button>
                </Box>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalHeader>
                <Flex alignItems={"center"}>
                  <FaChevronLeft onClick={() => setShowMedicationList(false)} />
                  <Text ml={2}>{medicationTitle}</Text>
                </Flex>
              </ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <Divider color={"#EBECEF"} />
                <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                  {selectedMedication.length} selected
                </Text>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                    placeholder="Search"
                    key={"searchList"}
                    borderRadius={100}
                    onChange={searchMedicationList}
                    borderColor={"#E3E4E8"}
                  />
                </InputGroup>
                <Flex width={"100%"} gap={3} mt={5} flexWrap={"wrap"}>
                  {medicationList.map((e, i) => (
                    <Box
                      w={{ base: "100%", md: "48%" }}
                      cursor={"pointer"}
                      key={i}
                      py="2"
                    >
                      <Flex>
                        <Flex
                          onClick={() => handleChange(e)}
                          gap="3"
                          alignItems={"start"}
                        >
                          <Checkbox
                            disabled={true}
                            isChecked={
                              selectedMedication.find(
                                (c) =>
                                  c.brand_name.trim() == e.brand_name &&
                                  c.generic_name.trim() == e.generic_name
                              )
                                ? true
                                : false
                            }
                            id={`${e?.brand_name}-${e?.generic_name}`}
                            size="sm"
                            colorScheme="red"
                          ></Checkbox>
                          <FormLabel
                            mt="-1"
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems={"start"}
                            htmlFor={`${e?.brand_name}-${e?.generic_name}`}
                          >
                            <Text p="0">{capitalizeFirstWord(`${e?.brand_name} ${e?.generic_name}`)}</Text>
                            &nbsp;
                          </FormLabel>
                        </Flex>
                        <Box mx={"2"}></Box>
                        <Icon
                          onClick={() => {
                            handleChange(e, 2);
                          }}
                          mt="-1"
                          as={ChevronDownIcon}
                          fontSize="22px"
                          color="black"
                        />
                      </Flex>
                    </Box>
                  ))}
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Box w={"160px"} float={"right"}>
                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    onClick={() => setShowMedicationList(false)}
                  >
                    Proceed
                  </Button>
                </Box>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </form>
    </Modal>
  );
}
