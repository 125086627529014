import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useToast,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { checkIconSuccess, delete_basket_y } from "../../images";
import { httpDeleteWithToken, httpPatch2WithTokenRest } from "../../utils/http_utils";
import { useNavigate } from "react-router-dom";

export default function GroupRemoveModal({
  isOpen,
  onClose,
  onFinished,
  cm,
  member,
  isDeleting = false
}) {
  const finishSelection = () => {
    // onFinished();
    onClose();
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isMe, setIsMe] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const toast = useToast();
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const navigate = useNavigate()
  const deleteMember = async () => {
    setIsLoading(true);
    let resp = await httpPatch2WithTokenRest("/care_circle/members/remove", {
      members: [member],
      community_id: cm,
    });
    setIsLoading(false);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      onFinished("delete");
    }
    setIsLoading(false);
  };
  const deleteGroup = async () => {
    setIsLoading(true);
    let resp = await httpDeleteWithToken("/care_circle/"+cm);
    setIsLoading(false);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      navigate("/care-circle")
      return;
    }
    setIsLoading(false);
  }
  useEffect(() => {
    setIsMe(member == user.user_id);
    setIsDelete(isDeleting);
  }, [member]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onFinished("close")}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} maxW={"340px"} p={4}>
        <ModalBody textAlign={"center"} marginBottom={"0"}>
          <Flex justifyContent={"center"}>
            <Image src={delete_basket_y} boxSize={"100px"} />
          </Flex>
          <ModalHeader textAlign={"center"}>
            {isDelete ? "Delete Group" :  isMe ? "Leave Group" : "Remove Member"}?
          </ModalHeader>
          <Text color={"#747A8B"} textAlign={"center"}>
            {isMe
              ? "You are about to leave this group"
              : "The selected member will be deleted"}
            .
          </Text>
          <Box w="100%" mt={"25px"}>
            <Button
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => {
                setIsLoading(false);
                onFinished("close");
              }}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              mt={8}
              mb={8}
              size={"lg"}
              color={"red"}
              w={"100%"}
              shadow={"sm"}
              border="1px solid #EBECEF"
              borderRadius={64}
              onClick={() => {
                if(isDelete) {
                  deleteGroup()
                }else{
                  deleteMember()
                }
              } }
            >
              Yes, {isDelete ? "Delete" : isMe ? "Leave" : "Remove"}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
