import {
  Box,
  Heading,
  Flex,
  Spacer,
  Stack,
  Skeleton,
  SimpleGrid,
  useToast,
  Text,
  Icon,
  GridItem,
  Grid,
  Button,
  Image,
  Link,
} from "@chakra-ui/react";
import "../../utils/mp3.css";

import { Link as ReachLink, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

import AppLink from "../../components/link";
import {
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  PlusIcon,
  TagIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";

import NoJorner from "../../components/dashboard/no_jorner";
import { medicationsRoute } from "../../utils/allRoutes";
import DashboardLayout from "../../layout/dashboard";
import { medicationIcon, medicationSchedule, pill, tabitemnotfound } from "../../images";
import JornerHeader from "../../components/dashboard/jorner_header";

function JornerMedicationPage() {
  const toast = useToast();
  const tabItems = [
    'Medications',
    'Medication Schedules'
  ]
  const [selectedTab, setSelectedTab] = useState("medications")
  const [tags, setTags] = useState([]);
  const [medications, setMedications] = useState([]);
  const [medicationsSchedules, setMedicationSchedules] = useState([])
  const [jorner, setJorner] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [notfound, setNotFound] = React.useState(false);
  const navigate = useNavigate();
  const params = useParams()
  const user = JSON.parse(sessionStorage.getItem("sj_user"));


  useEffect(() => {
    loadConstants();
    loadSchedules()
  }, [true]);

  const loadConstants = async () => {
    // console.log(user);
    var j = await httpGetWithTokenRest("/jorner/" + params.jorner_id);
    if (j.status == "success") {
      setJorner(j.data);
      setMedications(j.data.medications ? j.data.medications : []);
    } else {
      setNotFound(true);
    }
    setLoading(false);
  };

  const loadSchedules = async () => {
    const js = await httpGetWithTokenRest("medication-module/schedules/" + params.jorner_id)
    if (js.statusCode == 200) {
      setMedicationSchedules(js.data)
    }
  }

  return (
    <DashboardLayout
      element={

        <Box padding={"19px 20px"} minHeight="100vh" background={"#FAF8FF"}>

          <Spacer height={"22px"} />
          {loading ? (
            ""
          ) : notfound ? (
            <NoJorner source="medication" text="No Medication for Selected Jorner" />
          ) : (
            <>
              <Box>
                {/* greetings */}
                <Flex alignItems="center" justifyContent={"space-between"}>
                  <Box display="flex" alignItems={"center"}>
                    <AppLink
                      hasLeftIcon={true}
                      leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
                      shadow="none"
                      padding="0"
                      text=""
                      background="transparent"
                      color="secondary"
                      width="fit-content"
                      textDecoration="none"
                      href={medicationsRoute}
                    />
                    <Box width="2px" />
                    <Heading
                      color={"secondary"}
                      fontSize={"22px"}
                      fontWeight={"600"}
                      as={"h3"}
                    >
                      {jorner.first_name} {jorner.last_name}
                    </Heading>
                  </Box>


                </Flex>
                {
                  jorner && 
                <JornerHeader jorner={jorner} showButton={false} />
                }
                <Box h="20px" />
              </Box>
              <Flex flexWrap={"wrap"} mb="3" gap={"2"}>
                {tabItems.map((tabItem, i) => (
                  <Button
                    shadow={"none"}
                    rounded={"none"}
                    key={i}
                    onClick={() => setSelectedTab(tabItem.toLocaleLowerCase())}
                    width={"fit-content"}
                    fontWeight="normal"
                    fontSize="13px"
                    borderBottom={`1px solid ${selectedTab === tabItem.toLocaleLowerCase()
                      ? "#FF6E57"
                      : "grey"
                      }`}
                    color={
                      selectedTab === tabItem.toLocaleLowerCase()
                        ? "red"
                        : "black"
                    }
                    height="36px"
                    padding="4px 12px"
                  >
                    {tabItem}
                  </Button>
                ))}
              </Flex>


              <Box>
                {selectedTab === "medications" ? (
                  <Box
                    shadow={"sm"}
                    background="white"
                    padding="20px"
                    borderRadius={"16px"}
                  >
                    {medications.length > 0 ? (
                      <Grid
                        templateColumns={{
                          base: "repeact(1,1fr)",
                          md: "repeat(3, 1fr)",
                        }}
                        gap={6}
                      >
                        {medications.map((item, i) => (
                          <GridItem w="100%" background={"#FAF8FF"} padding="14px">
                            <Box>
                              <Flex alignItems={"center"} mb="3" b>
                                <Image src={medicationIcon} boxSize="30px" />
                                <Heading
                                  display={"flex"}
                                  fontSize={"14px"}
                                  fontWeight="600"
                                >
                                  {item.generic_name}&nbsp;
                                  <Text fontWeight={"400"} color="#747A8B">
                                    {item.brand_name}
                                  </Text>
                                </Heading>
                              </Flex>
                              <Flex
                                alignItems={"center"}
                                justifyContent="space-between"
                              >
                                <Text fontSize={"12"} fontWeight="400">
                                  Daily
                                </Text>
                                <Text
                                  fontSize={"12"}
                                  fontWeight="600"
                                  color={"#17181C"}
                                >
                                  {item.daily ? item.daily : "no"}
                                </Text>
                              </Flex>

                              <Flex
                                alignItems={"center"}
                                justifyContent="space-between"
                              >
                                <Text fontSize={"12"} fontWeight="400">
                                  Dosage
                                </Text>
                                <Text
                                  fontSize={"12"}
                                  fontWeight="600"
                                  color={"#17181C"}
                                >
                                  {item.dosage_description
                                    ? item.dosage_description
                                    : ""}
                                </Text>
                              </Flex>

                              <Flex
                                alignItems={"center"}
                                justifyContent="space-between"
                              >
                                <Text fontSize={"12"} fontWeight="400">
                                  Duration
                                </Text>
                                <Text
                                  fontSize={"12"}
                                  fontWeight="600"
                                  color={"#17181C"}
                                >
                                  {item.is_short_time ? "Short term" : "Long term"}
                                </Text>
                              </Flex>
                            </Box>
                          </GridItem>
                        ))}
                      </Grid>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems="center"
                        py="5"

                      >
                        <Image src={tabitemnotfound} boxSize={"130px"}></Image>
                        <Box h="10px" />
                        <Heading as="h6" fontSize={"16px"}>
                          No Medications
                        </Heading>
                        <Box h="10px" />

                        <Box textAlign={"center"} maxW="480px">
                          <Text color="paragraph">
                            No Medications found for this Jorner.
                          </Text>
                        </Box>
                      </Box>
                    )}

                  </Box>
                ) : (
                  ""
                )}
              </Box>



              <Box>
                {selectedTab === "medication schedules" ? (
                  <Box
                    shadow={"sm"}
                    background="white"
                    padding="20px"
                    borderRadius={"16px"}
                  >
                    {medicationsSchedules.length > 0 ? (
                      <Grid
                        templateColumns={{
                          base: "repeact(1,1fr)",
                          md: "repeat(3, 1fr)",
                        }}
                        gap={6}
                      >
                        {medicationsSchedules.map((item, i) => (
                          <>
                            <GridItem rounded={"lg"} w="100%" background={"#FAF8FF"} padding="14px">
                              <Link to={"/medications/schedules/"+item.id} style={{
                                textDecoration: "none"
                              }} as={ReachLink} >

                                <Flex fontSize={"14px"} color={"#747A8B"} gap={"4"} alignItems={"center"} justifyContent={"space-between"}>
                                  <Flex fontSize={"14px"} color={"#747A8B"} gap={"4"} alignItems={"center"}>
                                    <Image src={pill} boxSize={"45px"} objectFit={"contain"} />
                                    <Box >
                                      <Flex gap={"3"} alignItems={"center"}>
                                        <Heading fontSize={"14px"} color={"#17181C"} >{item.medication.generic_name}</Heading>
                                        <Text color={"#747A8B"} fontSize={"14px"} >{item.medication.brand_name}</Text>
                                      </Flex>
                                      <Box h="4px" />
                                      <Text>{item.track} : {item.track_value}{item.track.toLowerCase() == "instances" ? "times" : "hours"} </Text>
                                      <Box h="4px" />
                                      <Text>Dosage : {item.medication.dosage_description}</Text>
                                    </Box>
                                  </Flex>

                                  <Icon as={ChevronRightIcon} color={"#7353BA"} fontSize={"18px"} />
                                </Flex>
                              </Link>
                            </GridItem>
                          </>
                        ))}
                      </Grid>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent={"center"}
                        alignItems="center"
                        py="5"
                      >
                        <Image src={medicationSchedule} boxSize={"130px"}></Image>
                        <Box h="10px" />
                        <Heading as="h6" fontSize={"16px"}>
                          No Medication Schedules
                        </Heading>
                        <Box h="10px" />

                        <Box textAlign={"center"} maxW="480px">
                          <Text color="paragraph">
                            Proceed to create a medication schedule on our mobile app
                          </Text>
                        </Box>
                      </Box>
                    )}

                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </>
          )}
        </Box>
      }
    />
  );
}

export default JornerMedicationPage;
