import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { httpDeleteWithToken } from "../../utils/http_utils";
import CustomModal from "../../components/customModal";
import { deleteIcon } from "../../images";
import { capitalizeFirstWord } from "../../utils/utils";

export default function JornerBox({
  jorner,
  refetchAllJorners,
  editJornerInit = () => {},
}) {
  const toast = useToast();
  const [isLoading, setIsLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteJorner = async () => {
    setIsLoading(true);
    const resp = await httpDeleteWithToken(`jorner/${jorner?.id}`);
    if (resp.error !== null && resp.error !== undefined) {
      setIsLoading(false);
      onClose();
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      refetchAllJorners();
      setIsLoading(false);
      onClose();
      return toast({
        title: "Success.",
        description: `Jorner deleted successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const onDone = () => {};

  return (
    <Box bg={"gray"} p={{ base: 2, md: 4 }} borderRadius={8} mt={2}>
      <Flex
        gap={2}
        alignItems={"center"}
        direction={{ base: "column", md: "row" }}
      >
        {jorner.avatar ? (
          <Avatar
            src={jorner?.avatar}
            size={"md"}
            bg={"secondary"}
            cursor={"pointer"}
          />
        ) : (
          <Avatar
            name={jorner?.first_name}
            size={"md"}
            bg={"secondary"}
            cursor={"pointer"}
          />
        )}
        <Box flexDirection={"row"} direction={{ base: "column", md: "row" }}>
          <Text fontWeight={600} fontSize={14}>
            {jorner?.first_name}
            <Text ml={2} as={"span"} fontSize={12} fontWeight={500}>
              {capitalizeFirstWord(jorner?.type)}
            </Text>
          </Text>
          <Flex direction={{ base: "column" }}>
            <Text fontWeight={400} fontSize={12}>
              Disability -
            </Text>
            <Text
              color={"secondary"}
              overflowX={"ellipsis"}
              fontWeight={500}
              fontSize={12}
            >
              {jorner?.disabilities?.join()}
            </Text>
          </Flex>
        </Box>
        <Spacer />
        <Stack flexDirection={{ base: "row", md: "column" }}>
          <Avatar
            onClick={() => {
              console.log(jorner, "jorner")
              editJornerInit(jorner)
            } 
          }
            size={"sm"}
            bg={"white"}
            icon={<FaEdit color="#747A8B" />}
            b={2}
          />
          <Avatar
            onClick={onOpen}
            size={"sm"}
            bg={"white"}
            icon={
              isLoading ? (
                <Spinner size={"sm"} color="#747A8B" />
              ) : (
                <FaTrashAlt color="#747A8B" />
              )
            }
          />
        </Stack>
      </Flex>

      <CustomModal
        onClose={onClose}
        onDone={onDone}
        isOpen={isOpen}
        body={
          <Box>
            <Center>
              <Image src={deleteIcon} />
            </Center>
            <Text textAlign={"center"} fontSize={20} fontWeight={600}>
              Delete?
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              The selected jorner will have their account deleted
            </Text>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              mt={1}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="secondary"
              borderRadius={64}
              border={"1px solid #EBECEF"}
              background={"white"}
              onClick={deleteJorner}
            >
              {isLoading ? (
                <Spinner size={"sm"} color="#747A8B" />
              ) : (
                "Yes delete"
              )}
            </Button>
          </Box>
        }
      />
    </Box>
  );
}
