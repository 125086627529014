import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import React from "react";

function SidebarItem({ icon, text, href }) {
  return (
    <Link as={ReactLink} to={href} _hover={{
        textDecoration:"none"
      }}>
      <Flex alignItems={'center'} color="white" >
        {icon}
        <Box mx={'1'} />
        <Text fontSize={'13px'}>{text}</Text>
      </Flex>
    </Link>
  );
}

export default SidebarItem;
