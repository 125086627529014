import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import throttle from 'lodash/throttle';
import {
  logOut,
  checkIfTokenExpired,
  getAccessToken,
  getUserActivity,
  setUserActivity,
  onRefreshToken,
} from '../utils/auth';
import { IDLE_TIMEOUT, CHECK_ACTIVITY_INTERVAL_TIMEOUT, EVENT_LISTENER_THROTTLE_TIMEOUT } from '../constants/idleTimer';

const useIdleTimer = () => {
  const token = useSelector((state) => state?.auth?.token);

  useEffect(() => {
    const isAuthorized = Boolean(token);
    let timeInterval;

    const checkTokenExpiration = () => {
      const accessToken = getAccessToken();

      if (checkIfTokenExpired(accessToken)) {
        onRefreshToken();
      }
    };

    const setActivity = () => setUserActivity(new Date().getTime());

    const checkActivity = () => {
      const lastActivity = Number(getUserActivity());
      // if no lastActivity - break (user just logged in)
      if (!lastActivity) {
        setActivity();
        return;
      }

      const time = new Date().getTime();
      const diffMs = Math.abs(lastActivity - time);
      const isIdle = diffMs >= IDLE_TIMEOUT;

      if (isIdle) {
        stop();
        logOut();
      }
    };

    const onEvent = throttle(() => setActivity(), EVENT_LISTENER_THROTTLE_TIMEOUT);

    const start = () => {
      // always check on start (because eventListener may be called before setInterval)
      checkActivity();

      timeInterval = setInterval(() => {
        checkActivity();
        checkTokenExpiration();
      }, CHECK_ACTIVITY_INTERVAL_TIMEOUT);

      addEventListeners();
    };

    const stop = () => {
      clearInterval(timeInterval);
      removeEventListeners();
    };

    const addEventListeners = () => {
      document.addEventListener('mousemove', onEvent);
      document.addEventListener('click', onEvent);
    };

    const removeEventListeners = () => {
      document.removeEventListener('mousemove', onEvent);
      document.removeEventListener('click', onEvent);
    };   

    if (isAuthorized) {
      start();
    } else {
      stop();
    }

    return () => {
      stop();
    };
  }, [token]);
};

export default useIdleTimer;
