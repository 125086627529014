import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  PinInput,
  PinInputField,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import { AppLogo, LoginImg, Logo } from "../../images";
import { resetPasswordRoute } from "../../utils/allRoutes";
import LoginSide from "../../components/loginSide";
import ls from "localstorage-slim";
import { httpPostWithoutToken } from "../../utils/http_utils";

export default function ForgetPasswordOtpVerification() {
  const navigate = useNavigate();
  const email = ls.get("sj_email", { decrypt: true });
  const toast = useToast();
  const [otp, setOtp] = React.useState("");
  const [otpInvalid, setOtpInvalid] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const countdownRef = useRef();
  const restart = () => {
    countdownRef.current.start();
  };
  const onChangeOtp = (val) => {
    setOtp(val);
    setOtpInvalid(false);
  };

  const resendOtp = async () => {
    const resp = await httpPostWithoutToken("caregiver/forgot-password", {
      email,
    });
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      restart();
      return toast({
        title: "Success.",
        description: `OTP sent`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const resp = await httpPostWithoutToken("caregiver/verify-password-otp", {
      otp,
    });
    if (resp.error !== null && resp.error !== undefined) {
      setOtpInvalid(true);
      setIsSubmitting(false);
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      ls.set("sj_r_p_a", resp.data.authorization, { encrypt: true });
      navigate(resetPasswordRoute);
    }
  };
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Text onClick={resendOtp} cursor={"pointer"}>
          {" "}
          Resend Code
        </Text>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {" "}
          Resend in {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };
  return (
    <Flex min-height={"100vh"} width={"100%"}>
      <LoginSide />
      <Box
        zIndex={2}
        w={{ base: "100%", md: "50%" }}
        background={"white"}
        position="relative"
        overflowY={"scroll"}
        flex="1"
        borderTopRadius={{ base: "20px", md: 0 }}
        paddingTop={{ base: "40px", md: 0 }}
        display="flex"
        h={{ base: "calc(100vh - 30px)", md: "100vh" }}
        flexDirection={{ base: "column", md: "row" }}
        m={{ base: "30px 0 0 0", md: "0" }}
      >
        <Box display={{ md: "none" }} borderRadius={20}>
          <Center p={2}>
            <Image src={AppLogo} />
          </Center>
        </Box>
        <AbsoluteCenter p="4" axis="both" width={{ base: "auto", md: "70%" }}>
          <Heading
            pt={8}
            display={"flex"}
            justifyContent="center"
            flexWrap="nowrap"
            fontWeight={600}
            fontSize="32px"
            color={"black"}
          >
            OTP Verification
          </Heading>
          <Text py={8} textAlign={"center"}>
            Enter OTP sent to{" "}
            <Text as={"span"} color={"primary"}>
              {email}
            </Text>
          </Text>
          <Center>
            <HStack>
              <PinInput
                otp
                size={"lg"}
                onChange={onChangeOtp}
                isInvalid={otpInvalid}
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </Center>
          {otpInvalid ? (
            <Text textAlign={"center"} pt={1} size={"sm"} color={"#B32924"}>
              Incorrect Code
            </Text>
          ) : null}
          <Center mt={3}>
            <Text mr={2}>Didn't receive code? </Text>
            <Countdown
              ref={countdownRef}
              date={Date.now() + 30000}
              renderer={renderer}
            />
          </Center>
          <Button
            mt={10}
            mb={4}
            size={"lg"}
            w={"100%"}
            color="#fff"
            borderRadius={64}
            background={"primary"}
            isLoading={isSubmitting}
            onClick={onSubmit}
            isDisabled={otp.length < 6}
          >
            Verify
          </Button>
        </AbsoluteCenter>
      </Box>
    </Flex>
  );
}
