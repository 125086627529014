import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Input,
  Button,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft, FaSleigh } from "react-icons/fa";
import moment from "moment";
import { httpPostWithToken, httpPostWithoutToken } from "../../utils/http_utils";

export default function AgreementCaregiverModal({
  isOpen,
  onClose,
  onFinished,
  inviteeName,
  values
}) {
  const [selected, setselected] = React.useState([]);
  const [caregivername, setCaregivername] = React.useState("");
  const [postData, setPostData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const toast = useToast();

  const user = JSON.parse(sessionStorage.getItem("sj_user"));

  const styles = {
    ul: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    li: {
      marginBottom: "10px",
    },
  };
  useEffect(()=> {
    setPostData(values)
    setCaregivername("")
  }, [values])
  const finishSelection = async () => {
    let name = `${user?.first_name.replace(" ", "")}${user?.last_name.replace(" ", "")}`;
    if(caregivername.replace(" ", "").trim() != name.trim()) {
      return toast({
        title: "Invalid name!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    let data = postData;
    setIsLoading(true)
    let resp = await httpPostWithToken("co_caregiver/invite", data);

    if (resp.error !== null && resp.error !== undefined) {
      setIsLoading(false)
      onClose();
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setIsLoading(false)
      onFinished();
      onClose();
      setselected([]);
    }
  };

  const inputTag = () => {
    return (
      <Input
        value={caregivername}
        onChange={(e) => setCaregivername(e.target.value)}
        borderColor={"#E3E4E8"}
      />
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} maxW={"95%"} width={"720px"}>
        <ModalHeader>HIPPA Agreement</ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <ModalBody marginBottom={"0"}>

          <Box cursor={"pointer"} py="2">
            {/* <Box>
              Dear{" "}
              <strong>
                {user?.first_name} {user?.last_name}
              </strong>
              ,
            </Box> */}
            <br />
            <div>
              <strong>For the Parent/Legal Guardian</strong>
            </div>
            <p>
              I hereby authorize the release of my child’s protected health information to <strong>{inviteeName}</strong> for the purposes of providing healthcare or caregiver services to my child.

              I understand that the information disclosed may include, but is not limited to, medical records, diagnosis, treatment plans, and any other information related to my child’s healthcare.This authorization is granted for the following purposes:
            </p>
            <br />
            <div>
              <div>
                <strong>Care Circle Coordination</strong>
              </div>

              <p>
                This authorization is valid until otherwise revoked in the app.
                I understand that I have the right to revoke this authorization at any time, in writing, except to the extent that the provider has already taken action in reliance on this authorization. I also understand that once the information is disclosed, it may no longer be protected by federal privacy regulations.

                I acknowledge that my child’s healthcare information is protected by the Health Insurance Portability and Accountability Act (HIPAA) and that I am voluntarily authorizing the release of this information.
              </p>
            </div>
            <br />

          </Box>
          <br />
          <Box>
            {/* I acknowledge that my child’s healthcare information
              is protected by the Health Insurance Portability and
              Accountability Act (HIPAA) and that I am voluntarily
              authorizing the release of this information. */}
            {/* <strong>Signature of Parent/Guardian:</strong> */}
            {/* <strong>
                <i>
                  {caregiver.first_name} {caregiver.last_name}
                </i>
              </strong> */}
            <Box pl={"45px"}>
              {moment().format("YYYY-MM-DD")}
            </Box>
            Date: _______________________
            <br />
            
            <br />
            <br />
          </Box>
          {inputTag()}
          <p>
            <small>
              Enter your Fullname{" "}
              <strong>
                {user?.first_name} {user?.last_name}
              </strong>{" "}
              to <strong>sign</strong> this agreement
            </small>
          </p>
          <br />
          <Box textAlign={"center"}>
            <Button
              mx={"auto"}
              rounded={15}
              w={"200px"}
              bg={"primary"}
              color={"white"}
              isLoading={isLoading}
              onClick={() => finishSelection()}
            >
              Proceed
            </Button>
          </Box>

        <br />
      </ModalBody>
    </ModalContent>
    </Modal >
  );
}
