import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useToast,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  delete_basket_y,
  healthcare,
  homeIcon1,
  homeIcon2,
} from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { httpDeleteWithToken } from "../../utils/http_utils";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import JornerPdf from "./pdf/generate_pdf";

export default function ShareJorner({ isOpen, onClose, jorner }) {
  
  const finishSelection = () => {
    onClose();
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [activeJorner, setActiveJorner] = useState(jorner);

  useEffect(()=> {
    setActiveJorner(jorner)
    console.log("what does not work forme", jorner.what_doesnt_work_for_me)
  }, [jorner])

  const toast = useToast();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p={4}>
        <ModalBody textAlign={"center"} marginBottom={"0"}>
          <Heading as={"h5"} fontSize="16px">
            Confirmation
          </Heading>
          <Box mt="2" />
          <Text textAlign={"center"}>
            Tick the box below to give <br /> your consent.
          </Text>
          <Box w="100%" mt={"25px"}>
            <Checkbox
              colorScheme="red"
              style={{ color: "secondary" }}
              isChecked={isChecked}
              onChange={(e) => {
                console.log("e", e);
                setIsChecked(e.target.checked);
              }}
            >
              <Text>I give my consent to share jorner’s profile Share </Text>
            </Checkbox>
            {isChecked ? (
              <PDFDownloadLink
                className=""
                style={{ color: "yellow" }}
                document={<JornerPdf jorner={activeJorner} />}
                fileName={`${activeJorner?.first_name}.pdf`}
              >
                {/* {(blog) => (blog.loading ? "Loading document..." : "Export")} */}
                <Button
                  isLoading={isLoading}
                  mt={8}
                  size={"lg"}
                  w={"100%"}
                  shadow={"sm"}
                  background="#F7F7F8"
                  color="#747A8B"
                  borderRadius={64}
                  onClick={()=> {
                    setIsLoading(true)
                    setTimeout(() => {
                      setIsLoading(false)
                      finishSelection()
                    }, 1000);
                  }}
                >
                  Share
                </Button>
              </PDFDownloadLink>
            ) : (
              <Button
                isLoading={isLoading}
                mt={8}
                size={"lg"}
                w={"100%"}
                shadow={"sm"}
                background="#F7F7F8"
                color="#747A8B"
                borderRadius={64}
              >
                Share
              </Button>
            )}

            <Box w="100%" mt={"5px"} />

            <Button
              color="red"
              borderRadius={64}
              onClick={() => finishSelection()}
            >
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
