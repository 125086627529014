import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { capitalizeFirstWord, checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";

export default function CoCaregiverWhoModal({
  isOpen,
  onClose,
  title = null,
  constants,
  selectedOriginal,
  onFinished,
  source = "main",
}) {
  const toast = useToast()
  const [selected, setselected] = React.useState(selectedOriginal);
  const finishSelection = (item = null) => {
    if (item) {
      onFinished(item);
    } else {
      onFinished(selected);
    }
    onClose();
    setselected([]);
  };
  useEffect(() => {
    setselected(selectedOriginal ? selectedOriginal : []);
  }, [selectedOriginal]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (source !== "main") {
          onFinished("close");
        } else {
          onClose();
        }
      }}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader>{title || "Who is in charge"}</ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <ModalBody marginBottom={"0"}>
          <Text color={"#747A8B"} fontSize={"12px"}>
            Select the co-caregivers to join your care circle group
          </Text>
          {constants?.map((item, i) => (
            <Box cursor={"pointer"} key={item.id} py="2">
              <Flex justifyContent={"space-between"}>
                <FormLabel
                  gap={"3"}
                  display="flex"
                  alignItems={"center"}
                  htmlFor={`${item.user_id}`}
                >
                  <Box
                    position={"relative"}
                    fontWeight="600"
                    color="white"
                    boxSize={"45px"}
                    borderRadius={"100%"}
                    display="flex"
                    background={"secondary"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize={"14px"}>
                      {item.first_name.substring(0, 1)}
                      {item.last_name.substring(0, 1)}
                    </Text>
                  </Box>
                  <Box>
                    <Text>
                      {item.first_name} {item.last_name}
                    </Text>
                    {source == "jorner" ? (
                      <Text color={"#17181C"} fontSize={12}>
                        {capitalizeFirstWord(item.type)}
                      </Text>
                    ) : (
                      <>
                        <Text color={"#747A8B"} fontSize={12}>
                          {item.email}
                        </Text>
                        <Box
                          w={"fit-content"}
                          p={2}
                          borderRadius={12}
                          bg={"#FBEAEA"}
                          fontSize={10}
                          color={"#FF6E57"}
                        >
                          {item.caregiver_type}
                        </Box>
                      </>
                    )}
                  </Box>
                </FormLabel>
                {source == "jorner" ? (
                  <Checkbox
                    onChange={(e) => {
                      var s = selected;
                      if (e.target.checked) {
                        if (s.includes(title ? item.id : item.user_id)) {
                        } else {
                          s = [...s, title ? item.id : item.user_id];
                        }
                        if (source == "jorner") {
                          finishSelection(item);
                          return;
                        }
                      } else {
                        var i = s.indexOf(title ? item.id : item.user_id);
                        var splice = s.splice(i, 1);
                      }
                      setselected(s ? s : []);
                    }}
                    id={`${title ? item.id : item.user_id}`}
                    size="sm"
                    colorScheme="red"
                  ></Checkbox>
                ) : (
                  <Checkbox
                    onChange={(e) => {
                      var s = selected;
                      if (e.target.checked) {
                        if (s.includes(title ? item.id : item.user_id)) {
                        } else {
                          s = [...s, title ? item.id : item.user_id];
                        }
                        if (source == "jorner") {
                          finishSelection(item);
                          return;
                        }
                      } else {
                        var i = s.indexOf(title ? item.id : item.user_id);
                        var splice = s.splice(i, 1);
                      }
                      setselected(s ? s : []);
                    }}
                    isChecked={selected.includes(item.id)}
                    id={`${title ? item.id : item.user_id}`}
                    size="sm"
                    colorScheme="red"
                  ></Checkbox>
                )}
              </Flex>
              <Divider mt={"1"} color={"#EBECEF"} />
            </Box>
          ))}
          {
            source !== "jorner" &&
          <Button
            onClick={finishSelection}
            mt={8}
            mb="3"
            size={"lg"}
            w={"100%"}
            shadow={"sm"}
            colorScheme="purple"
            color="#fff"
            borderRadius={64}
          >
            Proceed
          </Button>
          }

        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
