import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useToast,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React,{useState} from "react";
import { iconCompletey } from "../../images";
import { useNavigate } from "react-router-dom";

export default function SubscriptionSuccessful({
  isOpen,
  onClose,
  sub,
}) {

  const finishSelection = () => {
    onClose()
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast();
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p={4}>
            <ModalBody textAlign={"center"} marginBottom={'0'}>
              <Flex justifyContent={'center'}>
              <Image src={iconCompletey} boxSize={'170px'} />
              </Flex>
              <ModalHeader textAlign={'center'} >Subscription Payment Successful!</ModalHeader>
              <Text textAlign={'center'}>
              Thank you for choosing our <strong>{sub.plan_name} Plan</strong>! 
              Your payment has been successfully processed, and you now have access to all <strong>{sub.plan_name} Plan</strong> features.
              </Text>
              <Box w="100%" mt={"25px"}>
              <Button
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={() => navigate("/dashboard")}
                >
                  Continue
                </Button>
              </Box>
            </ModalBody>
           
      </ModalContent>
    </Modal>
  );
}
