import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { AsYouType } from "libphonenumber-js";
import { getCountryTelCode } from "../utils/countries";
import { FaChevronDown, FaPhone } from "react-icons/fa";
import { containsSpecialChars } from "../utils/utils";

export default function PhoneNumberInput({
  size,
  value,
  country,
  options,
  onChange,
  onChangeCode,
  placeholder,
  defaultCountry,
  required = true,
  isDisabled = false,
  ...rest
}) {
  let [number, setNumber] = useState(value || "");
  let [display, setDisplay] = useState("");
  let [selectedCountry, setSelectedCountry] = useState(
    country || defaultCountry
  );
  let [countryCode, setCountryCode] = useState(
    getCountryTelCode(country) || defaultCountry
  );

  useEffect(() => {
    let c = "+1";
    let co = "USA";
    if(country){
      c = getCountryTelCode(country);
      co = country;  
    }

    let parsedNumber = new AsYouType().input(`${c}${value}`);
    setDisplay(parsedNumber)
    setSelectedCountry(co);
    setCountryCode(c);
    setDisplay(parsedNumber)
  }, [country, value]);

  const onCountryChange = (e) => {
    let value = e.target.value;
    let code = getCountryTelCode(value);
    // let parsedNumber = new AsYouType().input(`${number}`);

    setCountryCode(code);
    setSelectedCountry(value);
    onChange(number);
    onChangeCode(code);
  };

  const onPhoneNumberChange = (e) => {
    let value = e.target.value;

    let last = value.substring(value.length - 1);
    const alpha = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];
    if (alpha.includes(last)) {
      value = value.replace(last, "");
    }
    value = value.replace(" ", "").replace("+1", "").replace("undefined", "").replace("  ", "")
    if(value.length > 12) return;

    if (containsSpecialChars(last)) value = value.replace(last, "");
    let parsedNumber = new AsYouType().input(`${countryCode}${value}`);
    let d = value.replace(/\D/g, "");
    setDisplay(parsedNumber)
    setNumber(d);
    onChange(d);
  };

  return (
    <InputGroup size={size} {...rest}>
      <InputLeftElement width="4rem">
        <Box mt="10px" width={"50px"} pl="1" mr="-1px" flex={1}>
          <Flag width={"45px"} height="35px" code={selectedCountry || ""} />
        </Box>
        <Text fontSize={"14px"} m="0" p="0" mt="5px" as={"span"}>
          {countryCode}{" "}
        </Text>
        {/* <FaChevronDown/> */}
        <Select
          icon={<FaChevronDown />}
          iconSize="5"
          // top="0"
          // left="0"
          zIndex={1}
          bottom={0}
          opacity={0}
          // right="-1"
          id="phoneDrop"
          height="100%"
          position="absolute"
          value={selectedCountry}
          onChange={onCountryChange}
        >
          <option value="" />
          {options
            .filter((o) => o.value == "USA")
            .map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </Select>
        <Flex pl={2} width="100%" alignItems="center">
          {selectedCountry ? (
            <Box mr="4px" width="50%" flex={1}>
              <Flag height="1rem" code={selectedCountry || ""} />
            </Box>
          ) : (
            <FaPhone />
          )}
        </Flex>
      </InputLeftElement>
      <Input
        pl="4.7rem"
        type="tel"
        value={display}
        isDisabled={isDisabled}
        _disabled={{
          borderColor: "#E3E4E8",
        }}
        keyup
        // pattern="[0-9]"
        isRequired={required}
        placeholder={placeholder}
        onChange={onPhoneNumberChange}
        borderColor={"#E3E4E8"}
      />
    </InputGroup>
  );
}

PhoneNumberInput.defaultProps = {
  options: [],
  size: "md",
};
