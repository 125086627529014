import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
  Icon,
  FormLabel,
  FormControl,
  useToast,
  Progress,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  createDoc,
  createFolder as folderImage,
  createImage,
  homeIcon1,
  rightCaret,
} from "../../images";
import { AiFillCaretRight } from "react-icons/ai";
import { APP_API_URL, httpPostWithToken } from "../../utils/http_utils";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function CreateDocumentModal({
  isOpen,
  onClose,
  selectedOriginal,
  onFinished,
  isFolder,
}) {
  const toast = useToast();
  const [selected, setselected] = React.useState(selectedOriginal);
  const [showInputFolder, setShowInputFolder] = React.useState(false);
  const [folderLoading, setFolderLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [folderName, setFolderName] = React.useState("");
  const [percent, setPercent] = React.useState(0);
  const [inFolder, setIFolder] = React.useState(false);
  const params = useParams();
  const finishSelection = () => {};
  useEffect(() => {
    setShowInputFolder(false);
    setIFolder(isFolder);
  }, []);

  const documentTypes = [
    {
      icon: createImage,
      text: "Upload Pictures",
      value: "picture",
    },
    {
      icon: createDoc,
      text: "Upload Files",
      value: "files",
    },
    {
      icon: folderImage,
      text: "Create Folder",
      value: "folder",
    },
  ];

  const createFolder = async () => {
    setFolderLoading(true);
    let url = "file-manager/folder";
    if (params.folder_id) {
      url = `file-manager/folder/${params.folder_id}/folder`;
    }
    let res = await httpPostWithToken(url, {
      name: folderName,
    });
    if (res.status == "success") {
      setFolderName("");
      toast({
        title: "New Folder created",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setShowInputFolder(false);
      onClose();
      onFinished();
    } else {
      toast({
        title: "Sorry, we hit a snag!",
        description: `${res.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setFolderLoading(false);
  };

  const imageHandler = async (event) => {
    if (event.target.files) {
      const fd = new FormData();
      if(event.target.files.length > 10) {
        return toast({
          title: "Maximum of 10 files is allowed.",
          description: ``,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        fd.append("files", file);
      }
      // fd.append("files", event.target.files);
      const token = sessionStorage.getItem("sj_token");
      setIsLoading(true);
      let url = `${APP_API_URL}/file-manager`;
      if (params.folder_id) {
        url = `${APP_API_URL}/file-manager/folder/${params.folder_id}`;
      }
      await axios
        .post(url, fd, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setPercent(percentCompleted);
          },
        })
        .then((resp) => {
          toast({
            title: "New Folder created",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          onClose();
          onFinished();
        })
        .catch(function (error) {
          if (error.code === "ERR_NETWORK") {
            return toast({
              title: "Sorry, we hit a snag!",
              description: `An error occur, please try again.`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
          return toast({
            title: "Sorry, we hit a snag!",
            description: `${error?.response?.data?.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        {showInputFolder ? (
          <ModalHeader display={"flex"} justifyContent={"space-between"}>
            <Text>Get Started</Text>
            <Box
              onClick={() => setShowInputFolder(false)}
              p={2}
              cursor={"pointer"}
              mr={5}
              bg={"#F6F6F6"}
              borderRadius={100}
              color={"#17181C"}
              fontWeight={500}
              fontSize={14}
            >
              Close
            </Box>
          </ModalHeader>
        ) : (
          <>
            <ModalHeader color={"red"}>Get Started</ModalHeader>

            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
          </>
        )}

        <ModalBody marginBottom={"0"}>
          <Divider color={"#EBECEF"} />
          {showInputFolder ? (
            <Box w="100%" mt={"25px"}>
              <FormControl>
                <FormLabel>Folder Name</FormLabel>
                <Input
                  focusBorderColor="#E3E4E8"
                  border={"1px solid #E3E4E8"}
                  height="50px"
                  value={folderName}
                  placeholder="Enter folder name"
                  onChange={(e) => setFolderName(e.target.value)}
                />
              </FormControl>
              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                isLoading={folderLoading}
                background={"primary"}
                onClick={() => {
                  if (folderName !== "") createFolder();
                }}
              >
                Create
              </Button>
            </Box>
          ) : (
            <Box w="100%" mt={"25px"}>
              <Text>
                Here, you can add, search, preview, and organise all files and
                folders
              </Text>
              <Box my={2} opacity={isLoading ? 0.5 : 1} position={"relative"}>
                {isLoading && (
                  <Box
                    width={"100%"}
                    height={"100%"}
                    position={"absolute"}
                    left={"0"}
                    top={"0"}
                  ></Box>
                )}
                {documentTypes.map((item, i) => (
                  <>
                    {item.value != "folder" ? (
                      <>
                        <Input
                          hidden
                          id={item.value}
                          type={"file"}
                          accept={item.value == "picture" ? "image/*" : ""}
                          multiple
                          onChange={(e) => {
                            if (item.value == "picture") {
                              imageHandler(e);
                            } else {
                              imageHandler(e);
                            }
                          }}
                        />

                        <FormLabel
                          display={"flex"}
                          gap={4}
                          cursor={"pointer"}
                          alignItems={"center"}
                          key={i}
                          my="2"
                          htmlFor={item.value}
                        >
                          <Image src={item.icon} height={50} />
                          <Box
                            flex={1}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Text>{item.text}</Text>
                            <Image
                              src={rightCaret}
                              height={5}
                              objectFit={"contain"}
                            />
                          </Box>
                        </FormLabel>
                        <Divider color={"#EBECEF"} />
                      </>
                    ) : (
                      <>
                        <Box
                          onClick={() => setShowInputFolder(true)}
                          display={"flex"}
                          gap={4}
                          cursor={"pointer"}
                          alignItems={"center"}
                          key={i}
                          my="2"
                        >
                          <Image src={item.icon} height={50} />
                          <Box
                            flex={1}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Text>{item.text}</Text>
                            <Image
                              src={rightCaret}
                              height={5}
                              objectFit={"contain"}
                            />
                          </Box>
                        </Box>
                        <Divider color={"#EBECEF"} />
                      </>
                    )}
                  </>
                ))}
              </Box>
              {isLoading && (
                <Box shadow={"lg"} py="5px" px="3">
                  <Progress
                    bg={"gray"}
                    value={percent}
                    colorScheme="green"
                    borderRadius={"5"}
                  />
                  <Text fontSize={"12px"} color={"gray.500"}>
                    Uploading
                  </Text>
                </Box>
              )}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
