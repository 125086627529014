import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { FaEdit, FaSpinner, FaTrashAlt } from "react-icons/fa";
import { httpDeleteWithToken } from "../../utils/http_utils";
import CustomModal from "../../components/customModal";
import { deleteIcon } from "../../images";
import { QuestionIcon, SpinnerIcon } from "@chakra-ui/icons";
import ResendNotificationModal from "./resend_notfication_modal";

export default function CoCaregiverBox({
  caregiver,
  refetchAllCaregivers,
  editCareGiver = () => {},
  resendNotification = () => {},
}) {
  const toast = useToast();
  const [isLoading, setIsLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenResend,
    onOpen: onOpenResend,
    onClose: onCloseResend,
  } = useDisclosure();
  const deleteCareGiver = async () => {
    setIsLoading(true);
    const resp = await httpDeleteWithToken(`co_caregiver/${caregiver?.id}`);
    if (resp.error !== null && resp.error !== undefined) {
      setIsLoading(false);
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      refetchAllCaregivers();
      setIsLoading(false);
      onClose();
      return toast({
        title: "Success.",
        description: `Co Caregiver deleted successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <Box bg={"gray"} p={{ base: 2, md: 4 }} borderRadius={8} mt={2}>
      <Flex
        gap={2}
        alignItems={"center"}
        direction={{ base: "column", md: "row" }}
      >
        <Avatar
          name={`${caregiver?.first_name} ${caregiver?.last_name}`}
          size={"md"}
          bg={"secondary"}
          cursor={"pointer"}
        />
        <Box flexDirection={"row"} direction={{ base: "column", md: "row" }}>
          <Text fontWeight={600} fontSize={14}>
            {caregiver?.first_name} {caregiver?.last_name}
          </Text>
          <Box>
            <Text fontWeight={400} fontSize={12} color={"#747A8B"}>
              {caregiver?.email}
            </Text>
            <Text
              color={"#747A8B"}
              overflowX={"ellipsis"}
              fontWeight={500}
              fontSize={12}
            >
              {caregiver?.phone_number}
            </Text>
            <Text fontWeight={600} fontSize={12} color={"#747A8B"}>
              {caregiver?.caregiver_type}
            </Text>
            <Box
                              w={"fit-content"}
                              p={2}
                              py={1}
                              borderRadius={12}
                              bg={`${caregiver.ccg_status == 'pending' ? "secondary" : "primary"}`}
                              fontSize={10}
                              color={"#fff"}
                            >
                              {caregiver.ccg_status}
                            </Box>
          </Box>
        </Box>
        <Spacer />
        <Stack flexDirection={{ base: "row", md: "column" }}>
          {caregiver.ccg_status == "pending" && (
            <Avatar
              onClick={onOpenResend}
              size={"sm"}
              bg={"white"}
              icon={<SpinnerIcon color="#747A8B" />}
              b={2}
            />
          )}
          <Avatar
            onClick={() => editCareGiver(caregiver)}
            size={"sm"}
            bg={"white"}
            icon={<FaEdit color="#747A8B" />}
            b={2}
          />
          {caregiver.caregiver_type.toLowerCase() == "primary caregiver" ||
          caregiver.caregiver_type.toLowerCase() == "main" ? (
            <></>
          ) : (
            <Avatar
              onClick={onOpen}
              size={"sm"}
              bg={"white"}
              icon={
                isLoading ? (
                  <Spinner size={"sm"} color="#747A8B" />
                ) : (
                  <FaTrashAlt color="#747A8B" />
                )
              }
            />
          )}
        </Stack>
      </Flex>

      <CustomModal
        onClose={onClose}
        isOpen={isOpen}
        body={
          <Box>
            <Center>
              <Image src={deleteIcon} />
            </Center>
            <Text textAlign={"center"} fontSize={20} fontWeight={600}>
              Delete?
            </Text>
            <Text textAlign={"center"} fontSize={16} fontWeight={400}>
              The selected caregiver will have their account deleted
            </Text>
            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              mt={1}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="secondary"
              borderRadius={64}
              border={"1px solid #EBECEF"}
              background={"white"}
              onClick={deleteCareGiver}
            >
              {isLoading ? (
                <Spinner size={"sm"} color="#747A8B" />
              ) : (
                "Yes delete"
              )}
            </Button>
          </Box>
        }
      />
      <ResendNotificationModal
        onClose={onCloseResend}
        isOpen={isOpenResend}
        caregiver={caregiver.id}
      />
    </Box>
  );
}
