import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
  Icon,
  FormLabel,
  FormControl,
  useToast,
  Progress,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  createDoc,
  createFolder as folderImage,
  createImage,
  homeIcon1,
  rightCaret,
  carePlanPdf,
} from "../../images";
import { AiFillCaretRight } from "react-icons/ai";
import { APP_API_URL, httpPostWithToken } from "../../utils/http_utils";
import axios from "axios";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export default function OpenDocModal({
  isOpen,
  onClose,
  selectedOriginal,
  onFinished,
}) {
  const toast = useToast();
  const [selected, setselected] = React.useState(selectedOriginal);
  const [doc, setDoc] = useState([]);
  const [fileType, setFileType] = useState("docx");
  const finishSelection = () => {};
  useEffect(() => {
    setselected(selectedOriginal);
    let c = selectedOriginal.content_type;
    let i;
    if (c) {
      if (c.includes("image")) {
        i = "image";
      } else if (c == "application/pdf") {
        i = "pdf";
      } else if (c.includes("docx") || c.includes("doc")) {
        i = "docx";
      } else {
        i = "docx";
      }
      setFileType(i);
    }
  }, [selectedOriginal]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "95%", md: "600px", lg: "850px" }}
        borderRadius={16}
      >
        <ModalHeader color={"red"}>Document Viewer</ModalHeader>

        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>

        <ModalBody marginBottom={"0"}>
          <Box w="100%" height={"80vh"}>
            <Box height={"70vh"} my={2}>
              {fileType == "image" ? (
                <Image
                  src={selected.file_url}
                  width={"100%"}
                  objectFit={"fill"}
                />
              ) : (
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    {
                      uri: selected.file_url,
                      fileType: fileType,
                      fileName: "",
                    },
                  ]}
                />
              )}
            </Box>
            <Box height={"20px"} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
