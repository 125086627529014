import {
  Box,
  Heading,
  Flex,
  Spacer,
  Stack,
  Skeleton,
  SimpleGrid,
  useToast,
  Text,
  Icon,
  GridItem,
  Grid,
  Button,
  Image,
  Link,
} from "@chakra-ui/react";
import "../../utils/mp3.css";

import { Link as ReachLink, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";

import AppLink from "../../components/link";
import {
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  PlusIcon,
  TagIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { format } from "date-fns";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import NoJorner from "../../components/dashboard/no_jorner";
import { medicationsRoute } from "../../utils/allRoutes";
import DashboardLayout from "../../layout/dashboard";

import {
  medicationIcon,
  medicationSchedule,
  pill,
  skipped,
  tabitemnotfound,
  taken,
} from "../../images";
import JornerHeader from "../../components/dashboard/jorner_header";
import { DatePicker } from "rsuite";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";

function MedicationSchedulePage() {
  const toast = useToast(); //
  const tabItems = ["Medications Log", "Logged"];
  const [selectedTab, setSelectedTab] = useState("medications log");
  const [tags, setTags] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [medications, setMedications] = useState([]);
  const [loggedSchedules, setLoggedSchedules] = useState([]);
  const [loggedSchedulesOriginal, setLoggedSchedulesOriginal] = useState([]);
  const [whentouse, setWhentouse] = useState([]);

  const [loading, setLoading] = React.useState(true);
  const [notfound, setNotFound] = React.useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const [selected, setSelected] = useState("");

  useEffect(() => {
    loadConstants();
  }, [true]);
  const handleViewEvent = (e) => {
    console.log(e);
  };
  const loadConstants = async () => {
    var v1 = new Date();
    var v = moment(v1).format("YYYY-MM-D");
    var j = await httpGetWithTokenRest(
      "medication-module/log-schedules/" + params.schedule_id
    );
    setSelected(v1);
    if (j.status == "success") {
      setWhentouse(j.data.schedule.when_to_use);
      setSchedule(j.data.schedule);
      var logs = j.data.logs;
      logs = logs.filter((l) => l.schedule_date == v);
      setLoggedSchedules(logs);
      console.log(j.data.logs);
      setLoggedSchedulesOriginal(j.data.logs);
    } else {
      setNotFound(true);
    }
    setLoading(false);
  };
  const getAMPM = (time) => {
    // var t = time.split(":")
    return time;
  };
  return (
    <DashboardLayout
      element={
        <Box padding={"19px 20px"} minHeight="100vh" background={"#FAF8FF"}>
          <Spacer height={"22px"} />
          {loading ? (
            ""
          ) : notfound ? (
            <NoJorner
              source="medication"
              text="No Medication for Selected Jorner"
            />
          ) : (
            <>
              <Box>
                {/* greetings */}
                <Flex alignItems="center" justifyContent={"space-between"}>
                  <Box display="flex" alignItems={"center"}>
                    <AppLink
                      hasLeftIcon={true}
                      leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
                      shadow="none"
                      padding="0"
                      text=""
                      background="transparent"
                      color="secondary"
                      width="fit-content"
                      textDecoration="none"
                      href={medicationsRoute}
                    />
                    <Box width="2px" />
                    <Heading
                      color={"secondary"}
                      fontSize={"22px"}
                      fontWeight={"600"}
                      as={"h3"}
                    >
                      {schedule.medication.generic_name}{" "}
                      {schedule.medication.brand_name}
                    </Heading>
                  </Box>
                </Flex>
                <Box h="20px" />
              </Box>
              <Box
                display={"flex"}
                gap="3"
                flexDirection={{
                  base: "column",
                  lg: "row",
                }}
              >
                <Box
                  bg="white"
                  shadow={"lg"}
                  rounded={"lg"}
                  p="3"
                  width={{ base: "100%" }}
                >
                  <Flex flexWrap={"wrap"} mb="3" gap={"2"}>
                    {tabItems.map((tabItem, i) => (
                      <Button
                        shadow={"none"}
                        rounded={"none"}
                        key={i}
                        onClick={() =>
                          setSelectedTab(tabItem.toLocaleLowerCase())
                        }
                        width={"fit-content"}
                        fontWeight="normal"
                        fontSize="13px"
                        borderBottom={`1px solid ${
                          selectedTab === tabItem.toLocaleLowerCase()
                            ? "#FF6E57"
                            : "grey"
                        }`}
                        color={
                          selectedTab === tabItem.toLocaleLowerCase()
                            ? "red"
                            : "black"
                        }
                        height="36px"
                        padding="4px 12px"
                      >
                        {tabItem}
                      </Button>
                    ))}
                  </Flex>

                  <Box>
                    {selectedTab === "medications log" ? (
                      <Box
                        shadow={"sm"}
                        background="white"
                        padding="20px"
                        borderRadius={"16px"}
                      >
                        {whentouse.length > 0 ? (
                          <Grid
                            templateColumns={{
                              base: "repeact(1,1fr)",
                              md: "repeat(1, 1fr)",
                            }}
                            gap={6}
                          >
                            {whentouse.map((item, i) => (
                              <GridItem
                                key={i}
                                w="100%"
                                background={"#FAF8FF"}
                                padding="14px"
                              >
                                <Flex
                                  fontSize={"14px"}
                                  color={"#747A8B"}
                                  gap={"4"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Flex
                                    fontSize={"14px"}
                                    color={"#747A8B"}
                                    gap={"4"}
                                    alignItems={"center"}
                                  >
                                    <Image
                                      src={pill}
                                      boxSize={"45px"}
                                      objectFit={"contain"}
                                    />
                                    <Box>
                                      <Flex gap={"3"} alignItems={"center"}>
                                        <Heading
                                          fontSize={"14px"}
                                          color={"#17181C"}
                                        >
                                          {schedule.medication.generic_name}
                                        </Heading>
                                        <Text
                                          color={"#747A8B"}
                                          fontSize={"14px"}
                                        >
                                          {schedule.medication.brand_name}
                                        </Text>
                                      </Flex>
                                      <Box h="4px" />
                                      <Text>
                                        Dosage :{" "}
                                        {schedule.medication.dosage_description}
                                      </Text>
                                    </Box>
                                  </Flex>
                                  <Flex
                                    flexDir="column"
                                    width={{ base: "100%", md: "100px" }}
                                  >
                                    <Text fontWeight={"600"}>{item.time}</Text>
                                  </Flex>
                                </Flex>
                              </GridItem>
                            ))}
                          </Grid>
                        ) : (
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent={"center"}
                            alignItems="center"
                            py="5"
                          >
                            <Image
                              src={tabitemnotfound}
                              boxSize={"130px"}
                            ></Image>
                            <Box h="10px" />
                            <Heading as="h6" fontSize={"16px"}>
                              No Medications
                            </Heading>
                            <Box h="10px" />

                            <Box textAlign={"center"} maxW="480px">
                              <Text color="paragraph">
                                No Medications found for this Jorner.
                              </Text>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Box>
                    {selectedTab === "logged" ? (
                      <Box>
                        {loggedSchedules.length > 0 ? (
                          <Grid
                            templateColumns={{
                              base: "repeact(1,1fr)",
                            }}
                            gap={6}
                          >
                            {loggedSchedules.map((item, i) => (
                              <>
                                <GridItem
                                  key={i}
                                  rounded={"lg"}
                                  w="100%"
                                  background={"#FAF8FF"}
                                  padding="14px"
                                >
                                  <Link
                                    to={"/medications/schedules/" + item.id}
                                    style={{
                                      textDecoration: "none",
                                    }}
                                    as={ReachLink}
                                  >
                                    <Flex
                                      fontSize={"14px"}
                                      color={"#747A8B"}
                                      gap={"4"}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <Flex
                                        fontSize={"14px"}
                                        color={"#747A8B"}
                                        gap={"4"}
                                        alignItems={"center"}
                                      >
                                        <Image
                                          src={pill}
                                          boxSize={"45px"}
                                          objectFit={"contain"}
                                        />
                                        <Box>
                                          <Flex gap={"3"} alignItems={"center"}>
                                            <Heading
                                              fontSize={"14px"}
                                              color={"#17181C"}
                                            >
                                              {schedule.medication.generic_name}
                                            </Heading>
                                            <Text
                                              color={"#747A8B"}
                                              fontSize={"14px"}
                                            >
                                              {schedule.medication.brand_name}
                                            </Text>
                                          </Flex>
                                          <Box h="4px" />
                                          <Text>
                                            Dosage :{" "}
                                            {
                                              schedule.medication
                                                .dosage_description
                                            }
                                          </Text>
                                        </Box>
                                      </Flex>
                                      <Flex
                                        flexDir="column"
                                        width={{ base: "100%", md: "100px" }}
                                      >
                                        <Button
                                          size={"sm"}
                                          display={"flex"}
                                          gap="1"
                                          alignItems={"center"}
                                          bg={
                                            item.status.toLowerCase() == "taken"
                                              ? "#E7FEEE"
                                              : "#FBEAEA"
                                          }
                                          rounded="80px"
                                          fontSize={"12px"}
                                          border={
                                            item.status.toLowerCase() == "taken"
                                              ? "1px solid #0CCA4A"
                                              : "1px solid #DB504A"
                                          }
                                          type="button"
                                        >
                                          <Image
                                            src={
                                              item.status.toLowerCase() ==
                                              "taken"
                                                ? taken
                                                : skipped
                                            }
                                            boxSize={"12px"}
                                            objectFit={"contain"}
                                          />
                                          <Text>{item.status}</Text>
                                        </Button>
                                        <Box h="20px"></Box>
                                        <Text fontWeight={"600"}>
                                          {item.schedule_time}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  </Link>
                                </GridItem>
                              </>
                            ))}
                          </Grid>
                        ) : (
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent={"center"}
                            alignItems="center"
                            py="5"
                          >
                            <Image
                              src={medicationSchedule}
                              boxSize={"130px"}
                            ></Image>
                            <Box h="10px" />
                            <Heading as="h6" fontSize={"16px"}>
                              No Medication Schedules
                            </Heading>
                            <Box h="10px" />

                            <Box textAlign={"center"} maxW="480px">
                              <Text color="paragraph">
                                Proceed to create a medication schedule on our
                                mobile app
                              </Text>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>

                <Box
                  width={{ base: "100%", lg: "50%" }}
                  bg="white"
                  shadow="lg"
                  rounded={"lg"}
                  p="3"
                >
                  <Box position={"relative"}>
                    <DayPicker
                      style={{
                        width: "100%",
                      }}
                      mode="single"
                      selected={selected}
                      onSelect={setSelected}
                      footer={""}
                      onDayClick={(v) => {
                        // moment(v).format("");
                        var v1 = moment(v).format("YYYY-MM-DD");
                        var li = loggedSchedulesOriginal.filter(
                          (l) => l.schedule_date == v1
                        );
                        setLoggedSchedules(li);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      }
    />
  );
}

export default MedicationSchedulePage;
