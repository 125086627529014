import { Image } from "@chakra-ui/react";
import { isAudio, isDoc, isImage, isPdf, isVideo } from "../../utils/utils";
import { pdfIcon, wordIcon } from "../../images";

export default function RenderFile({ message }) {
    return (
        <>
            {
                isImage(message?.fileURL) ? (
                    <Image src={message?.fileURL} h={64} />
                ) : (
                    isVideo(message?.fileURL)
                    ?
                    <video style={{
                        height : "300px"
                    }} height="100" controls>
                    <source src={message?.fileURL} type="video/mp4"></source>
                    </video>
                    :
                    isAudio(message?.fileURL) 
                    ?
                    <audio controls >
                        <source src={message?.fileURL} type="audio/ogg"></source>
                    </audio>
                    :
                    isPdf(message?.fileURL)
                    ?
                    <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline" }}
                        href={message?.fileURL}
                    >
                        <Image src={pdfIcon} boxSize={"104px"} objectFit={"contain"} alt={message?.timestamp} />
                    </a>
                    :
                    isDoc(message?.fileURL)
                    ?
                    <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline" }}
                        href={message?.fileURL}
                    >
                        <Image src={wordIcon} boxSize={"104px"} objectFit={"contain"} alt={message?.timestamp} />
                    </a>
                    :
                    <a
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline" }}
                        href={message?.fileURL}
                    >
                        {message?.fileURL}
                    </a>
                )
            }

        </>
    )
}