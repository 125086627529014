import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Divider,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import "./rsuite.css";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  calendary,
  finance,
  flagIconsy,
  noise,
  provider,
  reminderm,
  squarem,
  team,
  tribe,
  usercirclem,
  who,
  xIcon,
} from "../../images";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import NoTask from "../../components/dashboard/no_task";
import JornerCard from "../../components/dashboard/jorner_card";
import {
  ChevronRightIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/solid";
import AddJorner from "../../components/dashboard/add_jorner_card";
import TaskCard from "../../components/dashboard/task_card";
import CreateTaskModal from "./create_task_modal";
import { BellIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import {
  PencilSquareIcon,
  TrashIcon,
  CalendarDaysIcon,
  UserCircleIcon,
  FlagIcon,
  BellAlertIcon,
} from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import TaskCategory from "./task_categories_modal";
import WhenModalCalendar from "./when_modal_calendar";
import { DatePicker } from "rsuite";
import moment from "moment/moment";
import WhoModal from "./who_modal";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
} from "../../utils/http_utils";
import ReminderModal from "./reminder_modal";
import ls from "localstorage-slim";
import { ValidDate } from "../../utils/utils";

function CreateTask() {
  const [tasks, setTasks] = useState([1, 2, 3, 4, 5, 6]);
  const [tasktype, setTasktype] = useState("");
  const [selectedCalendar, setSelectedCalendar] = useState("");
  const [coCaregivers, setCoCaregivers] = useState([]);
  const [selectedCaregivers, setselectedCaregivers] = useState([]);
  const [selectedReminder, setSelectedReminder] = useState("");
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const [remindersConstants, setRemindersConstants] = useState([
    { value: 5, text: "5 minutes before the due time" },
    { value: 30, text: "30 minutes before the due time" },
    { value: 60, text: "1 hour before the due time" },
    { value: 60 * 5, text: "5 hour before the due time" },
    { value: 60 * 24, text: "24 hour before the due time" },
  ]);
  const toast = useToast();

  const [categoryConstants, setCategoryConstants] = useState([
    "Cleaning and Chores",
    "Groceries",
    "Errands",
    "Bills",
    "School Pick Up and Drop Off",
    "Appointments",
    "Forms",
    "Medications",
  ]);
  const [showWhen, setShowWhen] = useState(false);
  const [showDueDate, setshowDueDate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDueDate, setSelectedDueDate] = useState("");
  const navigate = useNavigate();

  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();
  const {
    isOpen: isOpenReminder,
    onOpen: onOpenReminder,
    onClose: onCloseReminder,
  } = useDisclosure();

  useEffect(() => {
    console.log(user);
    getCocaregivers();
  }, []);

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const finishSelectionType = (arr) => {
    // navigate('/task/create')
    setTasktype(arr);
    // setValue('strength', arr)
  };
  const formik = useFormik({
    initialValues: {
      task_name: "",
      task_description: "",
      category: "",
      calendar: "",
      due_date: "",
      who: "",
      reminder: "",
    },
    onSubmit: async (values) => {
      
      if (values.task_name === "") {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `Please enter task name`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.task_description === "") {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `Please enter task description`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.category === "") {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `Please select task category`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.calendar === "") {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `Please select task date`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.due_date === "") {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `Please select task due date`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.who === "") {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `Please select task who's in charge`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.reminder === "") {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `Please select reminder`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      // setIsLoading(true)
      var remind = values.reminder;
      let now = moment();
      let cal = moment(values.calendar)
      if(now > cal){
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `You cannot create Task with date in the past`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      let cal2 = moment(values.due_date)
      if (now > cal2) {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `You cannot create Task with date in the past`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      
      var when = moment(values.calendar);
      var dd = moment(values.due_date);
      var duedate_reminder = moment(values.due_date)
        .subtract(values.reminder, "minutes")
        .format("YYYY-MM-D H:mm:ss");
      var mdd = dd.format("YYYY-MM-D H:mm:ss");
      if(when > dd) {
        return toast({
          title: "Sorry, we hit a snag!.",
          description: `Due date cannot be before start date`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      var data = {
        name: values.task_name,
        descriptions: values.task_description,
        category: values.category,
        when_to_perform_task: when.format("YYYY-MM-D H:mm:ss"),
        due_date: mdd,
        reminder: duedate_reminder,
        reminder_in_min: values.reminder,
        guests: values.who.split(","),
      };
      // console.log(data)
      setIsLoading(true)

      var response = await httpPostWithToken("tasks", data);
      if (response.error) {
        setIsLoading(false);
        return toast({
          title: "Sorry, we hit a snag!",
          description: `${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setIsLoading(false);
        toast({
          title: "Task create added successfully",
          // description: `${response.error}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setTimeout(() => {
          navigate("/tasks");
        }, 1000);
      }
    },
  });

  const getCocaregivers = async () => {
    var j = await httpGetWithTokenRest("co_caregiver");
    if (j.status == "success") {
      console.log(j.data);
      var user = JSON.parse(sessionStorage.getItem("sj_user"));

      // console.log(user)
      let d = j.data;
      let u = user.user_id;

      let isUser = d.find((l)=> l.user_id == u);

      if(!isUser) d = [...d, user];
      setCoCaregivers(d);
    }
  };

  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      {/* tsak */}
      <Spacer height={"22px"} />
      <form onSubmit={formik.handleSubmit}>
        <Box position="relative">
          <Flex alignItems={"center"} justifyContent="space-between">
            <Box display="flex" alignItems={"center"}>
              <AppLink
                hasLeftIcon={true}
                leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
                shadow="none"
                padding="0"
                text=""
                background="transparent"
                color="black"
                width="fit-content"
                textDecoration="none"
                href="/tasks"
              />
              <Box width="2px" />
              <Heading
                color={"black"}
                fontSize={"20px"}
                fontWeight={"600"}
                as={"h3"}
              >
                Create Task
              </Heading>
            </Box>
            <Flex gap="3" alignItems={"center"}>
              <Button
                isLoading={isLoading}
                height="45px"
                padding="0 15px"
                borderRadius={"50px"}
                text="Save Task"
                background="primary"
                color="white"
                textDecoration="none"
                type="submit"
              >
                <Icon as={PlusIcon} fontSize="20px" />
                Save Task
              </Button>
            </Flex>
          </Flex>
          <Box h="10px" />

          <WhoModal
            isOpen={isOpenModal}
            onFinished={(v) => {
              formik.setFieldValue("who", v.join());
              setselectedCaregivers([]);
              var s = coCaregivers;
              var selectedCaregiversArr = [];
              v.map((item) => {
                var i = s.find((c) => c.user_id === item);
                selectedCaregiversArr = [...selectedCaregiversArr, i];
              });
              setselectedCaregivers(selectedCaregiversArr);
            }}
            constants={coCaregivers}
            selectedOriginal={[]}
            onClose={onCloseModal}
            activeList={[]}
          />

          <ReminderModal
            isOpen={isOpenReminder}
            constants={remindersConstants}
            selectedOriginal={formik.values.reminder}
            onFinished={(v) => {
              formik.setFieldValue("reminder", v);
              var s = remindersConstants;
              var i = s.find((c) => c.value === v);
              setSelectedReminder(i.text);
            }}
            onClose={onCloseReminder}
          />

          <TaskCategory
            isOpen={isOpenCreate}
            constants={categoryConstants}
            onFinished={(v) => {
              formik.setFieldValue("category", v);
            }}
            selectedOriginal={formik.values.category}
            onClose={onCloseCreate}
          />
          <DatePicker
            onOk={(value) => {
              var d = new Date(value);
              setSelectedCalendar(moment(d).format("MMMM Do YYYY, h:mm:ss a"));
              var date = moment(d).format("YYYY-MM-D H:mm:ss");
              formik.setFieldValue("calendar", date);
              setShowWhen(false);
            }}
            open={showWhen}
            format="yyyy-MM-dd HH:mm:ss"
          />

          <DatePicker
            onOk={(value) => {
              var d = new Date(value);
              setSelectedDueDate(moment(d).format("MMMM Do YYYY, h:mm:ss a"));
              var date = moment(d).format("YYYY-MM-D H:mm:ss");
              formik.setFieldValue("due_date", date);
              setshowDueDate(false);
            }}
            open={showDueDate}
            format="yyyy-MM-dd HH:mm:ss"
          />
          <Box
            onClick={() => {
              if (showWhen) {
                setShowWhen(false);
              }
            }}
            shadow={"sm"}
            background="white"
            padding="20px"
            borderRadius={"16px"}
          >
            <Box mb="2">
              <Input
                px={0}
                height={"50px"}
                id="task_name"
                name="task_name"
                _placeholder={{ color: "#C7CAD1", fontSize: "14px" }}
                type="text"
                border={"none"}
                focusBorderColor="none"
                placeholder="Enter task name"
                onChange={formik.handleChange}
                value={formik.values.task_name}
              />

              <Textarea
                px="0"
                _placeholder={{ color: "#C7CAD1", fontSize: "14px" }}
                border={"none"}
                placeholder="Enter task descriptions"
                focusBorderColor="none"
                value={formik.values.task_description}
                onChange={(e) => {
                  formik.setFieldValue("task_description", e.target.value);
                }}
              />

              <Box height="5px" />

              {formik.values.category == "" ? (
                ""
              ) : (
                <>
                  <Flex>
                    <Flex alignItems={"center"} width="100%">
                      <Icon as={Squares2X2Icon} fontSize="14px" />
                      <Box width="10px" />
                      <Text fontSize={"13px"}>{formik.values.category}</Text>
                    </Flex>

                    <Image
                      onClick={() => {
                        formik.setFieldValue("category", "");
                      }}
                      src={xIcon}
                      boxSize="20px"
                    />
                  </Flex>
                  <Box height="10px" />
                  <Divider color={"#EBECEF"} />
                  <Box height="10px" />
                </>
              )}

              {formik.values.calendar == "" ? (
                ""
              ) : (
                <>
                  <Flex>
                    <Flex alignItems={"center"} width="100%">
                      <Icon as={CalendarDaysIcon} fontSize="14px" />
                      <Box width="10px" />
                      <Text fontSize={"13px"}>{selectedCalendar}</Text>
                    </Flex>

                    <Image
                      onClick={() => {
                        formik.setFieldValue("calendar", "");
                      }}
                      src={xIcon}
                      boxSize="20px"
                    />
                  </Flex>
                  <Box height="10px" />
                  <Divider color={"#EBECEF"} />
                  <Box height="15px" />
                </>
              )}

              {formik.values.who == "" ? (
                ""
              ) : (
                <>
                  <Flex>
                    <Flex alignItems={"center"} width="100%">
                      <Icon as={UserCircleIcon} fontSize="14px" />
                      <Box width="10px" />
                      <Flex gap="2" alignItems={"center"}>
                        {selectedCaregivers.length == 1 ? (
                          <>
                            <Box
                              position={"relative"}
                              fontWeight="600"
                              color="white"
                              boxSize={"45px"}
                              borderRadius={"100%"}
                              display="flex"
                              background={"secondary"}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Text fontSize={"14px"}>
                                {selectedCaregivers[0].first_name.substring(
                                  0,
                                  1
                                )}
                                {selectedCaregivers[0].last_name.substring(
                                  0,
                                  1
                                )}
                              </Text>
                            </Box>
                            <Heading fontSize={"12px"}>
                              {selectedCaregivers[0].first_name}
                              {selectedCaregivers[0].last_name}
                            </Heading>
                          </>
                        ) : (
                          selectedCaregivers.map((item, i) => (
                            <Box
                              position={"relative"}
                              fontWeight="600"
                              color="white"
                              boxSize={"45px"}
                              borderRadius={"100%"}
                              display="flex"
                              background={"secondary"}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Text fontSize={"14px"}>
                                {item.first_name.substring(0, 1)}
                                {item.last_name.substring(0, 1)}
                              </Text>
                            </Box>
                          ))
                        )}
                      </Flex>
                    </Flex>

                    <Image
                      onClick={() => {
                        formik.setFieldValue("who", "");
                        setselectedCaregivers([]);
                      }}
                      src={xIcon}
                      boxSize="20px"
                    />
                  </Flex>

                  <Box height="10px" />
                  <Divider color={"#EBECEF"} />
                  <Box height="10px" />
                </>
              )}

              {formik.values.due_date == "" ? (
                ""
              ) : (
                <>
                  <Flex>
                    <Flex alignItems={"center"} width="100%">
                      <Image src={flagIconsy} boxSize="14px" />
                      <Box width="10px" />
                      <Text fontSize={"13px"}>{selectedDueDate}</Text>
                    </Flex>

                    <Image
                      onClick={() => {
                        formik.setFieldValue("due_date", "");
                      }}
                      src={xIcon}
                      boxSize="20px"
                    />
                  </Flex>

                  <Box height="10px" />
                  <Divider color={"#EBECEF"} />
                  <Box height="10px" />
                </>
              )}
              {formik.values.reminder == "" ? (
                ""
              ) : (
                <>
                  <Flex>
                    <Flex alignItems={"center"} width="100%">
                      <Icon as={BellIcon} fontSize="14px" />
                      <Box width="10px" />
                      <Text fontSize={"13px"}>{selectedReminder}</Text>
                    </Flex>

                    <Image
                      onClick={() => {
                        formik.setFieldValue("reminder", "");
                      }}
                      src={xIcon}
                      boxSize="20px"
                    />
                  </Flex>

                  <Box height="10px" />
                  <Divider color={"#EBECEF"} />
                  <Box height="10px" />
                </>
              )}
            </Box>
            <Box h="10px" />
            <Box display="flex" flexWrap={"wrap"} gap="2">
              {formik.values.category == "" ? (
                <Button
                  onClick={onOpenCreate}
                  fontSize={"13px"}
                  background={"white"}
                  border="1px solid grey"
                  borderRadius={"50px"}
                >
                  {" "}
                  <Image src={squarem} boxSize="18px" objectFit={"contain"} />
                  &nbsp; Category
                </Button>
              ) : (
                ""
              )}
              {formik.values.calendar == "" ? (
                <Button
                  onClick={() => {
                    setShowWhen(true);
                    // setIsCalendarOpen(true)
                  }}
                  fontSize={"13px"}
                  background={"white"}
                  border="1px solid grey"
                  borderRadius={"50px"}
                >
                  {" "}
                  <Image src={calendary} boxSize="18px" objectFit={"contain"} />
                  &nbsp; When?
                </Button>
              ) : (
                ""
              )}

              {formik.values.due_date == "" ? (
                <Button
                  onClick={() => {
                    setshowDueDate(true);
                    // setIsCalendarOpen(true)
                  }}
                  fontSize={"13px"}
                  background={"white"}
                  border="1px solid grey"
                  borderRadius={"50px"}
                >
                  {" "}
                  <Image
                    src={flagIconsy}
                    boxSize="18px"
                    objectFit={"contain"}
                  />
                  &nbsp; Due date
                </Button>
              ) : (
                ""
              )}

              {formik.values.who == "" ? (
                <Button
                  onClick={onOpenModal}
                  fontSize={"13px"}
                  background={"white"}
                  border="1px solid grey"
                  borderRadius={"50px"}
                >
                  {" "}
                  <Image
                    src={usercirclem}
                    boxSize="18px"
                    objectFit={"contain"}
                  />
                  &nbsp; Who's in charge
                </Button>
              ) : (
                ""
              )}
              {formik.values.reminder == "" ? (
                <Button
                  onClick={onOpenReminder}
                  fontSize={"13px"}
                  background={"white"}
                  border="1px solid grey"
                  borderRadius={"50px"}
                >
                  {" "}
                  <Image src={reminderm} boxSize="18px" objectFit={"contain"} />
                  &nbsp; Reminder
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>
      </form>
      <Spacer height={"22px"} />
    </Box>
  );
}

export default CreateTask;
