import React from 'react'
import { Box, Image, Link, Heading, Text, Flex, Spacer, Icon } from '@chakra-ui/react'
import AppLink from '../link'
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import { notask} from '../../images';

function NoTask() {
  return (
   
        <Box shadow={"sm"} background="white" padding="30px" borderRadius={"16px"} display="flex" flexDirection="column" justifyContent={"center"} alignItems="center">
          <Image src={notask} boxSize={"105px"} ></Image>
          <Box h="10px" />
          <Heading as="h6" fontSize={"16px"}>No Tasks</Heading>
          <Box h="10px" />

          <Box textAlign={"center"} maxW="480px">
          <Text color="paragraph">
          The tasks tool to help you to stay organized and helps you manage your day-to-day activity.
          </Text>
          <Box h="10px" />

          <AppLink href='/task/create' text='Create task' color='primary' padding='0' height='fit-content' background='transparent' fontWeight='500' shadow='none' />
          </Box>
        </Box>

  )
}

export default NoTask