import { Box, Link, Flex, Heading, Image, Text, Icon } from '@chakra-ui/react'
import React from 'react'
import { Link as ReachLink } from "react-router-dom";
import {PlusIcon} from "@heroicons/react/24/solid";

function AddJorner() {
  return (
    <Link textDecor={"none"} as={ReachLink} to="/add-jorner">
    <Box width={{base : "100%" , md : "215px"}} h={"100%"} padding="12px 14px" alignItems="center" justifyContent={"center"} borderRadius={"16px"} border="1px solid #EBECEF" display="flex" shadow="sm" background={"white"}>
        <Flex gap="3" flexDirection={"column"} justifyContent={"center"} alignItems="center" >
        <Link background={"gray"} as={ReachLink} to="" borderRadius={"100%"} width="34px" height="34px" display={"flex"} justifyContent="center" alignItems="center" >
          <Icon as={PlusIcon} fontSize={"18px"} />
        </Link>
        <Heading fontWeight={"500"} fontSize="15px">Add Jorner</Heading>
        </Flex>
    </Box>
    </Link>
  )
}

export default AddJorner