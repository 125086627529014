import {configureStore} from "@reduxjs/toolkit";
import sidebarReducer from "./reducers/sidebar";
import authReducer from "./reducers/authSlice";
const store = configureStore({
  reducer : {
    sidebar : sidebarReducer,
    auth: authReducer
  }
  
})


export default store;