import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  useCheckboxGroup,
  useToast,
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Select,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import {
  ValidDate,
  capitalizeFirstWord,
  checkArraySimilarities,
} from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { useFormik } from "formik";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import { DatePicker } from "rsuite";
import PhoneNumberInput2 from "../../components/phoneNumberInput2";
import {
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { useEffect } from "react";
import moment from "moment";

export default function CreateAppointmentModal({
  isOpen,
  coGiverConstants,
  onClose,
  onFinished,
  appointment,
}) {
  const finishSelection = (value = "close") => {
    onClose();
    onFinished(value);
  };

  const insuranceTypes = [
    // "Healthcare Insurance",
    // "Life Insurance",
    // "Long-Term Disability Coverage",
    // "Auto Insurance",
    "Health",
    "Dental",
    "Vision",
    "Short Term Disability",
    "Other",
  ];

  const toast = useToast();
  const formik = useFormik({
    initialValues: {
      insurance_type: "",
    },
    onSubmit: async (values) => {
      if (values.insurance_type == "") {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Please select insurance type`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      finishSelection(values.insurance_type);
    },
  });
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p="3">
        <ModalBody marginBottom={"0"}>
          <Box>
            <Flex alignItems={"center"} justifyContent="space-between">
              <Heading fontSize={"20px"}>Insurance Type</Heading>
              <Button onClick={() => finishSelection()}>close</Button>
            </Flex>

            <Box mt={"3"} />
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl mt={5} fontWeight={600}>
                  <FormLabel htmlFor="insurance_types">
                    Insurance Type
                  </FormLabel>
                  <Select
                    focusBorderColor="#E3E4E8"
                    border={"1px solid #E3E4E8"}
                    height="50px"
                    id="insurance_types"
                    onChange={(e) => {
                      console.log(e);
                      formik.setFieldValue("insurance_type", e.target.value);
                    }}
                    name="role"
                    value={formik.values.insurance_type}
                    placeholder=""
                  >
                    <option value="">Select</option>
                    {insuranceTypes.map((types, i) => (
                      <option key={i} value={types}>
                        {types}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  isLoading={formik.isSubmitting}
                  type="submit"
                  h="50px"
                  borderRadius={"60px"}
                  colorScheme="purple"
                  width="full"
                >
                  Proceed
                </Button>
                <Box h="100px" />
              </VStack>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
