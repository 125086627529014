import {
  Box,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  useToast,
  Icon,
} from "@chakra-ui/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const isValidEmail = (email) => EMAIL_REGEXP.test(email);

/**
 * Represents an email added to the list. Highlighted with a close button for removal.
 */
export const Chip = ({ email, onCloseClick }) => (
  <Tag key={email} borderRadius="full" variant="solid" bg={"#FA945C"}>
    <TagLabel>{email}</TagLabel>
    <TagCloseButton
      onClick={() => {
        onCloseClick(email);
      }}
    />
  </Tag>
);

/**
 * A horizontal stack of chips. Like a Pringles can on its side.
 */
export const ChipList = ({ emails = [], onCloseClick }) => (
  <Wrap spacing={1} mt={3}>
    {emails?.map((email) => (
      <Chip email={email} key={email} onCloseClick={onCloseClick} />
    ))}
  </Wrap>
);

/**
 * Form field wrapper.
 */
export const ChipEmailInput = ({onClickF, isEmail, ...rest }) => (
  <Box position="relative" style={{
    postion : "relative"
  }}>
    <Input type="text" {...rest} borderColor={"#E3E4E8"} />
    <Box zIndex={"1212121"} cursor={"pointer"} onClick={onClickF} position={"absolute"} right="10px" top="12px">
      <Icon as={PlusIcon} fontSize={"18px"} color={"red"} top="0px" />
    </Box>
  </Box>
);

/**
 * Contains presentation, logic and state for inputting emails and having them saved as chips.
 */
export const EmailChipInput = ({
  initialEmails = [],
  isEmail = true,
  callBackFunc = () => {},
  placeholder = "Enter email addresses",
  updatePlus=null
}) => {
  const [inputValue, setInputValue] = useState("");
  const [emails, setEmails] = useState(initialEmails);
  const toast = useToast();
  // Checks whether we've added this email already.
  const emailChipExists = (email) => emails.includes(email);

  // Add an email to the list, if it's valid and isn't already there.
  const addEmails = (emailsToAdd) => {
    let validatedEmails = emailsToAdd;

    if (isEmail) {
      validatedEmails = emailsToAdd
        ?.map((e) => e.trim())
        .filter((email) => isValidEmail(email) && !emailChipExists(email));
    } else {
      // validatedEmails = emailsToAdd?.map((e) => e.trim())
      // .filter((email) => !emailChipExists(email));
      // console.log("here tipe");
    }

    const newEmails = [...emails, ...validatedEmails];

    setEmails(newEmails);
    callBackFunc(newEmails);
    setInputValue("");
    if(updatePlus) {
        updatePlus(true)
    }
  };

  // Remove an email from the list.
  const removeEmail = (email) => {
    const index = emails.findIndex((e) => e === email);
    if (index !== -1) {
      const newEmails = [...emails];
      newEmails.splice(index, 1);
      setEmails(newEmails);
      callBackFunc(newEmails);
    }
  };

  // Save input field contents in state when changed.
  const handleChange = (e) => {
    if(updatePlus) {
      if(e.target.value !== ""){
        updatePlus(false)
      } else{
        updatePlus(true)
      }
    }
    setInputValue(e.target.value);
  };

  // Validate and add the email if we press tab, enter or comma.
  const handleKeyDown = (e) => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();
      if (isEmail) {
        if (!isValidEmail(inputValue)) {
          toast({
            title: "Invalid email address.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }

      addEmails([inputValue]);
    }
  };
  const handleAddManual = (e) => {
      e.preventDefault();
      if (isEmail) {
        if (!isValidEmail(inputValue)) {
          toast({
            title: "Invalid email address.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }

      addEmails([inputValue]);
  };

  // Split and add emails when pasting.
  const handlePaste = (e) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData("text");
    const pastedEmails = pastedData.split(",");
    addEmails(pastedEmails);
  };

  const handleCloseClick = (email) => {
    removeEmail(email);
  };

  return (
    <>
      <ChipEmailInput
        placeholder={placeholder}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={inputValue}
        onClickF={handleAddManual}
        isEmail={isEmail}
      />
      <ChipList emails={emails} onCloseClick={handleCloseClick} />
    </>
  );
};
