import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { ellipse, healthcare, homeIcon1, homeIcon2 } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { PlusIcon } from "@heroicons/react/24/solid";
import { color } from "framer-motion";

export default function NoteTag({
  isOpen,
  onClose,
  selectedOriginal,
  onFinished,
  type = "create",
}) {
  const [selected, setselected] = React.useState(selectedOriginal);
  const finishSelection = () => {
    onFinished(selected);
    onClose();
  };
  useEffect(() => {
    setselected(selectedOriginal);
  }, [selectedOriginal]);
  const [tag, setTag] = React.useState("");
  const typeTag = (e) => {
    const key = e.key;
    let t = tag;
    console.log(e.keyCode);
    if (key === "," || e.keyCode === 13) {
      if (tag !== "") newTag(t);
    }
  };

  const newTag = (value) => {
    value = value.replace(",", "");
    // console.log(value);
    var s = selected;
    s = [...s, value];
    setselected(s);
    // console.log("s", s);
    setTag("");
    onFinished(s);
  };

  const removeTag = (index) => {
    var s = selected;
    // console.log("removed");
    var newS = [];
    s.map((item, i) => {
      if (i !== index) {
        newS = [...newS, item];
      }
    });

    setselected(newS);
    onFinished(newS);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader>
          <Flex alignItems={"center"} gap={2}>
            <FaChevronLeft />
            <Text>{type == "create" ? "Tags" : "Edit Tags"}</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <ModalBody marginBottom={"0"} minHeight={"300px"}>
          <Divider color={"#EBECEF"} />
          <Box height={"10px"} />
          <InputGroup>
            <InputLeftElement pointerEvents="none">
            </InputLeftElement>
            <Input
              placeholder="Type here"
              key={"searchList"}
              value={tag}
              borderRadius={100}
              onChange={(e) => setTag(e.target.value)}
              onKeyUp={typeTag}
              borderColor={"#E3E4E8"}
            />
          </InputGroup>
          <Text fontSize={"12px"} color={"primary"}>Use comma, enter or tab to save each tags.</Text>
          <Box height={"15px"} />
          <Box>
            <Flex flexWrap={"wrap"} gap={"15px"}>
              {selected.map((item, i) => (
                <Box
                  onClick={() => removeTag(i)}
                  display={"flex"}
                  gap={"5px"}
                  cursor={"pointer"}
                  alignItems={"center"}
                  borderRadius={"15px"}
                  bg={"#E5EDFF"}
                  padding={"2"}
                  fontSize={"sm"}
                  fontWeight={"500"}
                  width={"max-content"}
                >
                  <Image boxSize={"12px"} src={ellipse} />
                  {item}
                </Box>
              ))}
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Box w={"160px"} float={"right"}>
            <Button
              mt={8}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => {
                onClose();
                onFinished(selected, "save");
              }}
            >
              Save
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
