import {
  Box,
  Heading,
  Flex,
  Spacer,
  Icon,
  Button,
  useDisclosure,
  Input,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import AudioPlayer from "react-h5-audio-player";
import { Link as ReachLink, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CChartDoughnut } from "@coreui/react-chartjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CreateTagModal from "./create_tag_modal";

import { ChevronLeftIcon } from "@chakra-ui/icons";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import ls from "localstorage-slim";
import { notesRoute } from "../../utils/allRoutes";
import DeleteNote from "./delete_note_modal";
import AppLink from "../../components/link";
import { isValidUrl } from "../../utils/utils";
import { waves } from "../../images";
import Waveform from "./waveform";
import axios from "axios";

function CreateNote() {
  const [value, setValue] = useState("");
  const [note, setNote] = useState({});
  const [noteTitle, setNoteTitle] = useState("");
  const user = JSON.parse(sessionStorage.getItem("sj_user"));

  const toast = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const params = useParams();
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  useEffect(() => {
    // alert(window.location.href);
    fetchConstants();
  }, []);

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const finishSelectionType = async (arr, type = "pending") => {
    setTags(arr);
    if (type == "save") {
      if (params.id) {
        var response = await httpPutWithTokenRest("notes/" + params.id, {
          tags: arr,
        });
        if (response.error) {
          return toast({
            title: "Sorry, we hit a snag!",
            description: `${response.error}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          return toast({
            title: "Note tags updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
  };
  const onChange = (value) => {
    console.log(value);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
      tags: [],
    },
    onSubmit: async (values) => {
      if (values.title === "") {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Please enter note title`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (value === "") {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Please enter Note content`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      var data = {
        title: values.title,
        content: value,
        tags: tags,
      };
      // console.log(data)
      var response = null;
      if (params.id) {
        response = await httpPutWithTokenRest("notes/" + params.id, data);
      } else {
        response = await httpPostWithToken("notes", data);
      }
      if (response.error) {
        setIsLoading(false);
        return toast({
          title: "Sorry, we hit a snag!",
          description: `${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setIsLoading(false);
        toast({
          title: params.id ? "Schedule updated" : "Schedule added successfully",
          // description: `${response.error}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setNoteTitle(formik.values.title);
        setTimeout(() => {
          if (!params.id) navigate(notesRoute);
        }, 1000);
      }
    },
  });
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "table"],
      ["clean"],
    ],
  };

  const fetchConstants = async () => {
    if (!params.id) {
      setIsLoading(false);
      return false;
    }
    var j = await httpGetWithTokenRest("notes/" + params.id);
    if (j.status == "success" || j.message == "done") {
      console.log(j.data);
      setNote(j.data);
      setIsLoading(false);
      setNoteTitle(j.data.title);
      formik.setFieldValue("title", j.data.title);
      setValue(j.data.content);
      finishSelectionType(j.data.tags);
      console.log(j.data.tags);
    }
  };

  const transcribe = async () => {
    setIsFileLoading(true)
    axios({
      method: 'GET',
      url: note.content,
      responseType: 'arraybuffer',
    }).then((response) => {
      const file = new Blob([response.data]);
      const formData = new FormData()

      formData.append('file', file, 'file.mp3');
      formData.append("model", "whisper-1")
      //formData.append("response_format", "text")
      var token = "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"
      axios({
        method: "POST",
        url: 'https://api.openai.com/v1/audio/transcriptions',
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async (resp) => {
        // console.log(resp)
        var newNote = resp.data.text;
        var data = {
          title: note.title,
          content: newNote,
          tags: note.tags,
        };

        var res = await httpPostWithToken("notes", data);

        if (res.error) {
          setIsFileLoading(false)
          return toast({
            title: "Sorry, we hit a snag!",
            description: `${res.error}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setIsFileLoading(false)

          toast({
            title: "Schedule created successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      }).catch(() => {
        setIsFileLoading(false)
        return toast({
          title: "Sorry, we hit a snag!",
          description: `An error while Transcribing your Note`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
    }).catch(() => {
      setIsFileLoading(false)
      return toast({
        title: "Sorry, we hit a snag!",
        description: `An error while Transcribing your Note`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    })

  }
  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      {!isLoading && (
        <CreateTagModal
          isOpen={isOpenCreate}
          onClose={onCloseCreate}
          selectedOriginal={tags}
          onFinished={finishSelectionType}
        />
      )}
      {/* tsak */}
      <Spacer height={"22px"} />
      <DeleteNote
        onClose={() => {
          onCloseDelete();
        }}
        isOpen={isOpenDelete}
        note={params?.id}
        onFinished={() => { }}
      />
      <form onSubmit={formik.handleSubmit}>
        <Box position="relative">
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            alignItems={{ base: "start", md: "center" }}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems={"center"}>
              <AppLink
                hasLeftIcon={true}
                leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
                shadow="none"
                padding="0"
                text=""
                background="transparent"
                color="black"
                width="fit-content"
                textDecoration="none"
                href={
                  window.location.href.includes("source=ai")
                    ? "/ai-notes"
                    : notesRoute
                }
              />
              <Box width="2px" />
              <Heading
                color={"black"}
                fontSize={{ base: "16px", md: "20px" }}
                fontWeight={"600"}
                as={"h3"}
              >
                {params.id ? noteTitle : "Create Note"}
              </Heading>
            </Box>
          </Flex>
          <Box h="10px" />

          <Box>
            <Flex
              flexDirection={{ base: "column-reverse", md: "row" }}
              justifyContent={{ base: "space-between", sm: "end" }}
            >
              <Input
                px={0}
                height={"50px"}
                id="title"
                name="title"
                disabled={isValidUrl(note.content)}
                _disabled={{
                  color: "#000",
                  fontSize: "16px",
                }}
                _placeholder={{ color: "#C7CAD1", fontSize: "14px" }}
                type="text"
                border={"none"}
                focusBorderColor="none"
                placeholder="Enter Note Title"
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              <Flex alignItems={"center"}>
                {params.id && note.note_type == "voice" ? (
                  <Button
                    isLoading={isFileLoading}
                    height="45px"
                    padding="10px 15px"
                    borderRadius={"50px"}
                    text="Summarize with AI"
                    background="primary"
                    color="white"
                    size={"sm"}
                    textDecoration="none"
                    type="button"
                    onClick={transcribe}
                  >
                    Summarize with AI
                  </Button>
                ) : (
                  <Button
                    isLoading={formik.isSubmitting}
                    height="45px"
                    w={"100px"}
                    padding="10px 15px"
                    borderRadius={"50px"}
                    text="Save"
                    background="primary"
                    color="white"
                    textDecoration="none"
                    type="submit"
                  >
                    Save
                  </Button>
                )}
                <Menu>
                  <MenuButton
                    background="primary"
                    border={"1px solid #EBECEF"}
                    color="white"
                    fontSize={"13px"}
                    as={Button}
                    borderRadius="60px"
                    fontWeight={600}
                  >
                    :
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={onOpenCreate}>Tags</MenuItem>
                  </MenuList>
                </Menu>
                <AppLink
                  hasLeftIcon={true}
                  onclick={onOpenDelete}
                  shadow="none"
                  height="40px"
                  leftIcon={<Icon as={TrashIcon} fontSize="20px" />}
                  padding="0 12px"
                  text=""
                  background="#"
                  color="red"
                  textDecoration="none"
                  href="#?"
                />
              </Flex>
            </Flex>
            <Box h={"10px"} />
            {!isLoading && (
              <>
                {params.id && isValidUrl(note.content) ? (
                  <>
                    <Waveform
                      url={
                        window.location.host.includes("localhost")
                          ? "/mp3.mp3"
                          : note.content
                      }
                    />
                  </>
                ) : (
                  <CKEditor
                    editor={ClassicEditor}
                    data={
                      value == ""
                        ? '<figure style="width : 720px; min-width : 720px;" class="table"><table style="min-width : 720px; width : 100%"><tbody><tr><td></td><td>Mon</td><td>Tue</td><td>Wed</td><td>Thur</td><td>Fri</td><td>Sat</td><td>Sun</td></tr><tr><td>6am</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>7am</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>8am</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>9am</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>10am</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>11am</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>12am</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>1pm</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>2pm</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>3pm</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>4pm</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>5pm</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>6pm</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>7pm</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>8pm</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></figure>'
                        : value
                    }
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log("data", data);
                      setValue(data);
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </form>

      <Spacer height={"22px"} />
    </Box>
  );
}

export default CreateNote;
