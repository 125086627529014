import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import AppTheme from "./theme";
import Home from "./pages/landing/home";
import "./theme.css";
import Signup from "./pages/auth/signup";
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css"; // Specify weight and style
import OtpVerification from "./pages/auth/otpVerification";
import OnboardingStart from "./pages/onboarding/onboardingStart";
import DashboardLayout from "./layout/dashboard";
import {
  AddJornerPage,
  AddJornerPage2,
  DashboardHome,
  Jorner,
  Jorners,
} from "./pages";
import Login from "./pages/auth/login";
import ForgetPassword from "./pages/auth/forgetPassword";
import ForgetPasswordOtpVerification from "./pages/auth/forgetPasswordOtpVerification";
import {
  singleProviderDetailsRoute,
  singleProvidersRoute,
  communityRoute,
  singleCommunityRoute,
  profileRoute,
  profileNextRoute,
  EditJornerRoute,
  addNoteRoute,
  notesRoute,
  editNoteRoute,
  remindersRoute,
  appointmentsRoute,
  viewAppointmentRoute,
  medicationsRoute,
  jornerMedicationsRoute,
  aiRoute,
  medicationScheduleRoute,
  insurancesRoute,
  viewinsuranceRoute,
  plansRoute,
  subscriptionRoute,
  subscribeRoute,
  documentRoute,
  documentFolderRoute,
} from "./utils/allRoutes";

import {
  AddJornerRoute,
  AddJornerRoute2,
  calendarRoute,
  CreateTaskRoute,
  dashboardRoute,
  EditTaskRoute,
  forgetPasswordRoute,
  homeRoute,
  jornersRoute,
  loginRoute,
  onBoardingRoute,
  onboardingStartRoute,
  otpForgetPasswordRoute,
  otpRoute,
  providersRoute,
  resetPasswordRoute,
  signupRoute,
  singleJornerRoute,
  TaskRoute,
  ViewTaskRoute,
} from "./utils/allRoutes";
import ResetPassword from "./pages/auth/resetPassword";
import Onboarding from "./pages/onboarding/onboarding";
import Calendar from "./pages/calendar/calendar";
import Providers from "./pages/providers/providers";
import SingleProvider from "./pages/providers/singleProvider";
import SingleProviderDetails from "./pages/providers/singleProviderDetails";
import Community from "./pages/community/community";
import SingleCommunity from "./pages/community/singleCommunity";
import ProtectedRoute from "./routes/authRoute";
import Profile from "./pages/profile/profile";
import { CreateTask, EditTask, TaskPage, ViewTask } from "./pages/tasks";
import { CreateNote, NotesPage } from "./pages/notes";
import { RemindersPage } from "./pages/reminders";
import AppointmentsPage from "./pages/appointments/all_appointments";
import { ViewAppointment } from "./pages/appointments";
import { JornerMedicationPage, MedicationPage } from "./pages/medications";
import AiNotesPage from "./pages/notes/all_ai_voice";
import MedicationSchedulePage from "./pages/medications/medication_schedules";
import InsurancePage from "./pages/insurance/all_insurance";
import ViewInsurance from "./pages/insurance/insurance";
import { PlanPage, SubscriptionsPage } from "./pages/plans";
import PlanRestricted from "./components/dashboard/plan_restricted";
import SubscribeOut from "./pages/auth/subscribe";
import Documents from "./pages/documents/documents";
import useAuth from './hooks/useAuth';
import useIdleTimer from './hooks/useIdleTimer';

function App() {
  useAuth();
  useIdleTimer();

  // console.clear();
  return (
    <ChakraProvider theme={AppTheme}>
      <Router>
        <Routes>
          {/* Auth routes */}
          <Route path={homeRoute} element={<Home />} />
          <Route path={loginRoute} element={<Login />} />
          <Route path={forgetPasswordRoute} element={<ForgetPassword />} />
          <Route
            path={otpForgetPasswordRoute}
            element={<ForgetPasswordOtpVerification />}
          />
          <Route path={resetPasswordRoute} element={<ResetPassword />} />
          <Route path={signupRoute} element={<Signup />} />
          <Route path={otpRoute} element={<OtpVerification />} />

          {/* Onboarding routes */}
          <Route path={onboardingStartRoute} element={<OnboardingStart />} />
          <Route path={onBoardingRoute} element={<Onboarding />} />

          {/* <Route path={dashboardRoute} element={<DashboardLayout element={<DashboardHome />} />} /> */}
          <Route
            path={TaskRoute}
            element={<DashboardLayout element={<TaskPage />} />}
          />
          <Route
            path={ViewTaskRoute}
            element={<DashboardLayout element={<ViewTask />} />}
          />
          <Route
            path={EditTaskRoute}
            element={<DashboardLayout element={<EditTask />} />}
          />
          <Route
            path={CreateTaskRoute}
            element={<DashboardLayout element={<CreateTask />} />}
          />
          <Route
            path={jornersRoute}
            element={<DashboardLayout element={<Jorners />} />}
          />
          <Route
            path={singleJornerRoute}
            element={<DashboardLayout element={<Jorner />} />}
          />
          <Route
            path={AddJornerRoute}
            element={<DashboardLayout element={<AddJornerPage />} />}
          />
          <Route
            path={EditJornerRoute}
            element={<DashboardLayout element={<AddJornerPage />} />}
          />
          <Route
            path={AddJornerRoute2}
            element={<DashboardLayout element={<AddJornerPage2 />} />}
          />
          <Route path={calendarRoute} element={<Calendar />} />
          <Route path={providersRoute} element={<Providers />} />
          <Route path={subscribeRoute} element={<SubscribeOut />} />

          <Route
            path={onboardingStartRoute}
            element={
              <ProtectedRoute>
                <OnboardingStart />
              </ProtectedRoute>
            }
          />
          <Route
            path={onBoardingRoute}
            element={
              <ProtectedRoute>
                <Onboarding />
              </ProtectedRoute>
            }
          />

          <Route
            path={dashboardRoute}
            element={
              <ProtectedRoute>
                <DashboardLayout element={<DashboardHome />} />
              </ProtectedRoute>
            }
          />
          <Route
            path={calendarRoute}
            element={
              <ProtectedRoute>
                <Calendar />
              </ProtectedRoute>
            }
          />
          <Route
            path={providersRoute}
            element={
              <ProtectedRoute>
                <Providers />
              </ProtectedRoute>
            }
          />
          <Route
            path={singleProvidersRoute}
            element={
              <ProtectedRoute>
                <SingleProvider />
              </ProtectedRoute>
            }
          />
          <Route
            path={singleProviderDetailsRoute}
            element={
              <ProtectedRoute>
                <SingleProviderDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path={jornersRoute}
            element={
              <ProtectedRoute>
                <DashboardLayout element={<DashboardHome />} />
              </ProtectedRoute>
            }
          />

          <Route
            path={communityRoute}
            element={
              <ProtectedRoute>
                <Community />
              </ProtectedRoute>
            }
          />

          <Route
            path={singleCommunityRoute}
            element={
              <ProtectedRoute>
                <SingleCommunity />
              </ProtectedRoute>
            }
          />

          <Route
            path={profileRoute}
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path={profileNextRoute}
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          {/* medications */}
          <Route
            path={medicationsRoute}
            element={
              <ProtectedRoute>
                <MedicationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={jornerMedicationsRoute}
            element={
              <ProtectedRoute>
                <JornerMedicationPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={medicationScheduleRoute}
            element={
              <ProtectedRoute>
                <MedicationSchedulePage />
              </ProtectedRoute>
            }
          />

          <Route
            path={aiRoute}
            element={<DashboardLayout element={<AiNotesPage />} />}
          />
          <Route
            path={notesRoute}
            element={<DashboardLayout element={<NotesPage />} />}
          />

          <Route
            path={addNoteRoute}
            element={<DashboardLayout element={<CreateNote />} />}
          />

          <Route
            path={editNoteRoute}
            element={<DashboardLayout element={<CreateNote />} />}
          />

          <Route
            path={remindersRoute}
            element={<DashboardLayout element={<RemindersPage />} />}
          />

          <Route
            path={appointmentsRoute}
            element={<DashboardLayout element={<AppointmentsPage />} />}
          />
          <Route
            path={viewAppointmentRoute}
            element={<DashboardLayout element={<ViewAppointment />} />}
          />
          <Route
            path={insurancesRoute}
            element={<DashboardLayout element={<InsurancePage />} />}
          />
          <Route
            path={documentRoute}
            element={<DashboardLayout element={<Documents />} />}
          />
          <Route
            path={documentFolderRoute}
            element={<DashboardLayout element={<Documents />} />}
          />

          <Route
            path={viewinsuranceRoute}
            element={<DashboardLayout element={<ViewInsurance />} />}
          />

          <Route
            path={plansRoute}
            element={<DashboardLayout element={<PlanPage />} />}
          />

          <Route
            path={"/validate-payment"}
            element={<DashboardLayout element={<PlanPage />} />}
          />
          <Route
            path={subscriptionRoute}
            element={<DashboardLayout element={<SubscriptionsPage />} />}
          />
          <Route
            path={"/access-denied"}
            element={<DashboardLayout element={<PlanRestricted />} />}
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
