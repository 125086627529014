import React, { useEffect, useState } from 'react'
import { Box, Image, Link, Heading, Text, Flex, Spacer, Icon } from '@chakra-ui/react'
import AppLink from '../link'
import { noJorner } from '../../images';


function NoJorner({background="white", source="jorner", shadow="sm"}) {
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const [isCareChatMember, setIsCareChatMember] = useState(false);

  useEffect(() => {
      setIsCareChatMember(user.is_care_member);
  }, []);

  return (
    <Box shadow={shadow} background={background} padding="30px" borderRadius={"16px"} display="flex" flexDirection="column" justifyContent={"center"} alignItems="center">
      <Image src={noJorner} boxSize={"105px"} ></Image>
      <Box h="10px" />
      <Heading as="h6" fontSize={"16px"}>No Jorners</Heading>
      <Box h="10px" />

      <Box textAlign={"center"} maxW="480px">
      <Text color="paragraph">
        {
          source == "jorner" ? 
          "A jorner profile serves as a repository for your dependent’s info and can be shared on the go."
           : "No Medications for the selected Jorner" 
        }
      </Text>
      {
        !isCareChatMember 
        &&
      <>
      <Box h="10px" />
        {
          source == "jorner" 
          ?
          <AppLink href='/add-jorner' text='Add Jorners' color='primary' padding='0' height='fit-content' background='transparent' fontWeight='500' shadow='none' />
          :
          ""
        }

        {
          source == "medication" && 
          <AppLink href='/medications' text='Go back' color='primary' padding='0' height='fit-content' background='transparent' fontWeight='500' shadow='none' />
        }
      </>
    }
      </Box>

    </Box>

  )
}

export default NoJorner