import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../layout/dashboard";
import { Avatar, Box, Button, Divider, Flex, Spacer, Tag, Text } from "@chakra-ui/react";
import ls from "localstorage-slim";
import { FaEdit, FaUsers, FaWallet } from "react-icons/fa";
import { BsChevronRight, BsFillPeopleFill } from "react-icons/bs";
import { AiFillCloseCircle, AiFillFileText } from "react-icons/ai";
import { BiSolidLockAlt } from "react-icons/bi";
import JornerProfileOnboarding from "../onboarding/jornerProfileOnboarding";
import CareTeam from "../onboarding/careTeam";
import EditProfile from "./editProfile";
import PowerOfAttorney from "./powerOfAttorney";
import CarePlan from "./carePlan";
import ChangePassword from "./changePassword";
import { AppContext } from "../../redux/appContext";
import { useNavigate, useParams } from "react-router-dom";
import { ValidDate } from "../../utils/utils";
import moment from 'moment/moment'
import DeleteAccount from "./delete_account";
export default function Profile() {
  const { updateUser, activeUser, subscription } = useContext(AppContext);
  const [isCareChatMember, setIsCareChatMember] = useState(false);

  const [haveJorners, setHaveJorners] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [currentView, setCurrentView] = React.useState("");
  const [haveCaregivers, setHaveCaregivers] = React.useState(false);
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const userPermission = ls.get("sj_cocg_permission", { decrypt: true });

  const isCoCaregiver = user?.tag !== "cg";

  const params = useParams()
  const navigate = useNavigate()
  const updateCurrentStep = (step) => {
    setCurrentStep(step);
  };
  useEffect(()=> {
    setIsCareChatMember(user.is_care_member);
  }, [])
  useEffect(()=> {
    if(params.profileNext == "careplan") {
      setCurrentView("care-plan")
      renderView("care-plan")
    }

    if(params.profileNext == "careteam") {
      setCurrentView("caregiver")
      renderView("caregiver")
    }
  }, [])
  const renderView = (view) => {
    switch (view) {
      case "profile":
        return <EditProfile />;
      case "jorner":
        return (
          <JornerProfileOnboarding
            // currentStep={currentStep}
            updateCurrentStep={updateCurrentStep}
            updateHaveJorners={setHaveJorners}
          />
        );
      case "caregiver":
        return <CareTeam updateHaveCaregivers={setHaveCaregivers} />;
      case "care-plan":
        return <CarePlan />;
      case "power":
        return <PowerOfAttorney />;
      case "password":
        return <ChangePassword />;
      case "delete_account":
          return <DeleteAccount />;

      default:
        return <EditProfile />;
    }
  };
  return (
    <DashboardLayout
      element={
        <Box p={5} bg={"#FAF8FF"}>
          <Flex
            gap={{ base: 5, md: 3 }}
            display={{ base: "block", md: "flex" }}
          >
            <Box
              bg={"#fff"}
              borderRadius={16}
              p={4}
              w={{ base: "100%", lg: "30%" }}
            >
              <Box bg={"#F6F4FB"} borderRadius={16} p={2} boxShadow={"md"}>
                <Flex gap={3} p={2}>
                  <Avatar src={activeUser.avatar} size={"lg"} />
                  <Box gap={3}>
                    <Tag
                      bg={"primary"}
                      borderRadius={100}
                      color={"#fff"}
                      fontSize={12}
                      fontWeight={500}
                    >
                      {activeUser.family_name} Family
                    </Tag>
                    <Text fontSize={16} fontWeight={600}>
                      {activeUser.first_name} {activeUser.last_name} {activeUser.gender &&  '|'}
                      {activeUser.gender}
                    </Text>
                    <Text
                      fontSize={12}
                      fontWeight={400}
                      overflowWrap={"anywhere"}
                    >
                      {activeUser.email}
                    </Text>
                    {
                      subscription &&
                      <>
                      <Text fontSize={12} fontWeight={600}>
                        {subscription.plan_name} Plan
                      </Text>
                      {
                        ValidDate(subscription.end_date) ?
                        <>
                        <Text fontSize={12} color={"red"} fontWeight={600}>
                          Valid until: {moment(subscription.end_date).format("MMM Do, YYYY")}
                        </Text>
                        {!isCoCaregiver && <>
                        {subscription?.plan_name?.toLowerCase() != "premium" &&
                      <Button onClick={()=>navigate('/plans')} colorScheme="purple" size={"sm"}>Upgrade</Button> }
                        </>}
                        </>
                        :
                        <>
                        <Text fontSize={12} color={"red"} fontWeight={600}>
                          Plan Expired
                        </Text>
                        {!isCoCaregiver && <>
                        <Button onClick={()=>navigate('/plans')} colorScheme="purple" size={"sm"}>Renew</Button>

                        </>}
                        </>

                      }
                      </>
                      }
                  </Box>
                </Flex>
              </Box>
              <Box h={12} />
              <Text fontSize={10} fontWeight={500} color={"#747A8B"}>
                MAIN
              </Text>
              <Box h={8} />
              <SideBox
                icon={<FaEdit color="#747A8B" size={20} />}
                title={"Edit Profile"}
                onClick={() => setCurrentView("profile")}
              />
              <Box h={5} />
              {
                !isCareChatMember
                &&
                <>
                <SideBox
                  icon={<BsFillPeopleFill color="#747A8B" size={20} />}
                  title={"Jorner"}
                  onClick={() => setCurrentView("jorner")}
                />
                <Box h={5} />
                </>
                }
               {
                !isCareChatMember
                &&
              <SideBox
                icon={<FaUsers color="#747A8B" size={20} />}
                title={"Co-Caregivers"}
                onClick={() => setCurrentView("caregiver")}
              />
               }

              {
                !isCoCaregiver && 
              <>
              <Box h={5} />
               <SideBox
                icon={<FaWallet color="#747A8B" size={20} />}
                title={"Subscription"}
                onClick={() => navigate("/subscriptions")}
                />
                </>
              }

              <Box h={8} />
              <Text fontSize={10} fontWeight={500} color={"#747A8B"}>
                DOCUMENTS
              </Text>
              {
                !isCareChatMember
                  &&

              <>
              <Box h={5} />
              <SideBox
                icon={<AiFillFileText color="#747A8B" size={20} />}
                title={"Documents Upload"}
                onClick={() => navigate("/documents")}
              />
                <Box h={5} />
              <SideBox
                icon={<AiFillFileText color="#747A8B" size={20} />}
                title={"Insurance Cards"}
                onClick={() => navigate("/insurance-card")}
              />
              </>
            }

              {
                (!isCoCaregiver || userPermission.includes("documents_care_plan:view"))
                &&
                <>
                <Box h={5} />
                <SideBox
                icon={<AiFillFileText color="#747A8B" size={20} />}
                title={"Care Plan "}
                onClick={() => setCurrentView("care-plan")}
              />
                </>
              }
             
              {
                (!isCoCaregiver || userPermission.includes("documents_power_of_attorney:view"))
                &&
                <>
                <Box h={5} />
                <SideBox
                  icon={<AiFillFileText color="#747A8B" size={20} />}
                  title={"Power of Attorney"}
                  onClick={() => setCurrentView("power")}
                />
                </>
              }

              <Box h={8} />
              <Text fontSize={10} fontWeight={500} color={"#747A8B"}>
                ACCOUNT
              </Text>
              <Box h={5} />
              <SideBox
                icon={<BiSolidLockAlt color="#747A8B" size={20} />}
                title={"Change Password"}
                onClick={() => setCurrentView("password")}
              />
              <Box h={5} />
              <SideBox
                icon={<AiFillCloseCircle color="red" size={20} />}
                title={"Delete Account"}
                onClick={() => setCurrentView("delete_account")}
              />
              <Box h={5} />
            </Box>

            <Box
              bg={"#fff"}
              borderRadius={16}
              p={4}
              w={{ base: "100%", lg: "70%" }}
              mt={{ base: 3, md: 0 }}
            >
              {renderView(currentView)}
            </Box>
          </Flex>
        </Box>
      }
    />
  );
}

const SideBox = ({ icon, title, onClick }) => {
  return (
    <Flex alignItems={"center"} cursor={"pointer"} onClick={onClick}>
      {icon}
      <Text ml={2} fontSize={16} fontWeight={500} color={"#17181C"}>
        {title}
      </Text>
      <Spacer />
      <BsChevronRight color="#C7CAD1" />
    </Flex>
  );
};
