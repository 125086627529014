import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
  Stack,
  Skeleton,
  SimpleGrid,
  AvatarGroup,
  Avatar,
  Button,
} from "@chakra-ui/react";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  addNewFamilyImage,
  careteam2,
  circlePattern,
  dashboardCarepaln,
  dashboardCarepaln1,
  dashboardCarepaln2,
  dashboardCarepaln3,
  deleteIcon,
  finance,
  finance2,
  noise,
  provider,
  team,
  tribe,
  who,
} from "../../images";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import NoTask from "../../components/dashboard/no_task";
import JornerCard from "../../components/dashboard/jorner_card";
import { ChevronRightIcon, PlusIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import AddJorner from "../../components/dashboard/add_jorner_card";
import TaskCard from "../../components/dashboard/task_card";
import ls from "localstorage-slim";
import { httpGetWithTokenRest } from "../../utils/http_utils";
import InviteCareGiverModal from "./co_caregiver_modal";
import JornerCard2 from "../../components/dashboard/jorner_card2";
import { FaCaretRight } from "react-icons/fa";
import { communityRoute } from "../../utils/allRoutes";
import { capitalizeFirstWord } from "../../utils/utils";
import moment from "moment";
import AddNewFamilyModal from "./add_new_family";

function DashboardHome() {
  const [jorners, setJorners] = useState([]);
  const [jornerss, setJornerss] = useState([]);
  const [caregiverGroups, setCaregiverGroups] = useState([]);
  const [tasks, setTasks] = useState([1, 2, 3, 5, 6]);
  const [todos, setTodos] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const isCoCaregiver = user?.tag !== "cg";

  const [inviteGiversConstants, setInviteGiversConstants] = useState([]);
  const [percentage, setPercentage] = useState(50);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const [isCareChatMember, setIsCareChatMember] = useState(false);
  const [newDashboardItems, setNewDashboardItems] = useState([]);

  const newDashboardItems1 = [
    {
      name: "Care Plan",
      text: "Manage your care plan",
      buttonText: "View Care Plan",
      icon: dashboardCarepaln,
      href: "/profile/careplan",
    },
    {
      name: "Medications",
      text: "View medication reminders",
      buttonText: "View Medications",
      icon: dashboardCarepaln1,
      href: "/medications",
    },
    {
      name: "Jorner’s AI Note",
      text: "Review appointment summaries",
      buttonText: "View Notes",
      icon: dashboardCarepaln2,
      href: "/ai-notes",
    },
    {
      name: "Schedules",
      text: "Manage daily schedule",
      buttonText: "View Schedules",
      icon: dashboardCarepaln,
      href: "/jorner-schedule",
    },
  ];
  const newDashboardItems2 = [
    {
      name: "Care Circle",
      text: "Manage your care plan",
      buttonText: "View Care Circle",
      icon: dashboardCarepaln,
      href: "/care-circle",
    },
    {
      name: "Care Plan",
      text: "Manage your care plan",
      buttonText: "View Care Plan",
      icon: dashboardCarepaln,
      href: "/profile/careplan",
    },

  ];
  const getRandomColors = () => {
    const colorList = [
      {
        textColor: "secondary",
        bgColor: "#FF6E57",
      },
      {
        textColor: "primary",
        bgColor: "primary",
      },
      {
        textColor: "secondary",
        bgColor: "#36BBE2",
      },
    ];
    return colorList[Math.floor(Math.random() * colorList.length)];
  };
  useEffect(() => {
    setIsCareChatMember(user.is_care_member);
    if (user.is_care_member) {
      setNewDashboardItems(newDashboardItems2)
    } else {
      setNewDashboardItems(newDashboardItems1)
    }
    // console.log("user user user", user);
    try {
      ls.set("sj_jorner1", null, { encrypt: false });
    } catch (error) { }
    // ls.remove()
    (async () => {
      // console.log(user);
      await fetchCarecircleGroups()
      var url;
      if (user.tag == "cocg") {
        url = `co_caregiver/${user.id}/jorners`;
      } else {
        url = `jorner`;
      }
      var j = await httpGetWithTokenRest(url);
      if (j.status == "success") {
        const jor = j.data;
        const jornersss = jor.map((item, i) => {
          return {
            id: i,
            key: i,
            label: item.first_name + " " + item.last_name,
            value: item.id,
            ...item,
          };
        });
        setJornerss(jornersss);
        setJorners(j.data);
        if (j.data.length > 0) setPercentage(100);
      }

      var j = await httpGetWithTokenRest("permissions");
      if (j.status == "success") {
        setInviteGiversConstants(j.data.permissions);
      }

      var j = await httpGetWithTokenRest("/tasks?status=to do");
      if (j.status == "success") {
        setTodos(j.data.filter((m) => m.status === "to do"));
      }
      console.log("this is new dashboard");
      var j = await httpGetWithTokenRest("tasks?status=completed");
      if (j.status == "success") {
        setCompletedTasks(j.data);
      }
      setLoading(false);
    })();
  }, []);
  const fetchCarecircleGroups = async () => {
    const resp = await httpGetWithTokenRest("care_circle/me");
    if (resp.error !== null && resp.error !== undefined) {
    } else {
      const d = resp.data;
      var pCommunity = d.filter((c) => c.community_type === "private");
      setCaregiverGroups(pCommunity);

      setLoading(false);
    }
  };
  const {
    isOpen: isOpenCoGiverModal,
    onOpen: onOpenCoGiverModal,
    onClose: onCloseCoGiverModal,
  } = useDisclosure();

  const {
    isOpen: isOpenFam,
    onOpen: onOpenFam,
    onClose: onCloseFam,
  } = useDisclosure();

  const chartoptions = {
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };
  return (
    <Box padding={"19px 20px"} height="100%" background={"#FAF8FF"}>
      {/* greetings */}
      <Box>
        <Heading fontSize={"22px"} fontWeight={"600"} as={"h3"}>
          Hello, {user?.first_name}
        </Heading>
        <Text color={"paragraph"}>
          Welcome to the {(user?.family_name).replace("'s", "")}'s Care Circle
        </Text>
      </Box>
      <Box h="20px" />
      <InviteCareGiverModal
        coGiverConstants={inviteGiversConstants}
        onClose={onCloseCoGiverModal}
        isOpen={isOpenCoGiverModal}
        jorners={jornerss}
      />
      <AddNewFamilyModal
        onClose={onCloseFam}
        isOpen={isOpenFam}
      />

      {loading && (
        <Stack mt={5}>
          <Skeleton startColor="#808080" height="50px" />
          <Skeleton startColor="#808080" height="50px" />
          <Skeleton startColor="#808080" height="50px" />
        </Stack>
      )}
      {/* complete onboarding */}
      {!loading && percentage !== 100 && !isCoCaregiver ? (
        <Link as={ReachLink} to={"/profile"} style={{ textDecoration: "none" }}>
          <Box
            shadow={"sm"}
            background="white"
            padding="20px"
            borderRadius={"16px"}
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Box>
              <Heading as="h6" fontSize={"18px"}>
                Complete Onboarding
              </Heading>
              <Text color="paragraph" fontSize="13px">
                Provide information to get started
              </Text>
              <Box h="4px" />
              <Text color="primary" fontWeight="bold" fontSize="12px">
                Complete onboarding
              </Text>
            </Box>

            <Box position="relative">
              <Text
                position="absolute"
                top="20px"
                left="15px"
                fontWeight={"600"}
                fontSize={"11px"}
              >
                {percentage}%
              </Text>
              <CChartDoughnut
                className="mx-auto"
                style={{ height: "58px", width: "58px", border: "none" }}
                options={chartoptions}
                data={{
                  labels: ["Completed", "All"],
                  datasets: [
                    {
                      borderJoinStyle: "round",
                      borderColor: "transparent",
                      backgroundColor: ["#0CCA4A", "#E7FEEE"],
                      data: [percentage, percentage - 100],
                    },
                  ],
                }}
              />
            </Box>
          </Box>
        </Link>
      ) : (
        ""
      )}
      <Box h="20px" />
      {/* <Box display={"flex"} justifyContent={{ base: "start", md: "space-between" }} bg={"#F5F0FF"} borderRadius={8} p={4}>
        <Flex gap={4}>
          <Box>
            <Image src={addNewFamilyImage} boxSize={"51px"} />
          </Box>
          <Box>
            <Text fontWeight={"600"} color="#17181C" fontSize={"17px"}>Create New Family?</Text>
            <Text color="#747A8B" fontSize={"14px"}>Tap to create a new family on sojorne</Text>
          </Box>
        </Flex>
        <Box>
          <AppLink
            hasLeftIcon={true}
            leftIcon={<Icon as={PlusIcon} fontSize="20px" />}
            shadow="none"
            padding="0 12px"
            text="Create New"
            background="primary"
            color="white"
            width="fit-content"
            textDecoration="none"
            href="#?"
            onclick={()=> {onOpenFam()}}
          />
        </Box>

      </Box>
      <Box h="40px" /> */}

      {
        !isCareChatMember
        &&
        <Box>
          <Flex justifyContent="space-between">
            <Heading as={"h3"} fontSize={"16px"} fontWeight="600" color="black">
              My Jorners
            </Heading>
            {/* <AppLink href='/jorners' onclick={()=>navigate("/jorners")} hasRightIcon={true} rightIcon={<Icon as={ChevronRightIcon} fontSize={'18px'} />} text='See all' color='#747A8B' padding='0' height='fit-content' background='transparent' fontWeight='normal' shadow='none' /> */}
            <Box position="relative">
              {!isCareChatMember && <AppLink
                href="/jorners"
                onclick={() => navigate("/jorners")}
                hasRightIcon={true}
                rightIcon={<Icon as={ChevronRightIcon} fontSize={"18px"} />}
                text="See all"
                color="#747A8B"
                padding="0"
                height="fit-content"
                background="transparent"
                fontWeight="normal"
                shadow="none"
              />}
            </Box>
          </Flex>
          <Box h="10px" />
          {jorners.length === 0 ? (
            <NoJorner />
          ) : (
            <Flex gap={3} flexWrap={"wrap"}>
              {jorners.slice(0, 7).map((item, i) => (
                <JornerCard2 key={i} jorner={item} />
              ))}
              <AddJorner />
            </Flex>
          )}
        </Box>
      }

      <Spacer height={"22px"} />

      {/* cards */}
      <Box>
        <Flex
          alignItems={"center"}
          gap="4"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box
            onClick={() => {
              if (!isCareChatMember) {
                navigate("/profile/careteam");
              } else {
                navigate("/care-circle");
              }
            }}
            cursor="pointer"
            borderRadius={"14px"}
            width={"100%"}
            position={"relative"}
            background={
              "linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(180deg, #7353BA 0%, #6E47C6 100%);"
            }
          >
            <Image
              position={"absolute"}
              left="0"
              top="0"
              height={"100%"}
              width="100%"
              src={noise}
            ></Image>
            <Box
              width={"100%"}
              alignItems="center"
              top="0"
              display={"flex"}
              px="2"
              h={"100%"}
              justifyContent="space-between"
            >
              <Box
                h="100%"
                display={"flex"}
                py={{ base: "10px", md: 0 }}
                flexDirection="column"
                justifyContent={"center"}
              >
                <Heading
                  color={"white"}
                  mb="10px"
                  fontSize="14px"
                  fontWeight={"600"}
                >
                  Build your care team
                </Heading>
                <Text color={"white"} mb="10px" fontSize="12px">
                  Specify jorners health care and <br /> support needs.
                </Text>
                <AppLink
                  fontSize="12px"
                  width="fit-content"
                  padding="0 15px"
                  text="Build your Care Team"
                  background="white"
                  color="primary"
                  fontWeight="600"
                  href="/"
                />
              </Box>
              <Image
                src={careteam2}
                height={{ base: "100px", md: "150px" }}
                width={{ base: "100px", md: "auto" }}
                objectFit={"contain"}
              ></Image>
            </Box>
          </Box>

          <Box
            onClick={() => {
              navigate("/care-circle");
            }}
            cursor="pointer"
            borderRadius={"14px"}
            width={"100%"}
            position={"relative"}
            background={
              "linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(180deg, #7353BA 0%, #6E47C6 100%);"
            }
          >
            <Image
              zIndex={1}
              position={"absolute"}
              left="0"
              top="0"
              height={"100%"}
              width="100%"
              src={noise}
            ></Image>
            <Box
              width={"100%"}
              alignItems="center"
              top="0"
              display={"flex"}
              px="2"
              py="2"
              h={"100%"}
              justifyContent="space-between"
            >
              <Box
                h="100%"
                display={"flex"}
                py={{ base: "10px", md: 0 }}
                flexDirection="column"
                justifyContent={"center"}
              >
                <Heading
                  color={"white"}
                  mb="10px"
                  fontSize="14px"
                  fontWeight={"600"}
                >
                  Careers and Finances
                </Heading>
                <Text
                  maxWidth={"200px"}
                  color={"white"}
                  mb="10px"
                  fontSize="12px"
                >
                  Access to careers and finance providers is coming soon.
                </Text>
                <AppLink
                  href="/care-circle"
                  fontSize="12px"
                  width="fit-content"
                  padding="0 15px"
                  text="Join a community"
                  background="white"
                  color="black"
                  fontWeight="600"
                />
              </Box>
              <Image
                src={finance2}
                height={{ base: "100px", md: "130px" }}
                width={{ base: "100px", md: "auto" }}
                objectFit={"contain"}
              ></Image>
            </Box>
          </Box>
        </Flex>

        <Spacer height={"22px"} />
      </Box>
      <Box h="10px" />
      <SimpleGrid gap={3} columns={{ base: 1, md: 2, lg: isCareChatMember ? 2 : 4 }}>
        {newDashboardItems.map((item, i) => (
          <Box
            key={i}
            display={"flex"}
            shadow={"lg"}
            dropShadow={"md"}
            bg="white"
            rounded={"lg"}
            p="3"
            justifyContent={"space-between"}
          >
            <Box>
              <Heading color="#17181C" fontSize={"14px"}>
                {item.name}
              </Heading>
              <Box h="4px" />

              <Text color={"#747A8B"} fontSize={"12px"}>
                {item.text}
              </Text>
              <Box h="10px" />
              <Link
                to={item.href}
                fontWeight={"500"}
                color={"primary"}
                alignItems={"center"}
                display={"flex"}
                fontSize={"12px"}
                as={ReachLink}
              >
                {" "}
                <Text>{item.buttonText} </Text> <Icon as={ChevronRightIcon} />
              </Link>
            </Box>
            <Image src={item.icon} objectFit={"contain"} boxSize={"60px"} />
          </Box>
        ))}
      </SimpleGrid>
      {/* tsak */}
      <Spacer height={"22px"} />
      {
        !isCareChatMember
        &&
        <Box>
          <Flex justifyContent="space-between">
            <Heading as={"h3"} fontSize={"16px"} fontWeight="600" color="black">
              Tasks
            </Heading>
            <AppLink
              href="/tasks"
              hasRightIcon={true}
              rightIcon={<Icon as={ChevronRightIcon} fontSize={"18px"} />}
              text="See all"
              color="#747A8B"
              padding="0"
              height="fit-content"
              background="transparent"
              fontWeight="normal"
              shadow="none"
            />
          </Flex>

          <Box h="10px" />

          {!loading && todos.length === 0 && completedTasks.length === 0 ? (
            <NoTask />
          ) : (
            <Box
              shadow={"sm"}
              background="white"
              padding="30px"
              borderRadius={"16px"}
            >
              <Tabs borderColor={"#F1F2F4"} colorScheme="red">
                <TabList
                  flexDirection={{
                    base: "column",
                    md: "row",
                  }}
                >
                  <Tab px="30px" fontWeight={"600"}>
                    To-do ({todos.length})
                  </Tab>
                  <Tab px="30px" fontWeight={"600"}>
                    Completed ({completedTasks.length})
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    {/* a task */}
                    {todos.map((item, i) => (
                      <TaskCard task={item} key={i} />
                    ))}
                  </TabPanel>
                  <TabPanel>
                    {completedTasks.map((item, i) => (
                      <TaskCard task={item} key={i} />
                    ))}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </Box>
      }

      <Spacer height={"22px"} />

    </Box>
  );
}

export default DashboardHome;
