import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

export function getClosestEvent(events) {
  // Get current date
  const currentDate = new Date();

  // Filter events that have a date greater than now
  const futureEvents = events.filter(event => new Date(event.date) > currentDate);

  // Sort futureEvents by date in ascending order
  futureEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Return the event with the closest date to today
  return futureEvents[0];
}

export const sortArrayByKey = (arrayOfObjects, k) => {
  // Sort the array by the 'date' field within each object
  arrayOfObjects.sort((a, b) => {
    // Convert the date strings to Date objects and compare them
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });
  
return arrayOfObjects.reverse();

}
export const capitalizeFirstWord = (str) => {
  if (!str || str === "") return "";
  const arr = str.split(" ");
  //loop through each element of the array and capitalize the first letter.
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  const str2 = arr.join(" ");
  return str2;
};
export function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}
export function containsNumber(str) {
  return /\d/.test(str);
}

export function containsCapitalLetter(str) {
  var strArray = str.split("");
  for (let i = 0; i < strArray.length; i++) {
    const strr = strArray[i];
    const isUpperCase = /^[A-Z]*$/.test(strr);
    if (isUpperCase) return true;
  }

  return false;
}
export const checkArraySimilarities = (arr1, arr2) => {
  let ct = 0;
  for (let i = 0; i < arr1.length; i++) {
    if (arr2.includes(arr1[i])) {
      ct++;
    }
  }
  return ct;
};

export const checkArraySimilaritiesWithObject = (arr1, arr2) => {
  let ct = 0;
  let newArr2 = [];
  for (let i = 0; i < arr2.length; i++) {
    const element = arr2[i];
    newArr2.push(`${element?.brand_name} * ${element?.generic_name}`);
  }
  for (let i = 0; i < arr1.length; i++) {
    if (newArr2.includes(arr1[i])) {
      ct++;
    }
  }
  return ct;
};
export function lastIndexOfCharacter(inputString, character) {
  // Use lastIndexOf to find the last occurrence of the character
  var lastIndex = inputString.lastIndexOf(character);

  return lastIndex;
}

export const ValidDate = (UserDate, invalid = false) => {
  // the invlaid prop is for when the date is coming from moment, for some reason, it was throwing error,
  //i had to do a sperate check for it
  var ToDate = new Date();
  var isInvalid = UserDate.split(" ");
  if (invalid) {
    if (new Date(isInvalid[0]).getTime() <= ToDate.getTime()) {
      return false;
    }
  } else {
    if (new Date(UserDate).getTime() <= ToDate.getTime()) {
      return false;
    }
  }

  return true;
};

export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export function isImage(url) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
export function isVideo(url) {
  return /\.(mp4|mpeg-4|mkv|mov|avi|wmv|avchd|webm|flv)$/.test(url.toLowerCase());
}
export function isPdf(url) {
  return /\.(pdf)$/.test(url);
}
export function isDoc(url) {
  return /\.(rtf|doc|docx|txt)$/.test(url);
}
export function isAudio(url) {
  return /\.(mp3|wav|webm)$/.test(url);
}

export function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}
export function generateToken(length = 30) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function getYearDifference(date, today = new Date()) {
  // Calculate the time difference in milliseconds
  var timeDifference = today - date;

  // Calculate the number of milliseconds in a year (accounting for leap years)
  var millisecondsPerYear = 365.25 * 24 * 60 * 60 * 1000;

  // Calculate the difference in years
  var years = Math.floor(timeDifference / millisecondsPerYear);

  return years;
}

export function Today() {
  let t = new Date();
  let month = t.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = t.getDate();
  day = day < 10 ? `0${day}` : day;
  let today = t.getFullYear() + "-" + month + "-" + day;

  return today;
}

const firebaseConfig ={
    apiKey: "AIzaSyCD57DxBqzDt5AHLok0k9SoIk7tmHQNX7g",
  authDomain: "sojorne-6c22c.firebaseapp.com",
  databaseURL: "https://sojorne-6c22c-default-rtdb.firebaseio.com",
  projectId: "sojorne-6c22c",
  storageBucket: "sojorne-6c22c.appspot.com",
  messagingSenderId: "977302763921",
  appId: "1:977302763921:web:cda357af8ebbc178788230",
  measurementId: "G-LD7TVLV5D4"
}

const app = initializeApp(firebaseConfig);
  // Initialize Realtime Database and get a reference to the service

export const auth = getAuth(app);
export const database = getDatabase(app);