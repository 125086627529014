import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  FormLabel,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";

export default function FamiliesModal({
  isOpen,
  onClose,
  constants,
  selectedOriginal,
  onFinished,
}) {
  const [selected, setselected] = React.useState(selectedOriginal);
  const [families, setFamilies] = React.useState([]);
  const finishSelection = (v) => {
    onFinished(v);
    onClose();
    setselected([]);
  };

  useEffect(() => {
    if (constants) {
      setFamilies(constants);
    } else {
      setFamilies([]);
    }
  }, [constants]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader>Select Family</ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        <ModalBody marginBottom={"0"}>
          {families.map((item, i) => (
            <Box
              cursor={"pointer"}
              key={i}
              py="2"
              onClick={() => {
                finishSelection(item);
              }}
            >
              <Flex justifyContent={"space-between"}>
                <FormLabel
                  gap={"3"}
                  display="flex"
                  alignItems={"center"}
                  htmlFor={`${item.user_id}`}
                >
                  <Box
                    position={"relative"}
                    fontWeight="600"
                    color="white"
              cursor={"pointer"}
              boxSize={"45px"}
                    borderRadius={"100%"}
                    display="flex"
                    background={"secondary"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
              fontSize={"14px"}>
                      {item.family_name.substring(0, 2).toUpperCase()}
                    </Text>
                  </Box>
                  <Text
                  cursor={"pointer"}
                  >{item.family_name}</Text>
                </FormLabel>
              </Flex>
              <Divider mt={"1"} color={"#EBECEF"} />
            </Box>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
