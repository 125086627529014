import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

export function SearchBox({ handleSearch, placeholder = "Search" }) {
  return (
    <InputGroup bg={"#fff"} w={{ base: "100%", md: "300px" }} rounded={'18px'}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        fontSize={12}
        fontWeight={400}
        id="keyword"
        minW={"100px"}
        borderRadius={100}
        placeholder={placeholder}
        borderColor={"#E3E4E8"}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </InputGroup>
  );
}
