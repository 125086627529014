import { Box, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ProviderItem({ image, title, subtitle, link, bgColor, textColor }) {
    const navigate = useNavigate()
  return (
    <Box
      cursor={'pointer'}
      onClick={()=>navigate(link)}
      borderRadius={"14px"}
      position={"relative"}
      background={bgColor}
      p={5}
    >
      <Image src={image} height={"32px"} width={"32px"} objectFit={"contain"} />
      <Box width={"100%"} alignItems="center" mt={4}>
        <Box
          h="100%"
          display={"flex"}
          py={{ base: "10px", md: 0 }}
          flexDirection="column"
          justifyContent={"center"}
        >
          <Heading
            color={textColor}
            mb="10px"
            fontSize="14px"
            fontWeight={"600"}
          >
            {title}
          </Heading>
          <Text color={"black"} mb="10px" fontSize="12px">
            {subtitle}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
