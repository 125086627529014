import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Stack,
  Skeleton,
  SimpleGrid,
} from "@chakra-ui/react";
import AudioPlayer from "react-h5-audio-player";
import "../../utils/mp3.css";

import { Link as ReachLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  finance,
  noAppointments,
  noNotes,
  noise,
  notask,
  provider,
  team,
  tribe,
  waves,
  who,
} from "../../images";
import AppLink from "../../components/link";
import NoTask from "../../components/dashboard/no_task";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { httpGetWithTokenRest } from "../../utils/http_utils";
import { isValidUrl } from "../../utils/utils";
import CreateAppointmentModal from "./create_appointment_modal";
import AppointmentCreatedModal from "./appointment_created";
import AppointmentCard from "./appointment_card";
import DeleteAppointmentModal from "./delete_appointment_modal";

function AppointmentsPage() {
  const [tasktype, setTasktype] = useState("");
  const [delAppointment, setDelAppointment] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [jorners, setJorners] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const colors = ["#EBECEF", "#FFDCC9", "#FFF6E3", "#E5EDFF", "#FFDCC9"];
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const {
    isOpen: isOpenCreated,
    onOpen: onOpenCreated,
    onClose: onCloseCreated,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  useEffect(() => {
    loadConstants();
  }, []);

  const loadConstants = async (from = null) => {
    if (!from) setDelAppointment(null);
    const res = await httpGetWithTokenRest("appointments");
    if (res.statusCode == 200) {
      setAppointments(res.data);
    }
    setLoading(false);
  };

  const dangerousHTML = (content) => {
    var c = content.substring(0, 150);

    return content;
  };

  const deleteAppointment = (appointment) => {
    // console.log("deleted", appointment)
    setDelAppointment(appointment);
    onOpenDelete();
  };

  const editAppointment = (appointment) => {
    // console.log("edited", appointment)
    setDelAppointment(appointment);
    onOpenCreate();
  };

  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      <Spacer height={"22px"} />
      <CreateAppointmentModal
        coGiverConstants={[]}
        onClose={onCloseCreate}
        appointment={delAppointment}
        isOpen={isOpenCreate}
        onFinished={(callbackValue) => {
          setDelAppointment(null);
          if (callbackValue == "created") {
            loadConstants();
            onOpenCreated();
          }
          if (callbackValue == "updated") {
            // passing so the current appointment will not be cleared
            loadConstants("update");
          }
        }}
      />
      <AppointmentCreatedModal
        onClose={onCloseCreated}
        isOpen={isOpenCreated}
      />

      <DeleteAppointmentModal
        onClose={() => {
          setDelAppointment(null);
          onCloseDelete();
        }}
        isOpen={isOpenDelete}
        appointment={delAppointment?.id}
        onFinished={loadConstants}
      />

      <Box>
        <Flex alignItems={"center"} justifyContent="space-between">
          <Heading as={"h3"} fontSize={"18px"} fontWeight="600" color="black">
            Appointments
          </Heading>
          {!loading && appointments.length > 0 && (
            <Button
              borderRadius={"50px"}
              onClick={onOpenCreate}
              colorScheme={"purple"}
            >
              {" "}
              <Icon color={"white"} as={PlusIcon} />
              <Text ml={1} display={{ base: "none", md: "block" }}>
                Create Appointment
              </Text>
            </Button>
          )}
        </Flex>
        <Box h="10px" />
        {loading && (
          <Stack mt={5}>
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
          </Stack>
        )}
      </Box>
      <Spacer height={"22px"} />
      {!loading && appointments.length === 0 ? (
        <>
          <Box
            shadow={"sm"}
            background="white"
            padding="100px"
            borderRadius={"16px"}
            display="flex"
            flexDirection="column"
            justifyContent={"center"}
            alignItems="center"
          >
            <Image src={noAppointments} boxSize={"155px"}></Image>
            <Box h="10px" />
            <Box h="10px" />
            <Heading as="h6" fontSize={"16px"}>
              No Appointments Yet!
            </Heading>
            <Box h="10px" />

            <Box textAlign={"center"} maxW="480px">
              <Text color="paragraph">
                Create appointments to manage jorners appointment bookings and
                schedules
              </Text>
              <Box h="10px" />

              <Box h="5px"></Box>
              <AppLink
                href="#?"
                onclick={onOpenCreate}
                hasLeftIcon={true}
                leftIcon={<Icon as={PlusIcon} fontSize={"22px"} />}
                textDecoration="none"
                text="Create Appointment"
                color="red"
                padding="0"
                height="fit-content"
                background="transparent"
                fontWeight="500"
                shadow="none"
              />
            </Box>
          </Box>
        </>
      ) : (
        <Box shadow={"sm"} background="white" padding="5" borderRadius={"16px"}>
          {appointments.map((appointment, i) => (
            <AppointmentCard
              editAppointment={editAppointment}
              deleteAppointment={deleteAppointment}
              appointment={appointment}
              key={i}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default AppointmentsPage;
