import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  useCheckboxGroup,
  useToast,
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Select,
  Textarea,
  Icon,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import {
  ValidDate,
  capitalizeFirstWord,
  checkArraySimilarities,
  containsSpecialChars,
} from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { useFormik } from "formik";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import { DatePicker } from "rsuite";
import PhoneNumberInput2 from "../../components/phoneNumberInput2";
import {
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { useEffect } from "react";
import moment from "moment";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";

export default function CreateReminderModal({
  isOpen,
  selectedDate,
  onClose,
  onFinished,
  appointment,
}) {
  const finishSelection = (value = "close") => {
    onFinished(value);
    onClose();
  };

  const updated = () => {
    onFinished("updated");
  };
  const {
    value: selectedPermissions,
    getCheckboxProps,
    setValue: setCheckValue,
  } = useCheckboxGroup({
    defaultValue: [],
  });
  const appointmentTypes = ["Recurring", "Special", "Single"];
  const [isPicker, setIsPicker] = useState(false);
  const [isPicker1, setIsPicker1] = useState(false);
  const [customValue0, setCustomValue0] = useState("");
  const [customValue1, setCustomValue1] = useState("");
  const [isEdit, setIsEdit] = useState(appointment ? true : false);
  const appointmentReminder = [
    "Annually",
    "Monthly",
    "Daily",
    "Periodic (Custom)",
  ];
  const custom2Array = ["Days", "Weeks", "Months"];
  const allPermissions = ["home", "calendar", "task", "provider", "community"];
  const [custom1Array, setCustom1Array] = useState([]);
  const [showCustomValue, setShowCustomValue] = useState(false);
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const toast = useToast();
  const validateEmail = (e) => {
    return String(e)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  useEffect(() => {
    let emptyCustom1 = [];
    for (let index = 1; index < 101; index++) {
      emptyCustom1 = [...emptyCustom1, index];
    }
    setCustom1Array(emptyCustom1);
    setIsPicker(false);
    if (appointment) {
      setIsEdit(true);
      setTimeout(() => {
        fillFormik(appointment);
      }, 1000);
    } else {
      setIsEdit(false);
      clearFormik();
    }
    
  }, [appointment, selectedDate]);

  const formik = useFormik({
    initialValues: {
      name: "",
      department: "",
      description: "",
      reminder_time : "00:00",
      phone: "",
      appointment_type: "reminder",
      reminder: "",
      appointment_date: "",
      custom_value1: "",
      custom_value2: "",
      phone_code: "+1",
    },
    onSubmit: async (values) => {
      if (
        values.name == "" ||
        values.department == "" ||
        values.phone == "" ||
        values.reminder == "" ||
        values.appointment_date == ""
      ) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `All fields are required`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      if(containsSpecialChars(values.name)) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Doctor's name cannot contain special character`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      if (!ValidDate(values.appointment_date)) {
        return toast({
          title: "Invalid",
          description: `Date cannot be in the past`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.reminder.toLowerCase().includes("custom")) {
        if (values.custom_value1 == "" || values.custom_value2 == "") {
          return toast({
            title: "Invalid.",
            description: `Please select reminder frequency values`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
      var data = {
        name: values.name,
        department: values.department,
        phone_number: values.phone,
        country_code: values.phone_code,
        description: values.description,
        reminder_time: values.reminder_time,
        appointment_type: "reminder",
        reminder: values.reminder.toLowerCase(),

        appt_date: values.appointment_date,
        custom_value: values.custom_value1 + " " + values.custom_value2,
      };

      var response = null;
      if (isEdit) {
        response = await httpPutWithTokenRest(
          "/appointments/" + appointment.id,
          data
        );
      } else {
        response = await httpPostWithToken("/appointments", data);
      }

      if (response.error) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        if (isEdit) {
          toast({
            title: "Reminder updated successfully",
            // description: `${response.error}`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          updated();
        } else {
          formik.values.name = "";
          formik.values.description = "";
          formik.values.appointment_date = "";
          formik.values.reminder_time = "00:00";
          formik.values.appointment_type = "";
          formik.values.phone_code = "+1";
          formik.values.phone = "";
          formik.values.department = "";
          formik.values.reminder = "";
          formik.values.custom_value1 = "";
          formik.values.custom_value2 = "";
          // selectedPermissions = null
          setCheckValue([]);
          setTimeout(() => {
            finishSelection("created");
          }, 1000);
        }
      }
    },
  });

  const clearFormik = () => {
    formik.values.name = "";
    formik.values.description = "";
    formik.values.appointment_date = selectedDate;
    formik.values.appointment_type = "";
    formik.values.phone_code = "+1";
    formik.values.phone = "";
    formik.values.reminder_time = "00:00";
    formik.values.department = "";
    formik.values.reminder = "";
    formik.values.custom_value1 = "";
    formik.values.custom_value2 = "";
  };

  const fillFormik = (appt) => {
    // alert("hjere")
    // console.log(appt)
    formik.setFieldValue("phone", appt.phone_number);
    formik.setFieldValue("name", appt.name);
    formik.setFieldValue("description", appt.description);
    formik.setFieldValue("appointment_date", appt.appt_date);
    formik.setFieldValue("reminder_time", appt.reminder_time);

    formik.setFieldValue("phone_code", appt.country_code);
    formik.setFieldValue("department", appt.department);
    formik.setFieldValue("reminder", capitalizeFirstWord(appt.reminder.replace("custom", "Custom")));
    
    if (appt.custom_reminder_value && appt.reminder.includes("custom")) {
      var cv = appt.custom_reminder_value;
      const cvArray = cv.split(" ");
      formik.setFieldValue("custom_value1", cvArray[0].trim())
      formik.setFieldValue("custom_value2", cvArray[1].trim())

      setCustomValue1(cvArray[1].trim())
      setCustomValue0(cvArray[0].trim())
      setShowCustomValue(true)
    }else{
      setShowCustomValue(false)
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p="3">
        <ModalBody marginBottom={"0"}>
          <Box>
            <Flex alignItems={"center"} justifyContent="space-between">
              <Heading fontSize={"20px"}>
                {" "}
                {isEdit ? "Edit " : "Create "} Reminder
              </Heading>
              <Button onClick={finishSelection}>close</Button>
            </Flex>

            <Box mt={"3"} />
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="name">Doctor's Name</FormLabel>
                  <Input
                    height={"50px"}
                    id="name"
                    name="name"
                    type="text"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="E.g My Reminder"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </FormControl>

                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="department">Department</FormLabel>
                  <Input
                    height={"50px"}
                    id="department"
                    name="department"
                    type="text"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="E.g Orthopaedic"
                    onChange={formik.handleChange}
                    value={formik.values.department}
                  />
                </FormControl>
                <Box></Box>

                <FormControl mt={3} fontWeight={600}>
                  <FormLabel htmlFor="phone">
                    Phone number 
                  </FormLabel>
                  <PhoneNumberInput
                    onCodeChange={(v) => formik.setFieldValue("phone_code", v)}
                    key={"phoneInput"}
                    id={"phone"}
                    required={isEdit ? false : true}
                    value={formik.values.phone}
                    options={countryOptions}
                    placeholder="Enter phone number"
                    onChange={(v) => formik.setFieldValue("phone", v)}
                  />
                </FormControl>

                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Textarea
                    id="description"
                    name="description"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    placeholder="E.g Orthopaedic"
                    size="sm"
                  />
                </FormControl>
              
                <DatePicker
                  style={{ width: 260 }}
                  onOk={(value) => {
                    var d = new Date(value);
                    // setSelectedCalendar(moment(d).format('MMMM Do YYYY, h:mm:ss a'));
                    var date = moment(d).format("MMM, YYYY");
                    formik.setFieldValue("appointment_date", date);
                    // setShowWhen(false)
                    setIsPicker(false);
                  }}
                  open={isPicker}
                  format="yyyy-MM"
                />
                <FormControl position={"relative"} marginTop={"5px"}>
                  <FormLabel htmlFor="appointment_date">
                    Approximate Date
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                    >
                      <Icon
                        mt={"10px"}
                        as={CalendarDaysIcon}
                        color="black.500"
                      />
                    </InputLeftElement>
                    <Input
                      height={"50px"}
                      id="appointment_date"
                      name="appointment_date"
                      type="text"
                      onClick={() => setIsPicker(!isPicker)}
                      variant="outlined"
                      border={"1px solid #E3E4E8"}
                      focusBorderColor="#E3E4E8"
                      placeholder="Set date"
                      onChange={formik.handleChange}
                      value={formik.values.appointment_date}
                    />
                    <InputRightElement>
                      <Icon
                        mt={"10px"}
                        as={ChevronDownIcon}
                        color="black.500"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl position={"relative"} marginTop={"5px"}>
                  
                <DatePicker
                  style={{ width: 260, position : "absolute" }}
                  onOk={(value) => {
                    var d = new Date(value);
                    let h = moment(d).format("H");
                    let mm = moment(d).format("mm");
                    let date = `${h < 10 ? '0': ''}${h}:${mm}`
                    formik.setFieldValue("reminder_time", date);
                    setIsPicker1(false);
                  }}
                  open={isPicker1}
                  format="H:mm"
                />
                  <FormLabel htmlFor="appointment_date">
                    Set Reminder Time
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                    >
                      <Icon
                        mt={"10px"}
                        as={CalendarDaysIcon}
                        color="black.500"
                      />
                    </InputLeftElement>
                    <Input
                      onClick={() => setIsPicker1(!isPicker1)}
                      className="timetime"
                      height={"50px"}
                      id="reminder_time"
                      name="reminder_time"
                      type="text"
                      variant="outlined"
                      border={"1px solid #E3E4E8"}
                      focusBorderColor="#E3E4E8"
                      placeholder="Set reminder time"
                      onChange={formik.handleChange}
                      value={formik.values.reminder_time}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl mt={3} fontWeight={600}>
                  <FormLabel htmlFor="reminder">Reminder Sequence</FormLabel>
                  <Select
                    focusBorderColor="#E3E4E8"
                    border={"1px solid #E3E4E8"}
                    height="50px"
                    id="reminder"
                    onChange={(e) => {
                      setShowCustomValue(false);
                      formik.setFieldValue("reminder", e.target.value);
                      if (e.target.value.toLowerCase().includes("custom")) {
                        setShowCustomValue(true);
                        formik.setFieldValue("custom_value2", "");
                        formik.setFieldValue("custom_value1", "");
                      }
                    }}
                    name="reminder"
                    value={formik.values.reminder}
                    placeholder=""
                  >
                    <option value="">Select</option>
                    {appointmentReminder.map((apptType, i) => {
                      if(appointment && appointment.reminder.toLowerCase() == apptType.toLowerCase()){
                        return (<option selected key={i} value={apptType}>
                        {apptType}
                      </option>)
                      }else{
                        return (
                          <option key={i} value={apptType}>
                          {apptType}
                        </option>
                        )
                      }
                    }  
                    )}
                  </Select>
                  <Box mt="4" />

                  {showCustomValue && (
                    <SimpleGrid columns={2} gap={3}>
                      <Select
                        focusBorderColor="#E3E4E8"
                        border={"1px solid #E3E4E8"}
                        height="50px"
                        id="custom_value1"
                        onChange={(e) =>
                          formik.setFieldValue("custom_value1", e.target.value)
                        }
                        name="custom_value1"
                        value={formik.values.custom_value1}
                        placeholder=""
                      >
                        <option value="">Select</option>
                        {custom1Array.map((apptType, i) => {
                          if(apptType.toString() == customValue0){
                            return <option selected key={i} value={apptType}>
                            {apptType}
                          </option>
                          }else{
                          return <option key={i} value={apptType}>
                            {apptType}
                          </option>
                          }
                        })}
                      </Select>

                      <Select
                        focusBorderColor="#E3E4E8"
                        border={"1px solid #E3E4E8"}
                        height="50px"
                        id="custom_value2"
                        onChange={(e) =>
                          formik.setFieldValue("custom_value2", e.target.value)
                        }
                        name="custom_value2"
                        value={formik.values.custom_value2}
                        placeholder=""
                      >
                        <option value="">Select</option>
                        {custom2Array.map((apptType, i) => (
                          <option key={i} value={apptType}>
                            {apptType}
                          </option>
                        ))}
                      </Select>
                    </SimpleGrid>
                  )}
                </FormControl>

                <Button
                  isLoading={formik.isSubmitting}
                  type="submit"
                  h="50px"
                  borderRadius={"60px"}
                  colorScheme="purple"
                  width="full"
                >
                   {isEdit ? "Update " : "Create "}  Reminder
                </Button>
                {isPicker && <Box height={"300px"} />}
              </VStack>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
