import React from "react";
import { who, squareGreen, calendarBlue } from "../../images";
import {
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import moment from "moment";
import { FaTasks } from "react-icons/fa";
function TaskCard({ task }) {
  return (
    <Link
      style={{ textDecoration: "none" }}
      as={ReachLink}
      to={`/task/${task.id}`}
    >
      <Box my="2">
        <Heading fontSize={"13px"} fontWeight="600">
          {task.name}
        </Heading>

        <Box height="5px" />
        <Text color="paragraph" fontSize="12px">
          {task.descriptions}
        </Text>
        <Box height="5px" />
        <Flex
          gap="3"
          flexDirection={{
            base: "column",
            md: "row",
          }}
        >
          <Flex
            padding={"4px 8px"}
            borderRadius="10px"
            background="successLight"
            alignItems="center"
          >
            <Image src={squareGreen} boxSize="22px"></Image>
            <Text color="success" fontSize={"12px"}>
              {task.category}
            </Text>
          </Flex>
          <Flex
            padding={"4px 8px"}
            borderRadius="10px"
            background="successLight"
            alignItems="center"
          >
            <Image src={calendarBlue} boxSize="22px"></Image>
            <Text color="#1DA2C9" fontSize={"12px"}>
              {moment(task.when_to_perform_task).format("ddd, MMM, Do")}
            </Text>
          </Flex>
        </Flex>
        <Box height="5px" />
        {task.guests.length == 1 ? (
          <Flex gap="2" alignItems={"center"}>
            {task.guests[0].avatar ? (
              <Image borderRadius={"100%"} src={task.guests[0].avatar} boxSize="24px"></Image>
            ) : (
              <Box
                position={"relative"}
                fontWeight="600"
                color="white"
                boxSize={"24px"}
                borderRadius={"100%"}
                display="flex"
                background={"secondary"}
                alignItems="center"
                justifyContent="center"
              >
                {task.guests[0].first_name && task.guests[0].last_name ? (
                  <Text fontSize={"8px"}>
                    {task.guests[0].first_name.substring(0, 1)}
                    {task.guests[0].last_name.substring(0, 1)}
                  </Text>
                ) : (
                  <Text fontSize={"8px"}>CO</Text>
                )}
              </Box>
            )}
            <Heading fontSize={"12px"}>
              {task.guests[0].first_name} {task.guests[0].last_name}
            </Heading>
          </Flex>
        ) : (
          <Flex gap="-1" position="relative">
            {task.guests.slice(0, 2).map((guest, i) =>
              guest.avatar ? (
                <Image
                  position={"absolute"}
                  left={i * 22}borderRadius={"100%"}
                  key={i}
                  src={guest.avatar}
                  boxSize="24px"
                ></Image>
              ) : (
                <Box
                  position={"absolute"}
                  left={i * 22}
                  key={i}
                  fontWeight="600"
                  color="white"
                  boxSize={"24px"}
                  borderRadius={"100%"}
                  display="flex"
                  background={"secondary"}
                  alignItems="center"
                  justifyContent="center"
                >
                  {guest.first_name && guest.last_name ? (
                    <Text fontSize={"8px"}>
                      {guest.first_name.substring(0, 1)}
                      {guest.last_name.substring(0, 1)}
                    </Text>
                  ) : (
                    <Text fontSize={"8px"}>CO</Text>
                  )}
                </Box>
              )
            )}
            {task.guests.length > 3 ? (
              <Text>+ {task.guests.length - 3}</Text>
            ) : (
              ""
            )}
          </Flex>
        )}
        <Box height="35px" />
        <Divider color={"#EBECEF"} />
        <Box height="15px" />
      </Box>
    </Link>
  );
}

export default TaskCard;
