import React, { useEffect, useState } from "react";
import {
  PDFDownloadLink,
  Font,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { AppLogo } from "../../../images";
import moment from "moment";

const JornerPdf = (data) => {
  const { jorner } = data;
  Font.register({
    family: "Montserrat",
    src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw0aXx-p7K4KLjztg.woff",
  });
  
  const styles = StyleSheet.create({
    pdf: {
      height: "100vh",
      width: "100%",
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Montserrat",
    },
    titleProfile: {
      fontSize: 16,
      textAlign: "center",
      color: "#1a2020",
      fontFamily: "Montserrat",
    },
    titleProfileLarge: {
      fontSize: 28,
      fontWeight: "700",
      color: "#1a2020",
      // textAlign: "center",
      fontFamily: "Montserrat",
    },

    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 15,
    },
    subtitle: {
      fontSize: 14,
      // margin: 12,
      fontFamily: "Montserrat",
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },
    image: {
      // marginVertical: 15,
      // marginHorizontal: 50,
      width: "90%",
      maxHeight: "300px",
      objectFit: "contain",
      marginHorizontal: "auto",
    },
    logo: {
      width: "150px",
      // objectFit: "fill",
      margin: "0 auto",
      // marginHorizontal: 100,
    },
    space1: {
      marginBottom: 10,
    },
    space2: {
      marginBottom: 20,
    },
    spaceMain: {
      marginBottom: 70,
    },
    spaceMain2: {
      marginBottom: 40,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    websiteLink: {
      fontSize: 10,
      textAlign: "right",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    table: {
      // display: 'flex',
      width: "100%",
    },
    border: { borderBottom: "1px solid #1a2020", marginBottom: 4 },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    rowBetween: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tableHeader: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "5px",
      paddingRight: "5px",
      height: "30px",
      color: "rgb(11 1 73)",
    },
    flexGrow: {
      flexGrow: 1,
    },
    flex1: {
      flex: 1,
    },
    headerCell: {
      fontWeight: "bold",
      fontSize: 14,
      color: "#9297a4",
    },
    headerCell1: {
      fontWeight: "bold",
      fontSize: 14,
      color: "#9297a4",
      width: "100%",
    },

    cell: {
      // flex : 1,
      width: "50%",
      flexGrow: 1,
      color: "#9297a4",
      padding: 4,
      fontSize: 12,
      minHeight: 40,
    },
  });

  return (
    // <PDFViewer style={styles.pdf}>
    <Document>
      <Page style={styles.body}>
        <Text style={styles.titleProfile}>
          {jorner.first_name} {jorner.last_name}'s Profile
        </Text>

        <Text style={styles.spaceMain2}></Text>
        <View style={styles.rowBetween}>
          <View>
            <Text style={[styles.titleProfileLarge]} fixed>
              {jorner.first_name}
            </Text>
            <Text style={[styles.titleProfileLarge]} fixed>
              {jorner.last_name}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Image style={styles.logo} src={AppLogo} />
            <Text style={styles.websiteLink} fixed>
              https://www.sojorne.com
            </Text>
          </View>
        </View>
        <Text style={styles.spaceMain}></Text>

        <View style={styles.table}>
          <View style={[styles.tableHeader, { marginBottom: 1 }]}>
            <View style={[styles.headerCell, { width: "100%" }]}>
              <Text>Disability</Text>
            </View>
            <Text style={styles.headerCell}>Date Added</Text>
          </View>
          <View style={styles.border}></View>

          {jorner.disabilities &&
            jorner.disabilities.map((dis, i) => (
              <View key={i} style={[styles.row]}>
                <Text style={[styles.cell]}>{dis}</Text>
                <Text style={[styles.cell, { textAlign: "right" }]}>
                  {moment(jorner.created_at).format("MMM-YYYY-DD")}
                </Text>
              </View>
            ))}
          {!jorner.disabilities ||
            (jorner.disabilities.length == 0 && (
              <View style={{ height: "100px" }}></View>
            ))}
        </View>

        <Text style={styles.space2}></Text>

        <View style={styles.table}>
          <View style={[styles.tableHeader, { marginBottom: 1 }]}>
            <View style={[styles.headerCell, { width: "100%" }]}>
              <Text>Strength</Text>
            </View>
            <Text style={styles.headerCell}>Date Added</Text>
          </View>
          <View style={styles.border}></View>
          {jorner.strengths &&
            jorner.strengths.map((wdwfm, i) => (
              <View key={i} style={[styles.row]}>
                <Text style={[styles.cell]}>{wdwfm}</Text>
                <Text style={[styles.cell, { textAlign: "right" }]}>
                  {moment(jorner.created_at).format("MMM-YYYY-DD")}
                </Text>
              </View>
            ))}

          {!jorner.strengths || jorner.strengths.length == 0 ? (
            <View style={{ height: "100px" }}></View>
          ) : (
            ""
          )}
        </View>
        <Text style={styles.space2}></Text>
        <View style={styles.table}>
          <View style={[styles.tableHeader, { marginBottom: 1 }]}>
            <View style={[styles.headerCell, { width: "100%" }]}>
              <Text>Goals I am working on</Text>
            </View>
            <Text style={styles.headerCell}>Date Added</Text>
          </View>
          <View style={styles.border}></View>

          {jorner.goals_am_working_on &&
            jorner.goals_am_working_on.map((wdwfm, i) => (
              <View key={i} style={[styles.row]}>
                <Text style={[styles.cell]}>{wdwfm}</Text>
                <Text style={[styles.cell, { textAlign: "right" }]}>
                  {moment(jorner.created_at).format("MMM-YYYY-DD")}
                </Text>
              </View>
            ))}
          {!jorner.goals_am_working_on ||
            (jorner.goals_am_working_on.length == 0 && (
              <View style={{ height: "100px" }}></View>
            ))}
        </View>
        <Text style={styles.space2}></Text>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={[styles.headerCell1]}>Medications</Text>
            <Text style={styles.headerCell1}>Daily</Text>
            <Text style={styles.headerCell1}>Description</Text>
            <Text style={styles.headerCell1}>Short term</Text>
            <Text style={styles.headerCell1}>Date Added</Text>
          </View>
          <View style={styles.border}></View>

          {jorner.medications &&
            jorner.medications.map((medication, i) => (
              <View key={i} style={[styles.row]}>
                <Text style={[styles.cell]}>
                  {medication.brand_name}, {medication.generic_name}
                </Text>
                <Text style={[styles.cell]}>{medication.daily}</Text>
                <Text style={[styles.cell]}>
                  {medication.dosage_description}
                </Text>
                <Text style={[styles.cell]}>
                  {medication.is_short_time ? "Yes" : "No"}
                </Text>
                <Text style={[styles.cell, { textAlign: "right" }]}>
                  {moment(jorner.created_at).format("MMM-YYYY-DD")}
                </Text>
              </View>
            ))}

          {!jorner.medications ||
            (jorner.medications.length == 0 && (
              <View style={{ height: "100px" }}></View>
            ))}
        </View>
        <Text style={styles.space2}></Text>
        <View style={styles.table}>
          <View style={[styles.tableHeader, { marginBottom: 1 }]}>
            <View style={[styles.headerCell, { width: "100%" }]}>
              <Text>What works for me</Text>
            </View>
            <Text style={styles.headerCell}>Date Added</Text>
          </View>
          <View style={styles.border}></View>

          {jorner.what_works_for_me &&
            jorner.what_works_for_me.map((wwfm, i) => (
              <View key={i} style={[styles.row]}>
                <Text style={[styles.cell]}>{wwfm}</Text>
                <Text style={[styles.cell, { textAlign: "right" }]}>
                  {moment(jorner.created_at).format("MMM-YYYY-DD")}
                </Text>
              </View>
            ))}
          {!jorner.what_works_for_me ||
            (jorner.what_works_for_me.length == 0 && (
              <View style={{ height: "100px" }}></View>
            ))}
        </View>
        <Text style={styles.space2}></Text>
        <View style={styles.table}>
          <View style={[styles.tableHeader, { marginBottom: 1 }]}>
            <View style={[styles.headerCell, { width: "100%" }]}>
              <Text>What doesn't work for me</Text>
            </View>
            <Text style={styles.headerCell}>Date Added</Text>
          </View>
          <View style={styles.border}></View>

          {jorner.what_doesnt_work_for_me &&
            jorner.what_doesnt_work_for_me.map((wdwfm, i) => (
              <View key={i} style={[styles.row]}>
                <Text style={[styles.cell]}>{wdwfm}</Text>
                <Text style={[styles.cell, { textAlign: "right" }]}>
                  {moment(jorner.created_at).format("MMM-YYYY-DD")}
                </Text>
              </View>
            ))}
        </View>
        <Text style={styles.space2}></Text>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default JornerPdf;
