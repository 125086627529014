import {
  Box,
  Heading,
  Flex,
  Spacer,
  Stack,
  Skeleton,
  SimpleGrid,
  useToast,
  Text,
} from "@chakra-ui/react";
import "../../utils/mp3.css";

import { Link as ReachLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";

import AppLink from "../../components/link";
import NoTask from "../../components/dashboard/no_task";
import {
  CalendarDaysIcon,
  ChevronRightIcon,
  PlusIcon,
  TagIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { isValidUrl } from "../../utils/utils";
import { FaNotesMedical, FaShare } from "react-icons/fa";
import moment from "moment";
import {
  BiAlarm,
  BiDotsHorizontal,
  BiDotsVertical,
  BiTag,
} from "react-icons/bi";
import JornerCard2 from "../../components/dashboard/jorner_card2";
import NoJorner from "../../components/dashboard/no_jorner";
import DashboardLayout from "../../layout/dashboard";

function MedicationPage() {
  const toast = useToast();
  const [tags, setTags] = useState([]);
  const [jorners, setJorners] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  const user = JSON.parse(sessionStorage.getItem("sj_user"));


  useEffect(() => {
    loadConstants();
  }, [true]);

  const loadConstants = async () => {
      // console.log(user);
      var url;
      if (user.tag == "cocg") {
        url = `co_caregiver/${user.id}/jorners`;
      } else {
        url = `jorner`;
      }
      var j = await httpGetWithTokenRest(url);
      if (j.status == "success") {
        const jor = j.data;
        setJorners(j.data);
      }
 
    setLoading(false);
  };



  return (
    <DashboardLayout
    element={
    <Box padding={"19px 20px"} minHeight="100vh" background={"#FAF8FF"}>
    
      <Spacer height={"22px"} />
      <Box>
        <Flex flexDirection={"column"} >
          <Heading as={"h3"} fontSize={"18px"} fontWeight="600" color="black">
          Medications
          </Heading>
        <Text color="#747A8B" fontSize="12px" >Select a Jorner to view their medications and schedules</Text>
        </Flex>
        <Box h="10px" />
        {loading && (
          <Stack mt={5}>
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
            <Skeleton startColor="#808080" height="50px" />
          </Stack>
        )}
      </Box>
      <Spacer height={"22px"} />
      {jorners.length === 0 ? (
          <NoJorner background="transaparent" shadow="none" />
        ) :""}
        <Box rounded={"lg"} shadow="lg" background={"white"} padding={"5"}>

        <SimpleGrid gap={3} columns={{ base: 1, md: 2, lg: 3 }}>
          {
            jorners.map((item, i)=> (
              <JornerCard2 onclick={()=> {
                navigate("/medications/"+item.id)
              }} bg="#FAF8FF" key={i} jorner={item} width="100%" />
            ))
          }
          </SimpleGrid>

        </Box>
    </Box>}
     />
  );
}

export default MedicationPage;
