//Auth routes
const homeRoute = "/";
const loginRoute = "/login";
const forgetPasswordRoute = "/forget-password";
const otpForgetPasswordRoute = "/otp-forget-password";
const signupRoute = "/signup";
const otpRoute = "/otp";
const resetPasswordRoute = "/reset-password";

//Onboarding routes
const onboardingStartRoute = "/onboarding-start";
const dashboardRoute = "/dashboard";
const onBoardingRoute = "/onboarding";
const jornersRoute = "/jorners";
const singleJornerRoute = "/jorner/:id";
const AddJornerRoute = "/add-jorner";
const EditJornerRoute = "/jorner/edit/:id";
const AddJornerRoute2 = "/add-jorner/profile";
const TaskRoute = "/tasks";
const ViewTaskRoute = "/task/:id";
const EditTaskRoute = "/tasks/edit/:id";
const CreateTaskRoute = "/task/create";

//calendar
const calendarRoute = "/calendar";

//provider
const providersRoute = "/providers";
const subscribeRoute = "/subscribe/:sub";
const singleProvidersRoute = "/providers/:id";
const singleProviderDetailsRoute = "/providers/:id/:providerId";

//community
const communityRoute = "/care-circle";
const singleCommunityRoute = "/care-circle/:id";

//profile
const profileRoute = "/profile";
const profileNextRoute = "/profile/:profileNext";
const notesRoute = "/jorner-schedule";
const aiRoute = "/ai-notes";
const editNoteRoute = "/jorner-schedule/:id/edit";
const addNoteRoute = "/jorner-schedule/create";

const appointmentsRoute = "/appointments";
const viewAppointmentRoute = "/appointments/:id";
const addAppointmentsRoute = "/appointments/create";

const remindersRoute = "/reminders";
const editRemindersRoute = "/reminders/:id/edit";
const addReminderRoute = "/reminders/create";
const medicationsRoute = "/medications";
const jornerMedicationsRoute = "/medications/:jorner_id";
const medicationScheduleRoute = "/medications/schedules/:schedule_id";

const insurancesRoute = "/insurance-card";
const viewinsuranceRoute = "/insurance-card/:id";
const addinsurancesRoute = "/insurance-card/create";
const plansRoute = "/plans";
const subscriptionRoute = "/subscriptions";
const documentRoute = "/documents";
const documentFolderRoute = "/documents/:folder_id";

export {
  documentFolderRoute,
  documentRoute,
  subscribeRoute,
  subscriptionRoute,
  plansRoute,
  insurancesRoute,
  viewinsuranceRoute,
  addinsurancesRoute,
  medicationScheduleRoute,
  jornerMedicationsRoute,
  medicationsRoute,
  aiRoute,
  homeRoute,
  loginRoute,
  forgetPasswordRoute,
  otpForgetPasswordRoute,
  otpRoute,
  signupRoute,
  onboardingStartRoute,
  dashboardRoute,
  resetPasswordRoute,
  onBoardingRoute,
  jornersRoute,
  singleJornerRoute,
  AddJornerRoute,
  AddJornerRoute2,
  TaskRoute,
  calendarRoute,
  providersRoute,
  communityRoute,
  singleProvidersRoute,
  singleProviderDetailsRoute,
  singleCommunityRoute,
  profileRoute,
  ViewTaskRoute,
  CreateTaskRoute,
  EditTaskRoute,
  EditJornerRoute,
  notesRoute,
  addNoteRoute,
  editNoteRoute,
  appointmentsRoute,
  addAppointmentsRoute,
  remindersRoute,
  editRemindersRoute,
  addReminderRoute,
  viewAppointmentRoute,
  profileNextRoute,
};
