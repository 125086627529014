import { Box, Text } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Nav, Sidebar } from "../components";
import { Helmet } from "react-helmet";
import { loginRoute } from "../utils/allRoutes";
import ls from "localstorage-slim";
import { useLocation, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import {
  httpGetWithTokenRest,
  httpPatchWithTokenRest,
  httpPostWithToken,
} from "../utils/http_utils";
import { ValidDate, generateToken } from "../utils/utils";
import { AppContext } from "../redux/appContext";
import PlanExpired from "../components/dashboard/plan_expired";
import PlanRestricted from "../components/dashboard/plan_restricted";

function DashboardLayout({ element }) {
  const navigate = useNavigate();
  const { subscription } = useContext(AppContext);
  const sidebarState = useSelector((state) => state.sidebar.value);
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [current, setCurrent] = useState(0);
  const { pathname, search } = useLocation();

  const [isRestrictText, setIsRestrictText] = useState("");

  // when route change, lets do  something
  const onIdle = () => {
    setState("Idle");
    ls.clear();
    sessionStorage.clear();
    navigate(loginRoute);
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 600000,
    throttle: 500,
  });
  useEffect(() => {
    fetchSub();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
      // console.log("here here");
    }, 1000);

    const timeSpentInterval = setInterval(async () => {
      // console.log(current + 1);
      setCurrent(current + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(timeSpentInterval);
    };
  });
  const fetchSub = async () => {
    let r = await httpGetWithTokenRest("subscription");
    console.log(r);
  };
  useEffect(() => {
    // console.log(current);
    if (current == 60) {
      setCurrent(0);
      let token = sessionStorage.getItem("session_token") || null;

      if (!token) {
        token = generateToken();
        sessionStorage.setItem("session_token", token);
      }
      // console.log("going");
      httpPostWithToken("time-spent/new", {
        time_spent: 60,
        sessionKey: token,
      });
    }
  }, [current]);
  return (
    <Box overflowX={sidebarState == "sidebar_active" ? "hidden" : ""}>
      {/* sidebar */}
      {sidebarState == "sidebar_active" ? <Sidebar /> : ""}
      {/* main area */}
      <Box marginLeft={sidebarState == "sidebar_active" ? "264px" : "0"}>
        {/* top nav */}
        <Nav />

        {ValidDate(subscription.end_date) ||
        window.location.href.includes("plans") ||
        window.location.href.includes("payment") ||
        window.location.href.includes("access-denied") ? (
          element
        ) : (
          <PlanExpired />
        )}
      </Box>
      {/* main area end here */}
    </Box>
  );
}

export default DashboardLayout;
