import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import { Link as ReachLink, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  finance,
  noise,
  provider,
  team,
  tribe,
  who,
  xIcon,
} from "../../images";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import NoTask from "../../components/dashboard/no_task";
import JornerCard from "../../components/dashboard/jorner_card";
import {
  ChevronRightIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/solid";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  PencilSquareIcon,
  TrashIcon,
  CalendarDaysIcon,
  UserCircleIcon,
  FlagIcon,
  BellAlertIcon,
} from "@heroicons/react/24/solid";
import {
  httpGetWithTokenRest,
  httpPatchWithTokenRest,
} from "../../utils/http_utils";
import NoTabItem from "../../components/dashboard/no_tab_item";
import moment from "moment";
import DeleteAppointmentModal from "./delete_appointment_modal";
import CreateAppointmentModal from "./create_appointment_modal";

function ViewAppointment() {
  const [isLoading, setIsLoading] = useState(true);
  const [appointment, setAppointment] = useState({});
  const [noTask, setNoTask] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const {
    isOpen: isOpenComplete,
    onOpen: onOpenComplete,
    onClose: onCloseComplete,
  } = useDisclosure();
  useEffect(() => {
    loadConstants();
  }, []);

  const loadConstants = async () => {
    var j = await httpGetWithTokenRest(`appointments/${params.id}`);
    if (j.status == "success") {
      setIsLoading(false);
      setAppointment(j.data);
      console.log(j.data);
    } else {
      setNoTask(true);
    }
  };
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();

  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const getMinutes = (date1, date2) => {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    var d3 = Math.abs(d2 - d1);
    return Math.ceil(d3 / (1000 * 60));
    // return "";
  };

  const getMoment = (date) => {
    var d = date.replace("T", " ").replace("Z", "");
    return moment(d).format("h:mm:ss a");
  };
  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      {isLoading ? (
        ""
      ) : (
        <>
          <Spacer height={"22px"} />
          <DeleteAppointmentModal
            onClose={() => {
              onCloseDelete();
            }}
            isOpen={isOpenDelete}
            appointment={appointment?.id}
            onFinished={loadConstants}
          />

          <CreateAppointmentModal
            coGiverConstants={[]}
            onClose={onCloseCreate}
            appointment={appointment}
            isOpen={isOpenCreate}
            onFinished={(callbackValue) => {
              if (callbackValue == "updated") {
                loadConstants();
              }
            }}
          />
          <Box>
            <Flex alignItems={"center"} justifyContent="space-between">
              <Box display="flex" alignItems={"center"}>
                <AppLink
                  hasLeftIcon={true}
                  leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
                  shadow="none"
                  padding="0"
                  text=""
                  background="transparent"
                  color="black"
                  width="fit-content"
                  textDecoration="none"
                  href="/appointments"
                />
                <Box width="2px" />
                <Heading
                  color={"black"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                  as={"h3"}
                >
                  {appointment.name}
                </Heading>
              </Box>
              <Flex
                gap="3"
                display={{ base: "none", md: "flex", lg: "flex" }}
                alignItems={"center"}
              >
                <AppLink
                  hasLeftIcon={true}
                  shadow="none"
                  height="40px"
                  leftIcon={<Icon as={PencilSquareIcon} fontSize="20px" />}
                  padding="0 15px"
                  text="Edit"
                  background="white"
                  onclick={onOpenCreate}
                  color="paragraph"
                  textDecoration="none"
                  href={"#?"}
                />

                <AppLink
                  hasLeftIcon={true}
                  onclick={onOpenDelete}
                  shadow="none"
                  height="40px"
                  leftIcon={<Icon as={TrashIcon} fontSize="20px" />}
                  padding="0 15px"
                  text="Delete"
                  background="#DB504A"
                  color="white"
                  textDecoration="none"
                  href="#?"
                />
              </Flex>

              <Flex
                gap="1"
                display={{ base: "flex", md: "none", lg: "none" }}
                alignItems={"center"}
              >
                <AppLink
                  hasLeftIcon={true}
                  shadow="none"
                  height="40px"
                  leftIcon={<Icon as={PencilSquareIcon} fontSize="20px" />}
                  padding="0 2px"
                  text=""
                  background="#"
                  color="paragraph"
                  textDecoration="none"
                  href={"#?"}
                />

                <AppLink
                  hasLeftIcon={true}
                  onclick={onOpenDelete}
                  shadow="none"
                  height="40px"
                  leftIcon={<Icon as={TrashIcon} fontSize="20px" />}
                  padding="0 12px"
                  text=""
                  background="#"
                  color="red"
                  textDecoration="none"
                  href="#?"
                />
              </Flex>
            </Flex>
            <Box h="10px" />

            <Box
              shadow={"sm"}
              background="white"
              padding="30px"
              borderRadius={"16px"}
            >
              <Box my="2">
                <Heading fontSize={"13px"} fontWeight="600">
                  {appointment.name}
                </Heading>
                <Box height="5px" />
                <Text color="paragraph" fontSize="13px">
                  {appointment.description}
                </Text>
                <Box height="10px" />
                <Divider color={"#EBECEF"} />
                <Box height="10px" />
                <Flex>
                  <Flex alignItems={"center"} width="100%">
                    <Icon as={CalendarDaysIcon} fontSize="14px" />
                    <Box width="10px" />
                    <Text fontSize={"13px"}>
                      {moment(appointment.appt_date).format("ddd, MMM, Do")}
                    </Text>

                    <Text fontSize={"13px"}>
                      {" "}
                      &nbsp; &nbsp;{" "}
                      {
                        getMoment(appointment.appt_date)
                        // moment(appointment.appt_date).format("h:mm:ss a")
                      }
                    </Text>
                  </Flex>
                </Flex>
                <Box height="10px" />
                <Divider color={"#EBECEF"} />
                <Box height="15px" />

                <Box height="10px" />
              </Box>
            </Box>
          </Box>

          <Spacer height={"22px"} />
        </>
      )}
    </Box>
  );
}

export default ViewAppointment;
