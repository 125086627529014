import React, { useContext, useState } from 'react'
import { Box, Image, Link, Heading, Text, Flex, Spacer, Icon, Button, useToast } from '@chakra-ui/react'
import AppLink from '../link'
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import { notFound, notask} from '../../images';
import { httpPostWithToken } from '../../utils/http_utils';
import { AppContext } from '../../redux/appContext';
import { useNavigate } from 'react-router-dom';

function PlanRestricted() {
  const {restrictText} = useContext(AppContext)
  const navigate = useNavigate()
  return (
   
        <Box mt="40px" shadow={"sm"} background="white" padding="30px" borderRadius={"16px"} display="flex" flexDirection="column" justifyContent={"center"} alignItems="center">
          <Image src={notFound} boxSize={"205px"} ></Image>
          <Box h="10px" />
          <Heading as="h6" textAlign={"center"} fontSize={"20px"}>
            Access Restricted
            </Heading>
          <Box h="10px" />

          <Box textAlign={"center"} maxW="480px">
          <Text color="paragraph">
          Please upgrade your plan to use this service.
          </Text>
          <Box h="10px" />
          <Flex alignItems={"center"} gap="3" justifyContent={"center"}>
             <Button onClick={()=>navigate("/plans")} colorScheme='purple'>Upgrade</Button>
          </Flex>
          </Box>
        </Box>

  )
}

export default PlanRestricted