import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React from "react";

export default function CustomModal({isOpen, onClose, body, header='', isCloseable = false, size='sm'}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={isCloseable} size={size} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader>{header}</ModalHeader>
        {isCloseable ? <ModalCloseButton />: null}
        <ModalBody>
          {body}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
