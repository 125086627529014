import React, { useContext, useState } from "react";
import DashboardLayout from "../../layout/dashboard";
import {
    Avatar,
    AvatarGroup,
    Box,
    Flex,
    Image,
    Spacer,
    Text,
} from "@chakra-ui/react";
import { circlePattern, ring } from "../../images";
import { communityRoute } from "../../utils/allRoutes";
import { useNavigate } from "react-router-dom";

export default function CareCircleCard({ community }) {
    const navigate = useNavigate();
    const getRandomColors = () => {
        const colorList = [
            {
                textColor: "secondary",
                bgColor: "#FF6E57",
            },
            {
                textColor: "primary",
                bgColor: "primary",
            },
            {
                textColor: "secondary",
                bgColor: "#36BBE2",
            },
        ];
        return colorList[Math.floor(Math.random() * colorList.length)];
    };
    return (
        <Box key={community.id} position={"relative"}>
            <Box
                bg={getRandomColors().bgColor}
                bgImage={circlePattern}
                bgRepeat={"no-repeat"}
                bgPosition={"right"}
                bgSize={"contain"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                minH={{base : "124px", md : "180px", lg : "180px"}}
                py={4}
                px={3}
                color={"#ffffff"}
                borderRadius={16}
                cursor={"pointer"}
                onClick={
                    () => {
                        navigate(`${communityRoute}/${community?.id}`)
                    }
                }
            >
                <Box style={{
                    position : "relative"
                }}>
                    <Image src={ring} 
                    boxSize={{base : "85px", md : "100px", lg : "100px"}} />
                    <Box
                    bg="transparent"
                    position={"absolute"}
                    boxSize={{base : "85px", md : "100px", lg : "100px"}}
                    borderRadius={"100%"}
                    top={0}
                    left={0}
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    >
                <Box
                style={{
                    padding : 2,
                    borderRadius : "100%",
                    background : "white"
                }}
                >
                {community?.jorner?.avatar ? (
                  <Image
                    boxSize={{base : "71px", md : "84px", lg : "84px"}}
                    borderRadius={"100%"}
                    src={community?.jorner?.avatar}
                  />
                ) : (
                  <Box
                    fontWeight="600"
                    color="white"
                    boxSize={{base : "71px", md : "84px", lg : "84px"}}
                    borderRadius={"100%"}
                    display="flex"
                    background={"secondary"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text fontSize={"24px"}>
                      {community?.jorner?.first_name?.substring(0, 1)}
                      {community?.jorner?.last_name?.substring(0, 1)}
                    </Text>
                  </Box>
                )}
                </Box>
              </Box>
                </Box>
                <Box 
                    boxSize={"12px"}
                ></Box>
                <Box flex={1}>
                    <Text fontSize={18.9} fontWeight={600} mb={3}>
                        {community?.name}
                    </Text>
                    <Text fontSize={12} fontWeight={400} mb={3}>
                        {community?.descriptions}
                    </Text>
                    <Flex
                        alignItems={"center"}
                        display={{
                            base: "block",
                            md: "block",
                            lg: "block",
                            xl: "flex",
                        }}
                    >
                        <AvatarGroup
                            size="sm"
                            max={3}
                            mr={community.members.length > 3 ? 0 : 2}
                        >
                            {community.members.map((e, i) => (
                                <Avatar
                                    name={e?.first_name}
                                    src={e?.avatar}
                                />
                            ))}
                        </AvatarGroup>
                        <span>members</span>
                        <Spacer />
                    </Flex>
                </Box>

            </Box>
        </Box>
    )
}