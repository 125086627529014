import { extendTheme } from "@chakra-ui/react";
import {  MultiSelectTheme } from 'chakra-multiselect'
const AppTheme = extendTheme({
  colors: {
    black: "#17181C", // title color
    primary: "#7353BA", //this is the purple color
    primaryLight: "#7353BA", //this is the purple color
    primaryBg : "#FAF8FF",
    secondary : "#FF6E57", //this is the orange color
    paragraph : "#747A8B", // paragraph color
    background: '#FAF8FF',
    grey : "#EBECEF",
    gray : "#F7F7F8",
    successLight: "#E7FEEE",
    success : "#099A38",
    warning : "#FFBA2D"
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Montserrat, sans-serif",
  },
  components: {
    MultiSelect: MultiSelectTheme
  }
});

export default AppTheme;
