import {
    AbsoluteCenter,
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    SimpleGrid,
    useDisclosure,
    useToast,
    Spacer,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLogo, checkIcon, checkIconWhite, successGif } from "../../images";
import { loginRoute } from "../../utils/allRoutes";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CustomModal from "../../components/customModal";
import * as Yup from "yup";
import LoginSide from "../../components/loginSide";
import ls from "localstorage-slim";
import {
    httpGetWithTokenRest,
    httpPatchWithOneTimeToken,
    httpPatchWithoutToken,
    httpPostWithoutToken,
} from "../../utils/http_utils";
import { capitalizeFirstWord } from "../../utils/utils";
import PlanComponent from "../../components/plan_component";

function SubscribeOut() {
    const navigate = useNavigate();
    const authCode = ls.get("sj_r_p_a", { decrypt: true });
    const isCC = ls.get("sj_iscc", { decrypt: true });
    const [show, setShow] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const handleClick = () => setShow(!show);
    const handleClickConfirm = () => setShowConfirm(!showConfirm);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const params = useParams();
    const email = ls.get("sj_email", { decrypt: true });
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = React.useState(true);
    const [ploading, setPLoading] = React.useState(false);
    const [planType, setPlanType] = React.useState("monthly");
    const [currrentSub, setCurrentSub] = React.useState({})

    useEffect(() => {
        let sub = capitalizeFirstWord(params.sub)
        subForMe("", sub)

    }, [])
    const loadConstants = async () => {
        var j = await httpGetWithTokenRest("plans");
        if (j.status == "success") {
            console.log(j.data)
            setPlans(j.data)
        }

        setLoading(false);
    };
    const subForMe = async (subid, sub) => {
        const resp = await httpPostWithoutToken('subscribe', {
            email: email,
            plan_name: sub,
            callback_url: "https://app.sojorne.com/login"
        })

        if (resp.error !== null && resp.error !== undefined) {
            loadConstants()
            return toast({
                title: "Sorry, we hit a snag!.",
                description: `${resp.error}`,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } else {
            // console.log(resp)
            window.location.href = resp.data.payload.url
        }
    }
    return (
        <>
            <Box>
                <Box height={'100px'}></Box>
                <Flex flexDir="column" alignItems={"center"} justifyContent="center">
                    <Heading as={"h3"} fontSize={"40px"} fontWeight="600" color="#101828">
                        Pricing plans
                    </Heading>
                    <Box h="5px" />
                    <Text color="#475467" maxW={'700px'} >
                        Sojorne offers simplare, transparent pricing that grows with you. Subscribe annually to save and lock our lowest prices.</Text>
                </Flex>
                <Box h="20px" />

                <Flex mx="auto" w={"fit-content"} borderRadius={"10px"} borderWidth={"2px"} borderColor={"#7353BA"} p="1" justifyContent={"center"} alignItems={"center"} gap="3">
                    <Box p="2" borderRadius={"10px"} d="flex" cursor={"pointer"} onClick={() => {
                        setPlanType("monthly")
                    }} justifyContent={"center"} alignItems={"center"} bg={planType == "monthly" ? "#7353BA" : "none"}>
                        <Text fontSize="13px" fontWeight="600" color={planType == "monthly" ? "#fff" : "#667085"}>Monthly billing</Text>
                    </Box>
                    <Box p="2" borderRadius={"10px"} d="flex" cursor={"pointer"} onClick={() => {
                        setPlanType("yearly")
                    }} justifyContent={"center"} alignItems={"center"} bg={planType == "yearly" ? "#7353BA" : "none"}>
                        <Text fontSize="13px" fontWeight="600" color={planType == "yearly" ? "#fff" : "#667085"}>Annual billing</Text>
                    </Box>
                </Flex>
                <Spacer height={"22px"} />
                <SimpleGrid padding={'3'} mx={'auto'} maxWidth={'100px'} gap={3} columns={{ base: 1, md: 1, lg: 3 }}>
                    {
                        plans.map((plan, i) => (
                           <PlanComponent subForMe={(id, y)=> subForMe(id, y)} plan={plan} i={i} loading={loading} planType={planType} />
                        ))
                    }
                </SimpleGrid>
            </Box>
        </>
    );
}

export default SubscribeOut;
