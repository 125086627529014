import { jwtDecode } from "jwt-decode";
import { setUser } from "../reducers/authSlice";
import { httpPostWithoutToken } from "./http_utils";
import {
  SESSION_STORAGE_ACCESS_TOKEN,
  SESSION_STORAGE_REFRESH_TOKEN,
  SESSION_STORAGE_LAST_ACTIVITY,
  SESSION_STORAGE_USER,
} from "../constants/sessionStorage";

export const setAuth = (dispatch) => {
  const token = getAccessToken() || "";
  const user = getUser() || "";

  if (!token) return;

  dispatch(
    setUser({
      token,
      user,
      sj_isAuth: true,
    })
  );
};

export const logOut = () => {
  window.location.href = "/login";
};

export const getAccessToken = () =>
  sessionStorage.getItem(SESSION_STORAGE_ACCESS_TOKEN);
export const setAccessToken = (token) =>
  sessionStorage.setItem(SESSION_STORAGE_ACCESS_TOKEN, token);
export const removeAccessToken = () =>
  sessionStorage.removeItem(SESSION_STORAGE_ACCESS_TOKEN);

export const getRefreshToken = () =>
  sessionStorage.getItem(SESSION_STORAGE_REFRESH_TOKEN);
export const setRefreshToken = (token) =>
  sessionStorage.setItem(SESSION_STORAGE_REFRESH_TOKEN, token);
export const removeRefreshToken = () =>
  sessionStorage.removeItem(SESSION_STORAGE_REFRESH_TOKEN);

export const getUserActivity = () =>
  sessionStorage.getItem(SESSION_STORAGE_LAST_ACTIVITY);
export const setUserActivity = (activity) =>
  sessionStorage.setItem(SESSION_STORAGE_LAST_ACTIVITY, String(activity));
export const removeUserActivity = () =>
  sessionStorage.removeItem(SESSION_STORAGE_LAST_ACTIVITY);

export const getUser = () => sessionStorage.getItem(SESSION_STORAGE_USER);

export const setTokens = (accessToken, refreshToken) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

export const deleteTokens = () => {
  removeAccessToken();
  removeRefreshToken();
};

export const getDecodedToken = (token) => {
  let decodedToken;

  try {
    decodedToken = jwtDecode(token);
  } catch (e) {
    decodedToken = null;
  }

  return decodedToken;
};

export const checkIfTokenExpired = (token) => {
  const decodedToken = getDecodedToken(token);
  const exp = decodedToken?.exp;

  if (!exp || exp - new Date().getTime() / 1000 < 60) {
    return true;
  } else {
    return false;
  }
};

export const onRefreshToken = async () => {
  const refreshToken = getRefreshToken();

  const token = getAccessToken() || null;

  // Check if a refresh token is expired
  if (checkIfTokenExpired(refreshToken)) {
    // this will only take effect, if there is Auth User
    if (token) {
      logOut();
    }
    return;
  }

  const { data, error } = await httpPostWithoutToken("caregiver/auth/refresh", {
    refreshToken,
  });

  if (error) {
    // Logout only in case if a BE returns an error with a message "Invalid refresh token"
    if (error === 'Invalid refresh token') {
      logOut();
    }
    return;
  }

  const newAccessToken = data?.token;
  const newRefreshToken = data?.refreshToken;
  setTokens(newAccessToken, newRefreshToken);
};
