import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { httpPatch2WithTokenRest } from "../../utils/http_utils";
import { containsCapitalLetter, containsNumber, containsSpecialChars } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
  const [show, setShow] = React.useState(false);
  const [oldShow, setOldShow] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate()
  const handleClickConfirm = () => setShowConfirm(!showConfirm);
  const handleClickOld = () => setOldShow(!oldShow);
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    if (values.new_password !== values.confirm_password) {
      return toast({
        title: "Password mismatch.",
        description: "Password doesn't match",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    
    if (!containsCapitalLetter(values.new_password)) {
      return toast({
        title: "Please use a secure password.",
        description: "Password must contain atleast one Capital letter.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (!containsNumber(values.new_password)) {
      return toast({
        title: "Please use a secure password.",
        description: "Password must contain atleast one number.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (!containsSpecialChars(values.new_password)) {
      return toast({
        title: "Please use a secure password.",
        description:
          "Password must be at least 8 characters, password must contain at least 1 special character.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }


    let data = {
      new_password: values.new_password,
      old_password: values.old_password,
    };
    const resp = await httpPatch2WithTokenRest("change-password", data);    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      reset();
      navigate("/login")
      return toast({
        title: "Success.",
        description: `Password Changed successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <Box px={{ base: 2, md: 12 }} py={3}>
      <Box h={6} />
      <Text color={"#FF6E57"} fontSize={20} fontWeight={600} my={1}>
        Change Password
      </Text>
      <Box h={6} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt={5} isInvalid={errors.old_password} fontWeight={600}>
          <FormLabel htmlFor="name">Old Password</FormLabel>
          <InputGroup>
            <Input
              id="old_password"
              name="old_password"
              type={oldShow ? "text" : "old_password"}
              placeholder="e.g Sojorne1234$"
              {...register("old_password", {
                required: "This is required",
                minLength: {
                  value: 8,
                  message: "Minimum length should be 8",
                },
              })}
              borderColor={"#E3E4E8"}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClickOld}>
                {oldShow ? <FaEye /> : <FaEyeSlash />}
              </Button>
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage>
            {errors.old_password && errors.old_password.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt={5} isInvalid={errors.new_password} fontWeight={600}>
          <FormLabel htmlFor="name">New Password</FormLabel>
          <InputGroup>
            <Input
              id="new_password"
              name="new_password"
              type={show ? "text" : "password"}
              placeholder="e.g Sojorne1234$"
              {...register("new_password", {
                required: "This is required",
                minLength: {
                  value: 8,
                  message: "Minimum length should be 8",
                },
              })}
              borderColor={"#E3E4E8"}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? <FaEye /> : <FaEyeSlash />}
              </Button>
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage>
            {errors.new_password && errors.new_password.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          mt={5}
          isInvalid={errors.confirm_password}
          fontWeight={600}
        >
          <FormLabel htmlFor="name">Confirm Password</FormLabel>
          <InputGroup>
            <Input
              id="confirm_password"
              name="confirm_password"
              type={showConfirm ? "text" : "password"}
              placeholder="e.g Sojorne1234$"
              {...register("confirm_password", {
                required: "This is required",
                minLength: {
                  value: 8,
                  message: "Minimum length should be 8",
                },
              })}
              borderColor={"#E3E4E8"}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClickConfirm}>
                {showConfirm ? <FaEye /> : <FaEyeSlash />}
              </Button>
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage>
            {errors.confirm_password && errors.confirm_password.message}
          </FormErrorMessage>
        </FormControl>

        <Button
          mt={8}
          mb={4}
          size={"lg"}
          w={"100%"}
          color="#fff"
          borderRadius={64}
          background={"primary"}
          isLoading={isSubmitting}
          type="submit"
        >
          Reset
        </Button>
      </form>
    </Box>
  );
}
