import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { FaEdit, FaUser } from "react-icons/fa";
import PhoneNumberInput2 from "../../components/phoneNumberInput2";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { COUNTRIES } from "../../utils/countries";
import ls from "localstorage-slim";
import {
  httpGetWithTokenRest,
  httpPatchWithTokenRest,
  httpWithTokenRest,
} from "../../utils/http_utils";
import PhoneNumberInput from "../../components/phoneNumberInput";

export default function ProfileOnboarding({ currentStep, updateCurrentStep }) {
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const token = ls.get("sj_token", { decrypt: true });
  const toast = useToast();
  const [value, setPhone] = React.useState(user?.phone_number);
  const [countryCode, setCountryCode] = React.useState(
    user?.country_code ?? "+1"
  );
  const navigate = useNavigate();
  const imageRef = React.useRef();
  const [profileImage, setProfileImage] = React.useState(null);
  const [profileImageUrl, setProfileImageUrl] = React.useState(null);
  const [profileImageRes, setProfileImageRes] = React.useState(null);
  const chooseFile = () => {
    imageRef.current.click();
  };

  const imageHandler = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImageRes(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      age: user?.age_range,
      gender: user?.gender,
    },
  });

  async function onSubmit(values) {
    const phoneNumber = value.replace(/\s/g, "").replace("+1", "");
    // alert(phoneNumber);
    const formData = new FormData();
    if (countryCode === null) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: "Select Country code",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (profileImageUrl == null && profileImageRes == null) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: "Upload an image",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    formData.append("phone_number", phoneNumber);
    formData.append("age_range", values.age);
    formData.append("gender", values.gender);
    formData.append("country_code", countryCode);
    formData.append("avatar", profileImageRes);
    console.log("phone", phoneNumber)
    const resp = await httpPatchWithTokenRest("caregiver", formData);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      user.phone_number = phoneNumber;
      user.age_range = values.age;
      user.gender = values.gender;
      user.country_code = countryCode;
      ls.set("sj_user", user, { encrypt: true });
      updateCurrentStep(2);
    }
  }

  const fetchUserProfile = async () => {
    const resp = await httpGetWithTokenRest("caregivers/profile");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag1!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const data = resp.data;
      setPhone(
        data?.phone_number
          ? data?.phone_number.replace("+1", "").replace("undefined", "")
          : ""
      );
      // alert(data?.phone_number);
      setProfileImageUrl(data?.avatar);
      reset({
        gender: data?.gender,
      });
      setValue("gender", data?.gender);
      setValue("age", data?.age_range);
    }
  };
  const skipOnboarding = () => {
    navigate("/dashboard");
  };
  React.useEffect(() => {
    fetchUserProfile();
  }, [currentStep]);
  return (
    <>
      <Box mb={8}>
        <Text fontSize={20} fontWeight={600}>
          Complete Profile
        </Text>
        <Text fontSize={14} fontWeight={400} color={"#747A8B"}>
          Provide more basic information to complete your profile.
        </Text>
      </Box>
      <Box>
        <Center mb={2}>
          {profileImage !== null ? (
            <Avatar
              size={"xl"}
              cursor={"pointer"}
              src={profileImage}
              onClick={chooseFile}
            >
              <AvatarBadge
                boxSize="0.85em"
                children={<FaEdit color="#FF6E57" />}
              />
            </Avatar>
          ) : (
            <Avatar
              icon={<FaUser fontSize="2.5rem" />}
              size={"xl"}
              src={profileImageUrl}
              bg={"#EBECEF"}
              cursor={"pointer"}
              onClick={chooseFile}
            >
              <AvatarBadge
                boxSize="0.85em"
                children={<FaEdit color="#FF6E57" />}
              />
            </Avatar>
          )}
          <Input
            ref={imageRef}
            type={"file"}
            accept="image/*"
            onChange={(e) => imageHandler(e)}
            hidden
          ></Input>
        </Center>
        <Text
          textAlign={"center"}
          color={"secondary"}
          textDecoration={"underline"}
          cursor={"pointer"}
          onClick={chooseFile}
        >
          Add photo
        </Text>
        <Text mt={1} textAlign={"center"} color={"#747A8B"} fontSize={12}>
          Format allowed (jpg & png)
        </Text>
      </Box>
      <Box px={{ md: 8 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl mt={5} isInvalid={errors.phone} fontWeight={600}>
            <FormLabel htmlFor="phone">Phone Number</FormLabel>
            <PhoneNumberInput
              key={`phoneInputProfileOnboarding`}
              country={"USA"}
              id={"phone"}
              value={value}
              defaultCountry={countryCode}
              options={countryOptions}
              placeholder="Enter phone number"
              onChange={(value) => setPhone(value)}
              onChangeCode={(value) => setCountryCode(value)}
            />
            <FormErrorMessage>
              {errors.phone && errors.phone.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl mt={5} isInvalid={errors.gender} fontWeight={600}>
            <FormLabel htmlFor="gender">What is your gender?</FormLabel>
            <Select
              id="gender"
              name="gender"
              placeholder="Select option"
              {...register("gender", {
                required: "This is required",
              })}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
              <option value="non binary">Non binary</option>
              <option value="prefer not to answer">Prefer not to answer</option>
            </Select>
            <FormErrorMessage>
              {errors.gender && errors.gender.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl mt={5} isInvalid={errors.age} fontWeight={600}>
            <FormLabel htmlFor="age">
              What age range do you fall into?
            </FormLabel>
            <Controller
              name="age"
              id="age"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup onChange={onChange} value={value} aria-required>
                  <SimpleGrid columns={{ base: 2, md: 3 }} gap={2}>
                    <Box border={"1px solid #EBECEF"} borderRadius={8} p={4}>
                      <Radio
                        color={"#EBECEF"}
                        colorScheme="red"
                        isRequired
                        value="18-24"
                      >
                        18 - 24 years
                      </Radio>
                    </Box>
                    <Box border={"1px solid #EBECEF"} borderRadius={8} p={4}>
                      <Radio
                        color={"#EBECEF"}
                        colorScheme="red"
                        isRequired
                        value="25-34"
                      >
                        25 - 34 years
                      </Radio>
                    </Box>
                    <Box border={"1px solid #EBECEF"} borderRadius={8} p={4}>
                      <Radio
                        color={"#EBECEF"}
                        colorScheme="red"
                        isRequired
                        value="35-44"
                      >
                        35 - 44 years
                      </Radio>
                    </Box>
                    <Box border={"1px solid #EBECEF"} borderRadius={8} p={4}>
                      <Radio
                        color={"#EBECEF"}
                        colorScheme="red"
                        isRequired
                        value="45-54"
                      >
                        45 - 54 years
                      </Radio>
                    </Box>
                    <Box border={"1px solid #EBECEF"} borderRadius={8} p={4}>
                      <Radio
                        color={"#EBECEF"}
                        colorScheme="red"
                        isRequired
                        value="55-64"
                      >
                        55 - 64 years
                      </Radio>
                    </Box>
                    <Box border={"1px solid #EBECEF"} borderRadius={8} p={4}>
                      <Radio
                        color={"#EBECEF"}
                        colorScheme="red"
                        isRequired
                        value="65 and older"
                      >
                        65 and older
                      </Radio>
                    </Box>
                  </SimpleGrid>
                </RadioGroup>
              )}
            />
            <FormErrorMessage>
              {errors.age && errors.age.message}
            </FormErrorMessage>
          </FormControl>

          <Button
            mt={8}
            mb={4}
            size={"lg"}
            w={"100%"}
            color="#fff"
            borderRadius={64}
            background={"primary"}
            isLoading={isSubmitting}
            type="submit"
          >
            Continue Onboarding
          </Button>
          <Box mx="auto" textAlign="center">
            <Button
              mt={2}
              mb={4}
              size={"lg"}
              w={"fit-content"}
              color="#747A8B"
              background={"#fff"}
              type="submit"
              onClick={skipOnboarding}
            >
              Skip Onboarding
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}
