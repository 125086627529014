import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
  Radio,
  VStack,
  useRadioGroup,
  RadioGroup,
  Stack,
  FormLabel,
} from "@chakra-ui/react";
import React from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";

export default function ReminderModal({
  isOpen,
  onClose,
  constants,
  selectedOriginal,
  onFinished,
}) {
  const [selected, setselected] = React.useState(
    ""
  );
  const [value, setValue] = React.useState(selectedOriginal)

  const finishSelection = () => {
    onFinished(value);
    onClose()
  };
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'reminder',
    onChange: console.log,
  })

  const group = getRootProps()
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
            <ModalHeader>Reminder</ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody marginBottom={'0'}>

            <RadioGroup
            value={value}
               display={"flex"} name="profile">
                <Stack width="100%">
                {
                  constants.map((item, i)=> (
                      <FormLabel 
                      onClick={()=>{
                        // console.log(item.value)
                        setselected(item.value)
                        setValue(item.value)
                      }}
                      background={"white"} width={"100%"} htmlFor={item.value} borderRadius="8px" h="50px" gap="3" display={"flex"} alignItems="center" p="0 12px">
                      <FormLabel width="100%" margin={"0"} p="0" >{item.text}</FormLabel>
                      <Radio isChecked={value == item.value ? true : false} colorScheme="orange"  border={"1px solid gray"} id={item.value} value={item.value}></Radio>
                      </FormLabel>
                  ))
                }
                </Stack>
              </RadioGroup>
              <Button
              onClick={finishSelection}
                  mt={8}
                  mb="3"
                  size={"lg"}
                  w={"100%"}
                  shadow={"sm"}
                  colorScheme="purple"
                  color="#fff"
                  borderRadius={64}
                >
                 Save             
                 </Button>
            </ModalBody>
           
      </ModalContent>
    </Modal>
  );
}
