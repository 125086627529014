// MyContext.js
import { createContext, useState } from "react";
import ls from "localstorage-slim";

export const AppContext = createContext();
export default function AppStateProvider(props) {
  const [activeUser, setUser] = useState(
    JSON.parse(sessionStorage.getItem("sj_user_state"))
  );
  const [subscription, setSubscription] = useState(
    ls.get("sj_fs", {decrypt : true})
  );

  const updateUser = (u) => {
    setUser(u);
    sessionStorage.setItem("sj_user_state", JSON.stringify(u));
  };
  const setSub = (s) => {
      ls.set("sj_fs", s, {encrypt : true});
      setSubscription(s)
  }


  return (
    <AppContext.Provider
      value={{
        activeUser,
        updateUser,
        setSub,
        subscription
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

// export default {AppContext, useContextValues};
