import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
    name : "sidebar",
    initialState : {value : "sidebar_active"},
    reducers : {
        toggle : (state, action) =>{
            state.value = action.payload
        }
    }
})

export const {toggle} = sidebarSlice.actions;
export default sidebarSlice.reducer;