import React from "react";
import DashboardLayout from "../../layout/dashboard";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Skeleton,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FaChevronLeft, FaGlobeAfrica, FaStar } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
} from "../../utils/http_utils";
import { cardBg, finances, successGif } from "../../images";
import { LiaHospital } from "react-icons/lia";
import { HiOutlineLocationMarker } from "react-icons/hi";
import CustomModal from "../../components/customModal";
import { Rating } from "react-simple-star-rating";
import { Controller, useForm } from "react-hook-form";

export default function SingleProviderDetails() {
  const [loading, setLoading] = React.useState(true);
  const [provider, setProvider] = React.useState({});
  const navigate = useNavigate();
  const params = useParams();
  const providerId = params.providerId;
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenSuccess,
    onOpen: onOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure();

  const getSingleCategory = async () => {
    const resp = await httpGetWithTokenRest(`providers/${providerId}`);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setProvider(resp.data);
    }
    setLoading(false);
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    if (values.rating === undefined || values.rating == null) {
      return toast({
        title: "Rating.",
        description: `Please rate the provider`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    const data = {
      user_rating: values.rating,
      visit_quality: values.responseQuality,
      is_recommended: values.recommend === "true" ? true : false,
      recommendation_reason: values.responseRecommend,
    };
    const resp = await httpPostWithToken(
      `providers/reviews/${providerId}`,
      data
    );
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      getSingleCategory();
      onClose();
      reset();
      onOpenSuccess();
    }
  }

  const openWebsite = () => {
    window.open(provider?.website, "_blank");
  };

  React.useEffect(() => {
    getSingleCategory();
  }, []);

  return (
    <DashboardLayout
      element={
        <>
          {loading ? (
            <Skeleton height={"100px"} width={"100%"} bg={"red"} />
          ) : (
            <Box p={5} bg={"#FAF8FF"} minH={"100vh"}>
              <Flex gap={5} alignItems={"center"} onClick={() => navigate(-1)}>
                <FaChevronLeft cursor={"pointer"} />
                <Text fontSize={24} fontWeight={600} cursor={"pointer"}>
                  {provider.providers_name}
                </Text>
              </Flex>

              <Box display={{ base: "block", md: "flex" }} gap={3} mt={3}>
                <Box w={{ base: "100%", md: "30%" }} minH={"100px"} bg={"#fff"}>
                  <Box>
                    <Grid
                      bgColor="#fff"
                      w="100%"
                      borderRadius="xl"
                      gridTemplateColumns="100%"
                      gridTemplateRows="90px 50px 50px"
                    >
                      <Box
                        bgImage={cardBg}
                        borderTopLeftRadius={8}
                        borderTopRightRadius={8}
                        gridRow="1/3"
                        gridColumn="1/-1"
                      />
                      <Grid
                        justifyContent="center"
                        gridRow="2/4"
                        gridColumn="1/-1"
                        pos="relative"
                        zIndex="1"
                      >
                        <Image
                          src={finances}
                          rounded="full"
                          border="5px solid #fff"
                          h={"100px"}
                        />
                      </Grid>
                      <Grid
                        placeItems="center"
                        gridRow="4/5"
                        gridColumn="1/-1"
                        fontWeight="700"
                        textAlign="center"
                        px={2}
                      >
                        <Box
                          fontSize={12}
                          fontWeight={400}
                          color={"#fff"}
                          px={2}
                          py={1}
                          borderRadius={100}
                          bgColor={"secondary"}
                        >
                          {provider?.specialty}
                        </Box>
                        <Text fontSize={18} fontWeight={500}>
                          {provider?.providers_name}
                        </Text>
                        <Text
                          fontSize={14}
                          fontWeight={400}
                          color={"#747A8B"}
                          mt={2}
                        >
                          Phone Number
                        </Text>
                        <Text fontSize={16} fontWeight={500}>
                          {provider?.phone}
                        </Text>
                        <Box display={{ base: "block", md: "none" }}>
                          <Button
                            mt={10}
                            mb={4}
                            size={"lg"}
                            w={"100%"}
                            color="#fff"
                            borderRadius={64}
                            background={"#0CCA4A"}
                            onClick={() => {
                              window.open(`tel:${provider?.phone}`, "_blank");
                            }}
                          >
                            Call
                          </Button>
                        </Box>

                        <Button
                          mb={4}
                          mt={2}
                          size={"lg"}
                          w={"100%"}
                          color="#747A8B"
                          borderRadius={64}
                          background={"#fff"}
                          border={"1px solid #747A8B"}
                          onClick={onOpen}
                        >
                          Leave a review
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box w={{ base: "100%", md: "65%" }}>
                  <Box
                    py={4}
                    px={{ base: 2, md: 4 }}
                    bg={"#fff"}
                    minH={"200px"}
                    borderRadius={16}
                  >
                    <Flex alignItems={"center"} mb={5}>
                      <Text fontSize={20} fontWeight={600}>
                        Provider Information
                      </Text>
                      <Spacer />
                      <Flex alignItems={"center"} gap={1}>
                        <FaStar color="#FFD439" />
                        <Text color={"#747A8B"} fontSize={14} fontWeight={500}>
                          {provider?.google_rating}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex alignItems={"center"} gap={1}>
                      <LiaHospital />
                      <Text fontSize={16} fontWeight={500}>
                        {provider?.state}
                      </Text>
                    </Flex>
                    <Flex alignItems={"center"} gap={1}>
                      <FaGlobeAfrica />
                      <Text
                        fontSize={16}
                        fontWeight={500}
                        color={"#6582E9"}
                        textDecoration={"underline"}
                        cursor={"pointer"}
                        onClick={openWebsite}
                      >
                        {provider?.website}
                      </Text>
                    </Flex>
                    <Flex alignItems={"center"} gap={1} cursor={"pointer"}>
                      <HiOutlineLocationMarker />
                      <Text color={"#6582E9"} textDecoration={"underline"}>
                        <a
                          target="_blank"
                          href={`http://maps.google.com/?q=${provider?.address}`}
                        >
                          {provider?.address}
                        </a>
                      </Text>
                    </Flex>
                  </Box>
                  <Box bg={"#fff"} p={3} mt={3} borderRadius={16}>
                    <Accordion allowToggle>
                      <AccordionItem key={`review`} border={"1px solid white"}>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              Reviews
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          {provider?.reviews?.map((e, i) => (
                            <Box key={i} mb={2}>
                              <Flex gap={2} mb={2} alignItems={"center"}>
                                <Avatar src={e?.avatar} name={e?.name} />
                                <Text fontSize={16} fontWeight={500}>
                                  {e?.name}
                                </Text>
                                <Spacer />
                                <Rating
                                  size={15}
                                  readonly
                                  allowFraction
                                  initialValue={e?.user_rating}
                                  SVGstyle={{ display: "inline" }}
                                />
                              </Flex>
                              <Text
                                fontSize={14}
                                fontWeight={400}
                                color={"#747A8B"}
                                mb={2}
                              >
                                {e?.recommendation_reason}
                              </Text>
                              <Divider color={"#EBECEF"} />
                            </Box>
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={"2xl"}
            isCentered
            scrollBehavior={"inside"}
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={16}>
              <ModalHeader>Review</ModalHeader>
              <ModalCloseButton>
                <Box
                  p={2}
                  mr={5}
                  bg={"#F6F6F6"}
                  borderRadius={100}
                  color={"#17181C"}
                  fontWeight={500}
                  fontSize={14}
                >
                  Close
                </Box>
              </ModalCloseButton>
              <ModalBody>
                <Divider color={"#EBECEF"} h={2} />

                <form onSubmit={handleSubmit(onSubmit)}>
                  <Text
                    my={2}
                    color={"#17181C"}
                    textAlign={"center"}
                    fontSize={16}
                    fontWeight={500}
                  >
                    Give a rating
                  </Text>
                  <FormControl isInvalid={errors.rating}>
                    <Center>
                      <Rating
                        size={50}
                        allowFraction
                        SVGstyle={{ display: "inline" }}
                        onClick={(e) => setValue("rating", e)}
                      />
                    </Center>
                    <FormErrorMessage>
                      {errors.rating && errors.rating.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Text fontSize={14} fontWeight={600}>
                    What was the quality of your visit with this provider
                    (consider appointment wait time, customer service, and issue
                    expertise.)?
                  </Text>
                  <FormControl isInvalid={errors.responseQuality}>
                    <FormLabel
                      htmlFor="responseQuality"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Response
                    </FormLabel>
                    <Textarea
                      border={"1px solid #EBECEF"}
                      id="responseQuality"
                      placeholder="Type here..."
                      {...register("responseQuality", {
                        required: "This is required",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.responseQuality && errors.responseQuality.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mt={5}
                    isInvalid={errors.recommend}
                    fontWeight={600}
                  >
                    <FormLabel
                      htmlFor="recommend"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Would you recommend this provider to a friend?
                    </FormLabel>
                    <Controller
                      name="recommend"
                      id="recommend"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <RadioGroup
                          onChange={onChange}
                          value={value}
                          aria-required
                        >
                          <SimpleGrid columns={2} gap={2}>
                            <Box
                              border={"1px solid #EBECEF"}
                              borderRadius={8}
                              p={4}
                            >
                              <Radio
                                color={"#EBECEF"}
                                colorScheme="red"
                                isRequired
                                value={"true"}
                              >
                                Yes
                              </Radio>
                            </Box>
                            <Box
                              border={"1px solid #EBECEF"}
                              borderRadius={8}
                              p={4}
                            >
                              <Radio
                                color={"#EBECEF"}
                                colorScheme="red"
                                isRequired
                                value={"false"}
                              >
                                No
                              </Radio>
                            </Box>
                          </SimpleGrid>
                        </RadioGroup>
                      )}
                    />
                    <FormErrorMessage>
                      {errors.recommend && errors.recommend.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.responseRecommend}>
                    <FormLabel
                      htmlFor="responseRecommend"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Reason
                    </FormLabel>
                    <Textarea
                      border={"1px solid #EBECEF"}
                      id="responseRecommend"
                      placeholder="Type here..."
                      {...register("responseRecommend", {
                        required: "This is required",
                      })}
                    />
                    <FormErrorMessage>
                      {errors.responseRecommend &&
                        errors.responseRecommend.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Button
                    mt={8}
                    mb={4}
                    size={"lg"}
                    w={"100%"}
                    color="#fff"
                    borderRadius={64}
                    background={"primary"}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              </ModalBody>
            </ModalContent>
          </Modal>

          <CustomModal
            onClose={onCloseSuccess}
            onDone={onCloseSuccess}
            isOpen={isOpenSuccess}
            body={
              <Box>
                <Center>
                  <Image src={successGif} />
                </Center>
                <Text textAlign={"center"} fontSize={20} fontWeight={600}>
                  Review Submitted
                </Text>
                <Text textAlign={"center"} fontSize={16} fontWeight={400}>
                  Your review was successfully submitted to the admin
                </Text>
                <Button
                  mt={10}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={onCloseSuccess}
                >
                  Done
                </Button>
              </Box>
            }
          />
        </>
      }
    />
  );
}
