import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Image,
  Select,
  SimpleGrid,
  Skeleton,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import React, { useContext, useEffect, useState } from "react";
import AppLink from "../../components/link";
import { fileUpload, folderIcon, notFound, verticalDots } from "../../images";
import CreateDocumentModal from "./create_document_modal";
import { httpGetWithTokenRest } from "../../utils/http_utils";
import moment from "moment";
import {
  FaChevronLeft,
  FaChevronRight,
  FaFile,
  FaFileWord,
  FaGripLinesVertical,
} from "react-icons/fa";
import DocIcon from "./doc_icon";
import OpenDocModal from "./open_doc_modal";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import ReactPaginate from "react-paginate";
import DocActionModal from "./doc_action_modal";
import DeleteFileModal from "./delete_file_modal";
import { SearchBox } from "../../components/search_box";
import { AppContext } from "../../redux/appContext";

function Documents() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [fromSearch, setFromSearch] = useState(false);
  const [isFolder, setIsFolder] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("created_at");
  const [searchQuery, setSearchQuery] = useState("");
  const [allRecords, setAllRecords] = useState(0);
  const [limit, setLimit] = useState(100000000000000000);
  const [pageLimit, setPageLimit] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [active_doc, setDoc] = useState({});
  const [currentFolder, setCurrentFolder] = useState({});
  const { activeUser, subscription } = useContext(AppContext);
  const toast = useToast()
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const {
    isOpen: isOpenModalOpen,
    onOpen: onOpenModalOpen,
    onClose: onCloseModalOpen,
  } = useDisclosure();
  const {
    isOpen: isOpenModalAction,
    onOpen: onOpenModalAction,
    onClose: onCloseModalAction,
  } = useDisclosure();
  const {
    isOpen: isOpenModalDelete,
    onOpen: onOpenModalDelete,
    onClose: onCloseModalDelete,
  } = useDisclosure();

  useEffect(() => {
    fetchDocuments();
  }, [params.folder_id, sortBy]);

  const fetchDocuments = async (page = 1) => {
    let url = `/file-manager?limit=${limit}&page=${page}&sortBy=${sortBy}&searchQuery=${searchQuery}`;
    if (params.folder_id) {
      url = `/file-manager/folder/${params.folder_id}/files?limit=${limit}&page=${page}&sortBy=${sortBy}&searchQuery=${searchQuery}`;
    } else {
    }
    setLoading(true);
    let res = await httpGetWithTokenRest(url);
    if (res.status == "success") {
      let d = res.data.data;
      setAllRecords(d);
      setCurrentPage(res.data.currentPage);
      setDocuments(d.slice(0, pageLimit));
      setPageCount(Math.ceil(d.length / pageLimit));
      if (params.folder_id) {
        setCurrentFolder(res.data.folder);
        setIsFolder(true);
      } else {
        setIsFolder(false);
      }
    }
    setLoading(false);
  };
  const handlePageClick = (page) => {
    var current = page.selected + 1;
    setCurrentPage(page);
    var d = allRecords;
    var o = current == 1 ? 0 : (current - 1) * pageLimit;
    var e = o + pageLimit;
    setDocuments(d.slice(o, e));
    d = allRecords;
  };
  const handleSearch = (keyword) => {
    if (keyword.length > 0) {
      const d = allRecords.filter((e) =>
        e?.title?.toLowerCase().includes(keyword.toLowerCase())
      );
      setCurrentPage(1);
      setDocuments(d.slice(0, pageLimit));
      setPageCount(Math.ceil(d.length / pageLimit));
      if(d.length == 0) {
        setFromSearch(true)
      }
      setDocuments(d);
    } else {
      let d = allRecords;
      setCurrentPage(1);
      setDocuments(d.slice(0, pageLimit));
      setPageCount(Math.ceil(d.length / pageLimit));
      setDocuments(d);
    }
  };
  const uploadNewFile = () => {
    let uploadSize = subscription.document_upload;
    if(uploadSize) {
      uploadSize = uploadSize.replace("mb", "")
      if(uploadSize === "" || uploadSize === "0"){
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Please upgrade your account or renew your current subscription`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }else{
        onOpenModal()
      }
    }else{
      return toast({
        title: "Sorry, we hit a snag!",
        description: `Please upgrade your account or renew your current subscription`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }
  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      <CreateDocumentModal
        isOpen={isOpenModal}
        onFinished={fetchDocuments}
        onClose={onCloseModal}
        isFolder={isFolder}
      />

      <OpenDocModal
        isOpen={isOpenModalOpen}
        onFinished={() => {}}
        onClose={onCloseModalOpen}
        selectedOriginal={active_doc}
      />
      <DeleteFileModal
        isOpen={isOpenModalDelete}
        onFinished={() => {
          fetchDocuments(currentPage);
        }}
        onClose={onCloseModalDelete}
        selectedOriginal={active_doc}
        doc={active_doc}
      />

      <DocActionModal
        isOpen={isOpenModalAction}
        onFinished={(v) => {
          if (v == "open") {
            setDoc(active_doc);
            onOpenModalOpen();
          }

          if (v == "reload") {
            fetchDocuments(currentPage);
          }
          if (v == "delete") {
            onOpenModalDelete();
          }
        }}
        onClose={onCloseModalAction}
        selectedOriginal={active_doc}
        doc={active_doc}
      />

      <Spacer height={"22px"} />
      {
         subscription?.plan_name == "Starter"
         ?
         subscription?.plan_name == "Starter"
         :
         <>
      <Flex
        gap={"4"}
        alignItems={{ base: "start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems={"center"}>
          {isFolder && (
            <AppLink
              hasLeftIcon={true}
              leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
              shadow="none"
              padding="0"
              text=""
              background="transparent"
              color="black"
              width="fit-content"
              textDecoration="none"
              href="#?"
              onclick={() => {
                window.history.back();
              }}
            />
          )}
          <Box width="2px" />

          <Box>
            <Heading as={"h3"} fontSize={"18px"} fontWeight="600" color="black">
              {isFolder ? currentFolder.title : "Document Upload"}
            </Heading>
          </Box>
        </Box>
        {!loading && (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={5}
            justifyContent={"end"}
          >
            <Flex alignItems={"center"}>
              <Text fontSize={"13px"}>Sort by: &nbsp;</Text>
              <Select
                size={"sm"}
                onChange={(e) => setSortBy(e.target.value)}
                w={"100px"}
                value={sortBy}
                borderColor={"#E3E4E8"}
                borderRadius={"5px"}
              >
                <option value="created_at">Date</option>
                <option value="title">Name</option>
              </Select>
            </Flex>
            <Button
              onClick={uploadNewFile}
              size={"sm"}
              borderRadius={"50px"}
              colorScheme={"purple"}
            >
              <Icon color={"white"} as={PlusIcon} />
              &nbsp; Add New
            </Button>
          </Box>
        )}
      </Flex>
      <Spacer height={"12px"} />
      <Flex justifyContent={"flex-end"}>
        <SearchBox placeholder="Search" handleSearch={handleSearch} />
      </Flex>
      </>
}
      <Spacer height={"22px"} />
      {
        subscription?.plan_name == "Starter"
        ?
        <Box mt="40px" shadow={"sm"} background="white" padding="30px" borderRadius={"16px"} display="flex" flexDirection="column" justifyContent={"center"} alignItems="center">
        <Image src={notFound} boxSize={"205px"} ></Image>
        <Box h="10px" />
        <Heading as="h6" textAlign={"center"} fontSize={"20px"}>
          Access Restricted
          </Heading>
        <Box h="10px" />

        <Box textAlign={"center"} maxW="480px">
        <Text color="paragraph">
        Please upgrade your plan to use this service.
        </Text>
        <Box h="10px" />
        <Flex alignItems={"center"} gap="3" justifyContent={"center"}>
           <Button onClick={()=>navigate("/plans")} colorScheme='purple'>Upgrade</Button>
        </Flex>
        </Box>
      </Box>
        :
        <>
      {loading ? (
        [1, 1, 1, 1].map((e, i) => (
          <Skeleton key={i} h={"50px"} startColor="#808080" />
        ))
      ) : (
        <>
          {!loading && documents.length === 0 ? (
            <>
              <Box
                shadow={"sm"}
                background="white"
                padding={{ base: "3", md: "100px" }}
                borderRadius={"16px"}
                display="flex"
                flexDirection="column"
                justifyContent={"center"}
                alignItems="center"
              >
                <Image src={fileUpload} boxSize={"155px"}></Image>
                <Box h="10px" />
                <Box h="10px" />
                <Heading as="h6" fontSize={"16px"}>
                  {
                fromSearch ?
                "No search result"
                :
                 "No Upload Yet!"
                  }
                </Heading>
                <Box h="10px" />

                <Box textAlign={"center"} maxW="480px">
                  {
                    fromSearch
                    ?
                    <></>
                    :
                  <>
                  <Text color="paragraph">
                    {isFolder
                      ? "No files uploaded yet, click the button below to upload a file or create a folder"
                      : "The life document upload helps you store your documents safely all in place"}
                  </Text>
                  <Box h="10px" />

                  <Box h="5px"></Box>
                  <AppLink
                    href="#?"
                    onclick={() => {
                      uploadNewFile();
                    }}
                    hasLeftIcon={true}
                    leftIcon={<Icon as={PlusIcon} fontSize={"22px"} />}
                    textDecoration="none"
                    text={isFolder ? "Create new" : "Get Started"}
                    color="white"
                    padding="0"
                    height="40px"
                    background="primary"
                    fontWeight="500"
                    shadow="none"
                  />
                  </>
}
                </Box>
              </Box>
            </>
          ) : (
            <>
              <SimpleGrid gap={5} columns={{ base: 2, md: 3, lg: 5 }}>
                {documents.map((doc) => (
                  <Box
                    borderRadius={"8px"}
                    key={doc.id}
                    cursor={"pointer"}
                    shadow={"sm"}
                    bg={"#F3EDFF"}
                  >
                    <Box
                      onClick={() => {
                        if (doc.content_type == "folder") {
                          navigate("/documents/" + doc.id);
                        } else {
                          setDoc(doc);
                          onOpenModalOpen();
                        }
                      }}
                    >
                      <DocIcon doc={doc} />
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"flex-start"}
                      height={`${69}px`}
                      bg="white"
                      px={"12px"}
                      py="10px"
                      flexDir={"column"}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <Text
                          width={"100%"}
                          flex={1}
                          fontSize={"13px"}
                          color={"#000000"}
                          fontWeight={"bold"}
                        >
                          {doc.title.substr(0, 15)}
                          {doc.title.length > 15 ? "..." : ""}
                        </Text>
                        <Box
                          onClick={() => {
                            setDoc(doc);
                            setTimeout(() => {
                              onOpenModalAction();
                            }, 500);
                          }}
                        >
                          <Image
                            src={verticalDots}
                            boxSize={"12px"}
                            objectFit={"contain"}
                          />
                        </Box>
                      </Box>
                      <Text fontSize={"10px"} color={"#747A8B"}>
                        {moment(doc.created_at).format("YYYY-MMM-D, h:mm:ss a")}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>
            </>
          )}
          <Flex p={2} mt="20px">
            <Spacer />
            <ReactPaginate
              breakLabel="..."
              className="paginator"
              activeClassName="activeClassName"
              pageClassName="pageClassName"
              pageLinkClassName="pageLinkClass"
              nextLabel={<FaChevronRight />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<FaChevronLeft />}
              renderOnZeroPageCount={null}
            />
          </Flex>
        </>
      )}
        </>
}
    </Box>
  );
}

export default Documents;
