import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useToast,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  delete_basket_y,
  healthcare,
  homeIcon1,
  homeIcon2,
} from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import {
  httpDeleteWithToken,
  httpGetWithTokenRest,
} from "../../utils/http_utils";
import { useNavigate } from "react-router-dom";

export default function ResendNotificationModal({
  isOpen,
  onClose,
  caregiver,
}) {
  const finishSelection = () => {
    onClose();
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const deleteJorner = async () => {
    setIsLoading(true);
    var response = await httpGetWithTokenRest(
      "co_caregiver/invite/" + caregiver
    );
    if (response.error) {
      toast({
        title: "Sorry, we hit a snag!",
        description: `${response.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    } else {
      toast({
        title: "Invitation sent successfully",
        // description: `${response.error}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      finishSelection();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p={4}>
        <ModalBody textAlign={"center"} marginBottom={"0"}>
          <Flex justifyContent={"center"}></Flex>
          <ModalHeader textAlign={"center"}>Resend Invitation?</ModalHeader>
          <Text textAlign={"center"}>
            Confirm you want to resend Invitation to this co-caregiver
          </Text>
          <Box w="100%" mt={"25px"}>
            <Button
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              onClick={() => finishSelection()}
            >
              Cancel
            </Button>

            <Button
              isLoading={isLoading}
              mt={8}
              mb={8}
              size={"lg"}
              color={"red"}
              w={"100%"}
              shadow={"sm"}
              border="1px solid #EBECEF"
              borderRadius={64}
              onClick={() => deleteJorner()}
            >
              Yes, Resend
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
