import {
  HStack,
  Box,
  Image,
  Link,
  Heading,
  Text,
  Flex,
  Spacer,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  useDisclosure,
  Stack,
  Skeleton,
  SimpleGrid,
  Toast,
  useToast,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  TableContainer,
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { CChartDoughnut } from "@coreui/react-chartjs";
import {
  basic,
  checkIcon,
  checkIconWhite,
  finance,
  noise,
  notask,
  provider,
  standard,
  starter,
  team,
  tribe,
  who,
} from "../../images";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import {
  httpGetWithTokenRest,
  httpPostWithToken,
} from "../../utils/http_utils";
import { AppContext } from "../../redux/appContext";
import SubscriptionSuccessful from "./subscription_successful";
import { FaChevronLeft, FaChevronRight, FaCircle } from "react-icons/fa";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { ChevronLeftIcon } from "@chakra-ui/icons";

function SubscriptionsPage() {
  const { setSub } = useContext(AppContext);

  // const [tasks, setTasks] = useState([1,2,3,4,5,6])
  const [pageCount, setPageCount] = useState(1);
  const [offset, setOffset] = useState(0);
  const [subscriptions, setsubscriptions] = useState([]);
  const [allRecords, setAllrecords] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadConstants();
  }, []);
  const handlePageClick = (page) => {
    var current = page.selected + 1;
    var d = allRecords;
    var o = current == 1 ? 0 : (current - 1) * 10;
    var e = o + 10;
    console.log(d.slice(o, e));
    setsubscriptions(d.slice(o, e));
    d = allRecords;
  };

  const loadConstants = async () => {
    setOffset(0);
    var j = await httpGetWithTokenRest("subscriptions");
    if (j.status == "success") {
      const d = j.data;
      setAllrecords(d);
      setsubscriptions(d.slice(0, 10));
      setPageCount(Math.ceil(d.length / 10));
    }
    setLoading(false);
  };
  const toast = useToast();

  return (
    <Box padding={"19px 20px"} minHeight="100vh" background={"primaryBg"}>
      <Spacer height={"22px"} />
      {loading ? (
        <Text></Text>
      ) : (
        <Box>
          <Flex alignItems="center" justifyContent={"space-between"}>
            <Box display="flex" alignItems={"center"}>
              <AppLink
                hasLeftIcon={true}
                leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
                shadow="none"
                padding="0"
                text=""
                background="transparent"
                color="black"
                width="fit-content"
                textDecoration="none"
                href="/profile"
              />
              <Box width="2px" />
              <Heading
                color={"black"}
                fontSize={"22px"}
                fontWeight={"600"}
                as={"h3"}
              >
                Subscription History
              </Heading>
            </Box>
          </Flex>
          <Box mt={5} bg={"white"} p={"3"} rounded={2} shadow={"sm"}>
            <TableContainer>
              <Table variant="unstyled">
                <Thead bg={"#FAFAFA"}>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Subscription</Th>
                    <Th>Amount</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {subscriptions.map((subscription, i) => (
                    <Tr
                      key={`subscriber${i}`}
                      id={`${subscription.id}`}
                      cursor={"pointer"}
                      borderBottom={"1px solid #EBECEF"}
                    >
                      <Td>
                        <Text fontSize={14} fontWeight={400}>
                          {moment(subscription?.end_date).format(
                            "D MMM YYYY"
                          )}
                        </Text>
                      </Td>
                      <Td>
                        <Text fontSize={14} fontWeight={400}>
                          {subscription?.plan_name}
                        </Text>
                      </Td>
                      <Td>
                        {
                          subscription?.amount_paid == 0
                          ?
                          <Text fontSize={14} fontWeight={400}>
                          Free
                        </Text>
                          :
                        <Text fontSize={14} fontWeight={400}>
                          ${subscription?.amount_paid ? subscription?.amount_paid.toFixed(2) : '0'}
                          {subscription.sub_type == "monthly" ? "/mth" : "/yr"}
                        </Text>
                        }
                        
                      </Td>
                      <Td>
                        <Text
                          display={"flex"}
                          gap={2}
                          alignItems={"center"}
                          fontSize={14}
                          fontWeight={400}
                        >
                          <FaCircle
                            color={
                              subscription?.status == "paid" ? "green" : "red"
                            }
                            size={10}
                          />
                          {subscription?.status == "paid"
                            ? "Successful"
                            : subscription?.status}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                  {subscriptions.length == 0 && (
                    <Tr>
                      <Td colSpan={"4"}>No record found</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex p={2} mt="20px">
              <Spacer />
              <ReactPaginate
                breakLabel="..."
                className="paginator"
                activeClassName="activeClassName"
                pageClassName="pageClassName"
                pageLinkClassName="pageLinkClass"
                nextLabel={<FaChevronRight />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<FaChevronLeft />}
                renderOnZeroPageCount={null}
              />
            </Flex>
          </Box>
        </Box>
      )}
      <Spacer height={"22px"} />
    </Box>
  );
}

export default SubscriptionsPage;
