import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useCheckboxGroup,
  Icon,
  FormLabel,
  FormControl,
  useToast,
  Progress,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  createDoc,
  createFolder as folderImage,
  createImage,
  homeIcon1,
  rightCaret,
  folderIcon,
  deleteIconRed,
  renameFile,
  viewFile,
  downloadFile,
  deleteFile,
} from "../../images";
import { AiFillCaretRight } from "react-icons/ai";
import {
  APP_API_URL,
  httpPatchWithTokenRest,
  httpPostWithToken,
} from "../../utils/http_utils";
import axios from "axios";
import DocIcon from "./doc_icon";
import { useNavigate } from "react-router-dom";
import { lastIndexOfCharacter } from "../../utils/utils";

export default function DocActionModal({
  isOpen,
  onClose,
  selectedOriginal,
  onFinished,
  doc,
}) {
  const toast = useToast();
  const [selected, setselected] = React.useState(selectedOriginal);
  const [showInputFolder, setShowInputFolder] = React.useState(false);
  const [folderLoading, setFolderLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [percent, setPercent] = React.useState(0);
  const navigate = useNavigate();
  const finishSelection = () => {};
  useEffect(() => {
    setShowInputFolder(false);
  }, []);
  useEffect(() => {
    setselected(selectedOriginal);
    setShowInputFolder(false);
    setFileName("");
    setFolderLoading(false);
  }, [selectedOriginal]);
  const documentTypes = [
    {
      icon: createImage,
      text: "Upload Pictures",
      value: "picture",
    },
    {
      icon: createDoc,
      text: "Upload Files",
      value: "files",
    },
    {
      icon: folderImage,
      text: "Create Folder",
      value: "folder",
    },
  ];
  const renameFileAsync = async () => {
    setFolderLoading(true);
    const fd = new FormData();
    fd.append("name", fileName);
    const res = await httpPostWithToken("file-manager/update/" + selected.id, {
      name: fileName,
    });
    if ((res.status = "success")) {
      toast({
        title: "Action completed",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      return toast({
        title: "Sorry, we hit a snag!",
        description: `${res.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    let d = doc;
    d.title = fileName;
    setselected(d);
    setFileName("");
    onClose();
    onFinished("reload");
    setFolderLoading(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent minW={{ base: "94%", md: "300px" }} borderRadius={16}>
        <ModalHeader color={"red"}>Action</ModalHeader>
        <ModalCloseButton>
          <Box
            p={2}
            mr={5}
            bg={"#F6F6F6"}
            borderRadius={100}
            color={"#17181C"}
            fontWeight={500}
            fontSize={14}
          >
            Close
          </Box>
        </ModalCloseButton>
        {showInputFolder ? (
          <ModalBody minW={{ base: "94%", md: "300px" }} marginBottom={"0"}>
            <Divider color={"#EBECEF"} />

            <Box w="100%" mt={"25px"}>
              <FormControl>
                <FormLabel>
                  {selected.is_folder ? "Rename Folder" : "Rename File"}{" "}
                </FormLabel>
                <Input
                  focusBorderColor="#E3E4E8"
                  border={"1px solid #E3E4E8"}
                  height="50px"
                  value={fileName}
                  placeholder="Enter name"
                  onChange={(e) => setFileName(e.target.value)}
                />
              </FormControl>
              <Button
                mt={8}
                mb={4}
                size={"lg"}
                w={"100%"}
                color="#fff"
                borderRadius={64}
                isLoading={folderLoading}
                background={"primary"}
                onClick={() => {
                  if (fileName !== "") renameFileAsync();
                }}
              >
                Rename
              </Button>
            </Box>
          </ModalBody>
        ) : (
          <ModalBody minW={{ base: "94%", md: "300px" }} marginBottom={"0"}>
            <Divider color={"#EBECEF"} />

            <Box w="100%">
              <Box my={2}>
                <DocIcon source={2} doc={selected} />
                <Heading textAlign={"center"} fontSize={"15px"}>
                  {selected.title}
                </Heading>
                <Box height={"20px"} />
                <Divider color={"#DADADA"} />
                <Box height={"20px"} />
                <Box
                  onClick={() => {
                    onClose();
                    if (selected.is_folder) {
                      navigate("/documents/" + selected.id);
                    } else {
                      onFinished("open");
                    }
                  }}
                  display={"flex"}
                  gap={4}
                  cursor={"pointer"}
                  alignItems={"center"}
                  my="2"
                >
                  <Image src={viewFile} height={42} />
                  <Box
                    flex={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text fontWeight={"500"} fontSize={"15px"}>
                      Open {selected.is_folder ? "folder" : "file"}
                    </Text>
                    <Image src={rightCaret} height={5} objectFit={"contain"} />
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  gap={4}
                  cursor={"pointer"}
                  alignItems={"center"}
                  my="2"
                  onClick={() => {
                    var t = selected.title;
                    var extenstion = t.substring(lastIndexOfCharacter(t, "."));
                    t = t.replace(extenstion, "");
                    setFileName(t);
                    setShowInputFolder(true);
                  }}
                >
                  <Image src={renameFile} height={42} />
                  <Box
                    flex={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text fontWeight={"500"} fontSize={"15px"}>
                      Rename
                    </Text>
                    <Image src={rightCaret} height={5} objectFit={"contain"} />
                  </Box>
                </Box>
                {selected.content_type != "folder" && (
                  <Box
                    display={"flex"}
                    gap={4}
                    cursor={"pointer"}
                    alignItems={"center"}
                    my="2"
                    onClick={() => {
                      window.open(selected.file_url);
                    }}
                  >
                    <Image src={downloadFile} height={42} />
                    <Box
                      flex={1}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text fontWeight={"500"} fontSize={"15px"}>
                        Download
                      </Text>
                      <Image
                        src={rightCaret}
                        height={5}
                        objectFit={"contain"}
                      />
                    </Box>
                  </Box>
                )}

                <Box
                  display={"flex"}
                  gap={4}
                  cursor={"pointer"}
                  alignItems={"center"}
                  my="2"
                  onClick={() => {
                    onClose();
                    onFinished("delete");
                  }}
                >
                  <Image src={deleteFile} height={42} />
                  <Box
                    flex={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text fontWeight={"500"} fontSize={"15px"}>
                      Delete
                    </Text>
                    <Image src={rightCaret} height={5} objectFit={"contain"} />
                  </Box>
                </Box>
                <Box height={"20px"} />
              </Box>
            </Box>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}
