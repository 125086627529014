import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { useForm } from "react-hook-form";
import ls from "localstorage-slim";
import { COUNTRIES } from "../../utils/countries";
import {
  httpGetWithTokenRest,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { FaEdit, FaUser } from "react-icons/fa";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { AppContext } from "../../redux/appContext";
import { containsNumber, containsSpecialChars } from "../../utils/utils";
export default function EditProfile() {
  const { updateUser, activeUser } = useContext(AppContext);
  const [isSMS, setIsSMS] = useState(0)
  const [dailyMotivation, setDailyMotivation] = useState(0)
  const user = JSON.parse(sessionStorage.getItem("sj_user"));
  const [valuePhone, setValuePhone] = React.useState(
    user?.phone_number.replace("+1", "").replace("undefined", "")
  );
  const [countryCode, setCountryCode] = React.useState(user?.country_code);
  const toast = useToast();
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const imageRef = React.useRef();
  const [profileImage, setProfileImage] = React.useState(null);
  const [profileImageUrl, setProfileImageUrl] = React.useState(null);
  const [profileImageRes, setProfileImageRes] = React.useState(null);
  const chooseFile = () => {
    imageRef.current.click();
  };

  const imageHandler = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImageRes(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  React.useEffect(() => {
    setValue("first_name", user?.first_name);
    setValue("last_name", user?.last_name);
    setValue("last_name", user?.last_name);
    setIsSMS(user?.sms);
    setDailyMotivation(user?.daily_motivation);
    setValue("phone_number", (user?.phone_number).replace("+1", ""));
    setValue("family_name", user?.family_name);
    setProfileImageUrl(user?.avatar);
  }, []);

  async function onSubmit(values) {
    if (
      containsSpecialChars(values.first_name) ||
      containsSpecialChars(values.last_name) || containsSpecialChars(values.family_name)
    ) {
      return toast({
        title: "Names cannot contain special characters.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (containsNumber(values.first_name) || containsNumber(values.last_name) || containsNumber(values.family_name)) {
      return toast({
        title: "Names cannot contain numbers.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (values.first_name.length < 2 || values.last_name.length < 2) {
      return toast({
        title: "Invalid name",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (values.family_name.length < 2) {
      return toast({
        title: "Invalid Family name",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    values.phone_number = valuePhone;
    values.country_code = countryCode;
    const formData = new FormData();
    formData.append("phone_number", valuePhone);
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("family_name", values.family_name);
    formData.append("country_code", countryCode);
    if (profileImageRes !== null) {
      formData.append("avatar", profileImageRes);
    }

    const resp = await httpPutWithTokenRest("caregivers/profile", formData);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      console.log(resp);
      user.phone_number = valuePhone;
      user.first_name = values.first_name;
      user.last_name = values.last_name;
      user.family_name = values.family_name;
      user.country_code = countryCode;
      ls.set("sj_user", user, { encrypt: true });
      sessionStorage.setItem("sj_user", JSON.stringify(user));
      fetchUserProfile();
      return toast({
        title: "Success.",
        description: `Profile updated successfully`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const fetchUserProfile = async () => {
    const resp = await httpGetWithTokenRest("caregivers/profile");
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const data = resp.data;
      setProfileImageUrl(data?.avatar);
      user.avatar = data?.avatar;
      ls.set("sj_user", user, { encrypt: true });
      updateUser(user);
      setIsSMS(data?.sms);
      setDailyMotivation(data?.daily_motivation);
    }
  };

  React.useEffect(() => {
    fetchUserProfile();
  }, []);
  const updateMotivation = async () => {
    let newV = dailyMotivation == 1 ? 0 : 1
    setDailyMotivation(newV)
    const resp = await httpPutWithTokenRest("caregivers/profile/motivation", {
      motivation : newV
    });
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      return toast({
        title: "Profile updated",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }
  const updateSMS = async () => {
    let newV =isSMS == 1 ? 0 : 1
    setIsSMS(newV)
    const resp = await httpPutWithTokenRest("caregivers/profile/sms", {
      sms : newV
    });
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      return toast({
        title: "Profile updated",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }
  return (
    <Box px={{ md: 8 }}>
      <Text color={"#FF6E57"} fontSize={20} fontWeight={600} my={3}>
        Edit Profile
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Center mb={2}>
          {profileImage !== null ? (
            <Avatar
              size={"xl"}
              cursor={"pointer"}
              src={profileImage}
              onClick={chooseFile}
            >
              <AvatarBadge
                boxSize="0.85em"
                children={<FaEdit color="#FF6E57" />}
              />
            </Avatar>
          ) : (
            <Avatar
              icon={<FaUser fontSize="2.5rem" />}
              size={"xl"}
              src={profileImageUrl}
              bg={"#EBECEF"}
              cursor={"pointer"}
              onClick={chooseFile}
            >
              <AvatarBadge
                boxSize="0.85em"
                children={<FaEdit color="#FF6E57" />}
              />
            </Avatar>
          )}
          <Input
            ref={imageRef}
            type={"file"}
            accept="image/*"
            onChange={(e) => imageHandler(e)}
            hidden
          ></Input>
        </Center>
        <FormControl isInvalid={errors.first_name}>
          <FormLabel htmlFor="first_name" fontSize={14} fontWeight={600}>
            First Name
          </FormLabel>
          <Input
            id="first_name"
            placeholder="e.g Simon"
            {...register("first_name", {
              required: "This is required",
            })}
            borderColor={"#E3E4E8"}
          />
          <FormErrorMessage>
            {errors.first_name && errors.first_name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.last_name} mt={4}>
          <FormLabel htmlFor="last_name" fontSize={14} fontWeight={600}>
            Last Name
          </FormLabel>
          <Input
            id="last_name"
            placeholder="e.g Simon"
            {...register("last_name", {
              required: "This is required",
            })}
            borderColor={"#E3E4E8"}
          />
          <FormErrorMessage>
            {errors.last_name && errors.last_name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.family_name} mt={4}>
          <FormLabel htmlFor="family_name" fontSize={14} fontWeight={600}>
            Family Name{" "}
            <Tooltip
              bg={"#fff"}
              color={"#000"}
              label="Choose a name for your account ( your home base) that you, your child, and your secondary caregivers will be assigned to. We recommend using your last name or your jorner's last name (your kiddo or loved one that brought you to Sojorne)"
            >
              <InfoOutlineIcon />
            </Tooltip>
          </FormLabel>
          <Input
            id="family_name"
            placeholder="e.g Simon"
            {...register("family_name", {
              required: "This is required",
            })}
            borderColor={"#E3E4E8"}
          />
          <FormErrorMessage>
            {errors.family_name && errors.family_name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.email} mt={4}>
          <FormLabel htmlFor="email" fontSize={14} fontWeight={600}>
            Email Address
          </FormLabel>
          <Input
            id="email"
            placeholder="e.g Simon"
            readOnly
            value={user?.email}
            borderColor={"#E3E4E8"}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          mt={5}
          isInvalid={errors.phone}
          fontSize={14}
          fontWeight={600}
        >
          <FormLabel htmlFor="phone">Phone Number</FormLabel>
          <PhoneNumberInput
            key={"phoneInput"}
            id={"phone"}
            country={"USA"}
            value={valuePhone}
            defaultCountry={countryCode}
            options={countryOptions}
            placeholder="Enter phone number"
            onChange={(value) => setValuePhone(value)}
            onChangeCode={(value) => setCountryCode(value)}
          />
          <FormErrorMessage>
            {errors.phone && errors.phone.message}
          </FormErrorMessage>
        </FormControl>
        <Box h="20px" />
        <FormControl display='flex' alignItems='center'>
          <FormLabel onClick={() => updateMotivation()} htmlFor='email-alerts' mb='0'>
            Daily Inspiration?
          </FormLabel>
          {
            dailyMotivation ?
              <Switch onChange={() => updateMotivation()} isChecked={true} shadow={"lg"} borderWidth={"1px"} rounded={"15px"} bg={"grey"} color={"lightgray"} colorScheme="purple" id='email-alerts' />
              :
              <Switch onChange={() => updateMotivation()} shadow={"lg"} borderWidth={"1px"} rounded={"15px"} bg={"grey"} color={"lightgray"} colorScheme="purple" id='email-alerts' />
          }
        </FormControl>
        <Box h="15px" />

        <FormControl display='flex' alignItems='center'>
          <FormLabel onClick={() => updateSMS()} htmlFor='sms' mb='0'>
            Text Messages?
          </FormLabel>
          {
            isSMS
              ?
              <Switch onChange={() => updateSMS()} isChecked={true} borderWidth={"1px"} rounded={"15px"} bg={"grey"} color={"lightgray"} colorScheme="purple" id='sms' />
              :
              <Switch onChange={() => updateSMS()} borderWidth={"1px"} rounded={"15px"} bg={"grey"} color={"lightgray"} colorScheme="purple" id='sms' />
          }
        </FormControl>
        <Button
          mt={8}
          mb={4}
          size={"lg"}
          w={"100%"}
          color="#fff"
          borderRadius={64}
          background={"primary"}
          isLoading={isSubmitting}
          type="submit"
        >
          Save
        </Button>
      </form>
    </Box>
  );
}
