import { Box, Link, Heading, Text, Flex, Spacer, Icon } from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AppLink from "../../components/link";
import NoJorner from "../../components/dashboard/no_jorner";
import JornerCard from "../../components/dashboard/jorner_card";
import {
  ChevronRightIcon,
  PlusIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/solid";
import AddJorner from "../../components/dashboard/add_jorner_card";
import { httpGetWithTokenRest } from "../../utils/http_utils";
import ls from "localstorage-slim";
import JornerCard2 from "../../components/dashboard/jorner_card2";
function Jorners() {
  const [jorners, setJorners] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("sj_user"));

  useEffect(() => {
    (async () => {
      // console.log(user);
      var url;
      if (user.tag == "cocg") {
        url = `co_caregiver/${user.id}/jorners`;
      } else {
        url = `jorner`;
      }
      var j = await httpGetWithTokenRest(url);
      if (j.status == "success") {
        // console.log(j.data)
        setJorners(j.data);
      }
    })();
  }, []);
  return (
    <Box
      padding={"19px 20px"}
      height="100%"
      minHeight={"100vh"}
      background={"primaryBg"}
    >
      {/* greetings */}
      <Flex alignItems="center" justifyContent={"space-between"}>
        <Box display="flex" alignItems={"center"}>
          <AppLink
            hasLeftIcon={true}
            leftIcon={<Icon as={ChevronLeftIcon} fontSize="20px" />}
            shadow="none"
            padding="0"
            text=""
            background="transparent"
            color="secondary"
            width="fit-content"
            textDecoration="none"
            href="/dashboard"
          />
          <Box width="2px" />
          <Heading
            color={"secondary"}
            fontSize={"22px"}
            fontWeight={"600"}
            as={"h3"}
          >
            My Jorners
          </Heading>
        </Box>

        <AppLink
          hasLeftIcon={true}
          leftIcon={<Icon as={PlusIcon} fontSize="20px" />}
          shadow="none"
          padding="0"
          text="Add Jorner"
          background="transparent"
          color="secondary"
          width="fit-content"
          textDecoration="none"
          href="/add-jorner"
        />
      </Flex>
      <Box h="20px" />

      {/* journer */}
      <Box>
        {jorners.length === 0 ? (
          <NoJorner background="transaparent" shadow="none" />
        ) : (
          <Flex gap={3} flexWrap={"wrap"}>
            {jorners.map((item, i) => (
              <JornerCard2 jorner={item} key={i} />
            ))}
          </Flex>
        )}
      </Box>
      <Spacer height={"22px"} />
    </Box>
  );
}

export default Jorners;
