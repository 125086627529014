import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  useToast,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React,{useState} from "react";
import { checkIconSuccess, delete_basket_y, healthcare, homeIcon1, homeIcon2, iconCompletey } from "../../images";
import { checkArraySimilarities } from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { httpDeleteWithToken } from "../../utils/http_utils";
import { useNavigate } from "react-router-dom";
import { stubTrue } from "lodash";

export default function InsuranceCreatedModal({
  isOpen,
  onClose,
  onFinished
}) {

  const finishSelection = () => {
    onClose()
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast();
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p={4}>
            <ModalBody textAlign={"center"} marginBottom={'0'}>
              <Flex justifyContent={'center'}>
              <Image src={checkIconSuccess} boxSize={'228px'} />
              </Flex>
              <ModalHeader textAlign={'center'} >Insurance Card Added!</ModalHeader>
              <Text textAlign={'center'}>
              You have successfully uploaded your Insurance Card
              </Text>
              <Box w="100%" mt={"25px"}>
              <Button
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={finishSelection}
                >
                  Ok
                </Button>
              </Box>
            </ModalBody>
           
      </ModalContent>
    </Modal>
  );
}
