import React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Center,
  Checkbox,
  Flex,
  Button,
  Heading,
  Image,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { AppLogo, jornerProfile } from "../../images";
import {
  FaChevronRight,
  FaPlus,
  FaRegAddressCard,
  FaUser,
} from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ProfileOnboarding from "./profileOnboarding";
import JornerProfileOnboarding from "./jornerProfileOnboarding";
import ls from "localstorage-slim";
import CareTeam from "./careTeam";

export default function Onboarding() {
  const navigate = useNavigate();
  const [haveJorners, setHaveJorners] = React.useState(false);
  const [haveCaregivers, setHaveCaregivers] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(1);
  const updateCurrentStep = (step) => {
    if(step === 3){
      navigate("/dashboard")
    }else{
      setCurrentStep(step);
    }
  };
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("sj_user"));
    if (!user) {
      // navigate("/login");
    }
  }, []);

  return (
    <Box
      position={"relative"}
      overflowX={"hidden"}
      bg={"background"}
      minH={"100vh"}
      px={{ base: 6, md: 24 }}
      py={8}
    >
      <Box mb={8}>
        <Text fontSize={24} fontWeight={700}>
          One last thing
        </Text>
        <Text fontSize={16} fontWeight={400}>
          Complete the following tasks to have a best experience on Sojorne
        </Text>
      </Box>

      <Stack direction={{ base: "column", md: "row" }} gap={2}>
        <Box w={{ base: "100%", md: "35%" }} bg={"#fff"} p={5}>
          <Box px={3} mb={3} py={1} bg={"background"} borderRadius={14}>
            <Flex
              mt={5}
              gap={2}
              mb="15px"
              alignItems="start"
              cursor={"pointer"}
            >
              <Avatar bg={"#FFE9E5"} icon={<FaUser color="#FF6E57" />} />
              <Box>
                <Heading textAlign={"left"} fontSize={"18px"}>
                  Complete Profile
                </Heading>
                <Text mt={1} color={"paragraph"} fontSize="14px">
                  Provide more basic information...
                </Text>
              </Box>
              <Spacer />
              <Checkbox
                color={"#EBECEF"}
                colorScheme={"red"}
                size={"lg"}
                // readOnly
                isChecked={currentStep > 1}
              />
            </Flex>
          </Box>
          <Box
            px={3}
            mb={3}
            py={1}
            bg={currentStep > 1 ? "background" : "#fff"}
            borderRadius={14}
          >
            <Flex
              mt={5}
              gap={2}
              mb="15px"
              alignItems="start"
              cursor={"pointer"}
            >
              <Avatar
                bg={"#E7FEEE"}
                icon={<FaRegAddressCard color="#099A38" />}
              />
              <Box>
                <Heading textAlign={"left"} fontSize={"18px"}>
                  Create Jorners Profile
                </Heading>
                <Text mt={1} color={"paragraph"} fontSize="14px">
                  Name of Loved one, age, disability...
                </Text>
              </Box>
              <Spacer />
              {currentStep > 1 ? (
                <Checkbox
                  colorScheme={"red"}
                  size={"lg"}
                  readOnly
                  isChecked={haveJorners || currentStep > 2}
                />
              ) : (
                <FaChevronRight />
              )}
            </Flex>
          </Box>
          <Box
            px={3}
            display={"none"}
            py={1}
            bg={currentStep > 1 ? "background" : "#fff"}
            borderRadius={14}
          >
            <Flex
              mt={5}
              gap={2}
              mb="15px"
              alignItems="start"
              cursor={"pointer"}
            >
              <Avatar bg={"#FFF7E5"} icon={<IoIosPeople color="#C78600" />} />
              <Box>
                <Heading textAlign={"left"} fontSize={"18px"}>
                  Build Your Care Team
                </Heading>
                <Text mt={1} color={"paragraph"} fontSize="14px">
                  Invite your co-caregivers and collaborate on the go
                </Text>
              </Box>
              <Spacer />
              {currentStep > 2 ? (
                <Checkbox
                  colorScheme={"red"}
                  size={"lg"}
                  readOnly
                  isChecked={haveCaregivers || currentStep > 3}
                />
              ) : (
                <FaChevronRight />
              )}
            </Flex>
          </Box>
        </Box>

        <Box
          w={{ base: "100%", md: "60%" }}
          bg={"#fff"}
          p={{ base: 6, md: 10 }}
        >
          {currentStep === 1 ? (
            <ProfileOnboarding
              currentStep={currentStep}
              updateCurrentStep={updateCurrentStep}
            />
          ) : null}

          {currentStep === 2 ? (
            <JornerProfileOnboarding
              currentStep={currentStep}
              updateCurrentStep={updateCurrentStep}
              updateHaveJorners={setHaveJorners}
            />
          ) : null}

          {currentStep === 3 ? (
            <Button bg="primary" color="white" onClick={()=> navigate("/dashboard")} >Proceed to Dashboard</Button>
          ) : null}
        </Box>
      </Stack>
    </Box>
  );
}
