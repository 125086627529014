import React from 'react'
import { Box, Image, Link, Heading, Text, Flex, Spacer, Icon } from '@chakra-ui/react'
import AppLink from '../link'
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import { notask, tabitemnotfound} from '../../images';

function NoTabItem({hasButton = true, buttonText="Add Disability", title="No Disability", text='', onClick=()=>{}}) {
  return (
   
    <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems="center">
          <Image src={tabitemnotfound} boxSize={"55px"} ></Image>
          <Box h="10px" />
          <Heading as="h6" fontSize={"16px"}>{title}</Heading>
          <Box h="10px" />

          <Box textAlign={"center"} maxW="480px">
          <Text color="paragraph">
           {text == "" ? 'The tasks tool to help you to stay organized and helps you manage your day-to-day activity.' 
           : text
           } 
          </Text>
          <Box h="10px" />
           {
            hasButton ?
            <AppLink onclick={()=>onClick()} href="#?" textDecoration='underline' text={buttonText} color='primary' padding='0' height='fit-content' background='transparent' fontWeight='500' shadow='none' />
            : ''
          }
          </Box>
        </Box>

  )
}

export default NoTabItem