import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  useCheckboxGroup,
  useToast,
  Accordion,
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Select,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { healthcare, homeIcon1, homeIcon2 } from "../../images";
import {
  ValidDate,
  capitalizeFirstWord,
  checkArraySimilarities,
  containsSpecialChars,
} from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";
import { useFormik } from "formik";
import PhoneNumberInput from "../../components/phoneNumberInput";
import { COUNTRIES } from "../../utils/countries";
import { DatePicker } from "rsuite";
import PhoneNumberInput2 from "../../components/phoneNumberInput2";
import {
  httpPostWithToken,
  httpPutWithTokenRest,
} from "../../utils/http_utils";
import { useEffect } from "react";
import moment from "moment";

export default function CreateAppointmentModal({
  isOpen,
  coGiverConstants,
  onClose,
  onFinished,
  appointment,
}) {
  const finishSelection = (value = "close") => {
    onFinished(value);
    onClose();
  };

  const updated = () => {
    onClose();
    onFinished("updated");
  };
  const {
    value: selectedPermissions,
    getCheckboxProps,
    setValue: setCheckValue,
  } = useCheckboxGroup({
    defaultValue: [],
  });
  const appointmentTypes = ["Recurring", "Special", "Single"];
  const [isEdit, setIsEdit] = useState(appointment ? true : false);
  const appointmentReminder = [
    "Annually",
    "Monthly",
    "Daily",
    "Periodic (Custom)",
  ];
  const custom2Array = ["Days", "Weeks", "Months"];
  const allPermissions = ["home", "calendar", "task", "provider", "community"];
  const [constants, setConstants] = useState(coGiverConstants);
  const [custom1Array, setCustom1Array] = useState([]);
  const [showCustomValue, setShowCustomValue] = useState(false);
  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));
  const toast = useToast();
  const validateEmail = (e) => {
    return String(e)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  useEffect(() => {
    if (appointment) {
      setIsEdit(true);
      setTimeout(() => {
        fillFormik(appointment);
      }, 1000);
    } else {
      setIsEdit(false);
      clearFormik();
    }
    let emptyCustom1 = [];
    for (let index = 1; index < 101; index++) {
      emptyCustom1 = [...emptyCustom1, index];
    }
    setCustom1Array(emptyCustom1);
  }, [appointment]);

  const formik = useFormik({
    initialValues: {
      name: "",
      department: "",
      description: "",
      phone: "",
      appointment_type: "",
      reminder: "",
      appointment_date: "",
      custom_value1: "",
      custom_value2: "",
      phone_code: "+1",
    },
    onSubmit: async (values) => {
      if (
        values.name == "" ||
        values.department == "" ||
        values.phone == "" ||
        values.appointment_date == ""
      ) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `All fields are required`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      if(containsSpecialChars(values.name)) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Invalid Doctor's name`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      if(values.name.length < 3) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `Invalid Doctor's name`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      if (!ValidDate(values.appointment_date)) {
        return toast({
          title: "Invalid",
          description: `Date cannot be in the past`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (values.reminder.toLowerCase().includes("custom")) {
        if (values.custom_value1 == "" || values.custom_value2 == "") {
          return toast({
            title: "Invalid.",
            description: `Please select reminder frequency values`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
      var data = {
        name: values.name,
        department: values.department,
        phone_number: values.phone,
        country_code: values.phone_code,
        description: values.description,
        appointment_type: "appointment",
        appt_date: values.appointment_date,
      };

      var response = null;
      if (isEdit) {
        response = await httpPutWithTokenRest(
          "/appointments/" + appointment.id,
          data
        );
      } else {
        response = await httpPostWithToken("/appointments", data);
      }

      if (response.error) {
        return toast({
          title: "Sorry, we hit a snag!",
          description: `${response.error}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        if (isEdit) {
          toast({
            title: "Appointment updated successfully",
            // description: `${response.error}`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          updated();
        } else {
          formik.values.name = "";
          formik.values.description = "";
          formik.values.appointment_date = "";
          formik.values.appointment_type = "";
          formik.values.phone_code = "+1";
          formik.values.phone = "";
          formik.values.department = "";
          formik.values.reminder = "";
          formik.values.custom_value1 = "";
          formik.values.custom_value2 = "";
          // selectedPermissions = null
          setCheckValue([]);
          setTimeout(() => {
            finishSelection("created");
          }, 1000);
        }
      }
    },
  });

  const clearFormik = () => {
    formik.values.name = "";
    formik.values.description = "";
    formik.values.appointment_date = "";
    formik.values.appointment_type = "";
    formik.values.phone_code = "+1";
    formik.values.phone = "";
    formik.values.department = "";
    formik.values.reminder = "";
    formik.values.custom_value1 = "";
    formik.values.custom_value2 = "";
  };

  const fillFormik = (appt) => {
    // alert("hjere")
    formik.setFieldValue("phone", appt.phone_number);
    formik.setFieldValue("name", appt.name);
    formik.setFieldValue("description", appt.description);
    formik.setFieldValue("appointment_date", appt.appt_date);
    formik.setFieldValue(
      "appointment_type",
      capitalizeFirstWord(appt.appointment_type)
    );
    formik.setFieldValue("phone_code", appt.country_code);
    formik.setFieldValue("department", appt.department);
    formik.setFieldValue("reminder", capitalizeFirstWord(appt.reminder));

    if (appt.custom_value) {
      var cv = appt.custom_value;
      const cvArray = cv.split();
      formik.values.custom_value1 = cvArray[0].trim();
      formik.values.custom_value2 = cvArray[1].trim();
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16} p="3">
        <ModalBody marginBottom={"0"}>
          <Box>
            <Flex alignItems={"center"} justifyContent="space-between">
              <Heading fontSize={"20px"}>
                {" "}
                {isEdit ? "Edit " : "Create "} Appointment
              </Heading>
              <Button onClick={finishSelection}>close</Button>
            </Flex>

            <Box mt={"3"} />
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="name">Doctor's Name</FormLabel>
                  <Input
                    height={"50px"}
                    id="name"
                    name="name"
                    type="text"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="E.g My First Appointment"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </FormControl>

                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="department">Department</FormLabel>
                  <Input
                    height={"50px"}
                    id="department"
                    name="department"
                    type="text"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="E.g Orthopaedic"
                    onChange={formik.handleChange}
                    value={formik.values.department}
                  />
                </FormControl>
                <Box></Box>

                <FormControl mt={1} fontWeight={600}>
                  <FormLabel htmlFor="phone">
                    Phone number 
                  </FormLabel>
                  <PhoneNumberInput
                    onCodeChange={(v) => formik.setFieldValue("phone_code", v)}
                    key={"phoneInput"}
                    id={"phone"}
                    required={isEdit ? false : true}
                    value={formik.values.phone}
                    options={countryOptions}
                    placeholder="Enter phone number"
                    onChange={(v) => formik.setFieldValue("phone", v)}
                  />
                </FormControl>

                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Textarea
                    id="description"
                    name="description"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    placeholder="E.g Orthopaedic"
                    size="sm"
                  />
                </FormControl>

                <FormControl marginTop={"5px"}>
                  <FormLabel htmlFor="appointment_date">
                    Appointment Date
                  </FormLabel>
                  <Input
                    height={"50px"}
                    id="appointment_date"
                    name="appointment_date"
                    type="date"
                    variant="outlined"
                    border={"1px solid #E3E4E8"}
                    focusBorderColor="#E3E4E8"
                    placeholder="Set date"
                    onChange={formik.handleChange}
                    value={formik.values.appointment_date}
                  />
                </FormControl>

                <Button
                  isLoading={formik.isSubmitting}
                  type="submit"
                  h="50px"
                  borderRadius={"60px"}
                  colorScheme="purple"
                  width="full"
                >
                  {isEdit ? "Update" : "Create Appointment"}
                </Button>
              </VStack>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
