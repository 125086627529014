import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import AuthCarousel from "../../components/authCarousel";
import { AppLogo } from "../../images";
import { loginRoute, otpRoute } from "../../utils/allRoutes";
import { httpPostWithoutToken } from "../../utils/http_utils";
import ls from "localstorage-slim";
import {
  containsCapitalLetter,
  containsNumber,
  containsSpecialChars,
} from "../../utils/utils";
function Signup() {
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [show1, setShow1] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleClick1 = () => setShow1(!show1);
  const [isPayment, setIsPayment] = React.useState(false);
  const [sub, setSub] = React.useState("free");
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    let loc = window.location.href;
    let plan = loc.split("plan=")[1]
    plan = plan ? plan : null;
    
    // just incase the link was copied with empty space, or extra text or "#" just to be safe
    if (plan) {

      if(plan.includes("basic")){
        plan = "basic";
      }else if(plan.includes("standard")){
        plan = "standard";
      } else{
        plan = "free";
      }
      setIsPayment(true)
      setSub(plan)
    } else {
      const user = JSON.parse(sessionStorage.getItem("sj_user"));
      if (user) {
        navigate("/dashboard");
      }
    }
  }, []);

  async function onSubmit(values) {
    let data = {
      first_name: values.firstName,
      last_name: values.lastName,
      family_name: values.familyName,
      email: values.emailAddress,
      password: values.password,
      sms: values.sms == true ? 1 : 0,
    };

    if (!containsCapitalLetter(values.password)) {
      return toast({
        title: "Please use a secure password.",
        description: "Password must contain atleast one uppercase letter.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (values.firstName.length < 2) {
      return toast({
        title: "First name is invalid.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (values.lastName.length < 2) {
      return toast({
        title: "Last name is invalid.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (values.familyName.length < 2) {
      return toast({
        title: "Family name is invalid.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (
      containsSpecialChars(values.familyName) ||
      containsSpecialChars(values.lastName) ||
      containsSpecialChars(values.firstName)
    ) {
      return toast({
        title: "Names cannot contain special characters.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (!containsNumber(values.password)) {
      return toast({
        title: "Please use a secure password.",
        description: "Password must contain atleast one number.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    if (!containsSpecialChars(values.password)) {
      return toast({
        title: "Please use a secure password.",
        description:
          "Password must be at least 8 characters, password must contain at least 1 special character.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    if (values.password !== values.confirm_password) {
      return toast({
        title: "Password confirmation",
        description: "Password not matched.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    const resp = await httpPostWithoutToken("caregiver", data);
    if (resp.error !== null && resp.error !== undefined) {
      return toast({
        title: "Sorry, we hit a snag!.",
        description: `${resp.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      ls.set("sj_email", values.emailAddress, { encrypt: true });
      if(sub != "free"){
        navigate(`/subscribe/${sub}?u=${values.emailAddress}`)
      }else{
        navigate(otpRoute);
      }
    }
  }

  const gotoPrivacy = () => {
    window.open("https://www.sojorne.com/sojorne-privacy-policy.html", "_blank");
  };

  const gotoTerms = () => {
    window.open(
      "https://www.sojorne.com/sojorne-terms-and-services-agreement-0.html",
      "_blank"
    );
  };

  return (
    <>
      <Flex min-height={"100vh"} width={"100%"}>
        <AuthCarousel />
        <Box
          zIndex={2}
          w={{ base: "100%", md: "50%" }}
          background={"white"}
          borderTopRadius={{ base: "20px", md: 0 }}
          paddingTop={{ base: "40px", md: 0 }}
          h={{ base: "calc(100vh - 30px)", md: "100vh" }}
          alignItems={"center"}
          justifyContent={"center"}
          align-items="center"
          m={{ base: "30px 0 0 0", md: "0" }}
          px={{ base: 8, md: 28 }}
          overflowY={"scroll"}
          position="relative"
        >
          <Box display={{ md: "none" }} borderRadius={20}>
            <Center p={2}>
              <Image src={AppLogo} />
            </Center>
          </Box>
          <Heading
            pt={8}
            display={"flex"}
            justifyContent="center"
            flexWrap="nowrap"
            fontWeight={600}
            fontSize="32px"
            color={"black"}
          >
            Create Account
          </Heading>
          <Text py={8} textAlign={"center"}>
            Sign up on Sojorne and become more productive, <br /> focused,
            organized.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={errors.firstName}>
              <FormLabel htmlFor="firstName" fontWeight={600}>
                First Name
              </FormLabel>
              <Input
                id="firstName"
                placeholder="e.g Simon"
                {...register("firstName", {
                  required: "This is required",
                })}
                borderColor={"#E3E4E8"}
              />
              <FormErrorMessage>
                {errors.firstName && errors.firstName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={errors.lastName} fontWeight={600}>
              <FormLabel htmlFor="name">Last name</FormLabel>
              <Input
                id="lastName"
                placeholder="e.g Anderson"
                {...register("lastName", {
                  required: "This is required",
                })}
                borderColor={"#E3E4E8"}
              />
              <FormErrorMessage>
                {errors.lastName && errors.lastName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={errors.familyName} fontWeight={600}>
              <FormLabel htmlFor="name">
                Family Name{"  "}
                <Tooltip
                  bg={"#fff"}
                  color={"#000"}
                  label="Choose a name for your account ( your home base) that you, your child, and your secondary caregivers will be assigned to. We recommend using your last name or your jorner's last name (your kiddo or loved one that brought you to Sojorne)"
                >
                  <InfoOutlineIcon />
                </Tooltip>
              </FormLabel>
              <Input
                id="familyName"
                placeholder="e.g Simon's"
                {...register("familyName", {
                  required: "This is required",
                })}
                borderColor={"#E3E4E8"}
              />
              <FormErrorMessage>
                {errors.familyName && errors.familyName.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              mt={5}
              isInvalid={errors.emailAddress}
              fontWeight={600}
            >
              <FormLabel htmlFor="name">Email Address</FormLabel>
              <Input
                id="emailAddress"
                placeholder="e.g. jane@sojorne.com"
                {...register("emailAddress", {
                  required: "This is required",
                  validate: {
                    matchPattern: (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      "Email address must be a valid address",
                  },
                })}
                borderColor={"#E3E4E8"}
              />
              <FormErrorMessage>
                {errors.emailAddress && errors.emailAddress.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={errors.password} fontWeight={600}>
              <FormLabel htmlFor="name">Password</FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  type={show ? "text" : "password"}
                  placeholder="e.g Sojorne1234$"
                  {...register("password", {
                    required: "This is required",
                    minLength: {
                      value: 8,
                      message: "Minimum length should be 8",
                    },
                  })}
                  borderColor={"#E3E4E8"}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? <FaEye /> : <FaEyeSlash />}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <FormHelperText fontSize={12} color={"#747A8B"} fontWeight={400}>
                Minimum of 8 characters
              </FormHelperText>
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              mt={5}
              isInvalid={errors.confirm_password}
              fontWeight={600}
            >
              <FormLabel htmlFor="name">Confirm Password</FormLabel>
              <InputGroup>
                <Input
                  id="confirm_password"
                  type={show1 ? "text" : "password"}
                  placeholder="Confirm password"
                  {...register("confirm_password", {
                    required: "",
                  })}
                  borderColor={"#E3E4E8"}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick1}>
                    {show1 ? <FaEye /> : <FaEyeSlash />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.confirm_password && errors.confirm_password.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={5} isInvalid={errors.agree} fontWeight={600}>
              <Flex>
                <input
                  type="checkbox"
                  id="agree"
                  placeholder="I agree to Sojorne's Terms of Service & Privacy Policy"
                  {...register("agree", {
                    required: "This is required",
                  })}
                />
                <Text ml={3}>
                  I agree to Sojorne's{" "}
                  <Text
                    as={"span"}
                    color={"secondary"}
                    onClick={gotoTerms}
                    cursor={"pointer"}
                  >
                    Terms of Service
                  </Text>{" "}
                  &{" "}
                  <Text
                    as={"span"}
                    color={"secondary"}
                    onClick={gotoPrivacy}
                    cursor={"pointer"}
                  >
                    Privacy Policy
                  </Text>
                </Text>
              </Flex>

              <FormErrorMessage>
                {errors.agree && errors.agree.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={5}   fontWeight={600}>
              <Flex>
                <input
                  type="checkbox"
                  id="sms"
                  placeholder="I agree to Sojorne's Terms of Service & Privacy Policy"
                  {...register("sms")}
                />
                <Text ml={3}>
                  I agree to recieve text messages about medications reminders and/or care circles messages.
                </Text>
              </Flex>
            </FormControl>

            <Button
              mt={10}
              mb={4}
              size={"lg"}
              w={"100%"}
              color="#fff"
              borderRadius={64}
              background={"primary"}
              isLoading={isSubmitting}
              type="submit"
            >
              Next
            </Button>
            {
              isPayment ?
              <Text
              textAlign={"center"}
              fontWeight={400}
              color={"black"}
              fontSize={16}
              m={3}
            >
                <ReachLink to={"//sojorne.com"}> Back to Sojorne</ReachLink>
                </Text>

                :
                <Text
                  textAlign={"center"}
                  fontWeight={400}
                  color={"black"}
                  fontSize={16}
                  m={3}
                >
                  Already have an account?
                  <Text as={"span"} color={"primary"}>
                    <ReachLink to={loginRoute}> Log In</ReachLink>
                  </Text>
                </Text>
            }
          </form>
        </Box>
      </Flex>
    </>
  );
}

export default Signup;
