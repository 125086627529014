import React from "react";
import {
  Box,
  Image,
  Heading,
  Text,
  Flex,
  Input,
  Button,
  useToast,
  Icon,
} from "@chakra-ui/react";
import AppLink from "../../components/link";
import { imagePhoto, noise } from "../../images";
import { httpPutWithTokenRest } from "../../utils/http_utils";
import { capitalizeFirstWord } from "../../utils/utils";
import { DeleteIcon } from "@chakra-ui/icons";
import { ShareIcon } from "@heroicons/react/24/solid";

function JornerHeader({
  jorner,
  onOpenRemove,
  onOpenShare,
  source = "main",
  showButton = true,
  finishSelection = null,
}) {
  const [profileImageUrl, setProfileImageUrl] = React.useState(
    jorner ? jorner.avatar : ""
  );
  const [profileImage, setProfileImage] = React.useState(null);
  const [profileImageRes, setProfileImageRes] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const toast = useToast();
  const imageRef = React.useRef();
  const chooseFile = () => {
    imageRef.current.click();
  };

  const imageHandler = async (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImageRes(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        event.target.files = null;
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const updateJornerAvatar = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("avatar", profileImageRes);
    var response = await httpPutWithTokenRest(
      "caregivers/jorners/" + jorner.id,
      formData
    );
    if (response.error) {
      toast({
        title: "Sorry, we hit a snag!",
        description: `${response.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return false;
    } else {
      // console.log(response);
      finishSelection();
      setProfileImageUrl(profileImage);
      setProfileImage(null);
      setProfileImageRes(null);
      toast({
        title: "Jorner avatar uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);

      return true;
    }
  };
  return (
    <Flex
      alignItems={"center"}
      gap="4"
      flexDirection={{ base: "column", md: "row" }}
    >
      <Box
        borderRadius={"14px"}
        width={"100%"}
        position={"relative"}
        background={
          source == "main" &&
          "linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(180deg, #7353BA 0%, #6E47C6 100%);"
        }
      >
        {source == "main" && (
          <Image
            position={"absolute"}
            left="0"
            top="0"
            height={"100%"}
            zIndex="1"
            width="100%"
            src={noise}
          ></Image>
        )}
        <Box
          h="100%"
          display={"flex"}
          py={{
            base: source == "main" ? "10px" : "2px",
            md: source == "main" ? "30px" : "4px",
          }}
          position="sticky"
          zIndex="10"
          flexDirection="column"
          justifyContent={"center"}
          alignItems="center"
        >
          {source == "main" && showButton && (
            <Flex
              position={{ base: "static", md: "absolute" }}
              top="4"
              width={"100%"}
              padding="0 10px"
              gap={"2"}
              justifyContent={{ base: "space-between", md: "end" }}
            >
              <Box
                onClick={() => onOpenRemove()}
                display={{ base: "block", md: "none" }}
              >
                <Icon
                  color={"rgba(255, 255, 255, 0.34)"}
                  as={DeleteIcon}
                  fontSize="20px"
                />
              </Box>
              <Box display={{ base: "none", md: "block" }}>
                <AppLink
                  height="34px"
                  shadow="none"
                  fontSize="14px"
                  padding="0 15px"
                  text="Remove Jorner"
                  background="rgba(255, 255, 255, 0.34)"
                  color="white"
                  textDecoration="none"
                  onclick={onOpenRemove}
                  href="#?"
                />
              </Box>

              <Box
                onClick={() => onOpenShare()}
                display={{ base: "block", md: "none" }}
              >
                <Icon
                  color={"rgba(255, 255, 255, 0.34)"}
                  as={ShareIcon}
                  fontSize="20px"
                />
              </Box>
              <Box display={{ base: "none", md: "block" }}>
                <AppLink
                  height="34px"
                  shadow="none"
                  fontSize="14px"
                  padding="0 15px"
                  text="Share Profile"
                  background="white"
                  color="black"
                  textDecoration="none"
                  href="#?"
                  onclick={onOpenShare}
                />
              </Box>
            </Flex>
          )}
          <Input
            ref={imageRef}
            type={"file"}
            accept="image/*"
            onChange={(e) => imageHandler(e)}
            hidden
          ></Input>

          <>
            {profileImageUrl || profileImage ? (
              <Box
                onClick={() => (showButton ? chooseFile() : {})}
                position={"relative"}
              >
                <Image
                  shadow={"md"}
                  src={profileImage ? profileImage : profileImageUrl}
                  boxSize={source === "main" ? "85px" : "100px"}
                  borderRadius={"100%"}
                />
                {showButton && (
                  <Image
                    src={imagePhoto}
                    boxSize="35"
                    position={"absolute"}
                    bottom="0"
                    right="-2"
                  />
                )}
              </Box>
            ) : (
              <Box
                onClick={() => (showButton ? chooseFile() : {})}
                position={"relative"}
                fontWeight="600"
                color="white"
                boxSize={source === "main" ? "85px" : "100px"}
                borderRadius={"100%"}
                display="flex"
                background={"secondary"}
                alignItems="center"
                justifyContent="center"
              >
                <Text fontSize={"32px"}>
                  {jorner.first_name.substring(0, 1)}
                  {jorner.last_name.substring(0, 1)}
                </Text>
                {showButton && (
                  <Image
                    src={imagePhoto}
                    boxSize="35"
                    position={"absolute"}
                    bottom="0"
                    right="-2"
                  />
                )}
              </Box>
            )}
          </>
          {profileImage && (
            <Flex gap={2} my={"4"}>
              <Button
                isLoading={isLoading}
                onClick={updateJornerAvatar}
                size={"sm"}
                colorScheme={source == "main" ? "red" : "purple"}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setProfileImage(null);
                  setProfileImageRes(null);
                }}
                size={"sm"}
                colorScheme={source == "main" ? "primary" : "red"}
              >
                Cancel
              </Button>
            </Flex>
          )}
          {source === "main" && (
            <>
              <Box mb="10px" />
              <Heading
                color={"white"}
                mb="10px"
                fontSize="14px"
                fontWeight={"600"}
              >
                {jorner.first_name} {jorner.last_name}
              </Heading>
              <Text color={"white"} mb="10px" fontSize="12px">
                {capitalizeFirstWord(jorner.type)}
              </Text>
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
}

export default JornerHeader;
