import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useCheckboxGroup,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { healthcare } from "../../images";
import {
  capitalizeFirstWord,
  checkArraySimilarities,
  isObjEmpty,
} from "../../utils/utils";
import { FaChevronLeft } from "react-icons/fa";

export default function GoalsModal({
  isOpen,
  onClose,
  defaultV = [],
  goalsConstantsOriginal,
  onFinished,
  title = "Goals",
}) {
  const [goalsConstants, setGoalsConstants] = React.useState(
    goalsConstantsOriginal
  );
  const [disabilityList, setGoalsList] = React.useState([]);
  const [goalsListOriginal, setGoalsListOriginal] = React.useState([]);
  const [inputValue, setInputValue] = useState("");
  const [generalSearch, setGeneralSearch] = useState([]);
  const [showGeneralSearch, setShowGeneralSearch] = useState(false);
  const [showGoalList, setShowGoalsList] = React.useState(false);
  const [goalTitle, setGoalTitle] = React.useState(title);
  const { value: selectedGoals, getCheckboxProps } = useCheckboxGroup({
    defaultValue: defaultV.length > 0 ? defaultV : [],
  });
  useEffect(() => {
    setInputValue("");
    setShowGeneralSearch(false);
  }, [isOpen, goalsConstantsOriginal]);
  const searchGoalsConstants = (e) => {
    let keyword = e.target.value;
    setInputValue(keyword);
    let allListItems = [];
    const values = Object.values(goalsConstantsOriginal);
    values.map((list, i) => {
      allListItems = [...allListItems, ...list];
    });
    allListItems = [...new Set(allListItems)];
    if (keyword < 1) {
      setGoalsConstants(goalsConstantsOriginal);
    } else {
      let allGoals = goalsConstantsOriginal;
      let searchResult = allListItems.filter((e) =>
        e.toLowerCase().includes(keyword.toLowerCase())
      );
      setGeneralSearch(searchResult);
      setShowGeneralSearch(true);
      // setGoalsConstants(searchResult);
    }
  };

  const searchJornerDisabilityDetailsList = (e) => {
    let keyword = e.target.value;
    if (keyword < 1) {
      setGoalsList(goalsListOriginal);
    } else {
      let allDisabilityList = goalsListOriginal;
      let searchResult = allDisabilityList.filter((e) =>
        e.toLowerCase().includes(keyword.toLowerCase())
      );
      setGoalsList(searchResult);
    }
  };

  const handleShowGoalDetails = (disabilityKey) => {
    setGoalsList(goalsConstants[disabilityKey]);
    setGoalsListOriginal(goalsConstants[disabilityKey]);
    setGoalTitle(capitalizeFirstWord(disabilityKey.replaceAll("_", " ")));
    setShowGoalsList(true);
  };

  const reset = () => {
    setGoalsConstants(goalsConstantsOriginal);
  };

  useEffect(() => {
    if (!isObjEmpty(goalsConstantsOriginal)) {
      reset();
    }
  }, [goalsConstantsOriginal]);

  const finishSelection = () => {
    onFinished(selectedGoals);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"2xl"}
      isCentered
      scrollBehavior={"inside"}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        {!showGoalList ? (
          <>
            <ModalHeader display={"flex"} alignItems={"center"} w={"100%"}>
              {showGeneralSearch && (
                <FaChevronLeft
                  onClick={() => {
                    setShowGeneralSearch(false);
                    setInputValue("");
                    setGeneralSearch([]);
                  }}
                />
              )}
              <Text>{showGeneralSearch ? "Search Result" : title}</Text>
            </ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />
              <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {selectedGoals.length} selected
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  borderRadius={100}
                  value={inputValue}
                  onChange={searchGoalsConstants}
                  borderColor={"#E3E4E8"}
                />
              </InputGroup>
              {showGeneralSearch ? (
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                  {generalSearch.map((e, i) => (
                    <Checkbox
                      colorScheme="red"
                      style={{ color: "secondary" }}
                      {...getCheckboxProps({ value: e })}
                    >
                      <Text>{e}</Text>
                    </Checkbox>
                  ))}
                </SimpleGrid>
              ) : (
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3} mt={5}>
                  {Object.keys(goalsConstants).map((e, i) => (
                    <Box
                      key={`disability${i}`}
                      bg={"gray"}
                      cursor={"pointer"}
                      borderRadius={8}
                      p={3}
                      onClick={() => handleShowGoalDetails(e)}
                    >
                      <Image src={healthcare} />
                      <Text fontSize={14} fontWeight={500}>
                        {capitalizeFirstWord(e.replaceAll("_", " "))}
                      </Text>
                      <Text
                        color={"paragraph"}
                        fontSize={12}
                        fontWeight={500}
                        my={3}
                      >
                        {checkArraySimilarities(
                          selectedGoals,
                          goalsConstants[e]
                        )}{" "}
                        /{goalsConstants[e].length} Selected
                      </Text>
                    </Box>
                  ))}
                </SimpleGrid>
              )}
            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={finishSelection}
                >
                  Next
                </Button>
              </Box>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalHeader>
              <Flex alignItems={"center"}>
                <FaChevronLeft onClick={() => setShowGoalsList(false)} />
                <Text ml={2}>{goalTitle}</Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton>
              <Box
                p={2}
                mr={5}
                bg={"#F6F6F6"}
                borderRadius={100}
                color={"#17181C"}
                fontWeight={500}
                fontSize={14}
              >
                Close
              </Box>
            </ModalCloseButton>
            <ModalBody>
              <Divider color={"#EBECEF"} />
              <Text my={2} color={"secondary"} fontSize={12} fontWeight={600}>
                {checkArraySimilarities(selectedGoals, disabilityList)} selected
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  key={"searchList"}
                  borderRadius={100}
                  onChange={searchJornerDisabilityDetailsList}
                  borderColor={"#E3E4E8"}
                />
              </InputGroup>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                {disabilityList.map((e, i) => (
                  <Checkbox
                    colorScheme="red"
                    style={{ color: "secondary" }}
                    {...getCheckboxProps({ value: e })}
                  >
                    <Text>{e}</Text>
                  </Checkbox>
                ))}
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Box w={"160px"} float={"right"}>
                <Button
                  mt={8}
                  mb={4}
                  size={"lg"}
                  w={"100%"}
                  color="#fff"
                  borderRadius={64}
                  background={"primary"}
                  onClick={() => setShowGoalsList(false)}
                >
                  Proceed
                </Button>
              </Box>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
