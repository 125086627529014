import { Box, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { folderIcon, pdfIcon, wordIcon } from "../../images";
import { useEffect } from "react";

function DocIcon({ doc, source = 1 }) {
  const [imageIcon, setImageIcon] = useState("");
  useEffect(() => {
    pageIcon();
  }, []);

  const pageIcon = () => {
    let c = doc.content_type;
    let i;
    if (c.includes("image")) {
      i = doc.file_url;
    } else if (c == "application/pdf") {
      i = pdfIcon;
    } else if (c.includes("docx") || c.includes("doc")) {
      i = wordIcon;
    } else if(c == "folder") {
      i = folderIcon;
    }else{
      i = pdfIcon
    }

    setImageIcon(i);
  };
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={
        doc.content_type.includes("image")
          ? source == 1
            ? "flex-end"
            : "center"
          : "center"
      }
      height={`${176 - 69}px`}
    >
      <>
        {doc.content_type.includes("image") ? (
          <Image
            borderTopRadius={"10"}
            src={doc.file_url}
            height={source == 2 ? "74px" : "80%"}
            width={source == 2 ? "74px" : "80%"}
            objectFit={"cover"}
          />
        ) : (
          <Image
            boxSize={"72px"}
            alt={doc.content_type}
            objectFit={"contain"}
            src={imageIcon}
          />
        )}
      </>
    </Box>
  );
}

export default DocIcon;
