import { Box, Link, Flex, Heading, Image, Text, Icon } from "@chakra-ui/react";
import React from "react";
import { who } from "../../images";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { capitalizeFirstWord } from "../../utils/utils";

function JornerCard2({onclick = null, bg="white", key = 1, width = "250px", jorner }) {
  const navigate = useNavigate()
  return (
    <Box
    cursor={"pointer"}
    onClick={()=>{
      if(onclick){
        onclick()
      }else{
        navigate("/jorner/"+jorner.id)
      }
    }}
      width={{ base: "100%", md: width }}
      padding="12px 14px"
      alignItems="center"
      justifyContent={"center"}
      borderRadius={"16px"}
      border="1px solid #EBECEF"
      display="flex"
      shadow="sm"
      background={bg}
    >
      <Flex gap="3" flexDir={"column"} alignItems="center">
        {jorner.avatar ? (
          <Image
            boxSize={"109px"}
            borderRadius={"100%"}
            src={jorner.avatar}
          ></Image>
        ) : (
          <Box
            fontWeight="600"
            color="white"
            boxSize={"109px"}
            borderRadius={"100%"}
            display="flex"
            background={"warning"}
            alignItems="center"
            justifyContent="center"
          >
            {jorner.first_name.substring(0, 1)}
            {jorner.last_name.substring(0, 1)}
          </Box>
        )}
        <Box textAlign={"center"}>
          <Heading fontWeight={"500"} fontSize="15px">
            {jorner.first_name} {jorner.last_name}
          </Heading>
          <Box height="10px"></Box>
          <Text color="paragraph" fontSize="13px">
            {capitalizeFirstWord(jorner.type)}
          </Text>
        </Box>
      </Flex>
    
    </Box>
  );
}

export default JornerCard2;
