import React from "react";
import { communityImg, eventsImg, helpImg } from "../images";
import { Box, Center, Image, Text } from "@chakra-ui/react";
import Slider from "react-slick";

export default function AuthCarousel() {
  const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [slider, setSlider] = React.useState(null);
  const [currSlide, setCurrSlide] = React.useState(0);
  const cards = [
    {
      img: helpImg,
      text: "Sojorne helps you keep track of your dependents info, & their day-to-day life",
      color: "#65CBE9",
    },
    {
      img: eventsImg,
      text: "Create events and tasks. Monitor them, and stay-on top of your schedule",
      color: "#FFBA2D",
    },
    {
      img: communityImg,
      text: "Find and join a community group. Connect and bond over shared experiences",
      color: "#61F4DE",
    },
  ];

  return (
    <>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Box
        flex="1"
        // width={"50%"}
        minH={{ base: "calc(100vh - 30px)", md: "auto" }}
        position={{ base: "fixed", md: "static" }}
        display={{base: 'none', md: 'block'}}
        borderTopRadius={{ base: "20px", md: 0 }}
        width={{ base: "90%", md: "50%" }}
        background={"primary"}
        justifyContent={"center"}
        alignItems="center"
        m={{ base: "10px 5% 0", md: "0" }}
      >
        <Slider
          {...settings}
          ref={(slider) => setSlider(slider)}
          afterChange={(currentSlide) => setCurrSlide(currentSlide)}
        >
          {cards.map((e, i) => (
            <Box
              key={i}
              flex="1"
              zIndex={1}
              borderTopRadius={{ base: "20px", md: 0 }}
              width={{ base: "90%", md: "auto" }}
              background={e.color}
              position={{ base: "fixed", md: "static" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems="center"
              minH={"100vh"}
              m={{ base: "10px 5% 0", md: "0" }}
            >
              <Center>
                <Image src={e.img} height={"375px"} alt="" />
              </Center>
              <Center>
                <Text
                  fontSize={24}
                  fontWeight={600}
                  textAlign={"center"}
                  px={24}
                  py={10}
                >
                  {e.text}
                </Text>
              </Center>

              <Center>
                <Box
                  w={currSlide === 0 ? "70px" : "20px"}
                  h={"15px"}
                  background={"#fff"}
                  borderRadius={32}
                ></Box>
                <Box w={2}></Box>
                <Box
                  w={currSlide === 1 ? "70px" : "20px"}
                  h={"15px"}
                  background={"#fff"}
                  borderRadius={32}
                ></Box>
                <Box w={2}></Box>
                <Box
                  w={currSlide === 2 ? "70px" : "20px"}
                  h={"15px"}
                  background={"#fff"}
                  borderRadius={32}
                ></Box>
              </Center>
            </Box>
          ))}
        </Slider>
      </Box>
    </>
  );
}
